<script setup>
import { computed } from 'vue'

import CButton from '@/components/CButton'

const emit = defineEmits(['remove', 'update:condition'])
const props = defineProps({
  condition: Object,
  facts: Object
})

const allFacts = computed(() => Object.values(props.facts).flat())
const value = computed({
  get: () => props.condition,
  set: value => emit('update:condition', value)
})
const options = computed(
  () => allFacts.value.find(fact => fact.path === value.value.fact)?.options || []
)
</script>

<template>
<div class="form__fields form__fields--row mb-2 rules-builder__condition">
  <div class="input">
    <div class="input__select mb-0">
      <select
        class="input__control"
        v-model="value.fact"
      >
        <option value="">[ Escolha ]</option>
        <optgroup
          :key="index"
          :label="index"
          v-for="(group, index) of facts"
        >
          <option
            :key="option.path"
            :value="option.path"
            v-for="option of group"
          >{{ option.label }}</option>
        </optgroup>
      </select>
    </div>
  </div>
  <div class="input">
    <div class="input__select mb-0">
      <select
        class="input__control"
        v-model="value.operator"
      >
        <option value="">[ Escolha ]</option>
        <option value="lt">Menor que</option>
        <option value="lte">Menor ou igual a</option>
        <option value="equals">Igual a</option>
        <option value="notEquals">Não é igual a</option>
        <option value="gt">Maior que</option>
        <option value="gte">Maior ou igual a</option>
        <option value="isAny">Está entre</option>
        <option value="contains">Contém</option>
      </select>
    </div>
  </div>
  <div class="input">
    <div
      class="input__select mb-0"
      v-if="!!options.length"
    >
      <select
        class="input__control"
        v-model="value.value"
      >
        <option value="">[ Escolha ]</option>
        <option
          :key="option.value"
          :value="option.value"
          v-for="option of options"
        >{{ option.label }}</option>
      </select>
    </div>
    <input
      class="input__control"
      type="text"
      v-else-if="!options.length && !['lt', 'lte', 'gt', 'gte'].includes(value.operator)"
      v-model="value.value"
    />
    <input
      class="input__control"
      type="text"
      v-else
      v-model.number="value.value"
    />
  </div>
  <CButton
    class="btn--icon rules-builder__button"
    @click="emit('remove', condition)"
    icon="clear"
  />
</div>
</template>
