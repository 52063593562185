import { getApiEndpoint, getBucketEndpoint } from '@/support/tenant'

import auth from '@/services/auth'
import { createStore } from 'vuex'
import distributionRules from '@/repositories/distribution-rules'
import { firstToUpperCase } from '@/support/string'
import parameters from '@/repositories/parameters'
import platformAccounts from '@/repositories/platform-accounts'
import pushNotificationsRepo from '@/repositories/push-notifications'
import quizQuestions from '@/repositories/quiz-questions'
import quizzes from '@/repositories/quizzes'
import quizzesRepo from '@/repositories/quizzes-v2'
import smsPlatformAccountsRepo from '@/repositories/sms-platform-accounts'
import webhooks from '@/repositories/webhooks'
import workspaces from '@/repositories/workspaces'

import accounts from './accounts'
import adsAccounts from './ads-accounts'
import adsCampaigns from './ads-campaigns'
import adsSchedules from './ads-schedules'
import dashboard from './dashboard'
import fields from './fields'
import pushAccounts from './push-accounts'
import pushNotifications from './push-notifications'
import quizzesV2 from './quizzes'
import roles from './roles'
import smsPlatformAccounts from './sms-platform-accounts'
import teams from './teams'
import users from './users'

export default createStore({
  modules: {
    accounts,
    adsAccounts,
    adsCampaigns,
    adsSchedules,
    dashboard,
    fields,
    quizzesV2,
    pushAccounts,
    pushNotifications,
    roles,
    smsPlatformAccounts,
    teams,
    users
  },

  state: () => ({
    apiEndpoint: getApiEndpoint(),
    app: {
      loaded: false,
      notices: []
    },
    auth: {
      user: auth.logged() ? auth.user() : {}
    },
    availableWebhooks: [],
    baseUrl: process.env.BASE_URL,
    bucketEndpoint: getBucketEndpoint(),
    distributionRules: [],
    parameters: [],
    platformAccounts: [],
    platformAccountsLightList: [],
    quizQuestions: [],
    quizzes: [],
    ui: {
      rightBarOpen: false,
      sidebarOpen: false
    },
    webhooks: [],
    workspaceId: Number.parseInt(localStorage.getItem('workspaceId') || 0),
    // workspaceId: 0,
    workspaces: []
  }),

  mutations: {
    addDistributionRule (state, { item }) {
      state.distributionRules.push(item)
    },

    addParameters (state, { item }) {
      state.parameters.push(item)
    },

    addPlatformAccount (state, { item }) {
      state.platformAccounts.push(item)
    },

    addQuiz (state, { item }) {
      state.quizzes.push(item)
    },

    addQuizQuestion (state, { item }) {
      state.quizQuestions.push(item)
    },

    addWebhook (state, { item }) {
      state.webhooks.push(item)
    },

    addWorkspace (state, { item }) {
      state.workspaces.push(item)
    },

    removeDistributionRule (state, { item }) {
      state.distributionRules.splice(state.distributionRules.findIndex(one => one === item), 1)
    },

    removeParameters (state, { item }) {
      state.parameters.splice(state.parameters.findIndex(one => one === item), 1)
    },

    removePlatformAccount (state, { item }) {
      state.platformAccounts.splice(state.platformAccounts.findIndex(one => one === item), 1)
    },

    removeQuiz (state, { item }) {
      state.quizzes.splice(state.quizzes.findIndex(one => one === item), 1)
    },

    removeQuizQuestion (state, { item }) {
      state.quizQuestions.splice(state.quizQuestions.findIndex(one => one === item), 1)
    },

    removeWebhook (state, { item }) {
      state.webhooks.splice(state.webhooks.findIndex(one => one === item), 1)
    },

    removeWorkspace (state, { item }) {
      state.workspaces.splice(state.workspaces.findIndex(one => one === item), 1)
    },

    replaceDistributionRule (state, { item, index }) {
      state.distributionRules.splice(index, 1, item)
    },

    replaceParameter (state, { item, index }) {
      state.parameters.splice(index, 1, item)
    },

    replacePlatformAccount (state, { item, index }) {
      state.platformAccounts.splice(index, 1, item)
    },

    replaceQuiz (state, { item, index }) {
      state.quizzes.splice(index, 1, item)
    },

    replaceQuizQuestion (state, { item, index }) {
      state.quizQuestions.splice(index, 1, item)
    },

    replaceWebhook (state, { item, index }) {
      state.webhooks.splice(index, 1, item)
    },

    replaceWorkspace (state, { item, index }) {
      state.workspaces.splice(index, 1, item)
    },

    setAuthUser (state, payload) {
      state.auth.user = payload
    },

    setAvailableWebhooks (state, payload) {
      state.availableWebhooks = payload
    },

    setDistributionRules (state, payload) {
      state.distributionRules = payload
    },

    setAppLoaded (state, loaded) {
      state.app.loaded = loaded
    },

    setParameters (state, payload) {
      state.parameters = payload
    },

    setPlatformAccounts (state, payload) {
      state.platformAccounts = payload
    },

    setPlatformAccountsLightList (state, payload) {
      state.platformAccountsLightList = payload
    },

    setQuizQuestions (state, payload) {
      state.quizQuestions = payload
    },

    setQuizzes (state, payload) {
      state.quizzes = payload
    },

    setWorkspaceId (state, payload) {
      state.workspaceId = payload
      localStorage.setItem('workspaceId', payload)
    },

    setWebhooks (state, payload) {
      state.webhooks = payload
    },

    setWorkspaces (state, payload) {
      state.workspaces = payload
    },

    toggleRightBar (state) {
      state.ui.rightBarOpen = !state.ui.rightBarOpen
    },

    toggleSidebar (state) {
      state.ui.sidebarOpen = !state.ui.sidebarOpen
    }
  },

  actions: {
    async destroyDistributionRule ({ commit }, data) {
      await distributionRules.destroy(data)
      commit('removeDistributionRule', { item: data })
    },

    async destroyParameter ({ commit }, data) {
      await parameters.destroy(data)
      commit('removeParameter', { item: data })
    },

    async destroyPlatformAccount ({ commit }, data) {
      await platformAccounts.destroy(data)
      commit('removePlatformAccount', { item: data })
    },

    async destroyQuiz ({ commit }, data) {
      await quizzes.destroy(data)
      commit('removeQuiz', { item: data })
    },

    async destroyQuizQuestion ({ commit }, data) {
      await quizQuestions.destroy(data)
      commit('removeQuizQuestion', { item: data })
    },

    async destroyWebhook ({ commit }, data) {
      await webhooks.destroy(data)
      commit('removeWebhook', { item: data })
    },

    async destroyWorkspace ({ commit }, data) {
      await workspaces.destroy(data)
      commit('removeWorkspace', { item: data })
    },

    async loadAppInitialState ({ commit, dispatch, getters, state }) {
      try {
        if (!state.roles.roles.length) {
          await dispatch('roles/loadRoles')
        }

        if (!state.workspaces.length) {
          await dispatch('loadWorkspaces')
        }

        if (getters.hasWorkspaces && !state.parameters.length) {
          await dispatch('loadParameters')
        }
      } catch (error) {
        console.error(error)
      } finally {
        commit('setAppLoaded', true)
      }
    },

    async loadAvailableWebhooks ({ commit }) {
      commit('setAvailableWebhooks', await webhooks.available())
    },

    async loadDistributionRules ({ commit }) {
      commit('setDistributionRules', await distributionRules.all())
    },

    async loadParameters ({ commit }) {
      commit('setParameters', await parameters.all())
    },

    async loadPlatformAccounts ({ commit }) {
      commit('setPlatformAccounts', await platformAccounts.all())
    },

    async loadPlatformAccountsLightList ({ commit }) {
      commit('setPlatformAccountsLightList', await platformAccounts.lightList())
    },

    async loadQuizQuestions ({ commit }) {
      commit('setQuizQuestions', await quizQuestions.all())
    },

    async loadQuizzes ({ commit }) {
      const result = (await quizzes.all()).map(quiz => ({
        ...quiz,
        quiz_questions: quiz.quiz_questions.map(question => question.pivot)
      }))

      commit('setQuizzes', result)
    },

    async loadWebhooks ({ commit, state }) {
      const result = (await webhooks.all()).map(webhook => ({
        ...webhook,
        webhookObject: state.availableWebhooks.find(one => one.id === webhook.webhook)
      }))

      commit('setWebhooks', result)
    },

    async loadWorkspaces ({ commit, dispatch, state }) {
      const result = await workspaces.all()
      const workspaceId = state.workspaceId || result[0]?.id

      if (workspaceId) {
        commit('setWorkspaces', result)
        dispatch('setWorkspaceId', workspaceId)
      }
    },

    async saveDistributionRule ({ commit, state }, data) {
      const item = await distributionRules.save(data)
      const index = state.distributionRules.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceDistributionRule' : 'addDistributionRule', { item, index })

      return item
    },

    async saveParameter ({ commit, state }, data) {
      const item = await parameters.save(data)
      const index = state.parameters.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceParameter' : 'addParameter', { item, index })

      return item
    },

    async savePlatformAccount ({ commit, state }, data) {
      const item = await platformAccounts.save(data)
      const index = state.platformAccounts.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replacePlatformAccount' : 'addPlatformAccount', { item, index })

      return item
    },

    async saveQuiz ({ commit, state }, data) {
      const item = await quizzes.save(data)
      const index = state.quizzes.findIndex(one => one.id === item.id)

      item.quiz_questions.forEach(question => {
        question.quiz_question_id = question.pivot.quiz_question_id
        question.order = question.pivot.order
      })

      commit(index >= 0 ? 'replaceQuiz' : 'addQuiz', { item, index })

      return item
    },

    async saveQuizQuestion ({ commit, state }, data) {
      const item = await quizQuestions.save(data)
      const index = state.quizQuestions.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceQuizQuestion' : 'addQuizQuestion', { item, index })

      return item
    },

    async saveWebhook ({ commit, state }, data) {
      const item = await webhooks.save(data)
      const index = state.webhooks.findIndex(one => one.id === item.id)
      item.webhookObject = state.availableWebhooks.find(one => one.id === item.webhook)

      commit(index >= 0 ? 'replaceWebhook' : 'addWebhook', { item, index })

      return item
    },

    async saveWorkspace ({ commit, dispatch, state }, data) {
      const item = await workspaces.save(data)
      const index = state.workspaces.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceWorkspace' : 'addWorkspace', { item, index })

      if (!state.workspaceId) {
        dispatch('setWorkspaceId', item.id)
      }

      return item
    },

    setWorkspaceId ({ commit, dispatch, state }, id) {
      const idChanged = state.workspaceId !== id

      commit('setWorkspaceId', id)

      ;[{
        key: 'distributionRules',
        repo: distributionRules
      }, {
        key: 'parameters',
        repo: parameters
      }, {
        key: 'platformAccounts',
        repo: platformAccounts
      }, {
        key: 'smsPlatformAccounts/smsPlatformAccounts',
        repo: smsPlatformAccountsRepo,
        action: 'smsPlatformAccounts/loadSmsPlatformAccounts'
      }, {
        key: 'smsPlatformAccounts/smsPlatformAccountsLightList',
        repo: smsPlatformAccountsRepo,
        action: 'smsPlatformAccounts/loadSmsPlatformAccountsLightList'
      }, {
        key: 'pushNotifications/notifications',
        repo: pushNotificationsRepo,
        action: 'pushNotifications/loadNotifications'
      }, {
        key: 'quizzesV2/quizzes',
        repo: quizzesRepo,
        action: 'quizzesV2/loadQuizzes'
      }, {
        key: 'quizzes',
        repo: quizzes
      }]
        .filter(item => !!item)
        .map(item => { item.repo.onWorkspace(id); return item })
        .filter(item => idChanged || !!state[item.key]?.length)
        .forEach(item => dispatch(item.action || 'load' + firstToUpperCase(item.key)))
    }
  },

  getters: {
    hasWorkspaces (state) {
      return !!state.workspaces.length
    }
  }
})
