<script setup>
import { computed, ref, watch } from 'vue'
import { required } from '@vuelidate/validators'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import * as obj from '@/support/object'

import CButton from '@/components/CButton'
import CInput from '@/components/components/form/Input'
import CModal from '@/components/Modal'
import CSelect from '@/components/components/form/Select'
import Toolbar from '@/components/Toolbar'

const emit = defineEmits(['close'])
const props = defineProps({
  items: Array,
  item: Object
})

const emptyItem = () => ({
  connection: {},
  id: null,
  lists: [],
  name: '',
  platform: undefined,
  workspace_id: null
})

const countries = [
  { id: 'za', name: 'África do Sul', code: '+27' },
  { id: 'ar', name: 'Argentina', code: '+54' },
  { id: 'au', name: 'Austrália', code: '+61' },
  { id: 'br', name: 'Brasil', code: '+55' },
  { id: 'ca', name: 'Canadá', code: '+1' },
  { id: 'cl', name: 'Chile', code: '+56' },
  { id: 'co', name: 'Colômbia', code: '+57' },
  { id: 'us', name: 'Estados Unidos', code: '+1' },
  { id: 'id', name: 'Indonésia', code: '+62' },
  { id: 'mx', name: 'México', code: '+52' },
  { id: 'nz', name: 'Nova Zelândia', code: '+64' },
  { id: 'ng', name: 'Nigéria', code: '+234' },
]
const platforms = [
  {
    hasLocalSending: false,
    id: 'Akna',
    name: 'Akna',
    auth: 'user-pass'
  },
  {
    hasLocalSending: true,
    id: 'Twilio',
    name: 'Twilio',
    auth: 'acc-token-serv'
  },
].filter(one => !!one)

const store = useStore()
const rules = computed(() => ({
  lists: { required },
  name: { required },
  platform: { required }
}))
const data = ref(emptyItem())
const v$ = useVuelidate(rules, data)
const platform = computed(() => platforms.find(one => one.id === data.value.platform) || {})

const addList = () => {
  data.value.lists.push({
    country: undefined,
    id: null,
    message: '',
    name: ''
  })
}

const removeList = list => {
  data.value.lists.splice(data.value.lists.findIndex(one => one === list), 1)
}

const close = () => {
  data.value = emptyItem()

  v$.value.$reset()
  emit('close')
}

const tryToSave = async () => {
  if (!await v$.value.$validate()) return

  await store.dispatch('smsPlatformAccounts/saveSmsPlatformAccount', data.value)
  close()
}

watch(
  () => props.item,
  item => data.value = obj.isEmpty(item) ? emptyItem() : obj.copy(item),
  { immediate: true }
)
</script>

<template>
<CModal
  @close="close"
  :closeable-by-backdrop="false"
  :show-close-button="true"
  :title="data.id ? `Editar Conta [#${data.id}]` : 'Adicionar Conta'"
>
  <form
    class="form"
    id="itemModalForm"
    @submit.prevent="tryToSave"
  >
    <fieldset>
      <div class="form__fields form__fields--row">
        <CInput
          id="itemName"
          label="Nome da conta"
          v-model.trim="data.name"
        >
          <span class="input__error" v-if="v$.name.$error">Informe o nome da conta</span>
        </CInput>
        <CSelect
          id="itemPlatform"
          label="Plataforma"
          :options="platforms"
          v-model="data.platform"
        >
          <span class="input__error" v-if="v$.platform.$error">Escolha uma plataforma</span>
        </CSelect>
      </div>
      <div
        class="form__fields form__fields--row"
        v-if="platform.auth === 'acc-token-serv'"
      >
        <CInput
          id="itemAccountId"
          label="Id da conta"
          v-model.trim="data.connection.account_id"
        ></CInput>
        <CInput
          id="itemToken"
          label="Token"
          v-model.trim="data.connection.token"
        ></CInput>
        <CInput
          id="itemServiceId"
          label="Id do serviço"
          v-model.trim="data.connection.service_id"
        ></CInput>
      </div>
      <div
        class="form__fields form__fields--row"
        v-if="platform.auth === 'user-pass'"
      >
        <CInput
          id="itemUser"
          label="Usuário na Plataforma"
          v-model.trim="data.connection.user"
        ></CInput>
        <CInput
          id="itemPassword"
          label="Senha na Plataforma"
          v-model.trim="data.connection.password"
        ></CInput>
      </div>
    </fieldset>

    <fieldset>
      <Toolbar title="Listas">
        <template #actions>
          <div class="toolbar__group">
            <CButton
              caption="Adicionar Lista"
              class="btn--secondary"
              @click="addList()"
              icon="add"
            />
          </div>
        </template>
      </Toolbar>

      <section>
        <div
          class="mb-6"
          :key="index"
          v-for="(list, index) in data.lists"
        >
          <div class="form__fields form__fields--row form__fields--center mb-2">
            <div class="input">
              <input class="input__control" placeholder="Id" type="text" v-model="list.id" :readonly="platform.hasLocalSending" />
            </div>
            <div class="input flex--2">
              <input class="input__control" placeholder="Nome" type="text" v-model="list.name" />
            </div>
            <template v-if="platform.hasLocalSending">
              <div class="input flex--2">
                <CSelect
                  :options="countries"
                  v-model="list.country"
                ></CSelect>
              </div>
              <div class="input">
                <label class="input__switch nowrap">
                  <input type="checkbox" v-model="list.use_ruler" />
                  <span>Usar régua</span>
                </label>
              </div>
            </template>
            <button
              class="btn btn--icon"
              @click="removeList(list)"
              type="button"
            ><span class="material-icons-outlined">clear</span></button>
          </div>
          <div
            class="form__fields mb-0"
            v-if="platform.hasLocalSending"
          >
            <div class="input flex--4 flex--col mb-2">
              <div class="input__group flex">
                <span class="input__control input__control--static px-2 nowrap">Mensagem / dia 1</span>
                <input
                  class="input__control"
                  placeholder="Mensagem"
                  type="text"
                  v-model="list.message"
                />
                <span class="input__control input__control--static px-2">{{ list.message.length }}</span>
              </div>
            </div>
            <template v-if="list.use_ruler">
              <div class="input flex--4 flex--col mb-2">
                <div class="input__group flex">
                  <span class="input__control input__control--static px-2 nowrap">Mensagem / dia 2</span>
                  <input
                    class="input__control"
                    placeholder="Mensagem"
                    type="text"
                    v-model="list.message_2"
                  />
                  <span class="input__control input__control--static px-2">{{ list.message_2?.length || 0 }}</span>
                </div>
              </div>
              <div class="input flex--4 flex--col mb-2">
                <div class="input__group flex">
                  <span class="input__control input__control--static px-2 nowrap">Mensagem / dia 3</span>
                  <input
                    class="input__control"
                    placeholder="Mensagem"
                    type="text"
                    v-model="list.message_3"
                  />
                  <span class="input__control input__control--static px-2">{{ list.message_3?.length || 0 }}</span>
                </div>
              </div>
              <div class="input flex--4 flex--col mb-2">
                <div class="input__group flex">
                  <span class="input__control input__control--static px-2 nowrap">Mensagem / dia 4</span>
                  <input
                    class="input__control"
                    placeholder="Mensagem"
                    type="text"
                    v-model="list.message_4"
                  />
                  <span class="input__control input__control--static px-2">{{ list.message_4?.length || 0 }}</span>
                </div>
              </div>
              <div class="input flex--4 flex--col">
                <div class="input__group flex">
                  <span class="input__control input__control--static px-2 nowrap">Mensagem / dia 5</span>
                  <input
                    class="input__control"
                    placeholder="Mensagem"
                    type="text"
                    v-model="list.message_5"
                  />
                  <span class="input__control input__control--static px-2">{{ list.message_5?.length || 0 }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <span class="input__error" v-if="v$.lists.$error">Adicione pelo menos 1 lista</span>
      </section>
    </fieldset>
  </form>

  <template #footer>
    <footer class="modal__footer">
      <CButton
        caption="Cancelar"
        @click="close"
      />
      <CButton
        caption="Salvar"
        class="btn--primary"
        form="itemModalForm"
        icon="save"
        type="submit"
      />
    </footer>
  </template>
</CModal>
</template>
