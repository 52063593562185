<template>
<AppLayout>
  <section class="layout__main parameters">
    <div class="container">
      <Toolbar title="Parâmetros" />

      <section class="card mb-12">
        <header class="card__header">
          <h4 class="caption card__title">Distribuição de Leads</h4>
        </header>
        <form
          class="card__body"
          :class="{ 'loading loading--lg': loading }"
          @submit.prevent="tryToSaveParameter(uniquenessParameter)"
          v-if="uniquenessParameter"
        >
          <div class="input">
            <label
              class="input__label"
              :for="uniquenessParameter.key"
            >{{ uniquenessParameter.name }}</label>
            <div class="input__select">
              <select
                class="input__control"
                :id="uniquenessParameter.key"
                name="lead_uniqueness_period"
                v-model="uniquenessParameter.value"
              >
                <option value="disabled">Desabilitado</option>
                <option value="5 days">5 dias</option>
                <option value="7 days">7 dias</option>
                <option value="10 days">10 dias</option>
                <option value="15 days">15 dias</option>
                <option value="20 days">20 dias</option>
                <option value="30 days">30 dias</option>
                <option value="45 days">45 dias</option>
                <option value="2 months">2 meses</option>
                <option value="3 months">3 meses</option>
                <option value="6 months">6 meses</option>
              </select>
            </div>
            <span class="input__help">Nesse período um mesmo lead/email será tratado como único.</span>
          </div>
          <CButton
            caption="Salvar"
            class="btn--primary"
            icon="save"
            type="submit"
          />
        </form>
      </section>

      <section class="card mb-12">
        <header class="card__header">
          <h4 class="caption card__title">Calendário de Aquecimento</h4>
        </header>
        <form
          class="card__body"
          :class="{ 'loading loading--lg': loading }"
          @submit.prevent="tryToSaveParameter(warmingParameter)"
          v-if="warmingParameter"
        >
          <div class="input">
            <label
              class="input__label"
              :for="warmingParameter.key"
            >{{ warmingParameter.name }}</label>
            <table class="table table--bordered-all table--as-excel">
              <thead>
                <tr>
                  <th>Semana</th>
                  <th>Dia</th>
                  <th>Quantidade de Leads</th>
                  <th>Quantidade de Emails</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :key="index"
                  v-for="(day, index) of warmingParameter.value"
                >
                  <td
                    rowspan="7"
                    v-if="shouldSpanWeekRows(day)"
                  >Semana <strong>{{ weekOfDay(day) }}</strong></td>
                  <td>
                    <input
                      class="input__control"
                      readonly="readonly"
                      v-model="day.day"
                    />
                  </td>
                  <td>
                    <input
                      class="input__control"
                      v-model.number="day.leads"
                    />
                  </td>
                  <td>
                    <input
                      class="input__control"
                      v-model.number="day.emails"
                    />
                  </td>
                  <td>
                    <CButton
                      class="btn--icon btn--ghost"
                      @click="removeWarmingScheduleDay(day)"
                      icon="delete"
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="5">
                    <CButton
                      caption="Adicionar Dia"
                      class="btn--secondary"
                      @click="addWarmingScheduleDay"
                      icon="add"
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <CButton
            caption="Salvar"
            class="btn--primary"
            icon="save"
            type="submit"
          />
        </form>
      </section>

      <section class="card mb-12">
        <header class="card__header">
          <h4 class="caption card__title">Split de SMS</h4>
        </header>
        <form
          class="card__body"
          :class="{ 'loading loading--lg': loading }"
          @submit.prevent="tryToSaveParameter(smsSplitParameter)"
          v-if="smsSplitParameter"
        >
          <CInput
            :id="smsSplitParameter.key"
            :label="smsSplitParameter.name"
            v-model.number="smsSplitParameter.value"
          >
            <span class="input__help">Quantidade de SMS enviadas até usar a conta padrão.</span>
          </CInput>
          <CButton
            caption="Salvar"
            class="btn--primary"
            icon="save"
            type="submit"
          />
        </form>
      </section>

      <section class="card mb-12">
        <header class="card__header">
          <h4 class="caption card__title">Id de Serviço Padrão Para Split de SMS</h4>
        </header>
        <form
          class="card__body"
          :class="{ 'loading loading--lg': loading }"
          @submit.prevent="tryToSaveParameter(smsDefaultServiceIdParameter)"
          v-if="smsDefaultServiceIdParameter"
        >
          <CInput
            :id="smsDefaultServiceIdParameter.key"
            :label="smsDefaultServiceIdParameter.name"
            v-model.number="smsDefaultServiceIdParameter.value"
          >
            <span class="input__help">Id de serviço da conta padrão para envios dos SMSs acima do split.</span>
          </CInput>
          <CButton
            caption="Salvar"
            class="btn--primary"
            icon="save"
            type="submit"
          />
        </form>
      </section>
    </div>
  </section>
</AppLayout>
</template>

<script>
import AppLayout from '@/components/layout/App'
import CButton from '@/components/CButton'
import CInput from '@/components/components/form/Input'
import { mapState } from 'vuex'
import Toolbar from '../components/Toolbar.vue'

export default {
  name: 'Parameters',

  components: {
    AppLayout,
    CButton,
    CInput,
    Toolbar
  },

  data () {
    return {
      loading: false,
      smsDefaultServiceIdParameter: {},
      smsSplitParameter: {},
      uniquenessParameter: {},
      warmingParameter: {}
    }
  },

  computed: mapState(['parameters']),

  watch: {
    parameters: {
      handler: 'setParameters',
      immediate: true
    }
  },

  methods: {
    addWarmingScheduleDay () {
      this.warmingParameter.value.push({
        day: this.warmingParameter.value[this.warmingParameter.value.length - 1].day + 1,
        leads: 0,
        emails: 0
      })
    },

    setParameters () {
      this.smsDefaultServiceIdParameter = this.parameters.find(one => one.key === 'sms-default-service-id')
      this.smsSplitParameter = this.parameters.find(one => one.key === 'sms-split-amount')
      this.uniquenessParameter = this.parameters.find(one => one.key === 'lead-uniqueness-period')
      this.warmingParameter = this.parameters.find(one => one.key === 'accounts-warming-schedule')
    },

    removeWarmingScheduleDay (day) {
      this.warmingParameter.value.splice(
        this.warmingParameter.value.findIndex(one => one === day),
        1
      )
      this.warmingParameter.value.forEach((one, index) => { one.day = index + 1 })
    },

    shouldSpanWeekRows (day) {
      const weekDayAsPercentage = (day.day / 7) - Math.trunc(day.day / 7)

      return Number.parseFloat(weekDayAsPercentage.toFixed(2)) === 0.14
    },

    async tryToSaveParameter (parameter) {
      await this.$store.dispatch('saveParameter', parameter)
    },

    weekOfDay (day) {
      return Math.ceil(day.day / 7)
    }
  }
}
</script>

<style lang="scss">
.table--as-excel {
  thead th:last-child {
    width: 1%;
  }

  tbody td {
    padding: 0;

    &[rowspan] {
      padding: 0 var(--space-2);
    }
  }

  tfoot td {
    text-align: right;
  }

  .input__control {
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
  }
}
</style>
