<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { findNodes } from './editor/support.js'
import { nextStep, previousStep, remove, resetSteps, setStore, toLeft, toRight } from './support.js'
import { useEditor } from './editor/editor.js'
import { useStore } from 'vuex'

import CButton from '@/components/CButton'
import Nodes from './editor/Nodes.vue'

const { nodes, select } = useEditor()

setStore(useStore())

const viewLargeScreen = ref(false)

const hasSteps = computed(() => findNodes(nodes.value, 'step').length > 1)

onMounted(() => previousStep())
onUnmounted(() => resetSteps())

function handleDelete (eve) {
  if (['email', 'text', 'textarea'].includes(eve.target.type)) return

  remove()
}
</script>

<template>
<section
  class="quiz-builder__board"
  @click.self="select()"
  @keyup.delete="handleDelete($event)"
  @keyup.down="toRight()"
  @keyup.up="toLeft()"
  tabindex="-1"
>
  <div class="quiz-builder__view-options">
    <div class="quiz-builder__viewport-options mb-3">
      <CButton
        class="btn--fit btn--ghost mr-2"
        @click="viewLargeScreen = false"
        icon="smartphone"
      />
      <CButton
        class="btn--fit btn--ghost"
        @click="viewLargeScreen = true"
        icon="laptop"
      />
    </div>
    <div
      class="quiz-builder__steps-navigator mb-3"
      v-if="hasSteps"
    >
      <CButton
        class="btn--fit btn--ghost mr-2"
        @click="previousStep()"
        icon="navigate_before"
      />
      <CButton
        class="btn--fit btn--ghost"
        @click="nextStep()"
        icon="navigate_next"
      />
    </div>
  </div>
  <div
    class="quiz-builder__board-screen quiz-window"
    :class="{ 'quiz-builder__board-screen--large': viewLargeScreen }"
  >
    <Nodes :nodes="nodes" />
  </div>
</section>
</template>

<style>
.quiz-builder__board {
  background: var(--color-black);
  box-shadow: inset 0 0 10px #00000005;
  grid-area: center;
  outline: 0;
  overflow: auto;
  padding: var(--space-3);
  position: relative;
}

.quiz-builder__view-options {
  left: var(--space-2);
  position: absolute;
  top: var(--space-4);
  z-index: 99;
}

.quiz-builder__board-screen {
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 640px;
  margin: 0 auto;
  overflow-x: visible;
  overflow-y: auto;
  transition: width 0.3s ease;
  width: 360px;
}

.quiz-builder__board-screen--large {
  max-width: 1110px;
  width: 100%;
}

.quiz-builder__board-screen .quiz__steps {
  min-height: 100px;
}

.quiz-builder__board-screen .quiz__step {
  min-width: 100%;
}

.quiz-window {
  color: #333;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

.quiz-window a {
  color: #33f;
  text-decoration: none;
}

.quiz-window img {
  display: inline;
}

.quiz {
  margin: 0 auto;
  max-width: 28.75rem;
  padding: 1rem;
  width: 100%;
}

.quiz__header {
  margin-bottom: 1.5rem;
}

.quiz__header-image {
  height: 1.5rem;
  margin-bottom: 0.5rem;
}

.quiz__step {
  margin-bottom: 3rem;
}

.quiz__element--active {
  animation: select ease 0.5s;
}

@keyframes select {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}
</style>
