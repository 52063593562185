import { flatten } from 'lodash'

export function combineCartesian (...args) {
  return args.reduce((a, b) => flatten(a.map(x => b.map(y => x.concat([y])))), [[]])
}

export function range (start, stop, step = 1) {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + (i * step)
  )
}
