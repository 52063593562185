import notifications from '@/repositories/push-notifications'

export default {
  namespaced: true,

  state: () => ({
    notifications: [],
    statistics: []
  }),

  mutations: {
    addNotification (state, { item }) {
      state.notifications.push(item)
    },

    removeNotification (state, { item }) {
      state.notifications.splice(state.notifications.findIndex(one => one === item), 1)
    },

    replaceNotification (state, { item, index }) {
      state.notifications.splice(index, 1, item)
    },

    setNotifications (state, payload) {
      state.notifications = payload
    },

    setStatistics (state, payload) {
      state.statistics = payload
    }
  },

  actions: {
    async destroyNotification ({ commit }, data) {
      await notifications.destroy(data)
      commit('removeNotification', { item: data })
    },

    async loadAllStatistics ({ commit }, options) {
      commit('setStatistics', await notifications.allStatistics(options))
    },

    async loadNotifications ({ commit }) {
      commit('setNotifications', await notifications.all())
    },

    async loadStatistics (_, id) {
      return await notifications.statistics(id)
    },

    async saveNotification ({ commit, state }, data) {
      const item = await notifications.save(data)
      const index = state.notifications.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceNotification' : 'addNotification', { item, index })

      return item
    }
  }
}
