<template>
<section class="card assets-selector">
  <header class="assets-selector__header">
    <div class="input">
      <input
        class="input__control"
        placeholder="..."
        type="text"
        v-model.trim="term"
      />
    </div>
  </header>
  <section
    class="assets-selector__entries"
    :class="{ loading }"
  >
    <article
      class="assets-selector__entry"
      @click="choose(entry)"
      :key="entry.url"
      v-for="entry of validEntries"
    >
      <img
        class="assets-selector__entry-thumb"
        :src="entry.url"
      />
      {{ entry.name }}
    </article>
  </section>
  <footer class="assets-selector__footer">
    <CButton
      caption="Cancelar"
      @click="emit('cancel')"
    />
  </footer>
</section>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import assets from '@/repositories/assets'
import CButton from '@/components/CButton'

const emit = defineEmits(['cancel', 'choose'])
const props = defineProps({
  baseUrl: String,
  folder: String
})

const entries = ref([])
const loading = ref(false)
const term = ref('')

const entriesObjects = computed(() => entries.value.map(entry => ({
  name: entry.split('/').pop(),
  url: fullUrl(entry)
})))

const validEntries = computed(() => (!term.value.length
  ? entriesObjects.value
  : entriesObjects.value.filter(entry => entry.name.includes(term.value))
))

watch(() => props.folder, loadAssets, { immediate: true })

function choose (entry) {
  emit('choose', entry.url)
}

function fullUrl (url) {
  return url.match(/^http/) ? url : `${props.baseUrl}${url}`
}

async function loadAssets () {
  loading.value = true
  entries.value = await assets.all(props.folder)
  loading.value = false
}
</script>

<style lang="scss">
.assets-selector {
  max-height: 250px;
  min-width: 300px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 99;
}

.assets-selector__header {
  padding: var(--space-3);

  .input {
    margin-bottom: 0;
  }
}

.assets-selector__entries {
  min-height: 50px;
  overflow-x: hidden;
  overflow-y: auto;
}

.assets-selector__entry {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: var(--space-1) var(--space-3);

  &:hover {
    background: var(--color-gray-lighter);
  }
}

.assets-selector__entry-thumb {
  background: #bbb;
  height: 32px;
  margin-right: var(--space-2);
  object-fit: cover;
  object-position: center;
  width: 32px;
}

.assets-selector__footer {
  display: flex;
  justify-content: flex-end;
  padding: var(--space-3);
}
</style>
