<script setup>
import {
  completeDaysWithOtherMonths,
  getAvailableMonths,
  getDaysInMonth,
  getWeekDays,
  isToday,
  shortcuts
} from '@/support/datetime'
import { computed, ref } from 'vue'
import { range } from '@/support/array'

import Day from './Day'

const emit = defineEmits([
  'cancel',
  'select',
  'update:modelValue',
])

const props = defineProps({
  isRange: Boolean,
  maxYear: {
    default: () => new Date().getFullYear() + 5,
    type: Number
  },
  minDate: Date,
  minYear: {
    default: () => new Date().getFullYear() - 5,
    type: Number
  },
  modelValue: [Array, Date, Object],
  showShortcuts: {
    default: true,
    type: Boolean,
  }
})

const start = (props.isRange ? props.modelValue?.from : props.modelValue) ?? new Date()
const weekDays = getWeekDays()
const availableMonths = getAvailableMonths()

const month = ref(start.getMonth())
const year = ref(start.getFullYear())

const availableYears = computed(() => range(props.minYear, props.maxYear))
const monthDays = computed(() => getDaysInMonth(year.value, month.value, props.minDate))
const days = computed(() => completeDaysWithOtherMonths(monthDays.value, year.value, month.value))
const hasNextMonth = computed(() => year.value === props.maxYear && month.value === 11)
const hasPrevMonth = computed(() => year.value === props.minYear && month.value === 0)
const value = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const isDayInRange = day => day.date >= value.value?.from && day.date <= value.value?.to

const isDaySelected = day => {
  if (props.isRange) {
    return isToday(value.value?.from, day.date) || isToday(value.value?.to, day.date)
  }

  return isToday(value.value, day.date)
}

const setValue = day => {
  if (props.isRange) {
    return setValueAsRange(day)
  }

  value.value = day.date
}

const setValueAsRange = day => {
  if (value.value?.from && !value.value?.to) {
    value.value = day.date < value.value.from
      ? { from: day.date, to: value.value.from }
      : { ...value.value, to: day.date }

    return
  }

  value.value = { from: day.date, to: null }
}

const setValueFromShortcut = shortcut => {
  value.value = shortcuts.find(one => one.value === shortcut).handler(shortcut)
  emit('select')
}

const toNextMonth = () => {
  const date = new Date(year.value, month.value, 1)
  date.setMonth(date.getMonth() + 1)

  if (date.getFullYear() <= props.maxYear) {
    year.value = date.getFullYear()
    month.value = date.getMonth()
  }
}

const toPrevMonth = () => {
  const date = new Date(year.value, month.value, 1)
  date.setMonth(date.getMonth() - 1)

  if (date.getFullYear() >= props.minYear) {
    year.value = date.getFullYear()
    month.value = date.getMonth()
  }
}
</script>

<template>
<section class="date-picker">
  <section
    class="date-picker__shortcuts"
    v-if="showShortcuts"
  >
    <span class="caption">Atalhos</span>
    <button
      class="btn btn--link date-picker__shortcut"
      @click="setValueFromShortcut(shortcut.value)"
      :key="shortcut.value"
      type="button"
      v-for="shortcut of shortcuts"
    >{{ shortcut.label }}</button>
  </section>
  <section class="date-picker__calendars">
    <header class="date-picker__header">
      <button
        class="date-picker__header-nav btn btn--link btn--fit"
        @click="toPrevMonth()"
        :disabled="hasPrevMonth"
        type="button"
      >
        <span class="icon">chevron_left</span>
      </button>
      <div>
        <select v-model.number="year">
          <option
            :key="item"
            :value="item"
            v-for="item of availableYears"
          >{{ item }}</option>
        </select>
        <select v-model.number="month">
          <option
            :key="index"
            :value="index"
            v-for="(item, index) of availableMonths"
          >{{ item }}</option>
        </select>
      </div>
      <button
        class="date-picker__header-nav btn btn--link btn--fit"
        @click="toNextMonth()"
        :disabled="hasNextMonth"
        type="button"
      >
        <span class="icon">chevron_right</span>
      </button>
    </header>
    <section class="date-picker__calendar">
      <span
        class="date-picker__week-day muted"
        :key="index"
        v-for="(day, index) in weekDays"
      >{{ day }}</span>
      <Day
        @click="setValue(day)"
        :day="day"
        :is-in-range="isDayInRange(day)"
        :is-selected="isDaySelected(day)"
        :key="index"
        v-for="(day, index) in days"
      />
    </section>
    <footer class="date-picker__footer">
      <button
        class="btn btn--ghost btn--fit mr-4"
        @click="emit('cancel')"
        type="button"
      >Cancelar</button>
      <button
        class="btn btn--link btn--fit"
        @click="emit('select')"
        :disabled="props.isRange && !value?.to"
        type="button"
      >Selecionar</button>
    </footer>
  </section>
</section>
</template>

<style scoped>
.date-picker {
  display: flex;
}

.date-picker__shortcuts {
  border-right: 1px solid var(--color-gray-lighter);
  display: flex;
  flex-direction: column;
  margin-right: var(--space-3);
  padding-right: var(--space-3);
}

.date-picker__shortcut {
  font-size: var(--caption-sm-size);
  font-weight: 400;
  height: auto;
  padding: 0;
  text-align: left;
  white-space: nowrap;
}

.date-picker__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-2);
  padding-bottom: var(--space-2);
}

.date-picker__header-nav:disabled {
  opacity: 0.25;
}

.date-picker__header select {
  appearance: none;
  border: 0;
  cursor: pointer;
  font-weight: 500;
  margin: 0;
  outline: 0;
  padding: 0 var(--space-1);
  text-align: center;
}

.date-picker__calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  overflow: hidden;
  text-align: center;
}

.date-picker__footer {
  margin-top: var(--space-2);
  padding-top: var(--space-2);
  text-align: right;
}
</style>
