<script setup>
import { computed } from 'vue'

import CButton from '@/components/CButton'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Object })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <CButton
    caption="Limpar estilos"
    @click.prevent="data = {}"
  />
</section>
</template>
