<script setup>
import { computed, ref, watch } from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  label: String,
  measureUnit: {
    default: 'rem',
    type: String
  },
  measureUnits: {
    default: () => ['rem', '%', 'px'],
    type: Array
  },
  modelValue: [Number, String],
  step: {
    default: 0.05,
    type: Number
  },
  zeroValue: {
    default: 'unset',
    type: String
  }
})

const localMeasureUnit = ref(props.measureUnit)

const data = computed({
  get () {
    return props.modelValue === props.zeroValue || props.modelValue === undefined
      ? '' : Number.parseFloat(props.modelValue)
  },
  set (value) { changeValue(value) }
})

watch(() => props.measureUnit, unit => { localMeasureUnit.value = unit }, { immediate: true })
watch(
  () => props.modelValue,
  value => {
    const parsed = value?.replace(/([-\d.,]|auto|unset)/g, '')

    if (parsed) {
      localMeasureUnit.value = parsed
    }
  },
  { immediate: true }
)

function changeMeasureUnit () {
  let index = props.measureUnits.indexOf(localMeasureUnit.value) + 1

  if (index === props.measureUnits.length) {
    index = 0
  }

  localMeasureUnit.value = props.measureUnits[index]
  changeValue(data.value)
}

function changeValue (value) {
  emit('update:modelValue', value === '' ? props.zeroValue : value + localMeasureUnit.value)
}
</script>

<template>
<label class="input">
  <span class="input__label">{{ label }}</span>
  <span
    class="input__suffix"
    @click="changeMeasureUnit()"
    v-if="localMeasureUnit"
  >{{ localMeasureUnit }}</span>
  <input
    class="input__control"
    type="number"
    :step="localMeasureUnit === 'px' ? 1 : step"
    v-model.number="data"
  />
</label>
</template>

<style scoped>
.input__label {
  background: var(--color-white);
  font-size: 0.6rem;
  margin-bottom: var(--space--2);
  margin-left: var(--space-1);
  padding: 0 var(--space-1);
  z-index: 1;
}

.input__control {
  padding-right: var(--space-2);
}

.input__suffix {
  color: var(--color-gray);
  cursor: pointer;
  font-size: var(--space-3);
  min-width: var(--space-5);
  position: absolute;
  right: var(--space-8);
  text-align: center;
  top: var(--space-5);
}
</style>
