<script>
export default {
  meta: {
    closed: true,
    custom: true
  }
}
</script>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  columns: Number,
  imagePosition: Number,
  nextText: String,
  question: Object
})

const isMultipleChoice = computed(() => props.question.response_type === 'multiple-choice')
</script>

<template>
<section
  class="quiz__question"
  :class="{ 'empty-children': !question }"
  data-placeholder="Clique aqui e configure a pergunta no painel [Opções da pergunta]"
  style="--placeholder-size:1rem"
>
  <slot />
  <template v-if="question">
    <p class="quiz__question-statement">{{ question.question }}</p>
    <img
      class="quiz__question-image"
      :src="question.configs.image"
      :style="{ order: imagePosition }"
      v-if="question.configs.image"
    >
    <div
      class="quiz__question-options"
      :class="['cols-' + columns]"
    >
      <label
        class="quiz__question-option"
        :key="idx"
        v-for="(option, idx) of question.response_options"
      >
        <input
          :name="`opt-${question.id}`"
          :type="isMultipleChoice ? 'checkbox' : 'radio'"
          :value="option.value"
        >
        <img
          class="quiz__question-option-image"
          :src="option.image"
          v-if="option.image"
        >
        <span>{{ option.label }}</span>
      </label>
    </div>
    <button
      class="quiz__question-next"
      type="button"
      v-if="isMultipleChoice"
    >{{ nextText }}</button>
  </template>
</section>
</template>

<style>
.quiz__question {
  text-align: center;
}

.quiz__question-statement {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.quiz__question-image {
  margin-bottom: 1.5rem;
  max-height: 5rem;
}

.quiz__question-options {
  --gap: 0.75rem;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: center;
}

.quiz__question-option {
  align-items: center;
  border: 1px solid #666;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  transition: all 0.3s ease;
  width: 100%;
}

.quiz__question-option:hover {
  background: #f5f5f5;
}

.cols-2 .quiz__question-option {
  width: calc((100% / 2) - (var(--gap) / 2));
}

.cols-3 .quiz__question-option {
  width: calc((100% / 3) - ((var(--gap) * 2) / 3));
}

.cols-4 .quiz__question-option {
  width: calc((100% / 4) - ((var(--gap) * 3) / 4));
}

.quiz__question-option input {
  display: none;
}

.quiz__question-option-image {
  margin-bottom: var(--space-2);
  max-height: 3rem;
}

.quiz__question-next {
  align-items: center;
  background: #666;
  border: 1px solid #666;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  transition: all 0.3s ease;
  width: 100%;
}

.quiz__question-next:hover {
  background: #888;
  border: 1px solid #888;
}
</style>
