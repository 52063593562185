<template>
<BlankLayout>
  <section class="layout__main login">
    <div class="container">
      <header class="toolbar">
        <h3 class="toolbar__title">
          <img
            alt="Logo LeadsHUB"
            class="brand-icon"
            :src="$store.state.baseUrl + 'logo-jbf.png'"
          />
          Leads HUB
        </h3>
      </header>

      <form
        class="card"
        @submit.prevent="login"
      >
        <section class="card__body">
          <Alert
            class="alert--danger alert--ghost mb-8"
            icon="error_outline"
            title="Usuário ou senha inválidos."
            v-if="error"
          />
          <div class="input">
            <label class="input__label" for="email">Email</label>
            <input class="input__control" id="email" type="text" v-model="credentials.email" />
          </div>
          <div class="input">
            <label class="input__label" for="password">Senha</label>
            <input class="input__control" id="password" type="password" v-model="credentials.password" />
          </div>
          <CButton
            caption="Entrar"
            class="btn--primary"
            :class="{ loading }"
            icon="check"
            type="submit"
          />
        </section>
      </form>
    </div>
  </section>
</BlankLayout>
</template>

<script>
import Alert from '@/components/Alert'
import auth from '@/services/auth'
import BlankLayout from '@/components/layout/Blank'
import CButton from '@/components/CButton'

export default {
  name: 'Login',

  components: {
    Alert,
    BlankLayout,
    CButton
  },

  data () {
    return {
      credentials: {
        email: '',
        password: ''
      },
      error: false,
      loading: false
    }
  },

  methods: {
    login () {
      this.error = false
      this.loading = true
      this.$store.commit('setAppLoaded', false)
      auth.login(this.credentials)
        .then(() => { this.$router.push({ name: 'dashboard' }) })
        .catch(() => { this.error = true })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style lang="scss">
.login {
  .container {
    max-width: 300px;
  }

  .toolbar {
    justify-content: center;
  }
}
</style>
