<script setup>
import { computed, ref, watch } from 'vue'
import { formatDate } from '@/support/datetime'

import Datepicker from '@/components/components/date-picker/Datepicker'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  id: String,
  isRange: Boolean,
  label: String,
  maxYear: {
    default: () => new Date().getFullYear() + 5,
    type: Number
  },
  minDate: Date,
  minYear: {
    default: () => new Date().getFullYear() - 5,
    type: Number
  },
  modelValue: [Array, Date, Object, String],
  name: String,
  nullable: {
    default: true,
    type: Boolean
  },
  placeholder: {
    default: '[ Selecione ]',
    type: String
  },
  right: Boolean,
  showShortcuts: {
    default: true,
    type: Boolean,
  }
})

const isOpen = ref(false)
const tempValue = ref(null)

const value = computed({
  get: () => typeof props.modelValue === 'string' ? new Date(props.modelValue) : props.modelValue,
  set: value => emit('update:modelValue', value)
})

const close = () => {
  isOpen.value = false
  tempValue.value = null
}

const select = () => {
  value.value = tempValue.value
  close()
}

watch(() => isOpen.value, val => {
  if (val) {
    tempValue.value = value.value
  }
})
</script>

<template>
<div class="input flex--col flex--1">
  <label
    class="input__label"
    @click="isOpen = !isOpen"
    :for="id"
    v-if="label"
  >{{ label }}</label>
  <div class="dropdown">
    <label
      class="input__control btn dropdown__trigger"
      @click="isOpen = !isOpen"
      :for="id"
    >
      <span class="icon primary mr-2">calendar_month</span>
      <template v-if="value && props.isRange">
        De {{ formatDate(value.from) }} até {{ formatDate(value.to) }}
      </template>
      <template v-else-if="value">{{ formatDate(value) }}</template>
      <template v-else>{{ props.placeholder }}</template>
    </label>
    <div
      class="card dropdown__panel dropdown__panel--fit px-3"
      :class="{ 'dropdown__panel--right': right }"
      v-if="isOpen"
    >
      <Datepicker
        @cancel="close()"
        class="mb-0"
        :is-range="props.isRange"
        :max-year="props.maxYear"
        :min-date="props.minDate"
        :min-year="props.minYear"
        @select="select()"
        :show-shortcuts="props.showShortcuts"
        v-model="tempValue"
      />
    </div>
  </div>
  <slot />
</div>
</template>

<style scoped>
.dropdown__trigger {
  color: inherit;
  display: flex;
  font-weight: 400;
  margin-bottom: 0;
  white-space: nowrap;
}
</style>
