<script setup>
import { computed } from 'vue'

const props = defineProps({
  condition: Object,
  facts: Object
})

const allFacts = computed(() => Object.values(props.facts).flat())
const options = computed(
  () => allFacts.value.find(fact => fact.path === props.condition.fact)?.options || []
)
const option = computed(
  () => options.value.find(opt => opt.value === props.condition.value) || {}
)
const operator = computed(() => ({
  lt: 'menor que',
  lte: 'menor ou igual a',
  equals: 'igual a',
  notEquals: 'não é igual a',
  gt: 'maior que',
  gte: 'maior ou igual a',
  isAny: 'está entre',
  contains: 'contém'
}[props.condition.operator]))
</script>

<template>
<div class="flex mb-1 rules-viewer__condition">
  <div class="fact">{{ allFacts.find(fact => fact.path === condition.fact).label }}</div>
  <div class="operator muted">{{ operator }}</div>
  <div class="value">{{ !!options.length ? option.label : condition.value }}</div>
</div>
</template>

<style scoped>
.operator {
  margin-inline: var(--space-2);
}
</style>
