import { getApiEndpoint } from '@/support/tenant'
import Repository  from './repository'

class AdsGroups extends Repository
{
  all () {
    return this.client.get(this.urlTo())
  }

  find (domain, id) {
    return this.client.get(this.urlTo(`${domain}/${id}`))
  }

  save (data) {
    const method = data.id ? 'put' : 'post'

    return this.client[method](this.urlTo(), data)
  }
}

export default new AdsGroups({
  base: getApiEndpoint(),
  path: 'ads-groups'
})
