import { range } from '@/support/array'

export function completeDaysWithOtherMonths (days, year, month) {
  const firstDay = new Date(year, month, 1)
  const lastDay = new Date(year, month, getDaysInMonthCount(year, month))
  const firstWeekDay = firstDay.getDay()
  const lastWeekDay = lastDay.getDay()
  const prevMonthDays = firstWeekDay === 0
    ? []
    : getDaysInMonth((month === 0 ? year - 1 : year), (month === 0 ? 11 : month - 1))
  const nextMonthDays = lastWeekDay === 6
    ? []
    : getDaysInMonth((month === 11 ? year + 1 : year), (month === 11 ? 0 : month + 1))
  const prevDays = prevMonthDays.slice(-firstWeekDay).map(day => ({ ...day, disabled: true }))
  const nextDays = nextMonthDays.slice(0, 6 - lastWeekDay).map(day => ({ ...day, disabled: true }))

  return [...prevDays, ...days, ...nextDays]
}

export function daysOffsetToInterval (offset, from = new Date()) {
  const to = new Date(from)

  to.setDate(to.getDate() + offset)

  return to < from
    ? { from: to , to: from }
    : { from, to }
}

export function formatDate (date) {
  if (!date) return date

  return (new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }))
    .format(new Date(date))
}

export function formatToDB (date) {
  if (!date) return date

  return (new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }))
    .format(new Date(date))
    .replace(/(\d\d)\/(\d\d)\/(\d\d\d\d)/, '$3-$2-$1')
}

export function getAvailableMonths () {
  return [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]
}

export function getDaysInMonth (year, month, minDate) {
  if (minDate) {
    minDate.setHours(0, 0, 0, 0)
  }

  return range(1, getDaysInMonthCount(year, month))
    .map(day => ({ date: new Date(year, month, day, 0, 0, 0, 0) }))
    .map(day => ({
      ...day,
      disabled: minDate ? day.date < minDate : false,
      isToday: isToday(day.date),
      isWeekend: [0, 6].includes(day.date.getDay())
    }))
}

export function getDaysInMonthCount (year, month) {
  if (month === 1 && isLeapYear(year)) return 29

  if (month === 1) return 28

  if ([3, 5, 8, 10].includes(month)) return 30

  return 31
}

export function getWeekDays () {
  return ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
}

export function isLeapYear (year) {
  return new Date(year, 1, 29).getDate() === 29
}

export function isToday (date, today = new Date()) {
  if (!date || !today) return

  return date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear()
}

export const shortcuts = [
  {
    value: 0,
    label: 'Hoje',
    handler: daysOffsetToInterval
  },
  {
    value: 'yesterday',
    label: 'Ontem',
    handler () {
      const interval = daysOffsetToInterval(-1)

      return {
        from: interval.from,
        to: interval.from
      }
    }
  },
  {
    value: -1,
    label: 'Ontem e Hoje',
    handler: daysOffsetToInterval
  },
  {
    value: 'week',
    label: 'Essa semana',
    handler () {
      const now = new Date()

      return {
        from: new Date(now.setDate(now.getDate() - now.getDay())),
        to: new Date(now.setDate(now.getDate() - now.getDay() + 6))
      }
    }
  },
  {
    value: 'last-week',
    label: 'Semana passada',
    handler () {
      const now = new Date()
      const lastWeekEnd = new Date(now.setDate(now.getDate() - now.getDay() - 1))

      return {
        from: new Date(lastWeekEnd.setDate(lastWeekEnd.getDate() - lastWeekEnd.getDay())),
        to: new Date(lastWeekEnd.setDate(lastWeekEnd.getDate() - lastWeekEnd.getDay() + 6))
      }
    }
  },
  {
    value: 'month',
    label: 'Esse mês',
    handler () {
      const now = new Date()

      return {
        from: new Date(now.getFullYear(), now.getMonth(), 1),
        to: new Date(
          now.getFullYear(),
          now.getMonth(),
          getDaysInMonthCount(now.getFullYear(), now.getMonth())
        )
      }
    }
  },
  {
    value: 'last-month',
    label: 'Mês passado',
    handler () {
      const now = new Date()
      const monthStart = new Date(now.getFullYear(), now.getMonth(), 1)
      const lastMonthEnd = new Date(monthStart.setMonth(monthStart.getMonth() - 1))

      return {
        from: new Date(lastMonthEnd.getFullYear(), lastMonthEnd.getMonth(), 1),
        to: new Date(
          lastMonthEnd.getFullYear(),
          lastMonthEnd.getMonth(),
          getDaysInMonthCount(lastMonthEnd.getFullYear(), lastMonthEnd.getMonth())
        )
      }
    }
  },
  {
    value: -7,
    label: 'Últimos 7 dias',
    handler: daysOffsetToInterval
  },
  {
    value: -15,
    label: 'Últimos 15 dias',
    handler: daysOffsetToInterval
  },
  {
    value: -30,
    label: 'Últimos 30 dias',
    handler: daysOffsetToInterval
  },
  {
    value: -45,
    label: 'Últimos 45 dias',
    handler: daysOffsetToInterval
  },
]
