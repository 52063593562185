<script setup>
import { format } from '@/support/number'
import { computed, ref } from 'vue'

import CButton from '@/components/CButton'

const props = defineProps({ item: Object })

const showLeadsCounts = ref(false)

const leadsActiveCount = computed(() => {
  return props.item?.statistics?.leads?.active
    ?.reduce((sum, list) => sum + (list.count || 0), 0) || 0
})

const listsLeadsActiveCount = computed(() => props.item?.statistics?.leads?.active?.map(list => ({
  ...list,
  name: props.item.lists.find(one => one.id === list.id)?.name
})))

const maximumEngaged = computed(() => format(props.item?.maximum_engaged))
</script>

<template>
<div class="muted small">
  <strong>{{ item.lists.length }}</strong>
  {{ item.lists.length === 1 ? 'lista' : 'listas' }}
</div>
<div class="muted small platform-accounts__active-leads">
  <strong>{{ leadsActiveCount.toLocaleString() }}</strong>
  {{ leadsActiveCount === 1 ? 'lead ativo' : 'leads ativos' }}
  <CButton
    class="btn--icon btn--fit btn--ghost platform-accounts__active-leads-lists-toggler"
    @click="showLeadsCounts = !showLeadsCounts"
    :icon="showLeadsCounts ? 'expand_less' : 'expand_more'"
  />
  <div
    class="card platform-accounts__active-leads-lists"
    v-if="showLeadsCounts"
  >
    <div
      :key="count.id"
      v-for="count of listsLeadsActiveCount"
    >
      {{ count.name }}:
      <strong>{{ count.count.toLocaleString() }}</strong>
      {{ count.count === 1 ? 'lead ativo' : 'leads ativos' }}
    </div>
  </div>
</div>
<div class="muted small">
  Máximo de engajados:
  <strong>{{ maximumEngaged }}</strong>
</div>
</template>

<style lang="scss">
.platform-accounts__active-leads {
  position: relative;
  white-space: nowrap;
}

.platform-accounts__active-leads-lists-toggler {
  margin-top: -2px;
}

.platform-accounts__active-leads-lists-toggler .icon {
  line-height: 0.5;
}

.platform-accounts__active-leads-lists {
  padding: var(--space-2);
  position: absolute;
  white-space: nowrap;
}
</style>
