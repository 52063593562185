<script setup>
import { computed, ref, watchEffect } from 'vue'
import { actions, days, facts, hours, minutes } from './support'
import { required } from '@vuelidate/validators'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import * as obj from '@/support/object'

import CButton from '@/components/CButton'
import CModal from '@/components/Modal'
import CSelect from '@/components/components/form/Select'
import RulesBuilder from '@/components/components/rules-builder/Builder'

const emit = defineEmits(['close'])
const props = defineProps({ item: Object })

const emptyRule = (id = 1) => ({
  id,
  criteria: {
    match: 'all',
    conditions: []
  }
})

const emptySchedule = () => ({
  action: null,
  // ads_account_id: null,
  ads_accounts: [],
  criterias: [emptyRule()],
  cron_expression: '0 0 * * *',
  id: null,
  is_active: false
})

const store = useStore()
const rules = computed(() => ({
  action: { required },
  ads_accounts: { required },
  // ads_account_id: { required },
  cron_expression: { required },
  is_active: { required }
}))
const schedule = ref(emptySchedule())
const v$ = useVuelidate(rules, schedule)
const accounts = computed(() => store.state.adsAccounts.accounts)
const scheduleHours = computed({
  get: () => schedule.value.cron_expression.split(' ')[1] || 0,
  set: value => schedule.value.cron_expression = [
    scheduleMinutes.value,
    value,
    scheduleDays.value,
  ].join(' ')
})
const scheduleMinutes = computed({
  get: () => schedule.value.cron_expression.split(' ')[0] || 0,
  set: value => schedule.value.cron_expression = [
    value,
    scheduleHours.value,
    scheduleDays.value,
  ].join(' ')
})
const scheduleDays = computed({
  get: () => schedule.value.cron_expression.split(' ').slice(2).join(' '),
  set: value => schedule.value.cron_expression = [
    scheduleMinutes.value,
    scheduleHours.value,
    value,
  ].join(' ')
})

const cleanUpValue = value => {
  if (value.criterias.length === 1 && !value.criterias[0].criteria.conditions.length) {
    return { ...value, criterias: [] }
  }

  return { ...value }
}

const close = () => {
  schedule.value = emptySchedule()

  v$.value.$reset()
  emit('close')
}

const tryToSave = async () => {
  if (!await v$.value.$validate()) return

  await store.dispatch('adsSchedules/saveSchedule', cleanUpValue(schedule.value))
  close()
}

watchEffect(() => {
  const item = obj.isEmpty(props.item) ? emptySchedule() : obj.copy(props.item)

  if (!item?.criterias?.length) {
    item.criterias = [emptyRule()]
  }

  const itemAccountsIds = item.ads_accounts.map(acc => acc.id)
  item.ads_accounts = accounts.value.filter(acc => itemAccountsIds.includes(acc.id))
  schedule.value = item
})
</script>

<template>
<CModal
  @close="close"
  :show-close-button="true"
  size="lg"
  :title="schedule.id ? `Editar Agendamento [#${schedule.id}]` : 'Adicionar Agendamento'"
>
  <form
    class="form"
    id="scheduleModalForm"
    @submit.prevent="tryToSave"
  >
    <fieldset>
      <CSelect
        id="scheduleAction"
        label="Ação"
        :options="actions"
        v-model="schedule.action"
      >
        <span class="input__error" v-if="v$.action.$error">Escolha uma ação</span>
      </CSelect>
      <div class="input flex--col">
        <label class="input__label">Nas contas</label>
        <div class="mb-0 accounts-list">
          <label
            class="input__checkbox"
            :key="account.id"
            v-for="account of accounts"
          >
            <input
              type="checkbox"
              :value="account"
              v-model="schedule.ads_accounts"
            />
            <span>{{ account.name }}</span>
          </label>
        </div>
        <span class="input__error" v-if="v$.ads_accounts.$error">Escolha pelo menos uma conta</span>
      </div>
      <!-- <div class="form__fields form__fields--row">
        <CSelect
          id="scheduleAdsAccountId"
          label="Da conta"
          :options="accounts"
          v-model="schedule.ads_account_id"
        >
          <span class="input__error" v-if="v$.ads_account_id.$error">Escolha uma conta</span>
        </CSelect>
      </div> -->
      <div class="form__fields form__fields--row">
        <div class="input flex--col flex--1">
          <label class="input__label" for="scheduleHours">Às</label>
          <div class="flex time-fields">
            <CSelect
              id="scheduleHours"
              :nullable="false"
              :options="hours()"
              v-model="scheduleHours"
            />
            <CSelect
              id="scheduleMinutes"
              :nullable="false"
              :options="minutes()"
              v-model="scheduleMinutes"
            />
          </div>
        </div>
        <CSelect
          id="scheduleDays"
          label="Nos dias"
          :nullable="false"
          :options="days"
          v-model="scheduleDays"
        />
      </div>
      <div class="input">
        <label class="input__switch nowrap">
          <input type="checkbox" v-model="schedule.is_active" />
          <span>Está ativa</span>
        </label>
      </div>
    </fieldset>

    <fieldset>
      <h5 class="mb-4">Critérios</h5>
      <RulesBuilder
        :facts="facts"
        :has-multiple-rules="false"
        :has-named-rules="false"
        v-model:rules="schedule.criterias"
      />
    </fieldset>
  </form>

  <template #footer>
    <footer class="modal__footer">
      <CButton
        caption="Cancelar"
        @click="close"
      />
      <CButton
        caption="Salvar"
        class="btn--primary"
        form="scheduleModalForm"
        icon="save"
        type="submit"
      />
    </footer>
  </template>
</CModal>
</template>

<style scoped>
.accounts-list {
  max-height: 120px;
  overflow: auto;
}

.time-fields {
  align-self: stretch;
}
</style>
