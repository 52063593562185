<script setup>
import { ref } from 'vue'

import CButton from '@/components/CButton'

defineProps({ item: Object })

const showAccounts = ref(false)
</script>

<template>
<div class="muted ads-schedules__accounts">
  <strong>{{ item.ads_accounts.length }}</strong>
  {{ item.ads_accounts.length === 1 ? 'conta' : 'contas' }}
  <CButton
    class="btn--icon btn--fit btn--ghost ads-schedules__accounts-lists-toggler"
    @click="showAccounts = !showAccounts"
    :icon="showAccounts ? 'expand_less' : 'expand_more'"
  />
  <div
    class="card ads-schedules__accounts-lists"
    v-if="showAccounts"
  >
    <span
      class="label label--fit mb-1 mr-1"
      :key="account.id"
      v-for="account of item.ads_accounts"
    >{{ account.name }}</span>
  </div>
</div>
</template>

<style lang="scss">
.ads-schedules__accounts {
  position: relative;
  white-space: nowrap;
}

.ads-schedules__accounts-lists-toggler {
  margin-top: -2px;
}

.ads-schedules__accounts-lists-toggler .icon {
  line-height: 0.5;
}

.ads-schedules__accounts-lists {
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 750px;
  padding: var(--space-2) var(--space-2) var(--space-1);
  position: absolute;
  white-space: nowrap;
  z-index: 9;
}
</style>
