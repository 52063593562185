export function format (number) {
  return (new Intl.NumberFormat('pt-BR')).format(number || 0)
}

export function formatCurrency (number) {
  const options = {
    currency: 'BRL',
    maximumFractionDigits: 2,
    style: 'currency'
  }

  return (new Intl.NumberFormat('pt-BR', options)).format(number || 0)
}

export function formatCurrencyUSD (number) {
  const options = {
    currency: 'USD',
    maximumFractionDigits: 2,
    style: 'currency'
  }

  return (new Intl.NumberFormat('en-US', options)).format(number || 0)
}

export function formatPercentage (number, digits = 2) {
  const options = {
    maximumFractionDigits: digits,
    style: 'percent'
  }

  return (new Intl.NumberFormat('pt-BR', options)).format(number || 0)
}
