<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useFields } from './conversions'
import { useStore } from 'vuex'

import Alert from '@/components/Alert'
import { CanvasRenderer } from 'echarts/renderers'
import { DatasetComponent, GridComponent, LegendComponent, TooltipComponent } from 'echarts/components'
import { init, use } from 'echarts/core'
import { FunnelChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'

use([
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  FunnelChart,
  TooltipComponent,
  UniversalTransition
])

const { fields } = useFields()
const store = useStore()
let chart = null
const chartEle = ref(null)

const data = computed(() => store.state.quizzesV2.conversionsStatistics)
const minStep = computed(() =>
  Math.min(...data.value.map(one => one.steps_count).filter(count => count > 0)))
const validFieldsKeys = computed(() => {
  return Object.keys(fields.value)
    .filter(key => (
      ['start', 'end'].includes(key)
      || (parseInt(key.replace('step', '')) <= minStep.value)
    ))
})
const showingPartialSteps = computed(() =>
  validFieldsKeys.value.length !== Object.keys(fields.value).length)
const validFieldsLabels = computed(() => validFieldsKeys.value.map(key => fields.value[key]))

const totalData = computed(() => data.value.reduce((acc, curr) => {
  validFieldsKeys.value.forEach(key => {
    acc[key] = (acc[key] ?? 0) + (curr[key] ?? 0)
  })

  return acc
}, {}))

const seriesData = computed(() => {
  return validFieldsKeys.value.map(key => ({
    value: totalData.value[key],
    name: fields.value[key]
  }))
})

const loading = false

const initChart = () => {
  chart = init(chartEle.value)

  chart.setOption({
    legend: {
      data: validFieldsLabels.value,
      left: 0,
      padding: 0
    },
    series: [
      {
        name: 'Funil',
        type: 'funnel',
        top: 60,
        left: 0,
        bottom: 0,
        width: '80%',
        gap: 0,
        data: seriesData.value
      },
    ],
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}'
    }
  })
}

const updateChart = () => {
  if (!chart) return

  chart.setOption({
    legend: { data: validFieldsLabels.value },
    series: [{ data: seriesData.value }]
  })
}

watch(() => data.value, updateChart, { immediate: true })

onMounted(initChart)
onUnmounted(() => {
  chart.dispose()
  chart = null
})
</script>

<template>
<article class="card">
  <header class="card__header">
    <h3 class="card__title caption">Funil de Conversões dos Quizzes</h3>
  </header>
  <section
    class="card__body"
    :class="{ 'loading loading--lg': loading }"
  >
    <div
      class="chart mb-6"
      ref="chartEle"
    ></div>
    <Alert
      class="alert--info alert--ghost"
      icon="info"
      :title="`O gráfico geral está exibindo apenas os dados dos passos presentes em todos os quizzes (Passo ${minStep}).`"
      v-if="showingPartialSteps"
    />
  </section>
</article>
</template>

<style lang="scss" scoped>
.chart {
  height: 250px;
}
</style>
