import schedules from '@/repositories/ads-schedules'

export default {
  namespaced: true,

  state: () => ({
    schedules: []
  }),

  mutations: {
    addSchedule (state, { item }) {
      state.schedules.push(item)
    },

    removeSchedule (state, { item }) {
      state.schedules.splice(state.schedules.findIndex(one => one === item), 1)
    },

    replaceSchedule (state, { item, index }) {
      state.schedules.splice(index, 1, item)
    },

    setSchedules (state, payload) {
      state.schedules = payload
    }
  },

  actions: {
    async destroySchedule ({ commit }, data) {
      await schedules.destroy(data)
      commit('removeSchedule', { item: data })
    },

    async loadSchedules ({ commit }) {
      commit('setSchedules', await schedules.all())
    },

    async saveSchedule ({ commit, state }, data) {
      const item = await schedules.save(data)
      const index = state.schedules.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceSchedule' : 'addSchedule', { item, index })

      return item
    }
  }
}
