<template>
<AppLayout>
  <section class="layout__main accounts">
    <div class="container">
      <Toolbar
        :has-primary-action="true"
        @primary-action="edit()"
        primary-action-caption="Adicionar Subconta"
        primary-action-icon="add"
        title="Subcontas"
      />

      <List
        :accounts="subaccounts"
        @destroy="confirmDestroy"
        @edit="edit"
        :loading="loading"
      />
    </div>
  </section>

  <AccountForm
    @close="account = null"
    :item="account"
    v-if="editing"
  />

  <CConfirm
    alert="Apagar uma subconta é uma ação crítica e irá apagar todos os dados relacionados a ela."
    @cancel="toDestroy = null"
    @confirmed="destroy()"
    v-if="confirmingDestroy"
  >
    <p>Tem certeza que deseja apagar a subconta <strong>{{ toDestroy.name }}</strong>?</p>
  </CConfirm>
</AppLayout>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

import AppLayout from '@/components/layout/App'
import CConfirm from '@/components/components/dialogs/Confirm'
import List from './List'
import AccountForm from './Form'
import Toolbar from '@/components/Toolbar'

const store = useStore()
const loading = ref(true)
const account = ref(null)
const toDestroy = ref(null)

const subaccounts = computed(() => store.getters['accounts/subaccounts'])
const confirmingDestroy = computed(() => !!toDestroy.value)
const editing = computed(() => !!account.value)

const confirmDestroy = account => toDestroy.value = account

const destroy = async () => {
  await store.dispatch('accounts/destroyAccount', toDestroy.value)
  toDestroy.value = null
}

const edit = (item = {}) => account.value = item

onBeforeMount(async () => {
  await store.dispatch('accounts/loadAccounts')
  loading.value = false
})
</script>
