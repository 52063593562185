<script setup>
import { canShowAttrs, canShowDataAttrs } from '../support.js'
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useEditor } from '../editor/editor.js'

import AnchorAttrs from './Anchor.vue'
import BasicAttrs from './Basic.vue'
import Collapsible from '@/components/Collapsible'
import DataAttrs from './Data.vue'
import DisplayAttrs from './Display.vue'
import EmailFieldAttrs from './EmailField.vue'
import FillAttrs from './Fill.vue'
import GateAttrs from './Gate.vue'
import ImgAttrs from './Img.vue'
import LayoutAttrs from './Layout.vue'
import MobileFieldAttrs from './MobileField.vue'
import NameFieldAttrs from './NameField.vue'
import OthersAttrs from './Others.vue'
import PositioningAttrs from './Positioning.vue'
import ProgressAttrs from './Progress.vue'
import QuestionAttrs from './Question.vue'
import SizingAttrs from './Sizing.vue'
import SpacingAttrs from './Spacing.vue'
import StrokeAttrs from './Stroke.vue'
import StyleAttrs from './Style.vue'
import StylesAttrs from './Styles.vue'
import TermsFieldAttrs from './TermsField.vue'
import TextAttrs from './Text.vue'

const { currentStyle, select, selected, stepsCount, currentStep } = useEditor()
const store = useStore()

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Object,
  section: String
})

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})

watch(() => currentStyle.value, value => { if (value) select() })
</script>

<template>
<section class="quiz-builder__attrs">
  <section class="quiz-builder__attrs-section">
    <StyleAttrs
      @cancel="currentStyle = null"
      @saved="currentStyle = null"
      :style-def="currentStyle"
      v-model="data.configs.styles"
      v-show="section === 'style' && currentStyle && !selected"
    />
    <BasicAttrs v-if="canShowAttrs()" />
    <Collapsible
      title="Opções da pergunta"
      v-if="canShowAttrs('question')"
    >
      <QuestionAttrs :questions="store.state.quizQuestions" />
    </Collapsible>
    <Collapsible
      title="Opções do nome"
      v-if="canShowAttrs('name')"
    >
      <NameFieldAttrs />
    </Collapsible>
    <Collapsible
      title="Opções do email"
      v-if="canShowAttrs('email')"
    >
      <EmailFieldAttrs />
    </Collapsible>
    <Collapsible
      title="Opções do telefone"
      v-if="canShowAttrs('mobile')"
    >
      <MobileFieldAttrs />
    </Collapsible>
    <Collapsible
      title="Opções do aceite"
      v-if="canShowAttrs('terms')"
    >
      <TermsFieldAttrs />
    </Collapsible>
    <Collapsible
      title="Opções da barra de progresso"
      v-if="canShowAttrs('progress')"
    >
      <ProgressAttrs />
    </Collapsible>
    <Collapsible
      title="Opções de coleta do lead"
      v-if="canShowAttrs('gate')"
    >
      <GateAttrs />
    </Collapsible>
    <Collapsible
      title="Opções do link"
      v-if="canShowAttrs('a')"
    >
      <AnchorAttrs v-model="selected.attrs" />
    </Collapsible>
    <Collapsible
      title="Opções da imagem"
      v-if="canShowAttrs('img')"
    >
      <ImgAttrs v-model="selected.attrs" />
    </Collapsible>
    <Collapsible
      title="Dados"
      v-if="canShowDataAttrs()"
    >
      <DataAttrs />
    </Collapsible>
    <Collapsible
      title="Espaçamentos"
      v-if="canShowAttrs()"
    >
      <SpacingAttrs v-model="selected.attrs.style" />
    </Collapsible>
    <Collapsible
      title="Tamanho"
      v-if="canShowAttrs()"
    >
      <SizingAttrs v-model="selected.attrs.style" />
    </Collapsible>
    <Collapsible
      title="Layout"
      v-if="canShowAttrs()"
    >
      <LayoutAttrs v-model="selected.attrs.style" />
    </Collapsible>
    <Collapsible
      title="Exibição"
      v-if="canShowAttrs()"
    >
      <DisplayAttrs
        :current-step="currentStep"
        :steps-count="stepsCount"
        v-model="selected.configs"
      />
    </Collapsible>
    <Collapsible
      title="Bordas"
      v-if="canShowAttrs()"
    >
      <StrokeAttrs v-model="selected.attrs.style" />
    </Collapsible>
    <Collapsible
      title="Preenchimento"
      v-if="canShowAttrs()"
    >
      <FillAttrs v-model="selected.attrs.style" />
    </Collapsible>
    <Collapsible
      title="Texto"
      v-if="canShowAttrs()"
    >
      <TextAttrs v-model="selected.attrs.style" />
    </Collapsible>
    <Collapsible
      title="Posicionamento"
      v-if="canShowAttrs() && false"
    >
      <PositioningAttrs v-model="selected.attrs.style" />
    </Collapsible>
    <Collapsible
      title="Estilos"
      v-if="canShowAttrs() && false"
    >
      <StylesAttrs v-model="selected.attrs.style" />
    </Collapsible>
    <Collapsible
      title="Outros"
      v-if="canShowAttrs()"
    >
      <OthersAttrs v-model="selected.attrs.style" />
    </Collapsible>
  </section>
</section>
</template>

<style>
.quiz-builder__attrs {
  display: flex;
  flex-direction: column;
  grid-area: right;
  height: calc(69vh - 2px);
  width: 300px;
}

.quiz-builder__attrs-section {
  flex: 1;
  overflow-x: visible;
  overflow-y: auto;
  padding: var(--space-4) 2px var(--space-4) var(--space-3);
}

.quiz-builder__attrs-collapsible-section .input__label {
  background: var(--color-white);
  font-size: 0.6rem;
  margin-bottom: var(--space--2);
  margin-left: var(--space-1);
  padding: 0 var(--space-1);
  z-index: 1;
}

.quiz-builder__attrs-collapsible-section .row {
  display: grid;
  gap: var(--space-4);
  grid-template-columns: 1fr 1fr;
}

.quiz-builder__attrs-collapsible-section .input__trigger {
  height: var(--space-8);
  right: var(--space-1);
  top: calc(var(--space-2) + 2px);
  width: var(--space-8);
}

.quiz-builder__attrs-collapsible-section .input--file .input__control--file {
  justify-content: right;
}

.quiz-builder__attrs-collapsible-section .input--file .assets-selector {
  min-width: 275px;
  width: 275px;
}
</style>
