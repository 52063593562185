import adsGroups from '@/repositories/ads-groups'
import campaigns from '@/repositories/ads-campaigns'

export default {
  namespaced: true,

  state: () => ({
    adsGroups: [],
    campaigns: []
  }),

  mutations: {
    replaceAdsGroup (state, { item, index }) {
      state.adsGroups.splice(index, 1, item)
    },

    replaceCampaign (state, { item, index }) {
      state.campaigns.splice(index, 1, item)
    },

    setAdsGroups (state, payload) {
      state.adsGroups = payload
    },

    setCampaigns (state, payload) {
      state.campaigns = payload
    }
  },

  actions: {
    async loadAdsGroup ({ commit, state }, data) {
      const result = await adsGroups.find(data.domain, data.id)
      const index = state.adsGroups.findIndex(one => one.id === result.id)
      const item = { ...state.adsGroups[index], status: result.status }

      if (index >= 0) {
        commit('replaceAdsGroup', { item, index })
      }

      return item
    },

    async loadAdsGroups ({ commit }) {
      commit('setAdsGroups', await adsGroups.all())
    },

    async loadCampaign ({ commit, state }, data) {
      const result = await campaigns.find(data.domain, data.id)
      const index = state.campaigns.findIndex(one => one.id === result.id)
      const item = { ...state.campaigns[index], status: result.status }

      if (index >= 0) {
        commit('replaceCampaign', { item, index })
      }

      return item
    },

    async loadCampaigns ({ commit }) {
      commit('setCampaigns', await campaigns.all())
    },

    async saveAdsGroup ({ commit, state }, data) {
      const result = await adsGroups.save(data)
      const index = state.adsGroups.findIndex(one => one.id === result.id)
      const item = { ...state.adsGroups[index], status: result.status }

      if (index >= 0) {
        commit('replaceAdsGroup', { item, index })
      }

      return item
    },

    async saveCampaign ({ commit, state }, data) {
      const result = await campaigns.save(data)
      const index = state.campaigns.findIndex(one => one.id === result.id)
      const item = { ...state.campaigns[index], status: result.status }

      if (index >= 0) {
        commit('replaceCampaign', { item, index })
      }

      return item
    }
  }
}
