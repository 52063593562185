<script setup>
import { computed } from 'vue'

import CInput from '@/components/components/form/Input'
import CSelect from '@/components/components/form/Select'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Object,
  quizzes: Array
})

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
</script>

<template>
<div>
  <div class="input">
    <label class="input__switch">
      <input type="checkbox" v-model="data.is_active" />
      <span>Está ativo</span>
    </label>
  </div>
  <div class="row" style="--gap: 0.5rem">
    <CSelect
      class="mb-0"
      label="Variante"
      option-value-prop="key"
      :options="quizzes"
      v-model="data.variation"
    />
    <CInput
      class="mb-0 flex--1"
      label="Percentual"
      :max="100"
      :min="0"
      type="number"
      v-model.number="data.variation_percentage"
    />
  </div>
</div>
</template>
