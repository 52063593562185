import { combineCartesian, range } from '@/support/array'

const timePartToOption = one => ({ id: one, name: one.toString().padStart(2, 0) })

export const hours = (from = 0, to = 23, step = 1) => range(from, to, step).map(timePartToOption)

export const minutes = (from = 0, to = 59, step = 5) => range(from, to, step).map(timePartToOption)

export const hoursAndMinutes = (from = 0, to = 23, hoursStep = 1, minutesStep = 30) => (
  combineCartesian(hours(from, to, hoursStep), minutes(0, 59, minutesStep))
    .map(item => ({
      id: item[0].name + ':' + item[1].name,
      name: item[0].name + ':' + item[1].name,
    }))
)
