import client from './http/client'
import { getApiEndpoint } from './tenant'
import { onBeforeMount, onBeforeUnmount, ref, watch } from 'vue'
import { useDocumentVisibility } from '@vueuse/core'

const channel = setupBroadcastChannel()

export function useAppVersion () {
  const visibility  = useDocumentVisibility()
  const showVersionWarning = ref(false)
  const isOnMac = window.navigator.userAgent.includes('Mac')
  const refreshShortcut = ref(isOnMac ? 'Command + Shift + R' : 'Ctrl + Shift + R')

  watch(visibility, current => { if (current === 'visible') checkVersion() })

  onBeforeMount(checkVersion)
  onBeforeUnmount(cleanUp)

  async function checkVersion () {
    const version = await fetchVersion()
    const localVersion = localStorage.getItem('appVersion')

    if (localVersion !== version.app) {
      showVersionWarning.value = true
      window.addEventListener('beforeunload', () => {
        localStorage.setItem('appVersion', version.app)
        channel.postMessage({ action: 'close' })
      })
    }
  }

  return {
    refreshShortcut,
    showVersionWarning
  }
}

function cleanUp () {
  channel.close()
}

function fetchVersion () {
  return client.get(getApiEndpoint() + 'version')
}

function setupBroadcastChannel () {
  const channel = new BroadcastChannel('hub')

  channel.addEventListener('message', eve => { if (eve.data.action === 'close') window.close() })

  return channel
}
