<script setup>
import { computed } from 'vue'

import ColorInput from '@/components/components/form/ColorInput'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Object })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <div class="row">
    <ColorInput
      class="mb-0"
      label="Cor"
      v-model="data.backgroundColor"
    />
  </div>
</section>
</template>
