<script setup>
import { useEditor } from '../editor/editor.js'

import CInput from '@/components/components/form/Input'

const { selected } = useEditor()
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <CInput
    class="mb-2"
    label="Título do campo"
    v-model="selected.configs.label"
  />
  <CInput
    class="mb-2"
    label="Texto do link de termos"
    v-model="selected.configs.termsLinkText"
  />
  <CInput
    class="mb-2"
    label="URL dos termos de uso"
    v-model="selected.configs.termsUrl"
  />
  <div class="input mb-2">
    <label class="input__switch">
      <input type="checkbox" v-model="selected.configs.required" />
      <span>É obrigatório</span>
    </label>
  </div>
  <CInput
    class="mb-0"
    label="Mensagem de validação"
    v-model="selected.configs.validationMessage"
  />
</section>
</template>
