<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { format, formatCurrency, formatPercentage } from '@/support/number'
import { formatDate } from '@/support/datetime'
import { useStore } from 'vuex'

import AppLayout from '@/components/layout/App'
import CButton from '@/components/CButton'
import Grid from '@/components/components/grids/Grid'
import Toolbar from '@/components/Toolbar'

const store = useStore()
const loading = ref(true)
const campaigns = computed(() => store.state.adsCampaigns.campaigns)

const activate = async campaign => {
  await store.dispatch('adsCampaigns/saveCampaign', {
    id: campaign.id,
    domain: campaign.domain,
    action: 'activate'
  })
}

const checkState = async campaign => {
  await store.dispatch('adsCampaigns/loadCampaign', campaign)
}

const formatProfitStyle = value => {
  const style = {
    color: '#500',
    fontWeight: 500
  }

  if (value === 0) {
    style.color = 'inherit'
  }

  if (value > 0) {
    style.color = '#050'
  }

  if (value < 0 && value >= -10) {
    style.color = '#550'
  }

  return style
}

const formatRoiStyle = value => {
  const style = {
    background: '#fdd',
    fontWeight: 500
  }

  if (value > 0) {
    style.background = '#dfd'
  }

  if (value <= 0 && value > -0.2) {
    style.background = '#ffd'
  }

  return style
}

const formatStatus = value => ({
  ACTIVE: 'Ativa',
  PAUSED: 'Pausada'
}[value])

const formatStatusStyle = value => {
  const style = { fontWeight: 500 }

  if (value === 'PAUSED') {
    style.color = '#a22'
  }

  if (value === 'ACTIVE') {
    style.color = '#2a2'
  }

  return style
}

const pause = async campaign => {
  await store.dispatch('adsCampaigns/saveCampaign', {
    id: campaign.id,
    domain: campaign.domain,
    action: 'pause'
  })
}

const refresh = async () => {
  loading.value = true
  await store.dispatch('adsCampaigns/loadCampaigns')
  loading.value = false
}

onBeforeMount(refresh)
</script>

<template>
<AppLayout>
  <section class="layout__main campaigns">
    <div class="container">
      <Toolbar title="Campanhas">
        <template #actions>
          <div class="toolbar__group">
            <router-link
              class="btn btn--secondary"
              :to="{ name: 'ads-groups' }"
            >AdGroups</router-link>
            <router-link
              class="btn btn--secondary"
              :to="{ name: 'ads-schedules' }"
            >Agendamento</router-link>
          </div>
        </template>
      </Toolbar>

      <article class="card">
        <section class="card__body">
          <div class="flex mb-4 table-controls">
            <CButton
              @click="refresh"
              icon="refresh"
            />
          </div>
          <div
            class="table-wrapper"
            :class="{ 'loading loading--lg': loading }"
          >
            <Grid
              :actions="[{
                event: 'check-state',
                icon: 'published_with_changes',
                showIf: row => row.source === 'facebook' && !row.status
              }, {
                event: 'activate',
                icon: 'play_arrow',
                showIf: row => row.source === 'facebook' && row.status === 'PAUSED'
              }, {
                event: 'pause',
                icon: 'pause',
                showIf: row => row.source === 'facebook' && row.status === 'ACTIVE'
              }]"
              @activate="activate"
              @check-state="checkState"
              :data="campaigns"
              :fields="{
                date: { label: 'Data', formatter: formatDate },
                domain: { label: 'Domínio' },
                source: { label: 'Fonte' },
                account_name: { label: 'Conta' },
                campaign_name: { label: 'Campanha' },
                impressions: {
                  aggregator: (carry, item) => carry + item.impressions,
                  label: 'Impressões',
                  formatter: format
                },
                clicks: {
                  aggregator: (carry, item) => carry + item.clicks,
                  label: 'Clicks',
                  formatter: format
                },
                conversions: {
                  aggregator: (carry, item) => carry + item.conversions,
                  label: 'Conversões',
                  formatter: format
                },
                conversion_rate: {
                  afterAggregator: carry => carry.clicks ? carry.conversions / carry.clicks : 0,
                  formatter: formatPercentage,
                  label: '% Conv.'
                },
                cpa: {
                  afterAggregator: carry => carry.conversions ? carry.spend / carry.conversions : 0,
                  formatter: formatCurrency,
                  label: 'CPA'
                },
                spend: {
                  aggregator: (carry, item) => carry + item.spend,
                  label: 'Investimento',
                  formatter: formatCurrency
                },
                revenue: {
                  aggregator: (carry, item) => carry + item.revenue,
                  label: 'Receita',
                  formatter: formatCurrency
                },
                profit: {
                  aggregator: (carry, item) => carry + item.profit,
                  formatter: formatCurrency,
                  label: 'Lucro',
                  styleFormatter: formatProfitStyle
                },
                roi: {
                  afterAggregator: carry => (carry.revenue - carry.spend) / (carry.spend || 1),
                  formatter: formatPercentage,
                  label: 'ROI',
                  styleFormatter: formatRoiStyle
                },
                status: {
                  formatter: formatStatus,
                  label: 'Status',
                  styleFormatter: formatStatusStyle
                }
              }"
              @pause="pause"
            />
          </div>
        </section>
      </article>
    </div>
  </section>
</AppLayout>
</template>

<style lang="scss" scoped>
.container {
  max-width: 100%;
}

.table-controls {
  align-items: center;
  justify-content: space-between;
}

.table-wrapper {
  max-height: 59vh;
  min-height: 40px;
  overflow: auto;
}
</style>
