<script setup>
import { computed } from 'vue'

import NumberField from '../editor/NumberField.vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Object })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})

const measureUnits = ['rem', '%', 'px', 'vh', 'vw']
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <div class="row">
    <NumberField
      class="mb-2"
      label="Largura"
      :measure-units="measureUnits"
      v-model="data.width"
    />
    <NumberField
      class="mb-2"
      label="Altura"
      :measure-units="measureUnits"
      v-model="data.height"
    />
  </div>
  <div class="row">
    <NumberField
      class="mb-2"
      label="Largura Mínima"
      :measure-units="measureUnits"
      v-model="data.minWidth"
    />
    <NumberField
      class="mb-2"
      label="Altura Mínima"
      :measure-units="measureUnits"
      v-model="data.minHeight"
    />
  </div>
  <div class="row">
    <NumberField
      class="mb-0"
      label="Largura Máxima"
      :measure-units="measureUnits"
      v-model="data.maxWidth"
    />
    <NumberField
      class="mb-0"
      label="Altura Máxima"
      :measure-units="measureUnits"
      v-model="data.maxHeight"
    />
  </div>
</section>
</template>
