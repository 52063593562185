<template>
<div class="input input--column input--file">
  <label
    class="input__label"
    :for="id"
    v-if="label"
  >{{ label }}</label>
  <AssetsSelector
    :base-url="baseUrl"
    @cancel="showSearch = !showSearch"
    @choose="chooseValue"
    :folder="folder"
    v-if="showSearch"
  />
  <div
    class="input__group flex"
    :class="{ loading: sending }"
  >
    <div class="input__control input__control--file">
      {{ filename }}
      <input
        :accept="accept"
        @change="setFile($event.target.files)"
        :id="id"
        type="file"
      />
    </div>
    <CButton
      class="btn--icon btn--secondary"
      @click="showSearch = !showSearch"
      icon="search"
    />
    <CButton
      class="btn--icon btn--danger"
      @click="remove"
      icon="clear"
    />
  </div>
  <span
    class="input__help"
    v-if="help"
  >{{ help }}</span>
</div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue'
import { useStore } from 'vuex'

import assets from '@/repositories/assets'
import AssetsSelector from '@/components/components/form/AssetsSelector'
import CButton from '@/components/CButton'

const store = useStore()

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  accept: String,
  folder: String,
  help: String,
  id: String,
  label: String,
  modelValue: String
})

const file = ref(null)
const sending = ref(false)
const showSearch = ref(false)

const baseUrl = computed(() => `${store.state.apiEndpoint}storage/`)
const filename = computed(() => file.value ? file.value.name : props.modelValue)
const value = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

function chooseValue (url) {
  setValue(url)
  showSearch.value = false
}

function fullUrl (url) {
  return url.match(/^http/) ? url : `${baseUrl.value}${url}`
}

function remove () {
  file.value = null
  value.value = ''
}

function setFile (files) {
  if (!files.length) return

  file.value = files[0]
  sending.value = true

  assets.upload({ file: file.value, folder: props.folder })
    .then(response => {
      setValue(response.url)
      sending.value = false
    })
}

function setValue (url) {
  file.value = null
  value.value = fullUrl(url)
}
</script>

<style lang="scss">
.input--file {
  .input__group {
    align-self: stretch;
    margin-bottom: var(--space-1);
    max-width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    .input__control {
      margin-bottom: 0;
    }

    > *:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    > *:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    > *:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }

  .input__control--file {
    align-items: center;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    input {
      bottom: 0;
      cursor: pointer;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
}
</style>
