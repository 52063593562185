<script setup>
import { actionLabel, cronLabel, facts } from './support'
import { computed } from 'vue'
import groupBy from 'lodash/groupBy'

import AccountsColumn from './AccountsColumn'
import EmptyState from '@/components/utilities/EmptyState'
import ListGrid from '@/components/components/grids/ListGrid'
import RulesViewer from '@/components/components/rules-builder/Viewer'

const emit = defineEmits(['destroy', 'edit'])

const props = defineProps({
  items: Array,
  loading: Boolean
})

const itemsByAction = computed(() => groupBy(props.items, 'action'))
</script>

<template>
<section
  class="data-cards-list"
  :class="{ 'loading loading--lg': loading }"
>
  <section
    class="schedules-group"
    :key="index"
    v-for="(byAction, index) of itemsByAction"
  >
    <h6>{{ actionLabel(index) }}</h6>
    <ListGrid
      :actions="[{
        caption: 'Editar',
        cssClass: 'btn--link btn--fit',
        event: 'edit'
      }, {
        caption: 'Apagar',
        cssClass: 'btn--link btn--fit',
        event: 'destroy'
      }]"
      :data="byAction"
      @destroy="emit('destroy', $event)"
      @edit="emit('edit', $event)"
      :fields="{
        id: {
          label: 'Id',
          style: { width: '100px' },
          styleFormatter: () => ({ color: 'var(--color-black-lighter)' })
        },
        accounts: {
          label: 'Nas Contas',
          sortable: false,
          value: item => item.ads_accounts.map(acc => acc.name).join(', ')
        },
        configuration: {
          label: 'Configuração',
          sortable: false,
          value: item => cronLabel(item.cron_expression)
        },
        criterias: {
          filterable: false,
          label: 'Critérios',
          sortable: false
        },
        is_active: {
          label: 'Ativa',
          style: { width: '100px' },
          value: item => item.is_active ? 'Ativa' : 'Inativa'
        }
      }"
    >
      <template #col-1="{ item }">
        <AccountsColumn :item="item" />
      </template>
      <template #col-3="{ item }">
        <RulesViewer
          :facts="facts"
          :has-named-rules="false"
          :rules="item.criterias"
          v-if="item.criterias?.length && item.criterias[0].criteria.conditions.length"
        />
        <small
          class="muted"
          v-else
        >A regra não contém critérios para aplicação.</small>
      </template>
      <template #col-4="{ item, value }">
        <span
          class="label label--fit"
          :class="{ 'label--success': item.is_active }"
        >{{ value }}</span>
      </template>
    </ListGrid>
    <hr />
  </section>
</section>

<EmptyState
  message="Nenhum Agendamento está cadastrado ainda ..."
  v-if="!loading && !items.length"
/>
</template>

<style scoped>
.schedules-group {
  margin-bottom: var(--space-6);
}

.schedules-group > .table {
  margin-bottom: var(--space-4);
}

.schedules-group hr {
  border: 0;
  border-top: 1px solid var(--color-primary);
  opacity: 0.3;
}

.schedules-group:last-child hr {
  display: none;
}
</style>
