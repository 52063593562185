import { range } from '@/support/array'

export const actions = [
  { id: 'ActivateAdSets', name: 'Ativar AdGroups' },
  { id: 'PauseAdSets', name: 'Pausar AdGroups' },
  { id: 'ActivateCampaigns', name: 'Ativar Campanhas' },
  { id: 'PauseCampaigns', name: 'Pausar Campanhas' },
]

export const days = [
  { id: '* * *', name: 'Todos os dias' },
  { id: '* * 1-5', name: 'De segunda a sexta' },
  { id: '* * 6,0', name: 'Aos sábados e domingos' },
  { id: '* * 1', name: 'Todas as segundas' },
  { id: '* * 5', name: 'Todas as sextas' },
]

export const facts = {
  'Padrão': [
    {
      label: 'Gasto',
      path: 'spend',
      type: 'float'
    },
    {
      label: 'Impressões',
      path: 'impressions',
      type: 'int'
    },
    {
      label: 'Conversões',
      path: 'conversions',
      type: 'int'
    },
    {
      label: 'CPA',
      path: 'cpa',
      type: 'float'
    },
    {
      label: 'Status',
      options: [
        { label: 'Ativo', value: 'ACTIVE' },
        { label: 'Arquivado', value: 'ARCHIVED' },
        { label: 'Apagado', value: 'DELETED' },
        { label: 'Pausado', value: 'PAUSED' },
      ],
      path: 'status',
      type: 'string'
    },
  ]
}

export const actionLabel = action => actions.find(one => one.id === action)?.name

export const cronLabel = cron => {
  const parts = cron.split(' ')

  return [
    'Às',
    `${parts[1].padStart(2, 0)}:${parts[0].padStart(2, 0)}`,
    days.find(one => one.id === parts.slice(2).join(' '))?.name.toLowerCase(),
  ].join(' ')
}

const timePartToOption = one => ({ id: one, name: one.toString().padStart(2, 0) })

export const hours = (from = 0, to = 23, step = 1) => range(from, to, step).map(timePartToOption)

export const minutes = (from = 0, to = 59, step = 5) => range(from, to, step).map(timePartToOption)
