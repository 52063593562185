<script setup>
import { computed } from 'vue'

import ColorInput from '@/components/components/form/ColorInput'
import CSelect from '@/components/components/form/Select'
import NumberField from '../editor/NumberField.vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Object })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <CSelect
    class="mb-2"
    label="Fonte"
    :options="[
      { id: 'inherit', name: 'Mesma do elemento pai' },
      { id: 'Raleway,Segoe UI,Tahoma,Geneva,Verdana,sans-serif', name: 'Raleway' },
      { id: 'Verdana', name: 'Verdana' },
      { id: 'Georgia', name: 'Georgia' },
      { id: 'sans-serif', name: 'sans-serif' },
      { id: 'serif', name: 'serif' },
    ]"
    v-model="data.fontFamily"
  />
  <div class="row mb-2">
    <CSelect
      class="mb-0"
      label="Espessura"
      :options="[
        { id: 'inherit', name: 'Mesmo do elemento pai' },
        { id: 300, name: 'Fina' },
        { id: 400, name: 'Normal' },
        { id: 600, name: 'Médio' },
        { id: 700, name: 'Negrito' },
      ]"
      v-model="data.fontWeight"
    />
    <CSelect
      class="mb-0"
      label="Estilo"
      :options="[
        { id: 'inherit', name: 'Mesmo do elemento pai' },
        { id: 'normal', name: 'Normal' },
        { id: 'italic', name: 'Itálico' },
      ]"
      v-model="data.fontStyle"
    />
  </div>
  <div class="row mb-2">
    <NumberField
      class="mb-0"
      label="Tamanho"
      v-model="data.fontSize"
    />
    <ColorInput
      class="mb-0"
      label="Cor"
      v-model="data.color"
    />
  </div>
  <div class="row">
    <NumberField
      class="mb-0"
      label="Altura da linha"
      v-model="data.lineHeight"
    />
    <CSelect
      class="mb-0"
      label="Alinhamento"
      :options="[
        { id: 'left', name: 'Esquerda' },
        { id: 'center', name: 'Centro' },
        { id: 'right', name: 'Direita' },
        { id: 'justify', name: 'Justificado' }
      ]"
      v-model="data.textAlign"
    />
  </div>
</section>
</template>
