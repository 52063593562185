<script setup>
import { assignNodesIds, emptyItem, emptyStyle, getDefaultStyles } from './support.js'
import { builderSections, placeCss, refreshQuestionsConfigs } from './builder/support.js'
import { findNodes } from './builder/editor/support.js'
import { getPageCriteriasFacts } from './builder/support.js'
import { computed, ref, watch } from 'vue'
import { useEditor } from './builder/editor/editor.js'
import { useStore } from 'vuex'
import * as obj from '@/support/object'

import AbTest from './builder/tools/AbTest'
import Ads from './builder/tools/Ads'
import Attrs from './builder/attrs/Attrs'
import Basic from './builder/tools/Basic'
import Board from './builder/Board'
import CButton from '@/components/CButton'
import CModal from '@/components/Modal'
import Components from './builder/tools/Components.vue'
import Pages from './builder/tools/Pages.vue'
import Style from './builder/tools/Style.vue'

const emit = defineEmits(['close'])
const props = defineProps({
  items: Array,
  item: Object
})

const { currentStyle, nodes, reset, selected } = useEditor()

const store = useStore()
const section = ref('basic')
const data = ref(emptyItem())
const refs = {
  basic: ref(null),
  pages: ref(null)
}

const quizzes = computed(() => store.state.quizzesV2.quizzes)

watch(
  () => props.item,
  item => {
    data.value = obj.isEmpty(item) ? emptyItem() : normalizeItemToEdit(obj.copy(item))
    nodes.value = data.value.configs.nodes
    placeCss(data.value.configs.styles)
    store.commit('quizzesV2/setQuizQuestions', data.value.quiz_questions)
  },
  { immediate: true }
)
watch(nodes, val => data.value.configs.nodes = val)

function close () {
  data.value = emptyItem()

  refs.basic.value.v$.$reset()
  refs.pages.value.v$.$reset()
  reset()
  emit('close')
}

function normalizeAbTestDataToSave (abTest = {}) {
  if (abTest.is_active === props.item.configs?.ab_test?.is_active) return abTest

  if (abTest.is_active === true) {
    abTest.start = new Date()
    abTest.end = null
  }

  if (abTest.is_active === false) {
    abTest.end = new Date()
  }

  return abTest
}

function normalizeItemToEdit (item) {
  const stylesIds = item.configs.styles.map(style => style.id)
  item.configs.styles = [
    ...item.configs.styles,
    ...getDefaultStyles().filter(style => !stylesIds.includes(style.id))
  ].sort((a, b) => a.name.localeCompare(b.name))
  assignNodesIds(item.configs.nodes)
  refreshQuestionsConfigs(item.configs.nodes, store.state.quizQuestions)
  item.configs.ab_test ??= {}
  item.configs.tracking ??= {}

  return item
}

function normalizeDataToSave (item) {
  item.quiz_questions = findNodes(item.configs.nodes, 'question')
    .map((node, idx) => ({
      configs: {},
      order: idx + 1,
      quiz_id: null,
      quiz_question_id: node.configs.question?.id ?? null
    }))
  item.configs.styles = item.configs.styles.filter(style => !obj.isEmpty(style.declarations))
  item.configs.ab_test = normalizeAbTestDataToSave(item.configs.ab_test)

  return item
}

async function tryToSave () {
  refs.basic.value.v$.$touch()
  refs.pages.value.v$.$touch()

  const isInvalid = !await refs.basic.value.v$.$validate()
    || !await refs.pages.value.v$.$validate()

  if (isInvalid) return

  await store.dispatch('quizzesV2/saveQuiz', normalizeDataToSave(obj.copy(data.value)))
  close()
}
</script>

<template>
<CModal
  @close="close"
  :closeable-by-backdrop="false"
  :show-close-button="true"
  :title="data.id ? `Editar Quiz [#${data.id}]` : 'Adicionar Quiz'"
>
  <form
    class="form"
    id="itemModalForm"
    novalidate
    @submit.prevent="tryToSave"
  >
    <div
      class="quiz-builder"
      :class="{
        'quiz-builder--attrs': selected || currentStyle,
        'quiz-builder--tools-lg': section === 'pages'
      }"
    >
      <section class="quiz-builder__tools">
        <nav class="quiz-builder__tools-sections">
          <CButton
            :class="{ 'btn--ghost': true, active: section === key }"
            @click.prevent="section = key"
            :key="key"
            v-for="(curr, key) of builderSections"
          >
            <span class="icon icon--prefix">{{ curr.icon }}</span>
            {{ curr.caption }}
            <span
              class="errors-count"
              v-if="refs[key]?.value?.v$.$errors.length"
            >{{ refs[key]?.value?.v$.$errors.length }}</span>
          </CButton>
        </nav>
        <section class="quiz-builder__tools-section">
          <Basic
            :ref="el => refs.basic.value = el"
            v-model="data"
            v-show="section === 'basic'"
          />
          <Components v-show="section === 'components'" />
          <Ads
            v-model="data.configs.wrapper"
            v-show="section === 'ads'"
          />
          <Pages
            :facts="getPageCriteriasFacts(store.state.quizzesV2.quizQuestions, store.state.quizQuestions)"
            :ref="el => refs.pages.value = el"
            v-model="data.configs.result_pages"
            v-show="section === 'pages'"
          />
          <Style
            @add="currentStyle = emptyStyle()"
            @edit="currentStyle = $event"
            v-model="data.configs.styles"
            v-show="section === 'style'"
          />
          <AbTest
            :quizzes="quizzes"
            v-model="data.configs.ab_test"
            v-show="section === 'abtest'"
          />
        </section>
      </section>
      <Board />
      <Attrs
        :section="section"
        v-if="selected || currentStyle"
        v-model="data"
      />
    </div>
  </form>

  <template #footer>
    <footer class="modal__footer">
      <CButton
        caption="Cancelar"
        @click="close"
      />
      <CButton
        caption="Salvar"
        class="btn--primary"
        form="itemModalForm"
        icon="save"
        type="submit"
      />
    </footer>
  </template>
</CModal>
</template>

<style>
.quiz-builder {
  border-bottom: 1px solid var(--color-gray-light);
  border-top: 1px solid var(--color-gray-light);
  display: grid;
  grid-template-areas: 'left center right';
  grid-template-columns: auto 1fr auto;
  height: 69vh;
}

.quiz-builder__tools {
  display: flex;
  flex-direction: column;
  grid-area: left;
  height: calc(69vh - 2px);
  width: 300px;
}

.quiz-builder--tools-lg .quiz-builder__tools {
  width: 500px;
}

.quiz-builder__tools-sections {
  display: flex;
  flex-direction: column;
  margin: 0 var(--space-3) 0 var(--space--2);
  padding: var(--space-4) 0;
}

.quiz-builder__tools-sections .btn {
  border-radius: 0;
  padding: var(--space-2);
  position: relative;
}

.quiz-builder__tools-sections .active {
  color: var(--color-primary);
}

.quiz-builder__tools-sections .btn .errors-count {
  align-items: center;
  background: var(--color-danger);
  border-radius: 50%;
  color: var(--color-white);
  display: flex;
  font-size: 0.65rem;
  height: var(--space-4);
  justify-content: center;
  position: absolute;
  right: var(--space-2);
  text-align: center;
  width: var(--space-4);
}

.quiz-builder__tools-section {
  border-top: 1px solid var(--color-gray-light);
  flex: 1;
  overflow: auto;
  padding: var(--space-4) var(--space-3) var(--space-4) 2px;
}
</style>
