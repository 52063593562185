<script setup>
import { ref } from 'vue'

import CButton from '@/components/CButton'
import StatisticsPanel from './StatisticsPanel'

defineProps({ item: Object })

const showStatistics = ref(false)
</script>

<template>
<div class="small statistics">
  Estatísticas
  <CButton
    class="btn--icon btn--fit btn--ghost"
    @click="showStatistics = !showStatistics"
    :icon="showStatistics ? 'expand_less' : 'expand_more'"
  />
  <StatisticsPanel
    :item="item"
    v-if="showStatistics"
  />
</div>
</template>
