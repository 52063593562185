<script setup>
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  id: String,
  label: String,
  modelValue: String
})

const value = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
</script>

<template>
<div class="input">
  <label
    class="input__label"
    :for="id"
    v-if="label"
  >{{ label }}</label>
  <label
    class="input__trigger"
    :style="{ backgroundColor: value }"
  >
    <input
      type="color"
      v-model.trim="value"
    />
  </label>
  <input
    class="input__control"
    :id="id"
    maxlength="7"
    type="text"
    v-model.trim="value"
  />
</div>
</template>

<style lang="scss">
.input__trigger {
  background: #bbb;
  border-radius: 3px;
  cursor: pointer;
  height: var(--space-7);
  position: absolute;
  right: calc(var(--space-1) + 2px);
  top: var(--space-7);

  input {
    cursor: pointer;
    opacity: 0;
  }
}

input[type="color"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

input[type="color"]::-moz-color-swatch {
  border: none;
}
</style>
