<script setup>
import AppLayout from '@/components/layout/App'
import CampaignsStatisticsTableWidget from './widgets/CampaignsStatisticsTable'
import EmptyState from '@/components/utilities/EmptyState'
import LeadsAmountsChartWidget from './widgets/LeadsAmountsChart'
import LeadsAmountsTableWidget from './widgets/LeadsAmountsTable'
import PushNotificationsStatisticsTableWidget from '@/views/push-notifications/StatisticsTable'
import QuizzesConversionsFunnelChartWidget from '@/views/quizzes-v2/ConversionsFunnelChart'
import QuizzesConversionsStatisticsTableWidget from '@/views/quizzes-v2/ConversionsStatisticsTable'
import SmsErrorsAmountsTableWidget from './widgets/SmsErrorsAmountsTable'
import SmsLeadsAmountsTableWidget from './widgets/SmsLeadsAmountsTable'
import SmssOptOutStatisticsTableWidget from '@/views/sms-platform-accounts/OptOutStatisticsTable'

const isOnJBF = window.location.href.includes('jbfdigital') || window.location.href.includes('localhost')
</script>

<template>
<AppLayout>
  <section class="layout__main">
    <div class="container">
      <LeadsAmountsTableWidget class="mb-8" />
      <LeadsAmountsChartWidget class="mb-8" />
      <SmsLeadsAmountsTableWidget class="mb-8" />
      <SmsErrorsAmountsTableWidget class="mb-8" />
      <SmssOptOutStatisticsTableWidget class="mb-8" />
      <PushNotificationsStatisticsTableWidget class="mb-8" />
      <div class="row" style="--gap: 1rem" v-if="isOnJBF">
        <QuizzesConversionsFunnelChartWidget class="mb-8 flex--2" />
        <QuizzesConversionsStatisticsTableWidget class="mb-8 flex--5" />
      </div>
      <CampaignsStatisticsTableWidget v-if="isOnJBF" />
      <EmptyState icon="dashboard" v-if="false">
        <p class="empty-state__message">Seu dashboard está vazio.</p>
      </EmptyState>
    </div>
  </section>
</AppLayout>
</template>
