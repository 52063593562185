<script setup>
import { computed } from 'vue'

import CButton from '@/components/CButton'
import Rule from './Rule'

const emit = defineEmits(['update:rules'])
const props = defineProps({
  facts: Object,
  hasMultipleRules: {
    default: true,
    type: Boolean
  },
  hasNamedRules: {
    default: true,
    type: Boolean
  },
  rules: Array
})

const value = computed({
  get: () => props.rules,
  set: value => emit('update:rules', value)
})

function addRule () {
  value.value.push({
    id: value.value.length + 1,
    criteria: {
      match: 'all',
      conditions: []
    }
  })
}

function removeRule (rule) {
  value.value.splice(value.value.findIndex(one => one.id === rule.id), 1)
}
</script>

<template>
<section class="rules-builder">
  <header
    class="rules-builder__header"
    :class="{ 'mb-4': !!rules.length }"
    v-if="hasMultipleRules"
  >
    <CButton
      caption="Adicionar Regra"
      class="btn--secondary rules-builder__button"
      @click="addRule()"
      icon="add"
    />
  </header>

  <section>
    <Rule
      :facts="facts"
      :has-name="hasNamedRules"
      :is-removeable="hasMultipleRules"
      :key="rule.id"
      @remove="removeRule"
      v-model:rule="value[index]"
      v-for="(rule, index) of value"
    />
  </section>
</section>
</template>

<style lang="scss">
.rules-builder__rule {
  margin-bottom: var(--space-4);

  &:last-child {
    margin-bottom: 0;
  }
}

.rules-builder__criteria {
  padding-left: var(--space-4);
  position: relative;

  &::before {
    background: #bbb;
    content: '';
    height: calc(100% - 38px);
    left: 0;
    position: absolute;
    top: 20px;
    width: 2px;
  }

  & & .rules-builder__criteria-header::before {
    left: -32px;
    width: 34px;
  }

  .rules-builder__rule > & {
    margin-bottom: 0;
  }
}

.rules-builder__criteria-header,
.rules-builder__criteria-footer {
  position: relative;

  &::before {
    background: #bbb;
    content: '';
    height: 2px;
    left: -16px;
    position: absolute;
    top: 20px;
    width: 16px;
    z-index: 0;
  }

  > * {
    z-index: 1;
  }
}
</style>
