<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { daysOffsetToInterval, formatDate } from '@/support/datetime'
import { format, formatPercentage } from '@/support/number'
import { getApiEndpoint } from '@/support/tenant'
import { meanRate, sum } from '@tidyjs/tidy'
import { useStore } from 'vuex'
import without from 'lodash/without'

import FieldsSelector from '@/components/components/grids/FieldsSelector'
import Grid from '@/components/components/grids/SummaryGrid'

const store = useStore()
const availableFields = [
  { key: 'workspace_name', label: 'Workspace', selected: true },
  { key: 'account_name', label: 'Conta', selected: true },
  { key: 'list', label: 'Lista', selected: true },
]
const availableFieldsKeys = availableFields.map(field => field.key)
const shownFieldsKeys = availableFields.filter(field => field.selected).map(field => field.key)

const shownFields = ref(shownFieldsKeys)
const loading = ref(false)

const webhookUrl = computed(() => getApiEndpoint() + 'sms-inbound')
const data = computed(() => store.state.smsPlatformAccounts.optOutStatistics)
const hiddenFields = computed(() => without(availableFieldsKeys, ...shownFields.value))

onBeforeMount(load)

function copyToClipboard () {
  navigator.clipboard.writeText(webhookUrl.value)
}

async function load () {
  loading.value = true
  await store.dispatch('smsPlatformAccounts/loadOptOutStatistics')
  loading.value = false
}
</script>

<template>
<article class="card">
  <header class="card__header">
    <h3 class="card__title caption">OptOuts de SMS</h3>
  </header>
  <section class="card__body">
    <div class="flex mb-4 table-controls">
      <h6>Ontem, {{ formatDate(daysOffsetToInterval(-1).from) }}</h6>
      <div class="toolbar__group">
        <div class="dropdown">
          <label
            class="btn btn--link dropdown__trigger"
            for="optOutStatisticsSetupInstructions"
          >Instruções de setup</label>
          <input class="sensor" type="checkbox" id="optOutStatisticsSetupInstructions" />
          <div class="card dropdown__panel dropdown__panel--right setup-instructions">
            Para ver as métricas de Opt-Out da Twilio <strong>configure o
            webhook na parte de integração dos seus message services</strong>
            com a url:
            <span
              class="setup-instructions__url"
              @click="copyToClipboard()"
            >{{ webhookUrl }}</span> e <strong>ative o Opt-Out avançado em
            gerenciamento de Opt-Out</strong>.
          </div>
        </div>
        <FieldsSelector
          :fields="availableFields"
          id="optOutStatisticsTableFields"
          v-model="shownFields"
        />
      </div>
    </div>
    <div
      class="table-wrapper"
      :class="{ 'loading loading--lg': loading }"
    >
      <Grid
        class="table--statistics"
        :data="data"
        :except="hiddenFields"
        :fields="{
          workspace_name: { label: 'Workspace' },
          account_name: { label: 'Conta' },
          list: { label: 'Lista' },
          delivery_count: {
            aggregator: (carry, item) => carry + item.delivery_count,
            formatter: format,
            label: 'SMSs entregues'
          },
          opt_out_count: {
            aggregator: (carry, item) => carry + item.opt_out_count,
            formatter: format,
            label: 'OptOuts'
          },
          opt_out_ratio: {
            afterAggregator: carry => carry.delivery_count ? carry.opt_out_count / carry.delivery_count : 0,
            formatter: formatPercentage,
            label: 'Percentual de OptOut'
          }
        }"
        :summarize="{
          delivery_count: sum('delivery_count'),
          opt_out_count: sum('opt_out_count'),
          opt_out_ratio: meanRate('opt_out_count', 'delivery_count')
        }"
        :summary-fields="[
          'delivery_count',
          'opt_out_count',
          'opt_out_ratio',
        ]"
      />
    </div>
  </section>
</article>
</template>

<style lang="scss" scoped>
.table-controls {
  align-items: center;
  justify-content: space-between;

  > *:not(:last-child) {
    margin-right: var(--space-6);
  }
}

.table-wrapper {
  max-height: 500px;
  min-height: 40px;
  overflow: auto;
}

.disabled {
  opacity: 0.4;
}

.setup-instructions {
  width: 32rem;
}

.setup-instructions__url {
  background: var(--color-lighter-blue);
  border-radius: 0.25rem;
  color: var(--color-primary);
  cursor: pointer;
  padding: 0.1rem 0.5rem;
}
</style>
