<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import { daysOffsetToInterval } from '@/support/datetime'
import { format, formatPercentage } from '@/support/number'
import { meanRate, sum } from '@tidyjs/tidy'
import { useFields } from './conversions'
import { useStore } from 'vuex'
import without from 'lodash/without'

import CButton from '@/components/CButton'
import DateSelect from '@/components/components/form/DateSelect'
import FieldsSelector from '@/components/components/grids/FieldsSelector'
import Grid from '@/components/components/grids/SummaryGrid'

const { stepsFields } = useFields()
const store = useStore()
const availableFields = [
  { key: 'workspace_name', label: 'Workspace', selected: true },
  { key: 'quiz_name', label: 'Quiz', selected: true },
]
const availableFieldsKeys = availableFields.map(field => field.key)
const shownFieldsKeys = availableFields.filter(field => field.selected).map(field => field.key)

const period = ref(daysOffsetToInterval(-7))
const shownFields = ref(shownFieldsKeys)
const loading = ref(false)

const data = computed(() => store.state.quizzesV2.conversionsStatistics)
const hiddenFields = computed(() => without(availableFieldsKeys, ...shownFields.value))
const periodToRequest = computed(() => (!period.value ? {} : {
  from: period.value.from.toJSON(),
  to: period.value.to.toJSON()
}))

const stepsFieldsSummarizers = computed(() => {
  return Object.keys(stepsFields.value).reduce((acc, curr) => {
    acc[curr] = sum(curr)

    return acc
  }, {})
})

watch(() => period.value, load)

onBeforeMount(load)

async function load () {
  loading.value = true
  await store.dispatch('quizzesV2/loadConversionsStatistics', periodToRequest.value)
  loading.value = false
}
</script>

<template>
<article class="card">
  <header class="card__header">
    <h3 class="card__title caption">Conversões dos Quizzes</h3>
  </header>
  <section class="card__body">
    <div class="flex mb-4 table-controls">
      <div class="toolbar__group">
        <DateSelect
          class="mb-0"
          :is-range="true"
          v-model="period"
        />
      </div>
      <div class="toolbar__group">
        <CButton
          class="tooltip"
          @click="load()"
          data-tooltip="Atualizar"
          icon="refresh"
        />
        <FieldsSelector
          :fields="availableFields"
          id="conversionsStatisticsTableFields"
          v-model="shownFields"
        />
      </div>
    </div>
    <div
      class="table-wrapper"
      :class="{ 'loading loading--lg': loading }"
    >
      <Grid
        class="table--statistics"
        :data="data"
        :except="hiddenFields"
        :fields="{
          workspace_name: { label: 'Workspace' },
          quiz_name: { label: 'Quiz' },
          start: {
            aggregator: (carry, item) => carry + item.start,
            formatter: format,
            label: 'Sessões'
          },
          ...stepsFields,
          end: {
            aggregator: (carry, item) => carry + item.end,
            formatter: format,
            label: 'Conversões'
          },
          conversion_rate: {
            afterAggregator: carry => carry.start ? carry.end / carry.start : 0,
            formatter: formatPercentage,
            label: 'Percentual de Conversão'
          }
        }"
        :summarize="{
          start: sum('start'),
          ...stepsFieldsSummarizers,
          end: sum('end'),
          conversion_rate: meanRate('end', 'start')
        }"
        :summary-fields="[
          'start',
          ...Object.keys(stepsFields),
          'end',
          'conversion_rate',
        ]"
      />
    </div>
  </section>
</article>
</template>

<style lang="scss" scoped>
.table-controls {
  align-items: center;
  justify-content: space-between;

  > *:not(:last-child) {
    margin-right: var(--space-6);
  }
}

.table-wrapper {
  max-height: 340px;
  min-height: 40px;
  overflow: auto;
}
</style>
