<script setup>
import EmptyState from '@/components/utilities/EmptyState'
import ListGrid from '@/components/components/grids/ListGrid'
import OthersColumn from './OthersColumn'

const emit = defineEmits(['destroy', 'edit'])

defineProps({
  items: Array,
  loading: Boolean
})

const warmingCssClass = item => ({
  'warming-not-started': '',
  'warming-started': 'label--warning',
  'warming-finished': 'label--success'
}[item.warming_status])
const warmingValue = item => ({
  'warming-not-started': 'Não Iniciado',
  'warming-started': 'Iniciado',
  'warming-finished': 'Finalizado'
}[item.warming_status])
</script>

<template>
<section
  class="data-cards-list"
  :class="{ 'loading loading--lg': loading }"
>
  <ListGrid
    :actions="[{
      caption: 'Editar',
      cssClass: 'btn--link btn--fit',
      event: 'edit'
    }, {
      caption: 'Apagar',
      cssClass: 'btn--link btn--fit',
      event: 'destroy'
    }]"
    :data="items"
    @destroy="emit('destroy', $event)"
    @edit="emit('edit', $event)"
    :fields="{
      id: {
        label: 'Id',
        style: { width: '100px' },
        styleFormatter: () => ({ color: 'var(--color-black-lighter)' })
      },
      name: { label: 'Nome' },
      platform: { label: 'Plataforma' },
      is_default: {
        label: 'Uso',
        value: item => item.is_default ? 'Remanescente': 'Normal'
      },
      others: {
        filterable: false,
        label: 'Outros',
        sortable: false
      },
      warming_status: {
        label: 'Aquecimento',
        value: warmingValue
      }
    }"
  >
    <template #col-3="{ item, value }">
      <span
        class="label label--fit"
        :class="{ 'label--success': item.is_default }"
      >{{ value }}</span>
    </template>
    <template #col-4="{ item }">
      <OthersColumn :item="item" />
    </template>
    <template #col-5="{ item, value }">
      <div v-if="item.is_warming">{{ item.warming_day }}&ordm; dia</div>
      <span
        class="label label--fit"
        :class="warmingCssClass(item)"
      >{{ value }}</span>
    </template>
  </ListGrid>
</section>

<EmptyState
  message="Nenhuma Conta está cadastrada ainda ..."
  v-if="!loading && !items.length"
/>
</template>
