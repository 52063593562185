import client from '@/support/http/client'
import { getApiEndpoint } from '@/support/tenant'
import * as obj from '@/support/object'
import store from '@/store'

const endpoint = getApiEndpoint() + 'auth'

const rules = {
  'manage-accounts' () {
    return roleHasAbilities(['manage-accounts'])
      && store.getters['accounts/isCurrentUserOfMainAccount']
  }
}

export default {
  logged () {
    return !!localStorage.getItem('appUser')
  },

  login (data) {
    return client.post(endpoint + '/login', data)
      .then(user => {
        const appUser = obj.copy(user)

        delete appUser.api_token
        localStorage.setItem('appUser', JSON.stringify(appUser))
        store.commit('setAuthUser', this.user())

        return user
      })
  },

  logout () {
    return client.delete(endpoint + '/logout')
      .then(() => {
        localStorage.removeItem('appUser')
        store.commit('setAuthUser', {})
        store.commit('roles/setRoles', [])
        store.commit('accounts/setAccounts', [])
      })
  },

  user () {
    return JSON.parse(localStorage.getItem('appUser'))
  },

  userCan (abilities) {
    if (!abilities) return true

    const hasDynamicAbility = abilities.length === 1 && rules[abilities[0]]

    if (hasDynamicAbility) {
      return rules[abilities[0]]()
    }

    return roleHasAbilities(abilities)
  }
}

function roleHasAbilities (abilities) {
  return store.state.roles.allowedAbilities.includes('*')
    || abilities.some(ability => store.state.roles.allowedAbilities.includes(ability))
}
