<script setup>
import { computed, ref } from 'vue'
import { Templates } from './support.js'
import { useStore } from 'vuex'

import CButton from '@/components/CButton'
import CInput from '@/components/components/form/Input'
import CModal from '@/components/Modal'
import Collapsible from '@/components/Collapsible'
import Nodes from './builder/editor/Nodes.vue'

const emit = defineEmits(['close'])
const props = defineProps({ item: Object })

const store = useStore()
const ftp = ref({})
const publishing = ref(false)
const render = ref(null)

const cleanRender = computed(() => {
  return (render.value?.innerHTML || '')
    .replace(/<!--v-if-->/g, '')
    .replace(/<!---->/g, '')
    .replace(/component[^\s"]*/g, '')
    .replace(/class="([^"]*)"/g, (_, g) => `class="${g.trim()}"`)
    .replace(/data-placeholder="([^"]*)"/g, '')
    .replace(/<div /, '<div hidden ')
})

const fullPageTemplate = computed(() => {
  return Templates.QUIZ_FULL_PAGE
    .replace(/TITLE/g, props.item.name)
    .replace(/ANALYTICS_ID/g, props.item.configs.wrapper?.analytics_id || '')
    .replace(/BUCKET_ENPOINT/g, store.state.bucketEndpoint)
    .replace(/QUIZ_URL/g, `${store.state.apiEndpoint}quiz/${props.item.key}`)
    .replace(/RENDER/g, cleanRender.value)
})

function close () {
  emit('close')
}

async function publish () {
  const data = ftp.value
  data.id = props.item.id
  data.content = fullPageTemplate.value

  try {
    publishing.value = true
    await store.dispatch('quizzesV2/deploy', data)
    close()
  } finally {
    publishing.value = false
  }
}
</script>

<template>
<CModal
  @close="close()"
  :closeable-by-backdrop="false"
  title="Veicular Quiz"
>
  <section class="form">
    <Collapsible title="Página personalizada">
      <p>Use o template abaixo como base para sua página:</p>
      <div
        ref="render"
        v-show="false"
      >
        <Nodes :nodes="item.configs.nodes" />
      </div>
      <pre class="code"><code>{{ fullPageTemplate }}</code></pre>
    </Collapsible>
    <Collapsible title="Publicar via FTP">
      <p>Informe os dados do FTP para publicar o quiz:</p>
      <div class="form__fields form__fields--row">
        <CInput
          class="flex--2"
          label="Endereço"
          v-model="ftp.hostname"
        />
        <CInput
          label="Porta"
          v-model="ftp.port"
        />
        <CInput
          label="Usuário"
          v-model="ftp.username"
        />
        <CInput
          label="Senha"
          v-model="ftp.password"
        />
      </div>
      <div class="form__fields form__fields--row form__fields--to-end">
        <CInput
          label="Pasta"
          v-model="ftp.folder"
        />
        <CButton
          caption="Publicar"
          class="btn--secondary"
          :class="{ loading: publishing }"
          @click="publish()"
          :disabled="publishing"
          icon="publish"
        />
      </div>
    </Collapsible>
  </section>

  <template #footer>
    <footer class="modal__footer">
      <CButton
        caption="Fechar"
        class="btn--primary"
        @click="close()"
        :disabled="publishing"
      />
    </footer>
  </template>
</CModal>
</template>

<style>
.code {
  background: #f9f9f9;
  display: block;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.85rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.code code {
  display: block;
  overflow: auto;
  padding: 0.75rem;
}

.code code::-webkit-scrollbar {
  background: var(--color-gray-lighter);
  height: 6px;
}

.code code::-webkit-scrollbar-thumb {
  background: var(--color-black-lighter);
  border-radius: 1px;
}
</style>
