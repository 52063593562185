<script setup>
import { ref } from 'vue'

defineProps({ title: String })

const open = ref(false)
</script>

<template>
<section class="collapsible">
  <header
    class="collapsible__header"
    :class="{ 'collapsible__header--open': open }"
    @click="open = !open"
  >{{ title }}</header>
  <div
    class="collapsible__body"
    :class="{ 'collapsible__body--open': open }"
    v-if="open"
  >
    <slot />
  </div>
</section>
</template>
