<script setup>
import { computed } from 'vue'

import CInput from '@/components/components/form/Input'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Object })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
</script>

<template>
<div>
  <CInput
    id="wrapperConfigDomain"
    label="Domínio do wrapper"
    placeholder="Desabilitado"
    v-model="data.config_domain"
  >
    <span class="input__help">Pegue essa informação com o gestor do wrapper.</span>
  </CInput>
  <CInput
    id="wrapperConfigCompany"
    label="Empresa do wrapper"
    placeholder="Desabilitado"
    v-model="data.config_company"
  >
    <span class="input__help">Pegue essa informação com o gestor do wrapper.</span>
  </CInput>
  <div
    class="input input--column"
    v-if="data.config_domain && data.config_company"
  >
    <label class="input__switch nowrap">
      <input type="checkbox" v-model="data.is_reward_ads_active" />
      <span>Ativar reward ads</span>
    </label>
    <label class="input__switch">
      <input type="checkbox" v-model="data.is_params_bypass_test_active" />
      <span class="line-compact">Ativar teste de bypass de parâmetros</span>
    </label>
  </div>
  <CInput
    label="ID do Analytics"
    placeholder="UA- ou G- ..."
    v-model="data.analytics_id"
  />
</div>
</template>
