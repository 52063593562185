<script setup>
import { format, formatPercentage } from '@/support/number'
import quizzes from '@/repositories/quizzes-v2'
import { computed, ref, watch } from 'vue'

const props = defineProps({
  item: Object,
  quizzes: Array
})

const open = ref(false)
const data = ref([])
const loading = ref(false)

const variant = computed(() => props.quizzes.find(quiz => quiz.key === props.item.configs.ab_test.variation))
const controlCount = computed(() => data.value.find(one => one.id === props.item.id)?.end || 0)
const variantCount = computed(() => data.value.find(one => one.id === variant.value.id)?.end || 0)
const diff = computed(() => controlCount.value ? (variantCount.value - controlCount.value) / controlCount.value : 0)
const diffClass = computed(() => diff.value < 0 ? 'danger' : (diff.value < 0.05 ? 'warning' : 'success'))

watch(open, value => value && loadStatistics())

async function loadStatistics () {
  loading.value = true
  data.value = await quizzes.abStatistics({
    from: props.item.configs.ab_test.start,
    to: props.item.configs.ab_test?.end ?? (new Date()).toJSON(),
    control: props.item.id,
    variant: variant.value.id
  })
  loading.value = false
}
</script>

<template>
<div class="dropdown">
  <div
    class="btn btn--ghost btn--fit dropdown__trigger"
    @click="open = !open"
  ><span class="icon icon--sm">analytics</span></div>
  <div
    class="card dropdown__panel"
    v-if="open"
  >
    <h3 class="card__title caption caption--xs mb-2">Resultados do Teste</h3>
    <div
      class="tile"
      :class="{ loading }"
    >
      <div class="tile__content small">
        <p>
          {{ item.key }} (controle):
          <strong>{{ format(controlCount) }}</strong>
        </p>
        <p>
          {{ item.configs.ab_test.variation }} (variante):
          <strong>{{ format(variantCount) }}</strong>
        </p>
      </div>
      <div>
        <span
          class="diff-percentage"
          :class="diffClass"
        >{{ formatPercentage(diff, 1) }}</span>
        <span
          class="tooltip"
          data-tooltip="O quanto o variante é melhor ou pior que o controle."
        ><span class="icon icon--sm muted">info</span></span>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.dropdown__panel {
  min-width: 380px;
}

.diff-percentage {
  font-size: var(--h5-size);
  margin-left: var(--space-2);
  margin-right: var(--space-1);
}

.tooltip::before {
  bottom: calc(100% + 7px);
  white-space: normal;
  width: 8rem;
}
</style>
