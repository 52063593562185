<script setup>
import { getDef } from '../components/index.js'
import { remove, selectParent, setStore, toLeft, toRight } from '../support.js'
import { useEditor } from '../editor/editor.js'
import { useStore } from 'vuex'

import CButton from '@/components/CButton'

const { parent, selected } = useEditor()

setStore(useStore())
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <header class="caption primary strong mb-4">
    <span class="muted" v-if="parent">{{ getDef(parent).name || parent.is }} &raquo;</span>
    {{ getDef(selected).name || selected.is }}
  </header>
  <div class="toolbar mb-5">
    <CButton
      class="btn--fit btn--ghost"
      @click.prevent="selectParent()"
      :disabled="!parent"
      icon="ios_share"
    />
    <div class="toolbar__group">
      <CButton
        class="btn--fit btn--ghost"
        @click.prevent="toLeft()"
        :disabled="!parent"
        icon="move_up"
      />
      <CButton
        class="btn--fit btn--ghost"
        @click.prevent="toRight()"
        :disabled="!parent"
        icon="move_down"
      />
    </div>
    <CButton
      class="btn--fit btn--ghost"
      @click.prevent="remove()"
      :disabled="!parent"
      icon="delete"
    />
  </div>
</section>
</template>
