import teams from '@/repositories/teams'

export default {
  namespaced: true,

  state: () => ({
    teams: []
  }),

  mutations: {
    addTeam (state, { item }) {
      state.teams.push(item)
    },

    removeTeam (state, { item }) {
      state.teams.splice(state.teams.findIndex(one => one === item), 1)
    },

    replaceTeam (state, { item, index }) {
      state.teams.splice(index, 1, item)
    },

    setTeams (state, payload) {
      state.teams = payload
    }
  },

  actions: {
    async destroyTeam ({ commit }, data) {
      await teams.destroy(data)
      commit('removeTeam', { item: data })
    },

    async loadTeams ({ commit }) {
      commit('setTeams', await teams.all())
    },

    async saveTeam ({ commit, state }, data) {
      const item = await teams.save(data)
      const index = state.teams.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceTeam' : 'addTeam', { item, index })

      return item
    }
  },

  getters: {
    teams (state) {
      return state.teams.filter(one => !one.is_organization)
    }
  }
}
