<template>
<AppLayout>
  <section class="layout__main workspaces">
    <div class="container">
      <Toolbar
        :has-primary-action="true"
        @primary-action="showEditModal = true"
        primary-action-caption="Adicionar Workspace"
        primary-action-icon="add"
        title="Workspaces"
      />

      <List
        @edit="edit($event)"
        :items="workspaces"
        :loading="loading"
      />
    </div>
  </section>

  <Modal
    @close="showEditModal = false"
    :closeable-by-backdrop="false"
    :show-close-button="true"
    size="lg"
    :title="(workspace.id ? 'Editar' : 'Adicionar') + ' Workspace' + (workspace.id ? ` [#${workspace.id}]` : '')"
    v-if="showEditModal"
  >
    <form
      class="form"
      id="workspaceModalForm"
      @submit.prevent="tryToSave"
    >
      <fieldset>
        <!-- <Select
          id="workspaceAccount"
          label="Conta do workspace"
          name="workspaceAccount"
          :options="accounts"
          v-model="workspace.account_id"
        >
          <span class="input__error" v-if="v$.workspace.account_id.$error">Escolha uma conta</span>
        </Select> -->
        <div class="input">
          <label class="input__label" for="workspaceDomain">Domínio</label>
          <input class="input__control" id="workspaceDomain" maxlength="255" :readonly="!!workspace.id" type="text" v-model.trim="workspace.domain" />
          <span class="input__help">Informe um domínio e uma path opcional. Ex: domain.com ou domain.com/something</span>
          <div v-if="v$.workspace.domain.$error">
            <span class="input__error" v-if="v$.workspace.domain.required.$invalid">Informe o domínio do workspace</span>
            <span class="input__error" v-else-if="v$.workspace.domain.url.$invalid">Informe um domínio (URL) válido</span>
            <span class="input__error" v-else-if="v$.workspace.domain.unique.$invalid">Esse domínio já está cadastrado</span>
          </div>
        </div>
        <div class="input">
          <label class="input__label" for="workspaceName">Nome do workspace</label>
          <input class="input__control" id="workspaceName" type="text" v-model.trim="workspace.name" />
          <span class="input__error" v-if="v$.workspace.name.$error">Informe o nome do workspace</span>
        </div>
        <div class="input">
          <label class="input__label" for="workspaceDescription">Descrição do workspace</label>
          <textarea class="input__control" id="workspaceDescription" v-model.trim="workspace.description"></textarea>
        </div>
        <div class="input">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="workspace.is_archived" />
            <span>Está arquivado</span>
          </label>
        </div>
      </fieldset>
    </form>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="showEditModal = false"
        />
        <CButton
          caption="Salvar"
          class="btn--primary"
          form="workspaceModalForm"
          icon="save"
          type="submit"
        />
      </footer>
    </template>
  </Modal>

  <Modal
    @close="confirmingDestroy = false"
    title="Confirmação"
    v-if="confirmingDestroy"
  >
    <template #default>
      <p>Tem certeza que deseja apagar o workspace <strong>{{ workspaceToDestroy.name }}</strong>?</p>
      <Alert
        class="alert--info alert--ghost"
        icon="info"
        title="Apagar um workspace é uma ação crítica e irá apagar todos os dados relacionados com ele."
      />
    </template>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="confirmingDestroy = false"
        />
        <CButton
          caption="Apagar"
          class="btn--danger"
          @click="destroy"
          icon="delete"
        />
      </footer>
    </template>
  </Modal>
</AppLayout>
</template>

<script>
import Alert from '@/components/Alert'
import AppLayout from '@/components/layout/App'
import CButton from '@/components/CButton'
import List from './workspaces/List'
import { mapState } from 'vuex'
import { maxLength, required } from '@vuelidate/validators'
import Modal from '@/components/Modal'
import * as obj from '@/support/object'
// import Select from '@/components/components/form/Select'
import Toolbar from '@/components/Toolbar'
import { useVuelidate } from '@vuelidate/core'

const emptyWorkspace = () => ({
  account_id: null,
  description: '',
  domain: '',
  id: null,
  is_archived: false,
  name: '',
  teams: []
})

export default {
  name: 'Workspaces',

  components: {
    Alert,
    AppLayout,
    CButton,
    List,
    Modal,
    // Select,
    Toolbar
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      confirmingDestroy: false,
      loading: false,
      showEditModal: false,
      workspace: emptyWorkspace(),
      workspaceToDestroy: {}
    }
  },

  computed: mapState({
    accounts: state => state.accounts.accounts,
    workspaces: state => state.workspaces
  }),

  watch: {
    showEditModal (show) {
      if (!show) {
        this.workspace = emptyWorkspace()
        this.v$.$reset()
      }
    }
  },

  methods: {
    confirmDestroy (workspace) {
      this.workspaceToDestroy = workspace
      this.confirmingDestroy = true
    },

    async destroy () {
      await this.$store.dispatch('destroyWorkspace', this.workspaceToDestroy)
      this.workspaceToDestroy = {}
      this.confirmingDestroy = false
    },

    edit (workspace) {
      this.workspace = { teams: [], ...obj.copy(workspace) }
      this.showEditModal = true
    },

    async tryToSave () {
      if (!await this.v$.$validate()) return

      await this.$store.dispatch('saveWorkspace', this.workspace)

      this.showEditModal = false
    }
  },

  validations () {
    return {
      workspace: {
        // account_id: { required },
        domain: {
          maxLength: maxLength(255),
          required,
          url: value => !!value.match(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9_./]*)/),
          unique: value => !this.workspaces.find(one => one.domain === value && one.id !== this.workspace.id)
        },
        name: { required }
      }
    }
  }
}
</script>
