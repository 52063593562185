<script setup>
import { computed, watch } from 'vue'

import CButton from '@/components/CButton'
import RulesBuilder from '@/components/components/rules-builder/Builder'

const emit = defineEmits(['remove', 'update:modelValue'])
const props = defineProps({
  facts: Object,
  index: Number,
  modelValue: Object
})

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})

watch(() => data.value.by_rules, value => {
  if (!value || data.value.rules.length) return

  data.value.rules.push({
    id: data.value.rules.length + 1,
    criteria: {
      match: 'all',
      conditions: []
    }
  })
}, { immediate: true })
</script>

<template>
<div class="result-page">
  <div class="form__fields form__fields--row form__fields--to-end mb-2">
    <div class="input">
      <label
        class="input__label"
        :for="'resultPageUrl' + index"
      >URL da Página</label>
      <input
        class="input__control"
        :id="'resultPageUrl' + index"
        type="text"
        v-model.trim="data.url"
      />
    </div>
    <CButton
      class="btn--icon"
      @click="emit('remove', page)"
      icon="clear"
    />
  </div>
  <div
    class="input mb-0"
    :class="{ 'mb-2': data.by_rules }"
  >
    <label class="input__switch nowrap">
      <input type="checkbox" v-model="data.by_rules" />
      <span>Enviar para essa página de acordo com regras</span>
    </label>
  </div>
  <RulesBuilder
    :facts="facts"
    :has-multiple-rules="false"
    :has-named-rules="false"
    v-if="data.by_rules"
    v-model:rules="data.rules"
  />
</div>
</template>
