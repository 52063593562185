<script setup>
import dayjs from 'dayjs'

import EmptyState from '@/components/utilities/EmptyState'
import ListGrid from '@/components/components/grids/ListGrid'
import StatisticsDropdown from './StatisticsDropdown'

const emit = defineEmits(['destroy', 'edit'])

defineProps({
  items: Array,
  loading: Boolean
})

function formatDateTime (date) {
  return (new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }))
    .format(new Date(date))
}

function formatRecurrenceInfo (item) {
  const period = { daily: 'dia', weekly: 'semana', monthly: 'mês' }[item.configs.recurrence]
  const isWeekend = [0, 6].includes(dayjs(item.configs.recurrence_start_at).day())
  const weekDay = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ][dayjs(item.configs.recurrence_start_at).day()]

  return [
    item.configs.recurrence === 'weekly' ? 'Toda' : 'Todo',
    period,
    ...(item.configs.recurrence === 'monthly'
      ? ['no dia', dayjs(item.configs.recurrence_start_at).date()] : []),
    ...(item.configs.recurrence === 'weekly'
      ? [isWeekend ? 'no' : 'na', weekDay] : []),
    'às',
    dayjs(item.configs.recurrence_start_at).format('HH:mm'),
  ].filter(one => !!one).join(' ')
}

function getSendDate (item) {
  return item.is_scheduled ? item.configs.schedule_at : item.created_at
}
</script>

<template>
<section
  class="data-cards-list"
  :class="{ 'loading loading--lg': loading }"
>
  <ListGrid
    :actions="[{
      caption: 'Editar',
      cssClass: 'btn--link btn--fit',
      event: 'edit',
      showIf: item => !item.was_sent
    }, {
      caption: 'Apagar',
      cssClass: 'btn--link btn--fit',
      event: 'destroy'
    }]"
    :data="items"
    @destroy="emit('destroy', $event)"
    @edit="emit('edit', $event)"
    :fields="{
      id: {
        label: 'Id',
        style: { width: '100px' },
        styleFormatter: () => ({ color: 'var(--color-black-lighter)' }),
      },
      account: {
        label: 'Conta',
        style: { width: '130px' },
        value: item => item.push_account.name,
      },
      notification: {
        label: 'Notificação',
        sortable: false,
      },
      status: {
        label: 'Status',
        value: item => item.is_recurrent ? 'Recorrente' : item.was_sent ? 'Enviada' : 'Agendada',
      },
      configs: {
        filterable: false,
        label: 'Outras Configurações',
        sortable: false,
      },
    }"
  >
    <template #col-2="{ item }">
      <strong>{{ item.title }}</strong>
      <div>{{ item.message }}</div>
      <a :href="item.url" target="_blank">{{ item.url }}</a>
    </template>
    <template #col-3="{ item, value }">
      <template v-if="item.is_recurrent">
        <div class="flex gap2">
          <span class="icon mr-2 primary">event_repeat</span>
          <span>{{ value }}</span>
        </div>
        <div class="line-compact"><small class="muted">{{ formatRecurrenceInfo(item) }}</small></div>
      </template>
      <template v-else>
        <div class="flex gap2">
          <span
            class="icon mr-2"
            :class="[item.was_sent ? 'primary' : 'success']"
          >{{ item.was_sent ? 'done' : 'schedule_send' }}</span>
          <span>{{ value }}</span>
        </div>
        <div class="line-compact">
          <template v-if="item.was_sent">
            <small class="muted">Em {{ formatDateTime(getSendDate(item)) }}.</small>
            <small
              class="muted"
              v-if="item.configs.source_id"
            > Via recorrência.</small>
          </template>
          <small
            class="muted"
            v-else
          >Para {{ formatDateTime(getSendDate(item)) }}.</small>
        </div>
        <StatisticsDropdown
          :item="item"
          v-if="item.was_sent"
        />
      </template>
    </template>
    <template #col-4="{ item }">
      <div class="small">
        <div v-if="item.is_recurrent">
          <span class="muted">Próximo envio:</span> {{ formatDateTime(item.configs.recurrence_send_at) }}
        </div>
        <div>
          <span class="muted">Segmento:</span> {{ item.configs.segment ?? '#' + item.segment_id }}
        </div>
        <div v-if="item.remote_id">
          <span class="muted">ID na plataforma:</span> #{{ item.remote_id }}
        </div>
        <div v-if="item.configs.has_actions">
          <div class="flex" v-if="item.configs.action_1_title && item.configs.action_1_url">
            <span class="muted mr-2 nowrap">Ação 1:</span>
            <div>
              <div>{{ item.configs.action_1_title }}</div>
              <div>{{ item.configs.action_1_url }}</div>
            </div>
          </div>
          <div class="flex" v-if="item.configs.action_2_title && item.configs.action_2_url">
            <span class="muted mr-2 nowrap">Ação 2:</span>
            <div>
              <div>{{ item.configs.action_2_title }}</div>
              <div>{{ item.configs.action_2_url }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ListGrid>
</section>

<EmptyState
  message="Nenhum Push Notification está cadastrado ainda ..."
  v-if="!loading && !items.length"
/>
</template>

<style scoped>
.statistics {
  position: relative;
  white-space: nowrap;
}
</style>
