<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

import AppLayout from '@/components/layout/App'
import CButton from '@/components/CButton'
import CConfirm from '@/components/components/dialogs/Confirm'
import List from './List'
import ScheduleForm from './Form'
import Toolbar from '@/components/Toolbar'

const store = useStore()
const loading = ref(true)
const schedule = ref(null)
const toDestroy = ref(null)
const schedules = computed(() => store.state.adsSchedules.schedules)
const confirmingDestroy = computed(() => !!toDestroy.value)
const editing = computed(() => !!schedule.value)

const confirmDestroy = schedule => toDestroy.value = schedule

const destroy = async () => {
  await store.dispatch('adsSchedules/destroySchedule', toDestroy.value)
  toDestroy.value = null
}

const edit = (item = {}) => schedule.value = item

onBeforeMount(async () => {
  store.dispatch('adsAccounts/loadAccounts')
  await store.dispatch('adsSchedules/loadSchedules')
  loading.value = false
})
</script>

<template>
<AppLayout>
  <section class="layout__main ads-schedules">
    <div class="container">
      <Toolbar title="Agendamento de Campanhas">
        <template #actions>
          <div class="toolbar__group">
            <router-link
              class="btn btn--secondary"
              :to="{ name: 'ads-accounts' }"
            >Contas de Ads</router-link>
            <CButton
              caption="Adicionar Agendamento"
              class="btn--primary"
              @click="edit()"
              icon="add"
            />
          </div>
        </template>
      </Toolbar>

      <List
        @destroy="confirmDestroy($event)"
        @edit="edit($event)"
        :items="schedules"
        :loading="loading"
      />
    </div>
  </section>

  <ScheduleForm
    @close="schedule = null"
    :item="schedule"
    v-if="editing"
  />

  <CConfirm
    @cancel="toDestroy = null"
    @confirmed="destroy()"
    v-if="confirmingDestroy"
  >
    <p>Tem certeza que deseja apagar o agendamento?</p>
  </CConfirm>
</AppLayout>
</template>
