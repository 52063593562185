<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'

import CButton from '@/components/CButton.vue'
import CModal from '@/components/Modal.vue'
import Collapsible from '@/components/Collapsible.vue'
import WorkspaceSelector from '@/components/components/form/WorkspaceSelector.vue'

const emit = defineEmits(['close', 'doCopy'])
const props = defineProps({ item: Object })

const store = useStore()
const workspaceId = ref(store.state.workspaceId)

function close () {
  emit('close')
}

function copy () {
  store.state.quizzesV2.toCopy = props.item
  store.dispatch('setWorkspaceId', workspaceId.value)
  close()
}
</script>

<template>
<CModal
  @close="close()"
  title="Copiar Quiz"
>
  <section class="form">
    <Collapsible title="Para o mesmo workspace">
      <CButton
        caption="Copiar"
        class="btn--primary"
        @click="emit('doCopy')"
      />
    </Collapsible>
    <Collapsible title="Para outro workspace">
      <p>Escolha o workspace para copiar:</p>
      <WorkspaceSelector
        class="mb-4"
        v-model="workspaceId"
      />
      <CButton
        caption="Mudar de workspace e copiar"
        class="btn--primary"
        @click="copy()"
      />
    </Collapsible>
  </section>

  <template #footer>
    <footer class="modal__footer">
      <CButton
        caption="Fechar"
        class="btn--secondary"
        @click="close()"
      />
    </footer>
  </template>
</CModal>
</template>
