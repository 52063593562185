<template>
<AppLayout>
  <section class="layout__main webhooks">
    <div class="container">
      <Toolbar
        :has-primary-action="true"
        @primary-action="showEditModal = true"
        primary-action-caption="Adicionar Webhook"
        primary-action-icon="add"
        title="Webhooks"
      />

      <List
        @destroy="confirmDestroy($event)"
        @edit="edit($event)"
        :items="webhooks"
        :loading="loading"
      />
    </div>
  </section>

  <Modal
    @close="showEditModal = false"
    :closeable-by-backdrop="false"
    size="lg"
    :show-close-button="true"
    :title="(webhook.id ? 'Editar' : 'Adicionar') + ' Webhook' + (webhook.id ? ` [#${webhook.id}]` : '')"
    v-if="showEditModal"
  >
    <form
      class="form"
      id="webhookModalForm"
      @submit.prevent="tryToSave"
    >
      <fieldset>
        <Alert
          class="alert--info alert--ghost mb-8"
          icon="info"
          v-if="webhook.id"
        >
          <template #content>
            <p class="alert__description mb-1">{{ url }}</p>
            <CButton
              caption="Copiar URL"
              @click="copyToClipboard"
            />
          </template>
        </Alert>
        <div class="form__fields form__fields--row">
          <div class="input flex--2">
            <label class="input__label" for="webhookName">Nome do webhook</label>
            <input class="input__control" id="webhookName" type="text" v-model.trim="webhook.name" />
            <span class="input__error" v-if="v$.webhook.name.$error">Informe o nome do webhook</span>
          </div>
          <div class="input input--no-label">
            <label class="input__switch nowrap">
              <input type="checkbox" v-model="webhook.is_active" />
              <span>Está ativo</span>
            </label>
          </div>
        </div>
        <div class="input">
          <label class="input__label" for="webhookWebhook">Ação do webhook</label>
          <div class="input__select">
            <select class="input__control" name="webhookWebhook" id="webhookWebhook" v-model="webhook.webhook">
              <option :value="null">[ Selecione ]</option>
              <option
                :key="option.id"
                :value="option.id"
                v-for="option of availableWebhooks"
              >{{ option.description }}</option>
            </select>
          </div>
          <span class="input__error" v-if="v$.webhook.webhook.$error">Informe a ação do webhook</span>
        </div>
        <div
          class="input"
          v-if="currentWebhook?.options?.usesSourceAccount"
        >
          <label class="input__label" for="webhookSourceAccountId">Conta de origem</label>
          <div class="input__select">
            <select class="input__control" name="webhookSourceAccountId" id="webhookSourceAccountId" v-model="webhook.options.source_platform_account_id">
              <option :value="null">[ Selecione ]</option>
              <option
                :key="account.id"
                v-for="account in platformAccountsLightList"
                :value="account.id"
              >{{ account.workspace.name }} / {{ account.platform }} - {{ account.name }}</option>
            </select>
          </div>
          <span class="input__error" v-if="v$.webhook.options.source_platform_account_id.$error">Escolha uma conta de origem</span>
        </div>
        <div class="input">
          <label class="input__label" for="webhookAccountId">Conta alvo da ação</label>
          <div class="input__select">
            <select class="input__control" id="webhookAccountId" v-model="webhook.platform_account_id">
              <option :value="null">[ Selecione ]</option>
              <option
                :key="account.id"
                v-for="account in platformAccountsLightList"
                :value="account.id"
              >{{ account.workspace.name }} / {{ account.platform }} - {{ account.name }}</option>
            </select>
          </div>
          <span class="input__error" v-if="v$.webhook.platform_account_id.$error">Escolha uma conta</span>
        </div>
        <div
          class="input"
          v-if="currentWebhook?.options?.usesTargetList"
        >
          <label class="input__label" for="webhookTargetList">Lista alvo</label>
          <div class="input__select">
            <select class="input__control" name="webhookTargetList" id="webhookTargetList" v-model="webhook.options.target_list">
              <option :value="null">[ Selecione ]</option>
              <option
                :key="list.id"
                v-for="list in lists"
                :value="list.id"
              >{{ list.id }} / {{ list.name }}</option>
            </select>
          </div>
          <span class="input__error" v-if="v$.webhook.options.target_list.$error">Escolha uma lista alvo</span>
        </div>
        <div class="input">
          <label class="input__label" for="webhookDescription">Descrição do webhook</label>
          <textarea class="input__control" id="webhookDescription" v-model.trim="webhook.description"></textarea>
        </div>
        <div
          class="input flex--col"
          v-if="false && currentWebhook?.options?.usesProcessOptions"
        >
          <label class="input__label" for="webhookProcessAmount">Processar</label>
          <div class="input__group flex">
            <div class="input__select">
              <select class="input__control" name="webhookProcessAmount" id="webhookProcessAmount" v-model="webhook.options.process_amount">
                <option :value="null">[ Selecione ]</option>
                <option :value="null">[ Selecione ]</option>
                <option :value="null">[ Selecione ]</option>
                <option :value="null">[ Selecione ]</option>
                <option :value="null">[ Selecione ]</option>
              </select>
            </div>
            <span class="input__control input__control--static nowrap">a cada 10 minutos</span>
          </div>
          <span class="input__error" v-if="v$.webhook.options.target_list.$error">Escolha uma lista alvo</span>
        </div>
      </fieldset>
    </form>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="showEditModal = false"
        />
        <CButton
          caption="Salvar"
          class="btn--primary"
          form="webhookModalForm"
          icon="save"
          type="submit"
        />
      </footer>
    </template>
  </Modal>

  <Modal
    @close="showUrlModal = false"
    :closeable-by-backdrop="false"
    title="URL do Webhook"
    v-if="showUrlModal"
  >
    <template #default>
      <Alert
        class="alert--info alert--ghost mb-8"
        icon="info"
      >
        <template #content>
          <p class="alert__description mb-1">{{ url }}</p>
          <CButton
            caption="Copiar URL"
            @click="copyToClipboard"
          />
        </template>
      </Alert>
    </template>
  </Modal>

  <Modal
    @close="confirmingDestroy = false"
    title="Confirmação"
    v-if="confirmingDestroy"
  >
    <template #default>
      <p>Tem certeza que deseja apagar o webhook <strong>{{ webhookToDestroy.name }}</strong>?</p>
      <Alert
        class="alert--info alert--ghost"
        icon="info"
        title="Apagar um webhook é uma ação crítica e irá tornar não funcional os locais onde é usado."
      />
    </template>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="confirmingDestroy = false"
        />
        <CButton
          caption="Apagar"
          class="btn--danger"
          @click="destroy"
          icon="delete"
        />
      </footer>
    </template>
  </Modal>
</AppLayout>
</template>

<script>
import Alert from '@/components/Alert'
import AppLayout from '@/components/layout/App'
import CButton from '@/components/CButton'
import List from './webhooks/List'
import { mapState } from 'vuex'
import Modal from '@/components/Modal'
import * as obj from '@/support/object'
import orderBy from 'lodash/orderBy'
import { required, requiredIf } from '@vuelidate/validators'
import Toolbar from '@/components/Toolbar'
import { useVuelidate } from '@vuelidate/core'

const emptyWebhook = () => ({
  description: '',
  id: null,
  is_active: false,
  name: '',
  options: {
    process_amount: null,
    source_platform_account_id: null,
    target_list: null
  },
  platform_account_id: null,
  webhook: null
})

export default {
  name: 'Webhooks',

  components: {
    Alert,
    AppLayout,
    CButton,
    List,
    Modal,
    Toolbar
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      confirmingDestroy: false,
      loading: false,
      showEditModal: false,
      showUrlModal: false,
      webhook: emptyWebhook(),
      webhookToDestroy: {}
    }
  },

  created () {
    this.unwatch = this.$watch(
      () => this.$route,
      async () => {
        this.loading = true
        await this.$store.dispatch('loadAvailableWebhooks')
        await this.$store.dispatch('loadPlatformAccountsLightList')
        await this.$store.dispatch('loadWebhooks')
        this.loading = false
      },
      { immediate: true }
    )
  },

  beforeRouteLeave () {
    this.unwatch()
  },

  computed: {
    ...mapState(['availableWebhooks', 'platformAccountsLightList']),

    currentWebhook () {
      return this.availableWebhooks.find(webhook => webhook.id === this.webhook.webhook) || {}
    },

    lists () {
      return this.platformAccountsLightList
        .find(account => account.id === this.webhook.platform_account_id)?.lists || []
    },

    url () {
      return `${this.$store.state.apiEndpoint}webhooks/${this.webhook.id}/run`
    },

    webhooks () {
      return orderBy(this.$store.state.webhooks, ['name'])
    }
  },

  watch: {
    showEditModal (show) {
      if (!show) {
        this.webhook = emptyWebhook()
        this.v$.$reset()
      }
    },

    showUrlModal (show) {
      if (!show) {
        this.webhook = emptyWebhook()
        this.v$.$reset()
      }
    }
  },

  methods: {
    confirmDestroy (webhook) {
      this.webhookToDestroy = webhook
      this.confirmingDestroy = true
    },

    copyToClipboard () {
      navigator.clipboard.writeText(this.url)
    },

    async destroy () {
      await this.$store.dispatch('destroyWebhook', this.webhookToDestroy)
      this.webhookToDestroy = {}
      this.confirmingDestroy = false
    },

    edit (webhook) {
      this.webhook = obj.copy(webhook)
      this.showEditModal = true
    },

    async tryToSave () {
      if (!await this.v$.$validate()) return

      const isCreating = !this.webhook.id
      const webhook = await this.$store.dispatch('saveWebhook', this.webhook)

      this.showEditModal = false

      if (isCreating) {
        this.$nextTick(() => {
          this.webhook = webhook
          this.showUrlModal = true
        })
      }
    }
  },

  validations () {
    return {
      webhook: {
        name: { required },
        options: {
          source_platform_account_id: {
            required: requiredIf(this.currentWebhook?.options?.usesSourceAccount)
          },
          target_list: { required: requiredIf(this.currentWebhook?.options?.usesTargetList) }
        },
        platform_account_id: { required },
        webhook: { required }
      }
    }
  }
}
</script>
