<script setup>
import { components } from '../components/index.js'
import { nextTick, ref } from 'vue'
import { previousStep } from '../support.js'
import template from '../components/template.json'
import { useEditor } from '../editor/editor.js'
import { copy } from '@/support/object.js'

import CButton from '@/components/CButton.vue'
import CConfirm from '@/components/components/dialogs/Confirm.vue'

const { nodes, isDragging, isEmpty, rebuildParentsMap } = useEditor()

const confirmingTemplateApply = ref(false)

const drag = (eve, key) => {
  const transfer = eve.dataTransfer
  transfer.setData('text', key)
  transfer.setDragImage(eve.target, 0, 0)
  isDragging.value = true
}

function applyTemplate () {
  nodes.value = copy(template)
  rebuildParentsMap()
  nextTick(() => previousStep())
  confirmingTemplateApply.value = false
}

function tryToApplyTemplate () {
  if (isEmpty.value) {
    return applyTemplate()
  }

  confirmingTemplateApply.value = true
}
</script>

<template>
<div>
  <CButton
    caption="Aplicar template"
    class="mb-4"
    @click="tryToApplyTemplate()"
  />

  <div class="quiz-builder__components">
    <div
      class="quiz-builder__component"
      draggable="true"
      @dragstart="drag($event, key)"
      :key="key"
      v-for="(component, key) of components"
    >
      <span class="icon">{{ component.icon }}</span>
      <span>{{ component.name }}</span>
    </div>
  </div>

  <CConfirm
    @cancel="confirmingTemplateApply = null"
    @confirmed="applyTemplate()"
    confirmed-button-icon="check"
    confirmed-caption="Aplicar"
    v-if="confirmingTemplateApply"
  >
    <p>O quiz não está vazio. Se você aplicar o template
      <b>toda a estrutura será substituída</b>.
      Tem certeza que deseja aplicar o template?</p>
  </CConfirm>
</div>
</template>

<style>
.quiz-builder__components {
  display: grid;
  gap: var(--space-2);
  grid-template-columns: repeat(3, 1fr);
}

.quiz-builder__component {
  border: 1px solid var(--color-gray);
  cursor: grab;
  display: flex;
  flex-direction: column;
  font-size: var(--caption-xs-size);
  line-height: 1.25;
  padding: var(--space-2);
  text-align: center;
}

.quiz-builder__component .icon {
  margin-bottom: var(--space-1);
}
</style>
