<script setup>
import { useEditor } from '../editor/editor.js'

import CSelect from '@/components/components/form/Select'

const { selected } = useEditor()
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <div class="input mb-2">
    <label class="input__switch">
      <input type="checkbox" v-model="selected.configs.showIndex" />
      <span>Mostrar índice</span>
    </label>
  </div>
  <CSelect
    class="mb-2"
    label="Posição do índice"
    :options="[
      { id: 'left', name: 'Á esquerda' },
      { id: 'right', name: 'Á direta' }
    ]"
    v-model="selected.configs.indexPosition"
  />
</section>
</template>
