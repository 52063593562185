import { getApiEndpoint } from '@/support/tenant'
import Repository  from './repository'

class AdsAccounts extends Repository
{
  all () {
    return this.client.get(this.urlTo())
  }

  destroy (data) {
    return this.client.delete(this.urlTo(), data)
  }

  find (id) {
    return this.client.get(this.urlTo(id))
  }

  save (data) {
    const method = data.id ? 'put' : 'post'

    return this.client[method](this.urlTo(), data)
  }
}

export default new AdsAccounts({
  base: getApiEndpoint(),
  path: 'ads-accounts'
})
