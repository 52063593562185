<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

import AppLayout from '@/components/layout/App'
import CButton from '@/components/CButton'
import CConfirm from '@/components/components/dialogs/Confirm'
import List from './List'
import NotificationForm from './Form'
import Toolbar from '@/components/Toolbar'

const store = useStore()
const loading = ref(true)
const notification = ref(null)
const toDestroy = ref(null)
const destroying = ref(false)
const showRecurrentOnly = ref(false)
const notifications = computed(() => store.state.pushNotifications.notifications
  .filter(one => !!one.is_recurrent === showRecurrentOnly.value))
const confirmingDestroy = computed(() => !!toDestroy.value)
const editing = computed(() => !!notification.value)

const confirmDestroy = notification => toDestroy.value = notification

const destroy = async () => {
  try {
    destroying.value = true
    await store.dispatch('pushNotifications/destroyNotification', toDestroy.value)
    toDestroy.value = null
  } finally {
    destroying.value = false
  }
}

const edit = (item = {}) => notification.value = item

onBeforeMount(async () => {
  store.dispatch('pushAccounts/loadAccounts')
  await store.dispatch('pushNotifications/loadNotifications')
  loading.value = false
})
</script>

<template>
<AppLayout>
  <section class="layout__main push-notifications">
    <div class="container">
      <Toolbar title="Push Notifications">
        <template #actions>
          <div class="toolbar__group">
            <div class="input mb-0 mr-4">
              <label class="input__switch nowrap">
                <input type="checkbox" v-model="showRecurrentOnly" />
                <span>Mostrar apenas os recorrentes</span>
              </label>
            </div>
            <CButton
              caption="Adicionar Push Notification"
              class="btn--primary"
              @click="edit()"
              icon="add"
            />
          </div>
        </template>
      </Toolbar>

      <List
        @destroy="confirmDestroy($event)"
        @edit="edit($event)"
        :items="notifications"
        :loading="loading"
      />
    </div>
  </section>

  <NotificationForm
    @close="notification = null"
    :item="notification"
    v-if="editing"
  />

  <CConfirm
    @cancel="toDestroy = null"
    @confirmed="destroy()"
    :loading="destroying"
    v-if="confirmingDestroy"
  >
    <p>Tem certeza que deseja apagar o push notification?</p>
  </CConfirm>
</AppLayout>
</template>
