import roles from '@/repositories/roles'

export default {
  namespaced: true,

  state: () => ({
    allowedAbilities: [],
    roles: []
  }),

  mutations: {
    setAllowedAbilities (state, payload) {
      state.allowedAbilities = payload
    },

    setRoles (state, payload) {
      state.roles = payload
    }
  },

  actions: {
    loadAllowedAbilities ({ commit, rootState, state }) {
      const role = state.roles.find(role => role.key === rootState.auth.user.role)

      commit('setAllowedAbilities', role?.abilities || [])
    },

    async loadRoles ({ commit, dispatch }) {
      const results = await roles.all()

      commit('setRoles', results)
      dispatch('loadAllowedAbilities')

      return results
    }
  },

  getters: {
    allowedRoles (state, getters) {
      const startAt = getters.currentUserRoleIndex

      return state.roles
        .slice(startAt === 0 ? 0 : startAt - 1)
        .filter(one => one.key !== 'system')
    },

    currentUserRoleIndex (state, getters, rootState) {
      return state.roles.findIndex(one => one.key === rootState.auth.user.role)
    }
  }
}
