<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import { format, formatPercentage } from '@/support/number'
import { daysOffsetToInterval, formatDate, formatToDB } from '@/support/datetime'
import { mean, meanRate, sum } from '@tidyjs/tidy'
import { useStore } from 'vuex'
import without from 'lodash/without'

import CButton from '@/components/CButton'
import DateSelect from '@/components/components/form/DateSelect'
import FieldsSelector from '@/components/components/grids/FieldsSelector'
import Grid from '@/components/components/grids/SummaryGrid'

const store = useStore()
const key = 'leads-statistics'
const availableFields = [
  { key: 'created_at', label: 'Data', selected: true },
  { key: 'workspace', label: 'Workspace', selected: false },
  { key: 'platform_account', label: 'Conta', selected: false },
  { key: 'origin', label: 'Origem', selected: false },
  { key: 'path', label: 'Path', selected: false },
  { key: 'quiz', label: 'Quiz', selected: true },
  { key: 'target', label: 'Target', selected: false },
  { key: 'target_path', label: 'Target path', selected: false },
]
const availableFieldsKeys = availableFields.map(field => field.key)
const shownFieldsKeys = availableFields.filter(field => field.selected).map(field => field.key)

const period = ref(daysOffsetToInterval(-7))
const shownFields = ref(shownFieldsKeys)

const data = computed(() => store.getters['dashboard/leadsStatistics'])
const loading = computed(() => store.state.dashboard.widgetsLoading[key])
const hiddenFields = computed(() => without(availableFieldsKeys, ...shownFields.value))
const periodToRequest = computed(() => (!period.value ? {} : {
  from: formatToDB(period.value.from),
  to: formatToDB(period.value.to)
}))

const load = () => {
  store.dispatch('dashboard/updateWidget', { key, options: periodToRequest.value })
}

watch(() => period.value, load)

onBeforeMount(load)
</script>

<template>
<article class="card">
  <header class="card__header">
    <h3 class="card__title caption">Total de Leads</h3>
  </header>
  <section class="card__body">
    <div class="flex mb-4 table-controls">
      <div class="toolbar__group">
        <DateSelect
          class="mb-0"
          :is-range="true"
          v-model="period"
        />
      </div>
      <div class="toolbar__group">
        <CButton
          class="tooltip"
          @click="load()"
          data-tooltip="Atualizar"
          icon="refresh"
        />
        <FieldsSelector
          :fields="availableFields"
          id="leadsAmountTableFields"
          v-model="shownFields"
        />
      </div>
    </div>
    <div
      class="table-wrapper"
      :class="{ 'loading loading--lg': loading }"
    >
      <Grid
        class="table--statistics"
        :data="data"
        :except="hiddenFields"
        :fields="{
          created_at: { label: 'Data', formatter: formatDate },
          workspace: { label: 'Workspace' },
          platform_account: { label: 'Conta' },
          origin: { label: 'Origem' },
          path: { label: 'Path' },
          quiz: { label: 'Quiz' },
          target: { label: 'Target' },
          target_path: { label: 'Target path' },
          received_amount: {
            aggregator: (carry, item) => carry + item.received_amount,
            formatter: format,
            label: 'Recebidos'
          },
          valid_amount: {
            aggregator: (carry, item) => carry + item.valid_amount,
            formatter: format,
            label: 'Válidos'
          },
          invalidation_by_email: {
            aggregator: (carry, item) => carry + item.invalidation_by_email,
            formatter: format,
            label: 'Inválido por email'
          },
          invalidation_by_uniqueness_period: {
            aggregator: (carry, item) => carry + item.invalidation_by_uniqueness_period,
            formatter: format,
            label: 'Inválido por unicidade'
          },
          uniqueness_age: {
            formatter: format,
            label: 'Unicidade média'
          },
          valid_amount_sms: {
            aggregator: (carry, item) => carry + item.valid_amount_sms,
            formatter: format,
            label: 'SMS Válidos'
          },
          valid_percentage: {
            afterAggregator: carry => carry.received_amount ? carry.valid_amount / carry.received_amount : 0,
            formatter: formatPercentage,
            label: 'Aproveitamento'
          }
        }"
        :summarize="{
          received_amount: sum('received_amount'),
          valid_amount: sum('valid_amount'),
          invalidation_by_email: sum('invalidation_by_email'),
          invalidation_by_uniqueness_period: sum('invalidation_by_uniqueness_period'),
          uniqueness_age: mean('uniqueness_age'),
          valid_percentage: meanRate('valid_amount', 'received_amount'),
          valid_amount_sms: sum('valid_amount_sms')
        }"
        :summary-fields="[
          'received_amount',
          'valid_amount',
          'invalidation_by_email',
          'invalidation_by_uniqueness_period',
          'uniqueness_age',
          'valid_percentage',
          'valid_amount_sms',
        ]"
      />
    </div>
  </section>
</article>
</template>

<style lang="scss" scoped>
.table-controls {
  align-items: center;
  justify-content: space-between;

  > *:not(:last-child) {
    margin-right: var(--space-6);
  }
}

.table-wrapper {
  max-height: 250px;
  min-height: 40px;
  overflow: auto;
}

.disabled {
  opacity: 0.4;
}
</style>
