import { getApiEndpoint } from '@/support/tenant'
import Repository  from './repository'

class Roles extends Repository
{
  all () {
    return this.client.get(this.urlTo())
  }
}

export default new Roles({
  base: getApiEndpoint(),
  path: 'roles'
})
