import quizzes from '@/repositories/quizzes-v2'

export default {
  namespaced: true,

  state: () => ({
    conversionsStatistics: [],
    quizQuestions: [],
    quizzes: [],
    toCopy: null
  }),

  mutations: {
    addQuiz (state, { item }) {
      state.quizzes.push(item)
    },

    addQuizQuestion (state, { item }) {
      if (state.quizQuestions.find(one => one.id === item.id)) return

      state.quizQuestions.push({ ...item, pivot: { quiz_question_id: item.id } })
    },

    removeQuiz (state, { item }) {
      state.quizzes.splice(state.quizzes.findIndex(one => one === item), 1)
    },

    removeQuizQuestion (state, { item }) {
      state.quizQuestions.splice(state.quizQuestions.findIndex(one => one.pivot.quiz_question_id === item.id), 1)
    },

    replaceQuiz (state, { item, index }) {
      state.quizzes.splice(index, 1, item)
    },

    setConversionsStatistics (state, payload) {
      state.conversionsStatistics = payload
    },

    setQuizQuestions (state, payload) {
      state.quizQuestions = payload
    },

    setQuizzes (state, payload) {
      state.quizzes = payload
    }
  },

  actions: {
    async deploy (_, data) {
      await quizzes.deploy(data)
    },

    async destroyQuiz ({ commit }, data) {
      await quizzes.destroy(data)
      commit('removeQuiz', { item: data })
    },

    async loadConversionsStatistics ({ commit }, options) {
      commit('setConversionsStatistics', await quizzes.conversionsStatistics(options))
    },

    async loadQuizzes ({ commit }) {
      commit('setQuizzes', await quizzes.all())
    },

    async saveQuiz ({ commit, state }, data) {
      const item = await quizzes.save(data)
      const index = state.quizzes.findIndex(one => one.id === item.id)

      item.quiz_questions.forEach(question => {
        question.quiz_question_id = question.pivot.quiz_question_id
        question.order = question.pivot.order
      })

      commit(index >= 0 ? 'replaceQuiz' : 'addQuiz', { item, index })

      return item
    }
  }
}
