import { computed, ref } from 'vue'

const currentStyle = ref(null)
const dropPosition = ref(null)
const hovered = ref(null)
const hoveredElePosition = ref({})
const hoverPosition = ref({})
const isDragging = ref(false)
const nodes = ref()
const parent = ref(null)
const parentsMap = new Map()
const selected = ref(null)
const stepsCount = ref(0)
const currentStep = ref(0)

const isEmpty = computed(() => !nodes.value
  || (Array.isArray(nodes.value) && !nodes.value[0]?.children?.length))

export function useEditor () {
  return {
    assertHoverPosition,
    currentStep,
    currentStyle,
    dropPosition,
    hovered,
    hoveredElePosition,
    hoverPosition,
    isDragging,
    isEmpty,
    nodes,
    parent,
    parentsMap,
    rebuildParentsMap,
    reset,
    select,
    selected,
    stepsCount
  }
}

const assertHoverPosition = (eve = null, ele = null) => {
  if (!eve || !ele) {
    return hoverPosition.value = {}
  }

  const left = eve.pageX - hoveredElePosition.value.x
  const top = eve.pageY - hoveredElePosition.value.y
  const shouldChange = hoverPosition.value.left !== left
    || hoverPosition.value.top !== top

  if (shouldChange) {
    hoverPosition.value = { top, left }
  }
}

const rebuildParentsMap = (children = nodes.value, parentNode) => {
  if (!parentNode) parentsMap.clear()

  if (!Array.isArray(children)) return parentsMap.set(children, parentNode)

  children.forEach(node => {
    parentsMap.set(node, parentNode)

    if (node.children) {
      rebuildParentsMap(node.children, node)
    }
  })

  return parentNode
}

const reset = () => {
  parentsMap.clear()
  select()
}

const select = (node = null, nodeParent = null) => {
  selected.value = node

  if (selected.value) {
    selected.value.attrs ||= {}
    selected.value.attrs.style ||= {}
    selected.value.configs ||= {}
  }

  parent.value = nodeParent
}
