<template>
<AppLayout>
  <section class="layout__main teams">
    <div class="container">
      <Toolbar
        :has-primary-action="true"
        @primary-action="edit()"
        primary-action-caption="Adicionar Time"
        primary-action-icon="add"
        title="Times"
      />

      <List
        @destroy="confirmDestroy"
        @edit="edit"
        :loading="loading"
        :teams="teams"
      />
    </div>
  </section>

  <TeamForm
    @close="team = null"
    :item="team"
    v-if="editing"
    :workspaces="workspaces"
  />

  <CConfirm
    alert="Apagar um time é uma ação crítica e irá apagar todos os dados relacionados com ele."
    @cancel="toDestroy = null"
    @confirmed="destroy()"
    v-if="confirmingDestroy"
  >
    <p>Tem certeza que deseja apagar o time <strong>{{ toDestroy.name }}</strong>?</p>
  </CConfirm>
</AppLayout>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

import AppLayout from '@/components/layout/App'
import CConfirm from '@/components/components/dialogs/Confirm'
import List from './List'
import TeamForm from './Form'
import Toolbar from '@/components/Toolbar'

const store = useStore()
const loading = ref(true)
const team = ref(null)
const toDestroy = ref(null)

const confirmingDestroy = computed(() => !!toDestroy.value)
const editing = computed(() => !!team.value)
const teams = computed(() => store.getters['teams/teams'])
const workspaces = computed(() => store.state.workspaces)

const confirmDestroy = team => toDestroy.value = team

const destroy = async () => {
  await store.dispatch('teams/destroyTeam', toDestroy.value)
  toDestroy.value = null
}

const edit = (item = {}) => team.value = item

onBeforeMount(async () => {
  await store.dispatch('teams/loadTeams')
  loading.value = false
})
</script>
