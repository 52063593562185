<script setup>
import { computed, onMounted } from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  fields: Array,
  id: String,
  modelValue: Array
})

const data = computed({
  get () { return props.modelValue },
  set (value) {
    emit('update:modelValue', value)
    save(value)
  }
})

onMounted(restore)

function restore () {
  const saved = JSON.parse(localStorage.getItem(props.id))

  if (saved) {
    emit('update:modelValue', saved)
  }
}

function save (value) {
  localStorage.setItem(props.id, JSON.stringify(value))
}
</script>

<template>
<div class="dropdown">
  <label
    class="btn dropdown__trigger tooltip"
    data-tooltip="Campos"
    :for="id"
  >
    <span class="icon">tune</span>
  </label>
  <input
    class="sensor"
    :id="id"
    type="checkbox"
  >
  <div class="card dropdown__panel dropdown__panel--right">
    <label
      class="input__checkbox"
      :class="{ disabled: data.length === 1 && data[0] === field.key }"
      :key="field.key"
      v-for="field in fields"
    >
      <input
        type="checkbox"
        :value="field.key"
        v-model="data"
        :disabled="data.length === 1 && data[0] === field.key"
      />
      <span>{{ field.label }}</span>
    </label>
  </div>
</div>
</template>
