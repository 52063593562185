<script setup>
import { computed } from 'vue'

import NumberField from '../editor/NumberField.vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Object })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <p class="caption caption--xs mb-2">Interno</p>
  <div class="row mb-2">
    <div>
      <NumberField
        class="mb-2"
        label="Esquerda"
        v-model="data.paddingLeft"
      />
      <NumberField
        class="mb-2"
        label="Direita"
        v-model="data.paddingRight"
      />
    </div>
    <div>
      <NumberField
        class="mb-2"
        label="Superior"
        v-model="data.paddingTop"
      />
      <NumberField
        class="mb-2"
        label="Inferior"
        v-model="data.paddingBottom"
      />
    </div>
  </div>
  <p class="caption caption--xs mb-2">Externo</p>
  <div class="row">
    <div>
      <NumberField
        class="mb-2"
        label="Esquerda"
        v-model="data.marginLeft"
      />
      <NumberField
        class="mb-0"
        label="Direita"
        v-model="data.marginRight"
      />
    </div>
    <div>
      <NumberField
        class="mb-2"
        label="Superior"
        v-model="data.marginTop"
      />
      <NumberField
        class="mb-0"
        label="Inferior"
        v-model="data.marginBottom"
      />
    </div>
  </div>
</section>
</template>
