<script setup>
import { computed, ref, watch } from 'vue'
import { copy } from '@/support/object'
import { emptyStyle } from '../../support.js'
import slugify from 'slugify'

import CButton from '@/components/CButton'
import CInput from '@/components/components/form/Input'
import Collapsible from '@/components/Collapsible'
import FillAttrs from './Fill.vue'
import LayoutAttrs from './Layout.vue'
import OthersAttrs from './Others.vue'
import SizingAttrs from './Sizing.vue'
import SpacingAttrs from './Spacing.vue'
import StrokeAttrs from './Stroke.vue'
import TextAttrs from './Text.vue'

const emit = defineEmits(['cancel', 'saved', 'update:modelValue'])
const props = defineProps({
  modelValue: Array,
  styleDef: Object
})

const style = ref({})
const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})

const save = () => {
  if (!style.value.id) {
    style.value.id = (new Date()).getTime()
  }

  const index = data.value.findIndex(one => one.id === style.value.id)

  index >= 0
    ? data.value.splice(index, 1, style.value)
    : data.value.push(style.value)

  emit('saved')
}

watch(() => props.styleDef, def => {
  style.value = def ? copy(def) : emptyStyle()
}, { immediate: true })
watch(() => style.value.name, name => { if (!style.value.id) style.value.class = name })
watch(() => style.value.class, (value = '') => {
  style.value.class = slugify(value, { lower: true })
})
</script>

<template>
<section class="style-attrs">
  <div class="actions">
    <CButton
      @click="emit('cancel')"
      caption="Cancelar"
      class="mr-2"
    />
    <CButton
      @click="save()"
      caption="Salvar"
      class="btn--secondary"
    />
  </div>
  <CInput
    id="styleName"
    label="Nome do Estilo"
    :readonly="style.is_default"
    v-model.trim="style.name"
  />
  <div class="input">
    <label class="input__switch">
      <input false-value="" type="checkbox" true-value=":hover" v-model="style.state">
      <span>Usar quando passar o mouse</span>
    </label>
  </div>
  <CInput
    id="styleClass"
    label="Informe o nome da classe para o estilo"
    :readonly="style.is_default"
    v-model.trim="style.class"
    v-if="false"
  />
  <div
    class="mb-6"
    v-if="style.declarations"
  >
    <Collapsible title="Espaçamentos">
      <SpacingAttrs v-model="style.declarations" />
    </Collapsible>
    <Collapsible title="Tamanho">
      <SizingAttrs v-model="style.declarations" />
    </Collapsible>
    <Collapsible title="Layout">
      <LayoutAttrs v-model="style.declarations" />
    </Collapsible>
    <Collapsible title="Bordas">
      <StrokeAttrs v-model="style.declarations" />
    </Collapsible>
    <Collapsible title="Preenchimento">
      <FillAttrs v-model="style.declarations" />
    </Collapsible>
    <Collapsible title="Texto">
      <TextAttrs v-model="style.declarations" />
    </Collapsible>
    <Collapsible title="Outros">
      <OthersAttrs v-model="style.declarations" />
    </Collapsible>
  </div>
</section>
</template>

<style scoped>
textarea.input__control {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.85rem;
  font-weight: 700;
}

.actions {
  margin-bottom: var(--space-6);
}
</style>
