<script>
export default { inheritAttrs: false }
</script>

<script setup>
import { computed, useAttrs } from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  id: String,
  label: String,
  modelValue: [Number, String],
  rightAddOn: [Number, String],
  type: {
    default: 'text',
    type: String
  }
})
const attrs = useAttrs()

const value = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
</script>

<template>
<div
  class="input input--column flex--1"
  :class="attrs.class"
>
  <label
    class="input__label"
    :for="id"
    v-if="label"
  >{{ label }}</label>
  <div class="input__group flex">
    <input
      class="input__control"
      :id="id"
      :type="type"
      v-bind="{ ...attrs, class: '' }"
      v-model="value"
    />
    <span
      class="input__control input__control--static px-2"
      v-if="rightAddOn"
    >{{ rightAddOn }}</span>
  </div>
  <slot />
</div>
</template>
