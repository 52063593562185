<script setup>
import { computed } from 'vue'
import { emptyPage } from '../../support.js'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import CButton from '@/components/CButton'
import Page from './Page.vue'
import Toolbar from '@/components/Toolbar'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  facts: Object,
  modelValue: Array
})

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
const v$ = useVuelidate({ data: { required } }, { data })

// eslint-disable-next-line no-undef
defineExpose({ v$ })

function addPage () {
  data.value.push(emptyPage())
}

function remove (page) {
  data.value.splice(data.value.findIndex(one => one === page), 1)
}
</script>

<template>
<section>
  <Toolbar>
    <template #actions>
      <div class="toolbar__group">
        <CButton
          class="btn--secondary"
          @click="addPage()"
          icon="add"
        />
      </div>
    </template>
  </Toolbar>
  <Page
    :facts="facts"
    :index="index"
    :key="page.id"
    @remove="remove"
    v-for="(page, index) in data"
    v-model="data[index]"
  />
  <span class="input__error" v-if="v$.data.$error">Adicione pelo menos 1 página</span>
</section>
</template>
