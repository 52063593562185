<script setup>
import { computed, ref } from 'vue'

import CButton from '@/components/CButton'

const props = defineProps({
  item: Object,
  roles: Array
})

const showAbilities = ref(false)

const roleLabel = computed(
  () => props.roles.find(role => role.key === props.item.role)?.label || props.item.role
)
const roleAbilities = computed(
  () => props.roles.find(role => role.key === props.item.role)?.abilities_labels || []
)
</script>

<template>
<div class="users__role-abilities">
  {{ roleLabel }}
  <CButton
    class="btn--icon btn--fit btn--ghost users__role-abilities-list-toggler"
    @click="showAbilities = !showAbilities"
    :icon="showAbilities ? 'expand_less' : 'expand_more'"
  />
  <div
    class="card users__role-abilities-list text--small"
    v-if="showAbilities"
  >
    <h3 class="caption caption--xs mb-2">Habilidades:</h3>
    <div
      :key="ability"
      v-for="ability of roleAbilities"
    >{{ ability }}</div>
  </div>
</div>
</template>

<style lang="scss">
.users__role-abilities {
  position: relative;
  white-space: nowrap;
}

.users__role-abilities-list-toggler {
  margin-top: -2px;
}

.users__role-abilities-list-toggler .icon {
  line-height: 0.5;
}

.users__role-abilities-list {
  padding: var(--space-2);
  position: absolute;
  white-space: nowrap;
  z-index: 8;
}
</style>
