<template>
<AppLayout>
  <section class="layout__main platform-accounts">
    <div class="container">
      <Toolbar
        :has-primary-action="true"
        @primary-action="showEditModal = true"
        primary-action-caption="Adicionar Conta"
        primary-action-icon="add"
        title="Contas de Mailing"
      />

      <List
        @destroy="confirmDestroy"
        @edit="edit"
        :items="accounts"
        :loading="loading"
      />
    </div>
  </section>

  <Modal
    @close="showEditModal = false"
    :closeable-by-backdrop="false"
    :show-close-button="true"
    size="xxl"
    :title="(account.id ? 'Editar' : 'Adicionar') + ' Conta' + (account.id ? ` [#${account.id}]` : '')"
    v-if="showEditModal"
  >
    <form
      class="form"
      id="platformAccountModalForm"
      @submit.prevent="tryToSave"
    >
      <fieldset>
        <div class="form__fields form__fields--row">
          <div class="input">
            <label class="input__label" for="accountName">Nome da conta</label>
            <input class="input__control" id="accountName" type="text" v-model.trim="account.name" />
            <span class="input__error" v-if="v$.account.name.$error">Informe o nome da conta</span>
          </div>
          <div class="input">
            <label class="input__label" for="accountMaximumEngaged">Máximo de engajados</label>
            <input class="input__control" id="accountMaximumEngaged" type="text" v-model.number="account.maximum_engaged" />
            <span class="input__error" v-if="v$.account.maximum_engaged.$error">Informe o máximo de leads engajados</span>
          </div>
          <div class="input input--no-label">
            <label class="input__switch nowrap">
              <input type="checkbox" v-model="account.is_default" />
              <span>Conta remanescente</span>
            </label>
          </div>
        </div>
        <div class="form__fields form__fields--row">
          <div class="input">
            <label class="input__label" for="accountPlatform">Plataforma</label>
            <div class="input__select">
              <select
                class="input__control"
                :class="{ 'disabled': !!account.id }"
                id="accountPlatform"
                v-model="account.platform"
              >
                <option value="">[ Selecione ]</option>
                <option
                  :key="platform.key"
                  :value="platform.key"
                  v-for="platform of platforms"
                >{{ platform.name }}</option>
              </select>
            </div>
            <span class="input__error" v-if="v$.account.platform.$error">Escolha uma plataforma</span>
          </div>
          <div class="input flex--2 flex--col">
            <label class="input__label" for="accountEndpoint">URL da API</label>
            <div class="input__group flex">
              <input class="input__control" id="accountEndpoint" type="text" v-model.trim="accountEndpoint" />
              <span
                class="input__control input__control--static"
                v-if="accountEndpointVersion"
              >{{ accountEndpointVersion }}</span>
            </div>
            <span v-if="v$.account.endpoint.$error">
              <span class="input__error" v-if="v$.account.endpoint.required.$invalid">Informe o endpoint da conta</span>
              <span class="input__error" v-if="!v$.account.endpoint.required.$invalid && v$.account.endpoint.url.$invalid">Informe uma url válida</span>
            </span>
          </div>
        </div>
        <div
          class="form__fields form__fields--row"
          v-if="usesPassword"
        >
          <div class="input">
            <label class="input__label" for="accountUser">Usuário</label>
            <input class="input__control" id="accountUser" type="text" v-model.trim="account.meta.user" />
            <span class="input__error" v-if="v$.account.meta.user.$error">Informe o usuário da plataforma</span>
          </div>
          <div class="input">
            <label class="input__label" for="accountPassword">Senha</label>
            <input class="input__control" id="accountPassword" type="text" v-model.trim="account.meta.password" />
            <span class="input__error" v-if="v$.account.meta.password.$error">Informe a senha da plataforma</span>
          </div>
        </div>
        <div
          class="input"
          v-if="usesToken"
        >
          <label class="input__label" for="accountToken">Token da API</label>
          <input class="input__control" id="accountToken" type="text" v-model.trim="account.token" />
          <span class="input__error" v-if="v$.account.token.$error">Informe o token de integração da conta</span>
        </div>
        <div class="input">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="account.start_warming_now" />
            <span>Iniciar aquecimento agora</span>
          </label>
        </div>
      </fieldset>

      <fieldset>
        <Toolbar title="Listas">
          <template #actions>
            <div class="toolbar__group">
              <CButton
                caption="Adicionar Lista"
                class="btn--secondary"
                @click="addList"
                icon="add"
              />
            </div>
          </template>
        </Toolbar>

        <section>
          <div
            class="form__fields form__fields--row mb-4"
            :key="index"
            v-for="(list, index) in account.lists"
          >
            <div class="input">
              <input class="input__control" placeholder="Id" type="text" v-model="list.id" />
            </div>
            <div class="input flex--2">
              <input class="input__control" placeholder="Nome" type="text" v-model="list.name" />
            </div>
            <div class="input">
              <label class="input__switch nowrap">
                <input type="checkbox" v-model="list.is_of_engaged" />
                <span>De engajados</span>
              </label>
            </div>
            <div class="input">
              <label class="input__switch nowrap">
                <input type="checkbox" v-model="list.is_default" />
                <span>Lista padrão</span>
              </label>
            </div>
            <button
              class="btn btn--icon"
              @click="removeList(list)"
              type="button"
            ><span class="material-icons-outlined">clear</span></button>
          </div>
          <span class="input__error" v-if="v$.account.lists.$error">Adicione pelo menos 1 lista</span>
        </section>
      </fieldset>

      <fieldset>
        <Toolbar title="Campos">
          <template #actions>
            <div class="toolbar__group">
              <CButton
                caption="Sincronizar Campos"
                class="btn--secondary"
                :class="{ loading: syncingFields }"
                @click="syncFields"
                :disabled="!account.endpoint || !account.token"
                icon="sync"
                v-if="currentPlatform?.syncableFields"
              />
              <CButton
                caption="Adicionar Campo"
                class="btn--secondary"
                @click="addField()"
                icon="add"
              />
            </div>
          </template>
        </Toolbar>

        <section>
          <div
            class="form__fields form__fields--row mb-4"
            :key="index"
            v-for="(field, index) in account.fields"
          >
            <div class="input flex--2">
              <div class="input__select mb-0">
                <select class="input__control" v-model="field.field_id">
                  <option :value="null">[ Selecione ]</option>
                  <option
                    :key="fieldDef.id"
                    :value="fieldDef.id"
                    v-for="fieldDef of fields"
                  >{{ fieldDef.name }}</option>
                </select>
              </div>
            </div>
            <div class="input">
              <input class="input__control" placeholder="Id" type="text" v-model.number="field.id" />
            </div>
            <div class="input flex--2">
              <input class="input__control" placeholder="Chave" type="text" v-model="field.key" />
            </div>
            <div class="input flex--2">
              <input class="input__control" placeholder="Nome" type="text" v-model="field.name" />
            </div>
            <button
              class="btn btn--icon"
              @click="removeField(field)"
              type="button"
            ><span class="material-icons-outlined">clear</span></button>
          </div>
        </section>
      </fieldset>
    </form>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="showEditModal = false"
        />
        <CButton
          caption="Salvar"
          class="btn--primary"
          form="platformAccountModalForm"
          icon="save"
          type="submit"
        />
      </footer>
    </template>
  </Modal>

  <Modal
    @close="confirmingDestroy = false"
    title="Confirmação"
    v-if="confirmingDestroy"
  >
    <template #default>
      <p>Tem certeza que deseja apagar a conta <strong>{{ accountToDestroy.name }}</strong>?</p>
      <Alert
        class="alert--info alert--ghost"
        icon="info"
        title="
          Se a conta participar de alguma lógica interna não será possível apagá-la.
          Você precisará desassociar a conta das lógicas e tentar apagá-la novamente.
        "
        v-if="false"
      />
      <Alert
        class="alert--info alert--ghost"
        icon="info"
        title="Se a conta participar de alguma lógica interna a regra também será apagada."
      />
    </template>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="confirmingDestroy = false"
        />
        <CButton
          caption="Apagar"
          class="btn--danger"
          @click="destroy"
          icon="delete"
        />
      </footer>
    </template>
  </Modal>
</AppLayout>
</template>

<script>
import Alert from '@/components/Alert'
import AppLayout from '@/components/layout/App'
import CButton from '@/components/CButton'
import List from './platform-accounts/List'
import { mapState } from 'vuex'
import Modal from '@/components/Modal'
import platformAccounts from '@/repositories/platform-accounts'
import { required, requiredIf, url } from '@vuelidate/validators'
import Toolbar from '@/components/Toolbar'
import { useVuelidate } from '@vuelidate/core'
import * as obj from '@/support/object'

const emptyAccount = () => ({
  endpoint: '',
  fields: [],
  id: null,
  is_default: false,
  lists: [],
  maximum_engaged: 1000,
  meta: {
    password: '',
    user: ''
  },
  name: '',
  platform: '',
  start_warming_at: null,
  start_warming_now: false,
  token: ''
})

export default {
  name: 'PlatformAccounts',

  components: {
    Alert,
    AppLayout,
    CButton,
    List,
    Modal,
    Toolbar
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      account: emptyAccount(),
      accountToDestroy: {},
      confirmingDestroy: false,
      loading: false,
      platforms: [
        {
          key: 'ActiveCampaign',
          name: 'ActiveCampaign',
          auth: 'token',
          endpointSuffix: '/api/3/',
          syncableFields: true
        },
        // {
        //   key: 'Mautic',
        //   name: 'Mautic',
        //   auth: 'password',
        //   endpointSuffix: '/api/',
        //   syncableFields: true
        // },
        // {
        //   key: 'Akna',
        //   name: 'Akna',
        //   auth: 'password',
        //   endpoint: 'https://app.akna.com.br/emkt/int/integracao.php',
        //   endpointSuffix: '',
        //   syncableFields: false
        // }
      ],
      showEditModal: false,
      syncingFields: false
    }
  },

  created () {
    this.unwatch = this.$watch(
      () => this.$route,
      async () => {
        this.loading = true
        await this.$store.dispatch('fields/loadFields')
        await this.$store.dispatch('loadPlatformAccounts')
        this.loading = false
      },
      { immediate: true }
    )
  },

  beforeRouteLeave () {
    this.unwatch()
  },

  computed: {
    ...mapState({
      accounts: 'platformAccounts',
      fields: state => state.fields.fields
    }),

    accountEndpoint: {
      get () {
        return this.account.endpoint.replace(this.accountEndpointVersion, '')
      },
      set (value) {
        this.account.endpoint = value + this.accountEndpointVersion
      }
    },

    accountEndpointVersion () {
      return this.currentPlatform?.endpointSuffix
    },

    currentPlatform () {
      return this.platforms.find(platform => platform.key === this.account.platform) || {}
    },

    usesPassword () {
      return this.currentPlatform?.auth === 'password'
    },

    usesToken () {
      return this.currentPlatform?.auth === 'token'
    }
  },

  watch: {
    'account.meta.password': 'setPasswordToken',

    'account.meta.user': 'setPasswordToken',

    'account.platform' (platform, old) {
      if (!this.account.id) {
        this.accountEndpoint = ''
      }

      if (platform !== old && this.currentPlatform?.endpoint) {
        this.accountEndpoint = this.currentPlatform?.endpoint
      }
    },

    'account.start_warming_now' (now) {
      if (!now) return

      const date = new Date()

      this.account.start_warming_at = [
        date.getFullYear(), '-',
        (date.getMonth() + 1).toString().padStart(2, 0), '-',
        date.getDate().toString().padStart(2, 0), ' ',
        date.getHours().toString().padStart(2, 0), ':',
        date.getMinutes().toString().padStart(2, 0), ':',
        date.getSeconds().toString().padStart(2, 0)
      ].join('')
    },

    showEditModal (show) {
      if (!show) {
        this.account = emptyAccount()
        this.v$.$reset()
      }
    }
  },

  methods: {
    addField (field) {
      this.account.fields.push(field || {
        field_id: null,
        id: '',
        name: '',
        key: ''
      })
    },

    addList () {
      this.account.lists.push({
        id: '',
        name: '',
        is_of_engaged: false,
        is_default: false
      })
    },

    confirmDestroy (account) {
      this.accountToDestroy = account
      this.confirmingDestroy = true
    },

    async destroy () {
      await this.$store.dispatch('destroyPlatformAccount', this.accountToDestroy)
      this.accountToDestroy = {}
      this.confirmingDestroy = false
    },

    edit (account) {
      this.account = obj.copy(account)
      this.account.fields.map(field => { field.field_id ??= null })
      this.showEditModal = true
    },

    removeField (field) {
      this.account.fields.splice(this.account.fields.findIndex(one => one === field), 1)
    },

    removeList (list) {
      this.account.lists.splice(this.account.lists.findIndex(one => one === list), 1)
    },

    setPasswordToken () {
      if (this.usesPassword) {
        this.account.token = Buffer.from(this.account.meta.user + ':' + this.account.meta.password).toString('base64')
      }
    },

    async syncFields () {
      this.syncingFields = true

      try {
        const fields = await platformAccounts.fields(this.account)
        const ids = this.account.fields.map(field => field.id)

        fields.filter(field => !ids.includes(field.id))
          .forEach(field => this.addField({ field_id: null, ...field }))
      } catch (error) {
        console.error(error)
      } finally {
        this.syncingFields = false
      }
    },

    async tryToSave () {
      if (!await this.v$.$validate()) return

      await this.$store.dispatch('savePlatformAccount', this.account)

      this.showEditModal = false
    }
  },

  validations () {
    return {
      account: {
        endpoint: { required, url },
        is_default: { required },
        lists: { required },
        maximum_engaged: { required },
        meta: {
          password: { required: requiredIf(this.usesPassword) },
          user: { required: requiredIf(this.usesPassword) }
        },
        name: { required },
        platform: { required },
        token: { required: requiredIf(this.usesToken) }
      }
    }
  }
}
</script>
