<script setup>
import { computed, ref, watch, watchEffect } from 'vue'
import { format } from '@/support/number'
import { hours, hoursAndMinutes, minutes } from './support'
import { maxLength, minValue, required, url } from '@vuelidate/validators'
import { thru } from 'lodash'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import * as dayjs from 'dayjs'
import * as obj from '@/support/object'
import isToday from 'dayjs/plugin/isToday'

dayjs.extend(isToday)

import CButton from '@/components/CButton'
import CInput from '@/components/components/form/Input'
import CModal from '@/components/Modal'
import CSelect from '@/components/components/form/Select'
import DateSelect from '@/components/components/form/DateSelect'
import FileInput from '@/components/components/form/FileInput'

const emit = defineEmits(['close'])
const props = defineProps({ item: Object })

const emptyNotification = () => {
  const now = dayjs()
  const at = now.add(2, 'hours').minute(clampMinutes(now.minute()))
  const tomorrow = now.add(1, 'day')

  return {
    configs: {
      has_actions: false,
      schedule_at: at.toDate(),
      recurrence: 'daily',
      recurrence_start_at: tomorrow.minute(clampMinutes(now.minute(), 30)).second(0).toDate(),
      recurrence_end_at: tomorrow.add(1, 'year').startOf('day').toDate()
    },
    id: null,
    is_recurrent: false,
    is_scheduled: false,
    message: '',
    push_account_id: undefined,
    segment_id: undefined,
    title: '',
    url: '',
  }
}

const store = useStore()
const rules = computed(() => ({
  configs: {
    schedule_at: notification.value.is_scheduled
      ? { required, minValue: minValue(nowTracker) }
      : {},
  },
  message: { required, maxLength: maxLength(192) },
  push_account_id: { required },
  segment_id: { required },
  title: { required, maxLength: maxLength(64) },
  url: { required, url },
}))
const notification = ref(emptyNotification())
const nowTracker = ref()
const segmentsPlaceholder = ref()
const saving = ref(false)
const v$ = useVuelidate(rules, notification)

const isEditing = computed(() => !!notification.value.id)
const accounts = computed(() => store.state.pushAccounts.accounts)
const segments = computed(() => store.state.pushAccounts.segments.map(one => ({
  ...one,
  name: `${one.name} (${format(one.subscribers)} leads)`
})))
const hoursValue = computed({
  get: () => dayjs(notification.value.configs.schedule_at).hour(),
  set: value => notification.value.configs.schedule_at =
    dayjs(notification.value.configs.schedule_at).hour(value).toDate()
})
const minutesValue = computed({
  get: () => dayjs(notification.value.configs.schedule_at).minute(),
  set: value => notification.value.configs.schedule_at =
    dayjs(notification.value.configs.schedule_at).minute(clampMinutes(value)).toDate()
})
const validHours = computed(() => {
  const at = thru(
    dayjs(notification.value.configs.schedule_at),
    at => at.isToday() ? dayjs() : at.startOf('day')
  )

  return hours().map(one => ({ ...one, disabled: at.hour(one.id) < at }))
})
const validMinutes = computed(() => {
  const now = dayjs()
  const isToday = dayjs(notification.value.configs.schedule_at).isToday()
  const isCurrentHour = hoursValue.value === now.hour()

  return minutes().map(one => ({
    ...one,
    disabled: isToday && isCurrentHour && one.id < now.minute()
  }))
})
const recurrenceTimeValue = computed({
  get: () => dayjs(notification.value.configs.recurrence_start_at).format('HH:mm'),
  set: value => notification.value.configs.recurrence_start_at =
    dayjs(notification.value.configs.recurrence_start_at)
      .hour(parseInt(value.split(':')[0]))
      .minute(clampMinutes(parseInt(value.split(':')[1]), 30))
      .startOf('minute')
      .toDate()
})

const close = () => {
  notification.value = emptyNotification()

  v$.value.$reset()
  emit('close')
}

const tryToSave = async () => {
  if (!await v$.value.$validate()) return

  try {
    saving.value = true
    await store.dispatch('pushNotifications/saveNotification', notification.value)
    close()
  } finally {
    saving.value = false
  }
}

watchEffect(() => {
  const item = obj.isEmpty(props.item) ? emptyNotification() : obj.copy(props.item)
  notification.value = item
})
watch(
  () => notification.value.push_account_id,
  async value => {
    store.commit('pushAccounts/setSegments', [])

    if (!value) return

    const segmentId = notification.value.segment_id
    notification.value.segment_id = undefined
    segmentsPlaceholder.value = 'Carregando ...'
    await store.dispatch('pushAccounts/loadSegments', value)
    segmentsPlaceholder.value = undefined

    if (segmentId) {
      notification.value.segment_id = segmentId
    }
  },
  { immediate: true }
)
watch(
  () => notification.value.segment_id,
  value => notification.value.configs.segment = segments.value.find(one => one.id === value)
    .name.replace(/\s\(.+\)$/, '')
)
watch(
  () => notification.value.configs.schedule_at,
  value => {
    value = typeof value === 'string' ? new Date(value) : value
    notification.value.configs.schedule_at_time = value.getTime() / 1000

    if (validHours.value.find(one => one.id === hoursValue.value).disabled) {
      hoursValue.value = validHours.value.find(one => !one.disabled).id
    }

    if (validMinutes.value.find(one => one.id === minutesValue.value).disabled) {
      minutesValue.value = validMinutes.value.find(one => !one.disabled).id
    }
  },
  { immediate: true }
)
watch(
  () => notification.value.is_scheduled,
  value => { if (value) notification.value.is_recurrent = false }
)
watch(
  () => notification.value.is_recurrent,
  value => { if (value) notification.value.is_scheduled = false }
)

setupNowTracker()

function clampMinutes (minutes, steps = 5) {
  return Math.ceil(minutes / steps) * steps
}

function setupNowTracker () {
  setTimeout(() => {
    nowTracker.value = dayjs()
    setupNowTracker()
  }, 5000)
}
</script>

<template>
<CModal
  @close="close"
  :closeable-by-backdrop="false"
  :show-close-button="!saving"
  size="lg"
  :title="isEditing ? `Editar Push Notification [#${notification.id}]` : 'Adicionar Push Notification'"
>
  <form
    class="form"
    id="notificationModalForm"
    @submit.prevent="tryToSave"
  >
    <fieldset>
      <div class="form__fields form__fields--row">
        <CSelect
          :disabled="isEditing"
          id="notificationPushAccountId"
          label="Conta"
          :options="accounts"
          v-model="notification.push_account_id"
        >
          <span class="input__error" v-if="v$.push_account_id.$error">Escolha uma conta</span>
        </CSelect>
        <CSelect
          :disabled="isEditing"
          id="notificationSegmentId"
          label="Segmento"
          :options="segments"
          :placeholder="segmentsPlaceholder"
          v-model="notification.segment_id"
        >
          <span class="input__error" v-if="v$.segment_id.$error">Escolha um segmento</span>
        </CSelect>
      </div>
      <CInput
        id="notificationTitle"
        label="Título"
        :maxlength="v$.title.maxLength.$params.max"
        :right-add-on="(v$.title.maxLength.$params.max - notification.title.length) + ''"
        v-model.trim="notification.title"
      >
        <span class="input__error" v-if="v$.title.$error">Informe o título da notificação</span>
      </CInput>
      <div class="input input--column">
        <label class="input__label" for="notificationMessage">Mensagem</label>
        <div class="input__group flex">
          <textarea
            class="input__control"
            id="notificationMessage"
            :maxlength="v$.message.maxLength.$params.max"
            rows="3"
            v-model.trim="notification.message"
          ></textarea>
          <span
            class="input__control input__control--static px-2"
            style="height: auto"
          >{{ v$.message.maxLength.$params.max - notification.message.length }}</span>
        </div>
        <span class="input__error" v-if="v$.message.$error">Informe a mensagem da notificação</span>
      </div>
      <CInput
        id="notificationUrl"
        label="URL"
        v-model.trim="notification.url"
      >
        <span class="input__error" v-if="v$.url.$error">Informe uma URL válida</span>
      </CInput>
    </fieldset>

    <fieldset>
      <h5 class="mb-4">Agendamento e Recorrência</h5>
      <div class="input">
        <label class="input__switch nowrap" :class="{ disabled: isEditing }">
          <input :disabled="isEditing" type="checkbox" v-model="notification.is_scheduled" />
          <span>É agendado</span>
        </label>
      </div>
      <div class="input">
        <label class="input__switch nowrap" :class="{ disabled: isEditing }">
          <input :disabled="isEditing" type="checkbox" v-model="notification.is_recurrent" />
          <span>É recorrente</span>
        </label>
      </div>
      <div
        class="form__fields form__fields--row"
        v-if="notification.is_scheduled"
      >
        <DateSelect
          label="Enviar dia"
          :min-date="new Date()"
          :min-year="(new Date()).getFullYear()"
          :show-shortcuts="false"
          v-model="notification.configs.schedule_at"
        >
          <span
            class="input__error"
            v-if="v$.configs.schedule_at?.$error"
          >Informe uma data e horário válidos, no futuro</span>
        </DateSelect>
        <div class="input input--column flex--1">
          <label class="input__label" for="hoursValue">No horário</label>
          <div class="flex time-fields">
            <CSelect
              id="hoursValue"
              :nullable="false"
              :options="validHours"
              v-model="hoursValue"
            />
            <CSelect
              id="minutesValue"
              :nullable="false"
              :options="validMinutes"
              v-model="minutesValue"
            />
          </div>
        </div>
      </div>
      <div
        class="form__fields form__fields--row"
        v-if="notification.is_recurrent"
      >
        <CSelect
          id="recurrence"
          label="Enviar"
          :nullable="false"
          :options="[
            { id: 'daily', name: 'Diariamente' },
            { id: 'weekly', name: 'Semanalmente' },
            { id: 'monthly', name: 'Mensalmente' },
          ]"
          v-model="notification.configs.recurrence"
        />
        <DateSelect
          label="Iniciando em"
          :min-date="dayjs().add(1, 'day').toDate()"
          :min-year="dayjs().year()"
          :show-shortcuts="false"
          v-model="notification.configs.recurrence_start_at"
        />
        <CSelect
          id="recurrenceTime"
          label="No horário"
          :nullable="false"
          :options="hoursAndMinutes()"
          style="max-width: 100px;"
          v-model="recurrenceTimeValue"
        />
        <DateSelect
          label="Finalizando em"
          :min-date="dayjs().add(1, 'day').toDate()"
          :min-year="dayjs().year()"
          right
          :show-shortcuts="false"
          v-model="notification.configs.recurrence_end_at"
        />
      </div>
    </fieldset>

    <fieldset>
      <h5 class="mb-4">Ações</h5>
      <div class="input">
        <label class="input__switch nowrap">
          <input type="checkbox" v-model="notification.configs.has_actions" />
          <span>Usar botões de ação</span>
        </label>
      </div>
      <div v-if="notification.configs.has_actions">
        <div class="form__fields form__fields--row mb-4">
          <span class="input__control input__control--static action-label">Ação 1</span>
          <CInput
            class="action-label-field"
            id="notificationAction1Title"
            label="Título"
            :maxlength="16"
            :right-add-on="(16 - (notification.configs.action_1_title?.length ?? 0)) + ''"
            v-model.trim="notification.configs.action_1_title"
          />
          <CInput
            id="notificationAction1Url"
            label="URL"
            v-model.trim="notification.configs.action_1_url"
          />
        </div>
        <div class="form__fields form__fields--row mb-4">
          <span class="input__control input__control--static action-label">Ação 2</span>
          <CInput
            class="action-label-field"
            id="notificationAction2Title"
            label="Título"
            :maxlength="16"
            :right-add-on="(16 - (notification.configs.action_2_title?.length ?? 0)) + ''"
            v-model.trim="notification.configs.action_2_title"
          />
          <CInput
            id="notificationAction2Url"
            label="URL"
            v-model.trim="notification.configs.action_2_url"
          />
        </div>
      </div>
    </fieldset>

    <fieldset>
      <h5 class="mb-4">Outras Configurações</h5>
      <div class="form__fields form__fields--row valign--stretch">
        <div class="image-preview mr-3">
          <img
            :src="notification.configs.image"
            v-if="notification.configs.image"
          >
          <span
            class="icon muted"
            v-else
          >image</span>
        </div>
        <FileInput
          accept="image/png, image/jpeg"
          class="mb-0"
          folder="push"
          help="Prefira imagens com tamanho: 720x360 pixels"
          id="image"
          label="Imagem grande"
          style="min-width: 0;"
          v-model.trim="notification.configs.image"
        />
      </div>
    </fieldset>
  </form>

  <template #footer>
    <footer class="modal__footer">
      <CButton
        caption="Cancelar"
        @click="close"
        :disabled="saving"
      />
      <CButton
        caption="Salvar"
        class="btn--primary"
        :class="{ loading: saving }"
        form="notificationModalForm"
        icon="save"
        type="submit"
      />
    </footer>
  </template>
</CModal>
</template>

<style scoped>
:deep(.modal--lg) {
  width: 650px;
}

.input__switch.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.time-fields {
  align-self: stretch;
}

.action-label {
  justify-content: center;
  margin: calc(var(--space-5) + 2px) var(--space-3) 0 0;
  max-width: var(--space-16);
  min-width: var(--space-16);
  padding: 0;
  white-space: nowrap;
}

.action-label-field {
  max-width: 13rem;
}

.image-preview {
  align-items: center;
  background: var(--color-gray-lighter);
  border-radius: 3px;
  display: flex;
  flex-shrink: 0;
  height: 62px;
  justify-content: center;
  overflow: hidden;
  width: 100px;
}

.image-preview img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
</style>
