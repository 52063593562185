<template>
<AppLayout>
  <section class="layout__main quiz-questions">
    <div class="container">
      <Toolbar
        :has-primary-action="true"
        @primary-action="showEditModal = true"
        primary-action-caption="Adicionar Pergunta"
        primary-action-icon="add"
        title="Perguntas de Quiz"
      />

      <List
        @destroy="confirmDestroy($event)"
        @edit="edit($event)"
        :items="quizQuestions"
        :loading="loading"
        :response-types="availableResponseTypes"
      />
    </div>
  </section>

  <Modal
    @close="showEditModal = false"
    :closeable-by-backdrop="false"
    size="xl"
    :show-close-button="true"
    :title="(question.id ? 'Editar' : 'Adicionar') + ' Pergunta' + (question.id ? ` [#${question.id}]` : '')"
    v-if="showEditModal"
  >
    <form
      class="form"
      id="questionModalForm"
      @submit.prevent="tryToSave"
    >
      <fieldset>
        <div class="form__fields form__fields--row">
          <div class="input flex--2">
            <label class="input__label" for="questionQuestion">Texto da pergunta</label>
            <input class="input__control" id="questionQuestion" type="text" v-model.trim="question.question" />
            <span class="input__error" v-if="v$.question.question.$error">Informe o texto da pergunta</span>
          </div>
          <div class="input">
            <label class="input__label" for="questionKey">Pergunta em no máximo duas palavras</label>
            <input class="input__control" id="questionKey" :readonly="!!question.id" type="text" v-model.trim="question.key" />
            <span class="input__error" v-if="v$.question.key.$error">Informe a chave da pergunta</span>
          </div>
        </div>
        <FileInput
          accept="image/svg+xml, image/png, image/jpeg"
          folder="quizzes"
          id="questionImage"
          label="URL da imagem"
          v-model.trim="question.configs.image"
        />
        <div class="form__fields form__fields--row">
          <div class="input">
            <label class="input__label" for="questionResponseType">Tipo de resposta</label>
            <div class="input__select">
              <select
                class="input__control"
                :disabled="!!question.id"
                name="questionResponseType"
                id="questionResponseType"
                v-model="question.response_type"
              >
                <option :value="null">[ Selecione ]</option>
                <option
                  :key="option.key"
                  :value="option.key"
                  v-for="option of availableResponseTypes"
                >{{ option.label }}</option>
              </select>
            </div>
            <span class="input__error" v-if="v$.question.response_type.$error">Escolha um tipo de resposta</span>
          </div>
          <div class="input">
            <label class="input__label" for="questionResponseKey">Chave de respostas</label>
            <input
              class="input__control"
              id="questionResponseKey"
              :readonly="!!question.id"
              type="text"
              v-model.trim="question.configs.response_key"
            />
            <span class="input__error" v-if="v$.question.configs.response_key.$error">Informe a chave de resposta</span>
          </div>
          <div class="input input--no-label">
            <label class="input__switch nowrap">
              <input type="checkbox" v-model="question.is_active" />
              <span>Está ativa</span>
            </label>
          </div>
        </div>
      </fieldset>

      <fieldset v-if="question.response_type">
        <Toolbar title="Configurações" />

        <div class="input">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="question.configs.required" />
            <span>Resposta obrigatória</span>
          </label>
        </div>
        <div
          class="form__fields form__fields--row"
          v-if="question.response_type === 'multiple-choice'"
        >
          <div class="input">
            <label class="input__label" for="questionConfigMinChoices">Mínimo de escolhas</label>
            <input class="input__control" id="questionConfigMinChoices" type="text" v-model.number="question.configs.minChoices" />
          </div>
          <div class="input">
            <label class="input__label" for="questionConfigMaxChoices">Máximo de escolhas</label>
            <input class="input__control" id="questionConfigMaxChoices" type="text" v-model.number="question.configs.maxChoices" />
          </div>
        </div>
        <div
          class="form__fields form__fields--row"
          v-if="question.response_type === 'text'"
        >
          <div class="input">
            <label class="input__label" for="questionConfigMinLength">Mínimo de caracteres</label>
            <input class="input__control" id="questionConfigMinLength" type="text" v-model.number="question.configs.minLength" />
          </div>
          <div class="input">
            <label class="input__label" for="questionConfigMaxLength">Máximo de caracteres</label>
            <input class="input__control" id="questionConfigMaxLength" type="text" v-model.number="question.configs.maxLength" />
          </div>
        </div>
        <div class="input">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="question.configs.horizontal_options" />
            <span>Mostrar opções horizontalmente</span>
          </label>
        </div>
      </fieldset>

      <fieldset v-if="isOptionableResponse">
        <Toolbar title="Opções">
          <template
            #actions
            v-if="isOpenOptionsResponse"
          >
            <div class="toolbar__group">
              <CButton
                caption="Adicionar Opção"
                class="btn--secondary"
                @click="addOption"
                icon="add"
              />
            </div>
          </template>
        </Toolbar>

        <section>
          <div
            class="form__fields form__fields--row"
            :key="index"
            v-for="(option, index) in question.response_options"
          >
            <div class="input">
              <input
                class="input__control"
                @input="isOpenOptionsResponse && setValue(option)"
                placeholder="Nome"
                type="text"
                v-model.trim="option.label"
              />
            </div>
            <FileInput
              accept="image/svg+xml, image/png, image/jpeg"
              class="quiz-question__option-image"
              folder="quizzes"
              :id="'questionImage' + index"
              v-model.trim="option.image"
            />
            <div class="input">
              <input
                class="input__control"
                placeholder="Valor"
                :readonly="!isOpenOptionsResponse"
                type="text"
                v-model.trim="option.value"
              />
            </div>
            <button
              class="btn btn--icon"
              @click="removeOption(option)"
              :disabled="isOpenOptionsResponse && question.response_options.length <= 2"
              type="button"
              v-if="isOpenOptionsResponse"
            ><span class="material-icons-outlined">clear</span></button>
          </div>
        </section>
      </fieldset>
    </form>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="showEditModal = false"
        />
        <CButton
          caption="Salvar"
          class="btn--primary"
          form="questionModalForm"
          icon="save"
          type="submit"
        />
      </footer>
    </template>
  </Modal>

  <Modal
    @close="confirmingDestroy = false"
    title="Confirmação"
    v-if="confirmingDestroy"
  >
    <template #default>
      <p>Tem certeza que deseja apagar a pergunta <strong>{{ questionToDestroy.question }}</strong>?</p>
      <Alert
        class="alert--info alert--ghost"
        icon="info"
        title="Apagar uma questão irá removê-la de todos os quizzes onde é usada."
      />
    </template>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="confirmingDestroy = false"
        />
        <CButton
          caption="Apagar"
          class="btn--danger"
          @click="destroy"
          icon="delete"
        />
      </footer>
    </template>
  </Modal>
</AppLayout>
</template>

<script>
import Alert from '@/components/Alert'
import AppLayout from '@/components/layout/App'
import CButton from '@/components/CButton'
import FileInput from '@/components/components/form/FileInput'
import List from './quiz-questions/List'
import { load } from '@/support/loading'
import { mapState } from 'vuex'
import { minLength, required } from '@vuelidate/validators'
import Modal from '@/components/Modal'
import * as obj from '@/support/object'
import Toolbar from '@/components/Toolbar'
import { useVuelidate } from '@vuelidate/core'
import slugify from 'slugify'

const emptyQuestion = () => ({
  configs: {},
  id: null,
  is_active: false,
  key: '',
  question: '',
  response_options: [],
  response_type: null,
})

export default {
  name: 'QuizQuestions',

  components: {
    Alert,
    AppLayout,
    CButton,
    FileInput,
    List,
    Modal,
    Toolbar
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      availableResponseTypes: [
        { key: 'yes-no', label: 'Sim ou Não' },
        { key: 'yes-no-other', label: 'Sim ou Não ou Outra' },
        { key: 'single-choice', label: 'Escolha de Opção Única' },
        { key: 'multiple-choice', label: 'Escolha de Múltiplas Opções' },
        { key: 'text', label: 'Campo de texto' }
      ],
      confirmingDestroy: false,
      loading: false,
      showEditModal: false,
      question: emptyQuestion(),
      questionToDestroy: {}
    }
  },

  created () {
    this.unwatch = this.$watch(
      () => this.$route,
      () => load(this, 'loadQuizQuestions'),
      { immediate: true }
    )
    this.unwatchResponseKey = this.$watch(
      () => this.question.configs.response_key,
      (value = '') => this.question.configs.response_key = slugify(value, { replacement: '_', lower: true, strict: true }),
      { immediate: true }
    )
    this.unwatchKey = this.$watch(
      () => this.question.key,
      (value = '') => this.question.key = slugify(value, { lower: true, strict: true }),
      { immediate: true }
    )
  },

  beforeRouteLeave () {
    this.unwatch()
    this.unwatchResponseKey()
    this.unwatchKey()
  },

  computed: {
    ...mapState(['quizQuestions']),

    isOpenOptionsResponse () {
      return ['single-choice', 'multiple-choice'].includes(this.question.response_type)
        && !this.question.id
    },

    isOptionableResponse () {
      return [
        'yes-no',
        'yes-no-other',
        'single-choice',
        'multiple-choice'
      ].includes(this.question.response_type)
    },

    isYesNoResponse () {
      return ['yes-no', 'yes-no-other'].includes(this.question.response_type)
    }
  },

  watch: {
    'question.response_type' (value, old) {
      if ((!!old && value !== old) || !this.question.id) {
        this.question.response_options = this.makeResponseOptions()
      }
    },

    showEditModal (show) {
      if (!show) {
        this.question = emptyQuestion()
        this.v$.$reset()
      }
    }
  },

  methods: {
    addOption () {
      this.question.response_options.push({ label: '', value: '' })
    },

    confirmDestroy (question) {
      this.questionToDestroy = question
      this.confirmingDestroy = true
    },

    async destroy () {
      await this.$store.dispatch('destroyQuizQuestion', this.questionToDestroy)
      this.questionToDestroy = {}
      this.confirmingDestroy = false
    },

    edit (question) {
      this.question = obj.copy(question)
      this.showEditModal = true
    },

    makeResponseOptions () {
      const options = []

      if (this.isYesNoResponse) {
        options.push({ label: 'Sim', value: 'yes' })
        options.push({ label: 'Não', value: 'no' })
      }

      if (this.question.response_type === 'yes-no-other') {
        options.push({ label: 'Outro ...', value: 'other' })
      }

      if (this.isOpenOptionsResponse) {
        options.push({ label: '', value: '' })
        options.push({ label: '', value: '' })
      }

      return options
    },

    removeOption (option) {
      this.question.response_options.splice(
        this.question.response_options.findIndex(opt => opt === option),
        1
      )
    },

    setValue (option) {
      option.value = slugify(option.label, { lower: true, strict: true })
    },

    async tryToSave () {
      if (!await this.v$.$validate()) return

      await this.$store.dispatch('saveQuizQuestion', this.question)

      this.showEditModal = false
    }
  },

  validations () {
    return {
      question: {
        configs: {
          response_key: { required }
        },
        key: { required },
        question: { required, minLength: minLength(10) },
        response_options: { required },
        response_type: { required }
      }
    }
  }
}
</script>

<style lang="scss">
.quiz-question__option-image {
  max-width: 34%;
}
</style>
