<script setup>
import { computed, ref, watchEffect } from 'vue'
import { required } from '@vuelidate/validators'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import * as obj from '@/support/object'

import CButton from '@/components/CButton'
import CInput from '@/components/components/form/Input'
import CSelect from '@/components/components/form/Select'
import CModal from '@/components/Modal'

const emit = defineEmits(['close'])
const props = defineProps({ item: Object })

const emptyAccount = () => ({
  domain: null,
  id: null,
  name: '',
  platform: null,
  platform_account_id: '',
})

const store = useStore()
const rules = computed(() => ({
  domain: { required },
  name: { required },
  platform: { required },
  platform_account_id: { required }
}))
const account = ref(emptyAccount())
const v$ = useVuelidate(rules, account)

const close = () => {
  account.value = emptyAccount()

  v$.value.$reset()
  emit('close')
}

const tryToSave = async () => {
  if (!await v$.value.$validate()) return

  await store.dispatch('adsAccounts/saveAccount', account.value)
  close()
}

watchEffect(() => {
  account.value = obj.isEmpty(props.item) ? emptyAccount() : obj.copy(props.item)
})
</script>

<template>
<CModal
  @close="close"
  :show-close-button="true"
  :title="account.id ? `Editar Conta de Ads [#${account.id}]` : 'Adicionar Conta de Ads'"
>
  <form
    class="form"
    id="accountModalForm"
    @submit.prevent="tryToSave"
  >
    <fieldset>
      <CInput
        id="accountName"
        label="Nome da conta"
        v-model.trim="account.name"
      >
        <span class="input__error" v-if="v$.name.$error">Informe o nome da conta</span>
      </CInput>
      <CSelect
        id="accountDomain"
        label="Domínio"
        :options="[
          { id: 'ecofinanca', name: 'Ecofinanca' },
          { id: 'finc4you', name: 'Finc4You' },
          { id: 'memivi', name: 'Memivi' },
        ]"
        v-model="account.domain"
      >
        <span class="input__error" v-if="v$.domain.$error">Escolha um domínio</span>
      </CSelect>
      <CSelect
        id="accountPlatform"
        label="Plataforma"
        name="accountPlatform"
        :options="[{ id: 'facebook', name: 'Facebook' }]"
        v-model="account.platform"
      >
        <span class="input__error" v-if="v$.platform.$error">Escolha uma plataforma</span>
      </CSelect>
      <CInput
        id="accountPlatformId"
        label="Id da conta na plataforma"
        v-model.trim="account.platform_account_id"
      >
        <span class="input__error" v-if="v$.platform_account_id.$error">Informe o id da conta na plataforma</span>
      </CInput>
    </fieldset>
  </form>

  <template #footer>
    <footer class="modal__footer">
      <CButton
        caption="Cancelar"
        @click="close"
      />
      <CButton
        caption="Salvar"
        class="btn--primary"
        form="accountModalForm"
        icon="save"
        type="submit"
      />
    </footer>
  </template>
</CModal>
</template>
