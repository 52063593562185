import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'

export function initErrorReporting (app, router) {
  Sentry.init({
    app,
    dsn: 'https://0b5d390210084c1698f1d670ffe8374b@o1053728.ingest.sentry.io/6049809',
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
    ],
    logErrors: true,
    tracesSampleRate: 0.25
  })
}
