import { findNodes } from './editor/support.js'
import groupBy from 'lodash/groupBy'
import { isEmpty } from 'lodash'
import { mergeProps } from 'vue'
import { useEditor } from './editor/editor.js'

const { nodes, parent, parentsMap, select, selected, stepsCount, currentStep } = useEditor()

let store = null

export const builderSections = {
  basic: {
    icon: 'edit_note',
    caption: 'Básico'
  },
  components: {
    icon: 'smart_button',
    caption: 'Componentes'
  },
  ads: {
    icon: 'shop_two',
    caption: 'Ads, Wrapper e Analytics'
  },
  pages: {
    icon: 'library_books',
    caption: 'Páginas de resultado'
  },
  style: {
    icon: 'style',
    caption: 'Estilo'
  },
  // abtest: {
  //   icon: 'alt_route',
  //   caption: 'Teste A/B'
  // }
}

export const buildQuizQuestions = () => {
  const items = findNodes(nodes.value, 'question')
    .map(node => ({ pivot: { quiz_question_id: node.configs.question?.id ?? null } }))
  store.commit('quizzesV2/setQuizQuestions', items)
}

export const canShowAttrs = is => selected.value && (!is || selected.value.is === is)

export const canShowDataAttrs = () => {
  return selected.value
    && (typeof selected.value.children !== 'undefined')
    && !Array.isArray(selected.value.children)
}

export const findByClass = (onNode, className) => {
  return onNode.value.children
    .find(child => child.attrs.class === className)
}

export const findQuestion = (id, quizQuestions = []) => quizQuestions.find(one => one.id === id)

export const generateId = (prefix = 'id-') => prefix + Math.floor(Math.random() * 9999) + new Date().getTime()

export const getPageCriteriasFacts = (questions = [], quizQuestions = []) => {
  const facts = getQuestionsCriteriasFacts(questions, quizQuestions)

  facts.push({
    group: 'Automático',
    label: 'Percentual de distribuição',
    path: 'split_percentage',
    type: 'number'
  })

  return groupBy(facts, 'group')
}

export const getQuestionsCriteriasFacts = (questions = [], quizQuestions = []) => {
  return questions
    .filter(one => !!one.pivot.quiz_question_id)
    .map(one => {
      const question = findQuestion(one.pivot.quiz_question_id, quizQuestions)

      return {
        group: 'Perguntas',
        label: question.question,
        options: question.response_options,
        path: question.configs.response_key,
        type: 'string'
      }
    })
}

function moveIndex (array, index, step = 1) {
  const newIndex = index + step

  if (newIndex < 0 || newIndex === array.length) return null

  const indexes = [index, newIndex].sort()

  array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]])

  return newIndex
}

export const objectToCss = obj => {
  return Object.keys(obj)
    .map(key => key.replace(/\B([A-Z])/g, '-$1').toLowerCase() + `:${obj[key]}`)
    .join(';')
}

export const placeCss = styles => {
  let ele = document.querySelector('style#quiz')

  if (!ele) {
    ele = document.createElement('style')
    ele.id = 'quiz'

    document.querySelector('head').append(ele)
  }

  ele.innerHTML = styles
    .filter(one => !isEmpty(one.declarations))
    .map(one => {
      const parent = one.class === 'quiz-window' ? '' : '.quiz-window'

      return `${parent} .${one.class}${one.state ?? ''} { ${objectToCss(one.declarations)} }`
    }).join(' ')
}

export const refreshQuestionsConfigs = (ofNode, quizQuestions = []) => {
  if (Array.isArray(ofNode)) {
    return ofNode.forEach(one => refreshQuestionsConfigs(one, quizQuestions))
  }

  if (ofNode.is === 'question') {
    ofNode.configs.question = findQuestion(ofNode.configs.question?.id, quizQuestions)
  }

  if (Array.isArray(ofNode.children)) {
    ofNode.children.forEach(one => refreshQuestionsConfigs(one, quizQuestions))
  }
}

export const remove = () => {
  if (!parent.value) return

  parent.value.children.splice(parent.value.children.indexOf(selected.value), 1)
  parentsMap.delete(selected.value)

  if (selected.value.is === 'question') {
    buildQuizQuestions()
  }

  if (selected.value.is === 'step') {
    return previousStep()
  }

  select()
}

export const selectParent = () => {
  if (parent.value) {
    select(parent.value, parentsMap.get(parent.value))
  }
}

export const setStore = toSet => store = toSet

export const setStyles = (onNode, style) => {
  onNode.attrs.style = mergeProps({ style: onNode.attrs.style || {} }, { style }).style
}

export const toLeft = () => {
  if (!parent.value) {
    return
  }

  moveIndex(parent.value.children, parent.value.children.indexOf(selected.value), -1)

  if (selected.value.is === 'step') {
    previousStep()
  }
}

export const toRight = () => {
  if (!parent.value) {
    return
  }

  moveIndex(parent.value.children, parent.value.children.indexOf(selected.value), 1)

  if (selected.value.is === 'step') {
    nextStep()
  }
}

// @todo refactor
//#region steps
let index = 0

export function resetSteps () {
  index = 0
}

function moveToStep (direction = 'next') {
  const steps = findNodes(nodes.value, 'step')

  if (!steps.length) return

  steps.forEach(step => step.hide = true)
  index += direction === 'next' ? (steps.length === index + 1 ? 0 : 1) : (index === 0 ? 0 : -1)
  const found = steps[index]
  found.hide = false
  select(found, parentsMap.get(found))
  updateProgress(index, steps.length)
}

export function previousStep () {
  moveToStep('previous')
}

export function nextStep () {
  moveToStep('next')
}

export function updateProgress (step, count) {
  const progress = findNodes(nodes.value, 'progress')[0]

  if (!progress) return

  stepsCount.value = count
  currentStep.value = step
  progress.configs.count = count
  progress.configs.index = step
}
//#endregion
