<script setup>
import { computed, watch } from 'vue'
import { required } from '@vuelidate/validators'
import slugify from 'slugify'
import { useVuelidate } from '@vuelidate/core'

import CInput from '@/components/components/form/Input'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Object })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
const v$ = useVuelidate({
  key: { required },
  name: { required }
}, data)

watch(() => data.value.name, value => { if (!data.value.id) data.value.key = value })
watch(() => data.value.key, value => {
  data.value.key = slugify(value, { lower: true, strict: true })
})

// eslint-disable-next-line no-undef
defineExpose({ v$ })
</script>

<template>
<div>
  <CInput
    id="itemName"
    label="Nome do Quiz"
    v-model.trim="data.name"
  >
    <span class="input__error" v-if="v$.name.$error">Informe o nome do quiz</span>
  </CInput>
  <CInput
    id="itemKey"
    label="Nome em no máximo duas palavras"
    v-model.trim="data.key"
  >
    <span class="input__error" v-if="v$.key.$error">Informe a chave do quiz</span>
  </CInput>
  <div class="input">
    <label class="input__switch">
      <input type="checkbox" v-model="data.is_active" />
      <span>Está ativo</span>
    </label>
  </div>
  <!-- <div class="input">
    <label class="input__switch">
      <input type="checkbox" v-model="data.configs.tracking.is_active" />
      <span>Ativar tracking de eventos</span>
    </label>
  </div> -->
</div>
</template>
