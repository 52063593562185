<script setup>
import { computed } from 'vue'

import ColorInput from '@/components/components/form/ColorInput'
import CSelect from '@/components/components/form/Select'
import NumberField from '../editor/NumberField.vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Object })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <div class="row mb-2">
    <CSelect
      class="mb-0"
      label="Tipo"
      :options="[
        { id: 'solid', name: 'Sólida' },
        { id: 'dashed', name: 'Tracejada' },
        { id: 'dotted', name: 'Pontilhada' },
        { id: 'double', name: 'Dupla' }
      ]"
      v-model="data.borderStyle"
    />
    <NumberField
      class="mb-0"
      label="Arredondamento"
      measure-unit="px"
      :step="1"
      v-model="data.borderRadius"
    />
  </div>
  <div class="row">
    <NumberField
      class="mb-0"
      label="Espessura"
      measure-unit="px"
      :step="1"
      v-model="data.borderWidth"
    />
    <ColorInput
      class="mb-0"
      label="Cor"
      v-model="data.borderColor"
    />
  </div>
</section>
</template>
