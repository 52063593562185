<script setup>
import { computed, watch } from 'vue'

import CSelect from '@/components/components/form/Select'
import NumberField from '../editor/NumberField.vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Object })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})

watch(() => data.value.flexDirection, value => {
  data.value.display = value ? 'flex' : ''
})
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <div class="row mb-2">
    <CSelect
      class="mb-0"
      label="Direção"
      :options="[
        { id: 'row', name: 'Horizontal' },
        { id: 'column', name: 'Vertical' }
      ]"
      v-model="data.flexDirection"
    />
    <NumberField
      class="mb-0"
      label="Espaçamento"
      v-if="data.flexDirection"
      v-model="data.gap"
    />
  </div>
  <CSelect
    class="mb-2"
    label="Distribuição"
    :options="[
      { id: 'start', name: 'Itens juntos ' + (data.flexDirection === 'row' ? 'à esquerda' : 'acima') },
      { id: 'center', name: 'Itens juntos ao centro' },
      { id: 'end', name: 'Itens juntos ' + (data.flexDirection === 'row' ? 'à direta' : 'abaixo') },
      { id: 'space-evenly', name: 'Espaçados igualmente' },
      { id: 'space-between', name: 'Com espaços entre' },
      { id: 'space-around', name: 'Com espaços ao redor' }
    ]"
    v-if="data.flexDirection"
    v-model="data.justifyContent"
  />
  <CSelect
    class="mb-0"
    :label="`Alinhamento ` + (data.flexDirection === 'row' ? 'Vertical' : 'Horizontal')"
    :options="[
      { id: 'flex-start', name: data.flexDirection === 'row' ? 'Acima' : 'À esquerda' },
      { id: 'center', name: 'Central' },
      { id: 'flex-end', name: data.flexDirection === 'row' ? 'Abaixo' : 'À direta' },
    ]"
    v-if="data.flexDirection"
    v-model="data.alignItems"
  />
</section>
</template>
