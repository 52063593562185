<template>
<AppLayout>
  <section class="layout__main distribution-rules">
    <div class="container">
      <Toolbar
        :has-primary-action="true"
        @primary-action="showEditModal = true"
        primary-action-caption="Adicionar Regra"
        primary-action-icon="add"
        title="Distribuição"
      />

      <section
        class="data-cards-list"
        :class="{ 'loading loading--lg': loading }"
        v-if="loading || !!distributionRules.length"
      >
        <article class="card card--flat data-cards-list__item">
          <div>
            Quando o lead é único no período de
            <span class="distribution-rules__rule-attribute">
              <b>{{ uniquenessPeriodParameter?.value }}</b>
            </span>
          </div>
        </article>
        <ListItem
          @destroy="confirmDestroy($event)"
          @edit="edit($event)"
          :fields="fields"
          :key="rule.id"
          :rule="rule"
          v-for="rule of distributionRules"
        />
        <article class="card card--flat data-cards-list__item">
          <div>
            Caso não atenda aos critérios acima, enviar para a lista padrão
            <span class="distribution-rules__rule-attribute">
              <b>{{ defaultAccount?.name }} / {{ defaultList?.name }}</b>
            </span>.
          </div>
        </article>
      </section>

      <EmptyState
        message="Nenhuma Regra está cadastrada ainda ..."
        v-if="!loading && !distributionRules.length"
      />
    </div>
  </section>

  <Modal
    @close="showEditModal = false"
    :closeable-by-backdrop="false"
    :show-close-button="true"
    size="lg"
    :title="(distributionRule.id ? 'Editar' : 'Adicionar') + ' Regra' + (distributionRule.id ? ` [#${distributionRule.id}]` : '')"
    v-if="showEditModal"
  >
    <form
      class="form"
      id="distributionRuleModalForm"
      @submit.prevent="tryToSave"
    >
      <fieldset>
        <div class="input">
          <label class="input__label" for="ruleName">Nome da regra</label>
          <input class="input__control" id="ruleName" type="text" v-model.trim="distributionRule.name" />
          <span class="input__error" v-if="v$.distributionRule.name.$error">Informe o nome da regra</span>
        </div>
        <div class="form__fields form__fields--row">
          <CSelect
            id="ruleTargetAccountId"
            label="Conta de destino"
            :options="platformAccounts"
            v-model="distributionRule.target_platform_account_id"
          >
            <span class="input__error" v-if="v$.distributionRule.target_platform_account_id.$error">Escolha uma conta de destino</span>
          </CSelect>
          <CSelect
            id="ruleTargetListId"
            label="Lista de destino"
            :options="lists"
            v-model="distributionRule.target_list_id"
          >
            <span class="input__error" v-if="v$.distributionRule.target_list_id.$error">Escolha uma lista de destino</span>
          </CSelect>
        </div>
        <div class="form__fields form__fields--row">
          <CSelect
            id="ruleSmsAccountId"
            label="Conta de SMS de destino"
            :options="smsPlatformsAccounts"
            v-model="distributionRule.meta.sms_account_id"
          ></CSelect>
          <CSelect
            id="ruleSmsList"
            label="Lista de SMS de destino"
            :options="smsLists"
            v-model="distributionRule.meta.sms_list"
          ></CSelect>
        </div>
      </fieldset>

      <fieldset>
        <Toolbar title="Critérios">
          <template #actions>
            <div class="toolbar__group">
              <CButton
                caption="Adicionar Critério"
                class="btn--secondary"
                @click="addCriteria"
                icon="add"
              />
            </div>
          </template>
        </Toolbar>

        <section>
          <div
            class="form__fields form__fields--row"
            :key="criteria.field"
            v-for="criteria in criterias"
          >
            <div class="input">
              <div class="input__select">
                <select class="input__control" v-model="criteria.field">
                  <option value="">[ Selecione ]</option>
                  <option
                    :key="field.key"
                    v-for="field in fields"
                    :value="field.key"
                  >{{ field.name }}</option>
                </select>
              </div>
            </div>
            <div class="input">
              <div class="input__select">
                <select class="input__control" v-model="criteria.value">
                  <option value="">[ Selecione ]</option>
                  <option
                    :key="value.key"
                    v-for="value in getValuesForField(criteria.field)"
                    :value="value.key"
                  >{{ value.name }}</option>
                </select>
              </div>
            </div>
            <CButton
              class="btn--icon"
              @click="removeCriteria(criteria)"
              icon="clear"
            />
          </div>
          <span class="input__error" v-if="v$.distributionRule.criterias.$error">Adicione pelo menos 1 critério</span>
        </section>
      </fieldset>

      <fieldset>
        <Toolbar title="Tags">
          <template #actions>
            <div class="toolbar__group">
              <CButton
                caption="Adicionar Tag"
                class="btn--secondary"
                @click="addTag"
                icon="add"
              />
            </div>
          </template>
        </Toolbar>

        <section>
          <div
            class="form__fields form__fields--row"
            :key="index"
            v-for="(tag, index) in tags"
          >
            <div class="input">
              <input class="input__control" placeholder="Tag" type="text" v-model="tag.value" />
            </div>
            <CButton
              class="btn--icon"
              @click="removeTag(tag)"
              icon="clear"
            />
          </div>
        </section>
      </fieldset>
    </form>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="showEditModal = false"
        />
        <CButton
          caption="Salvar"
          class="btn--primary"
          form="distributionRuleModalForm"
          icon="save"
          type="submit"
        />
      </footer>
    </template>
  </Modal>

  <Modal
    @close="confirmingDestroy = false"
    title="Confirmação"
    v-if="confirmingDestroy"
  >
    <template #default>
      <p>Tem certeza que deseja apagar a regra <strong>{{ distributionRuleToDestroy.name }}</strong>?</p>
    </template>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="confirmingDestroy = false"
        />
        <CButton
          caption="Apagar"
          class="btn--danger"
          @click="destroy"
          icon="delete"
        />
      </footer>
    </template>
  </Modal>
</AppLayout>
</template>

<script>
import AppLayout from '@/components/layout/App'
import CButton from '@/components/CButton'
import CSelect from '@/components/components/form/Select'
import EmptyState from '@/components/utilities/EmptyState'
import ListItem from './components/distribution-rules/ListItem'
import { mapState } from 'vuex'
import Modal from '@/components/Modal'
import * as obj from '@/support/object'
import { required } from '@vuelidate/validators'
import Toolbar from '@/components/Toolbar'
import { useVuelidate } from '@vuelidate/core'

const emptyRule = () => ({
  criterias: {},
  id: null,
  meta: {
    sms_account_id: undefined,
    sms_list: undefined
  },
  name: '',
  target_list_id: undefined,
  target_platform_account_id: undefined,
  target_tags: []
})

function transformCriteriaToArray (criterias) {
  return Object.entries(criterias)
    .map(entry => ({ field: entry[0], value: entry[1] }))
}

export default {
  name: 'DistributionRules',

  components: {
    AppLayout,
    CButton,
    CSelect,
    EmptyState,
    ListItem,
    Modal,
    Toolbar
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      confirmingDestroy: false,
      criterias: [],
      distributionRule: emptyRule(),
      distributionRuleToDestroy: {},
      loading: false,
      showEditModal: false,
      tags: []
    }
  },

  created () {
    this.unwatch = this.$watch(
      () => this.$route,
      async () => {
        this.loading = true
        await Promise.all([
          this.$store.dispatch('loadPlatformAccounts'),
          this.$store.dispatch('smsPlatformAccounts/loadSmsPlatformAccountsLightList'),
          this.$store.dispatch('loadQuizzes'),
          this.$store.dispatch('loadDistributionRules'),
          this.$store.dispatch('loadQuizQuestions')
        ])
        this.loading = false
      },
      { immediate: true }
    )
  },

  beforeRouteLeave () {
    this.unwatch()
  },

  watch: {
    criterias: {
      handler (criterias) {
        this.distributionRule.criterias = criterias.reduce((result, current) => {
          result[current.field] = current.value

          return result
        }, {})
      },
      deep: true
    },

    showEditModal (show) {
      if (!show) {
        this.distributionRule = emptyRule()
        this.criterias = []
        this.tags = []
        this.v$.$reset()
      }
    },

    tags: {
      handler (tags) {
        this.distributionRule.target_tags = tags.map(tag => tag.value)
      },
      deep: true
    }
  },

  computed: {
    ...mapState([
      'distributionRules',
      'parameters',
      'platformAccounts',
      'quizQuestions',
      'quizzes',
    ]),

    ...mapState({
      smsPlatformsAccounts: state => state.smsPlatformAccounts.smsPlatformAccountsLightList
    }),

    defaultAccount () {
      return this.platformAccounts.find(one => one.is_default) || {}
    },

    defaultList () {
      return this.defaultAccount?.lists?.find(one => one.is_default) || {}
    },

    fields () {
      return [
        ...this.questionsFields,
        {
          name: 'Meta » Quiz',
          key: 'meta.quiz',
          options: () => this.quizzesOptions
        },
        {
          name: 'Meta » Índice de Split',
          key: 'meta.split_index',
          options: [
            { key: 1, name: 'Conta 1' },
            { key: 2, name: 'Conta 2' },
            { key: 3, name: 'Conta 3' },
            { key: 4, name: 'Conta 4' },
            { key: 5, name: 'Conta 5' },
          ]
        },
        // {
        //   name: 'Meta » Probabilidade de Split',
        //   key: 'meta.split_percentage',
        //   options: [
        //     { key: 1, name: 'Conta 1' },
        //     { key: 2, name: 'Conta 2' },
        //     { key: 3, name: 'Conta 3' },
        //     { key: 4, name: 'Conta 4' },
        //     { key: 5, name: 'Conta 5' },
        //   ]
        // },
      ]
    },

    lists () {
      return this.platformAccount?.lists || []
    },

    platformAccount () {
      return this.platformAccounts
        .find(one => one.id === this.distributionRule.target_platform_account_id)
    },

    questionsFields () {
      return this.quizQuestions.map(question => ({
        name: 'Pergunta » ' + question.question,
        key: question.configs.response_key,
        options: question.response_options.map(option => ({
          key: option.value,
          name: option.label
        }))
      }))
    },

    quizzesOptions () {
      return this.quizzes.map(quiz => ({
        key: quiz.id,
        name: quiz.name
      }))
    },

    smsLists () {
      return this.smsPlatformAccount?.lists || []
    },

    smsPlatformAccount () {
      return this.smsPlatformsAccounts
        .find(one => one.id === this.distributionRule.meta.sms_account_id)
    },

    uniquenessPeriodParameter () {
      return this.parameters.find(one => one.key === 'lead-uniqueness-period')
    }
  },

  methods: {
    addCriteria () {
      this.criterias.push({
        field: '',
        value: ''
      })
    },

    addTag () {
      this.tags.push({ value: '' })
    },

    confirmDestroy (rule) {
      this.distributionRuleToDestroy = rule
      this.confirmingDestroy = true
    },

    async destroy () {
      await this.$store.dispatch('destroyDistributionRule', this.distributionRuleToDestroy)
      this.distributionRuleToDestroy = {}
      this.confirmingDestroy = false
    },

    edit (rule) {
      this.distributionRule = obj.copy(rule)
      this.distributionRule.meta.sms_list = this.distributionRule.meta.sms_list || null
      this.criterias = transformCriteriaToArray(rule.criterias)
      this.tags = rule.target_tags.map(tag => ({ value: tag }))
      this.showEditModal = true
    },

    getValuesForField (field) {
      const values = this.fields.find(one => one.key === field)?.options || []

      return typeof values === 'function' ? values() : values
    },

    removeCriteria (criteria) {
      this.criterias.splice(this.criterias.findIndex(one => one === criteria), 1)
    },

    removeTag (tag) {
      this.tags.splice(this.tags.findIndex(one => one === tag), 1)
    },

    async tryToSave () {
      if (!await this.v$.$validate()) return

      await this.$store.dispatch('saveDistributionRule', this.distributionRule)

      this.showEditModal = false
    }
  },

  validations () {
    return {
      distributionRule: {
        criterias: { required },
        name: { required },
        target_list_id: { required },
        target_platform_account_id: { required }
      }
    }
  }
}
</script>

<style lang="scss">
.distribution-rules__rule-attribute {
  background: #f0f0f0;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
}
</style>
