import users from '@/repositories/users'

export default {
  namespaced: true,

  state: () => ({ users: [] }),

  mutations: {
    addUser (state, { item }) {
      state.users.push(item)
    },

    removeUser (state, { item }) {
      state.users.splice(state.users.findIndex(one => one === item), 1)
    },

    replaceUser (state, { item, index }) {
      state.users.splice(index, 1, item)
    },

    setUsers (state, payload) {
      state.users = payload
    }
  },

  actions: {
    async destroyUser ({ commit }, data) {
      await users.destroy(data)
      commit('removeUser', { item: data })
    },

    async loadUsers ({ commit }) {
      commit('setUsers', await users.all())
    },

    async saveUser ({ commit, state }, data) {
      const item = await users.save(data)
      const index = state.users.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceUser' : 'addUser', { item, index })

      return item
    }
  }
}
