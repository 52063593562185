let apiEndpoint
let bucketEndpoint
let tenant

export function getApiEndpoint () {
  return apiEndpoint
}

export function getBucketEndpoint () {
  return bucketEndpoint
}

export function getTenant () {
  return tenant
}

export function isOnJBF () {
  return tenant === 'localhost' || tenant === 'jbfdigital' || tenant === 'jbf'
}

// @todo refactor removing jbfdigital
export function init () {
  tenant = window.location.hostname.split('.')[0].replace('-hub', '')

  if (tenant === 'localhost') {
    apiEndpoint = process.env.VUE_APP_API_ENDPOINT
    bucketEndpoint = process.env.VUE_APP_BUCKET_ENDPOINT

    return
  }

  if (tenant === 'jbfdigital' || tenant === 'jbf') {
    apiEndpoint = 'https://hub-api.jbfdigital.com.br/'
    bucketEndpoint = 'https://leads-hub.s3.sa-east-1.amazonaws.com'

    return
  }

  apiEndpoint = `https://${tenant}-api.leadsonhub.com/`
  bucketEndpoint = 'https://leadsonhub.nyc3.cdn.digitaloceanspaces.com'
}

init()
