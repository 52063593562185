<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import { format, formatPercentage } from '@/support/number'
import { daysOffsetToInterval, formatDate, formatToDB } from '@/support/datetime'
import { meanRate, sum } from '@tidyjs/tidy'
import orderBy from 'lodash/orderBy'
import { useStore } from 'vuex'
import without from 'lodash/without'

import CButton from '@/components/CButton'
import CSelect from '@/components/components/form/Select'
import DateSelect from '@/components/components/form/DateSelect'
import FieldsSelector from '@/components/components/grids/FieldsSelector'
import Grid from '@/components/components/grids/SummaryGrid'

const store = useStore()
const key = 'campaigns-statistics'
const availableFields = [
  { key: 'name', label: 'Nome', selected: true },
  { key: 'statistics_at', label: 'Data', selected: true },
]
const availableFieldsKeys = availableFields.map(field => field.key)
const shownFieldsKeys = availableFields.filter(field => field.selected).map(field => field.key)

const shownFields = ref(shownFieldsKeys)
const period = ref(daysOffsetToInterval(-8))

const data = computed(() => store.getters['dashboard/campaignsStatistics'])
const loading = computed(() => store.state.dashboard.widgetsLoading[key])
const hiddenFields = computed(() => without(availableFieldsKeys, ...shownFields.value))
const periodToRequest = computed(() => (!period.value ? {} : {
  from: formatToDB(period.value.from),
  to: formatToDB(period.value.to)
}))

const platformAccounts = computed(() => orderBy(store.state.platformAccounts, ['name']))
const accountId = ref(platformAccounts.value[0]?.id ?? null)

watch(() => accountId.value, tryToLoad)
watch(() => period.value, tryToLoad)
watch(() => platformAccounts.value, accounts => { accountId.value = accounts[0]?.id ?? null })

onBeforeMount(tryToLoad)

function load () {
  store.dispatch('dashboard/updateWidget', {
    key,
    options: {
      account_id: accountId.value,
      ...periodToRequest.value
    }
  })
}

function tryToLoad () {
  if (accountId.value) {
    load()
  }
}
</script>

<template>
<article class="card">
  <header class="card__header">
    <h3 class="card__title caption">Estatísticas de Campanhas</h3>
  </header>
  <section class="card__body">
    <div class="flex mb-4 table-controls">
      <div class="toolbar__group">
        <CSelect
          class="mb-0"
          :nullable="false"
          :options="platformAccounts"
          v-model="accountId"
        />
        <DateSelect
          class="mb-0"
          :is-range="true"
          v-model="period"
        />
      </div>
      <div class="toolbar__group">
        <CButton
          class="tooltip"
          @click="tryToLoad()"
          data-tooltip="Atualizar"
          icon="refresh"
        />
        <FieldsSelector
          :fields="availableFields"
          id="campaignsStatisticsTableFields"
          v-model="shownFields"
        />
      </div>
    </div>
    <div
      class="table-wrapper"
      :class="{ 'loading loading--lg': loading }"
    >
      <Grid
        class="table--statistics"
        :data="data"
        :except="hiddenFields"
        :fields="{
          name: { label: 'Nome' },
          statistics_at: { label: 'Data', formatter: formatDate },
          send: {
            aggregator: (carry, item) => carry + item.send,
            formatter: format,
            label: 'Envios'
          },
          unique_open: {
            aggregator: (carry, item) => carry + item.unique_open,
            formatter: format,
            label: 'Aberturas'
          },
          unique_clicks: {
            aggregator: (carry, item) => carry + item.unique_clicks,
            formatter: format,
            label: 'Cliques'
          },
          unsubscribes: {
            aggregator: (carry, item) => carry + item.unsubscribes,
            formatter: format,
            label: 'Cancelamentos'
          },
          bounces: {
            aggregator: (carry, item) => carry + item.bounces,
            formatter: format,
            label: 'Bounces'
          },
          open_rate: {
            afterAggregator: carry => carry.send ? carry.unique_open / carry.send : 0,
            formatter: formatPercentage,
            label: 'Taxa de Abertura'
          },
          open_click_rate: {
            afterAggregator: carry => carry.unique_open ? carry.unique_clicks / carry.unique_open : 0,
            formatter: formatPercentage,
            label: 'Taxa de Cliques Abertura'
          },
          click_rate: {
            afterAggregator: carry => carry.send ? carry.unique_clicks / carry.send : 0,
            formatter: formatPercentage,
            label: 'Taxa de Cliques'
          },
          unsubscribe_rate: {
            afterAggregator: carry => carry.send ? carry.unsubscribes / carry.send : 0,
            formatter: formatPercentage,
            label: 'Taxa de Cancelamento'
          },
          bounce_rate: {
            afterAggregator: carry => carry.send ? carry.bounces / carry.send : 0,
            formatter: formatPercentage,
            label: 'Taxa de Bounce'
          }
        }"
        :summarize="{
          send: sum('send'),
          unique_open: sum('unique_open'),
          unique_clicks: sum('unique_clicks'),
          unsubscribes: sum('unsubscribes'),
          bounces: sum('bounces'),
          open_rate: meanRate('unique_open', 'send'),
          open_click_rate: meanRate('unique_clicks', 'unique_open'),
          click_rate: meanRate('unique_clicks', 'send'),
          unsubscribe_rate: meanRate('unsubscribes', 'send'),
          bounce_rate: meanRate('bounces', 'send')
        }"
        :summary-fields="[
          'send',
          'unique_open',
          'unique_clicks',
          'unsubscribes',
          'bounces',
          'open_rate',
          'open_click_rate',
          'click_rate',
          'unsubscribe_rate',
          'bounce_rate',
        ]"
      />
    </div>
  </section>
</article>
</template>

<style lang="scss" scoped>
.table-controls {
  align-items: center;
  justify-content: space-between;

  > *:not(:last-child) {
    margin-right: var(--space-6);
  }
}

.table-wrapper {
  max-height: 500px;
  min-height: 40px;
  overflow: auto;
}

.disabled {
  opacity: 0.4;
}
</style>
