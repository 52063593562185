<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import { daysOffsetToInterval, formatDate } from '@/support/datetime'
import { format, formatPercentage } from '@/support/number'
import { meanRate, sum } from '@tidyjs/tidy'
import { useStore } from 'vuex'
import without from 'lodash/without'

import CButton from '@/components/CButton'
import DateSelect from '@/components/components/form/DateSelect'
import FieldsSelector from '@/components/components/grids/FieldsSelector'
import Grid from '@/components/components/grids/SummaryGrid'

const store = useStore()
const availableFields = [
  { key: 'date', label: 'Data', selected: true },
  { key: 'push_account_name', label: 'Conta', selected: false },
  { key: 'platform', label: 'Plataforma', selected: false },
  { key: 'domain', label: 'Domínio', selected: true },
  { key: 'title', label: 'Título', selected: true },
  { key: 'segment', label: 'Segmento', selected: false },
  { key: 'workspace_name', label: 'Workspace', selected: true },
  { key: 'sent', label: 'Enviados', selected: true },
  { key: 'delivered', label: 'Entregues', selected: true },
  { key: 'clicked', label: 'Clicados', selected: true },
  { key: 'ctr', label: 'CTR', selected: true },
]
const availableFieldsKeys = availableFields.map(field => field.key)
const shownFieldsKeys = availableFields.filter(field => field.selected).map(field => field.key)

const period = ref(daysOffsetToInterval(-7))
const shownFields = ref(shownFieldsKeys)
const loading = ref(false)

const data = computed(() => store.state.pushNotifications.statistics)
const hiddenFields = computed(() => without(availableFieldsKeys, ...shownFields.value))
const periodToRequest = computed(() => (!period.value ? {} : {
  from: period.value.from.toJSON(),
  to: period.value.to.toJSON()
}))

watch(() => period.value, load)

onBeforeMount(load)

async function load () {
  loading.value = true
  await store.dispatch('pushNotifications/loadAllStatistics', periodToRequest.value)
  loading.value = false
}
</script>

<template>
<article class="card">
  <header class="card__header">
    <h3 class="card__title caption">Push Notifications</h3>
  </header>
  <section class="card__body">
    <div class="flex mb-4 table-controls">
      <div class="toolbar__group">
        <DateSelect
          class="mb-0"
          :is-range="true"
          v-model="period"
        />
      </div>
      <div class="toolbar__group">
        <CButton
          class="tooltip"
          @click="load()"
          data-tooltip="Atualizar"
          icon="refresh"
        />
        <FieldsSelector
          :fields="availableFields"
          id="pushNotificationsStatisticsTableFields"
          v-model="shownFields"
        />
      </div>
    </div>
    <div
      class="table-wrapper"
      :class="{ 'loading loading--lg': loading }"
    >
      <Grid
        class="table--statistics"
        :data="data"
        :except="hiddenFields"
        :fields="{
          date: { label: 'Data', formatter: formatDate },
          push_account_name: { label: 'Conta' },
          platform: { label: 'Plataforma' },
          domain: { label: 'Domínio' },
          title: { label: 'Título' },
          segment: { label: 'Segmento' },
          workspace_name: { label: 'Workspace' },
          sent: {
            aggregator: (carry, item) => carry + item.sent,
            formatter: format,
            label: 'Enviados'
          },
          delivered: {
            aggregator: (carry, item) => carry + item.delivered,
            formatter: format,
            label: 'Entregues'
          },
          clicked: {
            aggregator: (carry, item) => carry + item.clicked,
            formatter: format,
            label: 'Clicados'
          },
          ctr: {
            afterAggregator: carry => carry.delivered ? carry.clicked / carry.delivered : 0,
            formatter: formatPercentage,
            label: 'CTR'
          }
        }"
        :summarize="{
          sent: sum('sent'),
          delivered: sum('delivered'),
          clicked: sum('clicked'),
          ctr: meanRate('clicked', 'delivered')
        }"
        :summary-fields="[
          'sent',
          'delivered',
          'clicked',
          'ctr',
        ]"
      />
    </div>
  </section>
</article>
</template>

<style lang="scss" scoped>
.table-controls {
  align-items: center;
  justify-content: space-between;

  > *:not(:last-child) {
    margin-right: var(--space-6);
  }
}

.table-wrapper {
  max-height: 340px;
  min-height: 40px;
  overflow: auto;
}
</style>
