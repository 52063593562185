<template>
<header class="top-bar">
  <div class="container">
    <span class="top-bar__brand">
      <img
        alt="Logo LeadsHUB"
        class="brand-icon"
        :src="store.state.baseUrl + 'logo-jbf.png'"
      />
      Leads HUB
    </span>
    <div>
      <WorkspaceSelector
        v-if="route.meta.workspaced !== false && workspaceId"
        v-model="workspaceId"
      />
    </div>
    <section class="top-bar__controls">
      <CButton
        class="btn--icon btn--fit btn--ghost badge"
        @click="emit('toggle-right-bar')"
        icon="notifications"
        hidden
      />
      <CButton
        class="btn--icon btn--fit btn--ghost badge"
        @click="emit('toggle-right-bar')"
        icon="email"
        hidden
      />
      <div class="dropdown">
        <label class="dropdown__trigger" for="userDropdown">
          <figure
            class="avatar avatar--sm"
            :data-initials="store.state.auth.user.initials"
          />
        </label>
        <input class="sensor" type="checkbox" id="userDropdown" />
        <div class="card dropdown__panel dropdown__panel--right dropdown__panel--fit">
          <nav class="menu">
            <a href="#" class="menu__item" hidden>
              <span class="icon">settings</span>
              Minhas Opções
            </a>
            <a
              class="menu__item"
              @click.prevent="logout"
              href="#"
            >
              <span class="icon">logout</span>
              Sair
            </a>
          </nav>
        </div>
      </div>
    </section>
  </div>
</header>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import auth from '@/services/auth'
import CButton from '@/components/CButton'
import WorkspaceSelector from '@/components/components/form/WorkspaceSelector'

const emit = defineEmits(['toggle-right-bar'])

const store = useStore()
const route = useRoute()
const router = useRouter()

const workspaceId = computed({
  get: () => store.state.workspaceId,
  set: id => store.dispatch('setWorkspaceId', id)
})

async function logout () {
  await auth.logout()
  router.push({ name: 'auth.login' })
}
</script>

<style lang="scss">
.brand-icon {
  display: inline-flex;
  height: 28px;
  margin-right: var(--space-1);
  vertical-align: middle;
  width: 28px;
}
</style>
