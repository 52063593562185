<script setup>
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  disabled: Boolean,
  id: String,
  label: String,
  modelValue: [Number, String],
  name: String,
  nullable: {
    default: true,
    type: Boolean
  },
  nullValue: {
    default: undefined,
    type: [Number, String]
  },
  options: Array,
  optionLabelProp: {
    default: 'name',
    type: String
  },
  optionValueProp: {
    default: 'id',
    type: String
  },
  placeholder: {
    default: '[ Selecione ]',
    type: String
  }
})

const value = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
</script>

<template>
<div class="input">
  <label
    class="input__label"
    :for="id"
    v-if="label"
  >{{ label }}</label>
  <div class="input__select mb-0">
    <select
      class="input__control"
      :disabled="disabled"
      :id="id"
      :name="name"
      v-model="value"
    >
      <option
        v-if="nullable"
        :value="nullValue"
      >{{ placeholder }}</option>
      <option
        :key="option[optionValueProp]"
        :value="option[optionValueProp]"
        :disabled="option.disabled"
        v-for="option of options"
      >{{ option[optionLabelProp] }}</option>
    </select>
  </div>
  <slot />
</div>
</template>
