import accounts from '@/repositories/accounts'

export default {
  namespaced: true,

  state: () => ({
    accounts: []
  }),

  mutations: {
    addAccount (state, { item }) {
      state.accounts.push(item)
    },

    removeAccount (state, { item }) {
      state.accounts.splice(state.accounts.findIndex(one => one === item), 1)
    },

    replaceAccount (state, { item, index }) {
      state.accounts.splice(index, 1, item)
    },

    setAccounts (state, payload) {
      state.accounts = payload
    }
  },

  actions: {
    async destroyAccount ({ commit }, data) {
      await accounts.destroy(data)
      commit('removeAccount', { item: data })
    },

    async loadAccounts ({ commit }) {
      commit('setAccounts', await accounts.all())
    },

    async saveAccount ({ commit, state }, data) {
      const item = await accounts.save(data)
      const index = state.accounts.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceAccount' : 'addAccount', { item, index })

      return item
    }
  },

  getters: {
    isCurrentUserOfMainAccount (state, getters, rootState) {
      return !rootState.auth.user.account?.parent_id
    },

    mainAccount (state) {
      return state.accounts.find(one => !one.parent_id)
    },

    subaccounts (state) {
      return state.accounts.filter(one => !!one.parent_id)
    }
  }
}
