<script setup>
import { useEditor } from '../editor/editor.js'

import CInput from '@/components/components/form/Input'

const { selected } = useEditor()
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <CInput
    class="mb-0"
    label="Conteúdo"
    v-model="selected.children"
  />
</section>
</template>
