<script setup>
import { computed } from 'vue'
import { useEditor } from './editor.js'

const { hoverPosition, hoveredElePosition, isDragging, dropPosition } = useEditor()

const THICK = 3
const OFFSET = 5
const THICK_UNIT = `${THICK}px`
const OFFSET_UNIT = `-${OFFSET}px`
const OFFSET_CALC = `calc(100% - ${THICK_UNIT} + (${OFFSET_UNIT} * -1))`

const assertDropPosition = info => (!info.horizontal && !info.vertical) ? 'inside' : (info.left || info.top ? 'before' : 'after')

const calculateHeight = info => info.vertical ? THICK_UNIT : (info.horizontal ? '100%' : null)

const calculateLeft = info => info.vertical ? 0 : (info.left ? OFFSET_UNIT : (info.right ? OFFSET_CALC : null))

const calculateSnapDistance = size => Math.max(5, Math.min(size * 0.15, 15))

const calculateTop = info => info.horizontal ? 0 : (info.top ? OFFSET_UNIT : (info.bottom ? OFFSET_CALC : null))

const calculateWidth = info => info.vertical ? '100%' : (info.horizontal ? THICK_UNIT : null)

const dropHelperStyle = computed(() => {
  // eslint-disable-next-line no-unused-vars
  const { top, left, height, width } = hoveredElePosition?.value || {}
  const horizontalSnapDistance = calculateSnapDistance(width)
  const verticalSnapDistance = calculateSnapDistance(height)
  const info = {}

  if (isDragging.value) {
    info.top = hoverPosition.value.top <= verticalSnapDistance
    info.bottom = (height - hoverPosition.value.top) <= verticalSnapDistance
    info.vertical = info.top || info.bottom
    info.left = !info.vertical && hoverPosition.value.left <= horizontalSnapDistance
    info.right = !info.vertical && (width - hoverPosition.value.left) <= horizontalSnapDistance
    info.horizontal = info.left || info.right
    // @todo adjust
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    dropPosition.value = assertDropPosition(info)
  }

  return {
    top: calculateTop(info),
    left: calculateLeft(info),
    height: calculateHeight(info),
    width: calculateWidth(info)
  }
})
</script>

<template>
<div
  class="node-drop-handler"
  :style="dropHelperStyle"
></div>
</template>

<style>
.node-drop-handler {
  animation: 0.15s infinite alternate blink;
  background: #00f;
  pointer-events: none;
  position: absolute;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
</style>
