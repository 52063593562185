export const Templates = {
  QUIZ_FULL_PAGE: `<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <title>Título do Quiz</title>
  <link rel="stylesheet" href="BUCKET_ENPOINT/assets/quiz/css/app.css">
  <!-- PLACE ANALYTICS -->
</head>
<body>
  <div id="app"></div>
  <script>window.jbf={quiz:{url:'QUIZ_URL',selector:'#app'}}</script>
  <script src="BUCKET_ENPOINT/assets/quiz/js/app.js"></script>
</body>
</html>`,

  QUIZ_HEAD: `<link rel="stylesheet" href="BUCKET_ENPOINT/assets/quiz/css/app.css">`,

  QUIZ_BODY: `<div id="app"></div>
<script>window.jbf={quiz:{url:'QUIZ_URL',selector:'#app'}}</script>
<script src="BUCKET_ENPOINT/assets/quiz/js/app.js"></script>`
}
