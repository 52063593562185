<script setup>
import { computed } from 'vue'

import CInput from '@/components/components/form/Input'
import CSelect from '@/components/components/form/Select'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  currentStep: Number,
  modelValue: Object,
  stepsCount: Number
})

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <CSelect
    class="mb-2"
    label="Esconder elemento quando"
    :options="[
      { id: 'is', name: 'Steps são' },
      { id: 'between', name: 'Steps estão entre' },
      { id: 'from', name: 'Steps a partir de'},
      { id: 'to', name: 'Steps até'}
    ]"
    v-model="data.hideWhen"
  />
  <CInput
    class="mb-0"
    label="Steps"
    v-model="data.hideSteps"
  />
</section>
</template>
