<script setup>
import { computed } from 'vue'

import CButton from '@/components/CButton'
import Condition from './Condition'

const emit = defineEmits(['remove', 'update:criteria'])
const props = defineProps({
  criteria: Object,
  facts: Object,
  isRoot: Boolean
})

const value = computed({
  get: () => props.criteria,
  set: value => emit('update:criteria', value)
})

function addCondition () {
  value.value.conditions.push({
    fact: '',
    operator: '',
    value: null
  })
}

function addCriteria () {
  value.value.conditions.push({
    match: 'all',
    conditions: []
  })
}

function removeCondition (condition) {
  value.value.conditions.splice(
    value.value.conditions.findIndex(one => one === condition),
    1
  )
}
</script>

<template>
<article class="rules-builder__criteria mb-2">
  <header class="rules-builder__criteria-header flex--space-between mb-2">
    <div class="btn__group">
      <CButton
        caption="Todos (E)"
        class="rules-builder__button"
        :class="{ 'btn--selected': value.match === 'all' }"
        @click="value.match = 'all'"
      />
      <CButton
        caption="Qualquer (OU)"
        class="rules-builder__button"
        :class="{ 'btn--selected': value.match === 'any' }"
        @click="value.match = 'any'"
      />
    </div>
    <CButton
      class="btn--icon rules-builder__button"
      @click="emit('remove', criteria)"
      icon="clear"
      v-if="!isRoot"
    />
  </header>

  <section class="rules-builder__criteria-items">
    <template
      :key="index"
      v-for="(item, index) of value.conditions"
    >
      <Condition
        :facts="facts"
        @remove="removeCondition"
        v-if="!item.match"
        v-model:condition="value.conditions[index]"
      />
      <Criteria
        :facts="facts"
        @remove="removeCondition"
        v-else
        v-model:criteria="value.conditions[index]"
      />
    </template>
  </section>

  <footer class="rules-builder__criteria-footer">
    <CButton
      caption="Condição"
      class="btn--secondary rules-builder__button mr-2"
      @click="addCondition()"
      icon="add"
    />
    <CButton
      caption="Grupo"
      class="btn--secondary rules-builder__button"
      @click="addCriteria()"
      icon="add"
    />
  </footer>
</article>
</template>
