import { getApiEndpoint } from '@/support/tenant'
import Repository  from './repository'

class Assets extends Repository
{
  all (folder = '') {
    return this.client.get(this.urlTo() + '?folder=' + folder)
  }

  upload (data) {
    const formData = new FormData()

    Object.entries(data).forEach(([key, value]) => formData.append(key, value))

    return this.client.post(this.urlTo(), formData)
  }
}

export default new Assets({ base: getApiEndpoint(), path: 'assets' })
