<script setup>
import { computed, watch } from 'vue'
import { placeCss } from '../support.js'

import CButton from '@/components/CButton'
import Toolbar from '@/components/Toolbar'

const emit = defineEmits(['add', 'edit', 'update:modelValue'])
const props = defineProps({ modelValue: Array })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})

const removeStyle = style => data.value.splice(data.value.indexOf(style), 1)

watch(() => props.modelValue, () => placeCss(data.value), { deep: true })
</script>

<template>
<section>
  <Toolbar v-if="false">
    <template #actions>
      <div class="toolbar__group">
        <CButton
          class="btn--secondary"
          @click="emit('add')"
          icon="add"
        />
      </div>
    </template>
  </Toolbar>
  <section
    class="tile"
    :key="style.id"
    v-for="style of data"
  >
    <section class="tile__content">
      <div class="tile__main">{{ style.name }}</div>
      <small class="tile__secondary muted">{{ style.id }} / {{ style.class }}</small>
    </section>
    <CButton
      class="btn--fit btn--ghost"
      @click="emit('edit', style)"
      icon="edit"
    />
    <CButton
      class="btn--fit btn--ghost"
      @click="removeStyle(style)"
      icon="clear"
      v-if="!style.is_default"
    />
  </section>
</section>
</template>

<style scoped>
.tile__content {
  position: relative;
}

.tile__secondary {
  bottom: var(--space--3);
  display: none;
  position: absolute;
}

.tile:hover .tile__secondary {
  display: block;
}
</style>
