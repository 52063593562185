<template>
<CModal
  @close="close"
  :show-close-button="true"
  size="lg"
  :title="team.id ? `Editar Time [#${team.id}]` : 'Adicionar Time'"
>
  <form
    class="form"
    id="teamModalForm"
    @submit.prevent="tryToSave"
  >
    <fieldset>
      <div class="form__fields form__fields--row">
        <div class="input flex--2">
          <label class="input__label" for="teamName">Nome do time</label>
          <input class="input__control" id="teamName" type="text" v-model.trim="team.name" />
          <span class="input__error" v-if="v$.team.name.$error">Informe o nome do time</span>
        </div>
        <div class="input input--no-label">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="team.is_active" />
            <span>Está ativo</span>
          </label>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <Toolbar title="Workspaces">
        <template #actions>
          <div class="toolbar__group">
            <CButton
              caption="Adicionar Workspace"
              class="btn--secondary"
              @click="addWorkspace()"
              icon="add"
            />
          </div>
        </template>
      </Toolbar>

      <section>
        <div
          class="form__fields form__fields--row mb-4"
          :key="index"
          v-for="(workspace, index) in team.workspaces"
        >
          <CSelect
            class="flex--1"
            :options="workspaces"
            v-model="workspace.id"
          />
          <button
            class="btn btn--icon"
            @click="removeWorkspace(index)"
            type="button"
          ><span class="material-icons-outlined">clear</span></button>
        </div>
      </section>
    </fieldset>
  </form>

  <template #footer>
    <footer class="modal__footer">
      <CButton
        caption="Cancelar"
        @click="close"
      />
      <CButton
        caption="Salvar"
        class="btn--primary"
        form="teamModalForm"
        icon="save"
        type="submit"
      />
    </footer>
  </template>
</CModal>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { required } from '@vuelidate/validators'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import * as obj from '@/support/object'

import CButton from '@/components/CButton'
import CModal from '@/components/Modal'
import CSelect from '@/components/components/form/Select'
import Toolbar from '@/components/Toolbar'

const emit = defineEmits(['close'])
const props = defineProps({
  item: Object,
  workspaces: Array
})

const emptyTeam = () => ({
  id: null,
  is_active: false,
  name: '',
  users: [],
  workspaces: []
})

const store = useStore()
const rules = computed(() => ({ name: { required } }))
const team = ref(emptyTeam())
const v$ = useVuelidate(rules, team)

const addWorkspace = () => team.value.workspaces.push({ id: null })

const close = () => {
  team.value = emptyTeam()

  v$.value.$reset()
  emit('close')
}

const removeWorkspace = index => team.value.workspaces.splice(index, 1)

const tryToSave = async () => {
  if (!await v$.value.$validate()) return

  await store.dispatch('teams/saveTeam', team.value)
  close()
}

watch(
  () => props.item,
  item => team.value = obj.isEmpty(item) ? emptyTeam() : { users: [], ...obj.copy(item) },
  { immediate: true }
)
</script>
