<script setup>
import { computed } from 'vue'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { useStore } from 'vuex'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: [Number, String] })

const store = useStore()
const workspaces = computed(() => orderBy(store.state.workspaces, ['name']))
const activeWorkspaces = computed(() => workspaces.value.filter(one => !one.is_archived))
const workspacesWithGroups = computed(() => activeWorkspaces.value.map(groupMapper))
const groupedWorkspaces = computed(() => groupBy(workspacesWithGroups.value, 'group'))

const value = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

function groupMapper (data) {
  const parts = data.name.split('/')

  if (parts.length === 1) {
    parts.unshift('Geral')
  }

  return {
    ...data,
    group: parts[0].trim(),
    name: parts.slice(1).join('/').trim()
  }
}
</script>

<template>
<div class="input__select">
  <select
    class="input__control"
    id="workspaceSelector"
    v-model="value"
  >
    <optgroup
      :key="label"
      :label="label"
      v-for="(group, label) of groupedWorkspaces"
    >
      <option
        :key="workspace.id"
        :value="workspace.id"
        v-for="workspace of group"
      >{{ workspace.name }} / {{ workspace.domain }}</option>
    </optgroup>
  </select>
  <slot />
</div>
</template>
