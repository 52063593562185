export function assignNodesIds (node) {
  if (Array.isArray(node)) {
    return node.forEach(one => assignNodesIds(one))
  }

  node.uid ??= createNodeId()

  if (Array.isArray(node.children)) {
    node.children.forEach(one => assignNodesIds(one))
  }
}

export function createNodeId () {
  return '' + Date.now() + Math.floor(Math.random() * 999999)
}

export const emptyQuestion = (order = null) => ({
  configs: {},
  id: (new Date()).getTime(),
  order,
  quiz_id: null,
  quiz_question_id: null
})

export const emptyItem = () => ({
  configs: {
    version: 2,
    ab_test: {},
    tracking: {},
    wrapper: { config_domain: null },
    styles: [...getDefaultStyles()],
    nodes: [{
      uid: '1',
      is: 'div',
      children: [],
      attrs: { class: 'quiz' }
    }],
    result_pages: []
  },
  id: null,
  is_active: false,
  key: '',
  name: '',
  quiz_questions: []
})

export const emptyPage = () => ({
  id: (new Date()).getTime(),
  rules: [],
  url: ''
})

export const emptyStyle = () => ({
  class: '',
  id: null,
  is_default: false,
  name: '',
  declarations: {}
})

export const getDefaultStyles = () => ([
  {
    class: 'quiz-window',
    id: 1,
    is_default: true,
    name: 'Janela',
    declarations: {}
  },
  {
    class: 'quiz',
    id: 2,
    is_default: true,
    name: 'Quiz',
    declarations: {}
  },
  {
    class: 'quiz__header',
    id: 3,
    is_default: true,
    name: 'Cabeçalho',
    declarations: {}
  },
  {
    class: 'quiz__steps',
    id: 4,
    is_default: true,
    name: 'Todos os passos do quiz',
    declarations: {}
  },
  {
    class: 'quiz__step',
    id: 17,
    is_default: true,
    name: 'Cada passo do quiz',
    declarations: {}
  },
  {
    class: 'quiz__question',
    id: 5,
    is_default: true,
    name: 'Pergunta do quiz',
    declarations: {}
  },
  {
    class: 'quiz__question-statement',
    id: 6,
    is_default: true,
    name: 'Enunciado da pergunta',
    declarations: {}
  },
  {
    class: 'quiz__question-image',
    id: 7,
    is_default: true,
    name: 'Imagem da pergunta',
    declarations: {}
  },
  {
    class: 'quiz__question-options',
    id: 8,
    is_default: true,
    name: 'Conjunto de respostas',
    declarations: {}
  },
  {
    class: 'quiz__question-option',
    id: 9,
    is_default: true,
    name: 'Opção de resposta',
    declarations: {}
  },
  {
    class: 'quiz__question-option',
    id: 10,
    is_default: true,
    name: 'Opção de resposta (mouse)',
    state: ':hover',
    declarations: {}
  },
  {
    class: 'quiz__question-option-image',
    id: 11,
    is_default: true,
    name: 'Imagem da opção de resposta',
    declarations: {}
  },
  {
    class: 'quiz__question-next',
    id: 12,
    is_default: true,
    name: 'Botão de próximo',
    declarations: {}
  },
  {
    class: 'quiz__question-next',
    id: 18,
    is_default: true,
    name: 'Botão de próximo (mouse)',
    state: ':hover',
    declarations: {}
  },
  {
    class: 'quiz__gate',
    id: 13,
    is_default: true,
    name: 'Formulário de coleta',
    declarations: {}
  },
  {
    class: 'quiz__gate-field',
    id: 14,
    is_default: true,
    name: 'Campo do formulário',
    declarations: {}
  },
  {
    class: 'quiz__gate-send',
    id: 15,
    is_default: true,
    name: 'Botão de envio do lead',
    declarations: {}
  },
  {
    class: 'quiz__gate-send',
    id: 19,
    is_default: true,
    name: 'Botão de envio do lead (mouse)',
    state: ':hover',
    declarations: {}
  },
  {
    class: 'quiz__footer',
    id: 16,
    is_default: true,
    name: 'Rodapé',
    declarations: {}
  },
  {
    class: 'quiz__progress',
    id: 20,
    is_default: true,
    name: 'Barra de progresso',
    declarations: {}
  },
  {
    class: 'quiz__progress-bar',
    id: 21,
    is_default: true,
    name: 'Barra de progresso (total)',
    declarations: {}
  },
  {
    class: 'quiz__progress-bar-thumb',
    id: 22,
    is_default: true,
    name: 'Barra de progresso (decorrido)',
    declarations: {}
  },
  {
    class: 'quiz__gate-field-label',
    id: 23,
    is_default: true,
    name: 'Título de campo do formulário',
    declarations: {}
  },
  {
    class: 'quiz__gate-field-control',
    id: 24,
    is_default: true,
    name: 'Input de campo do formulário',
    declarations: {}
  },
  {
    class: 'quiz__gate-field-control',
    id: 25,
    is_default: true,
    name: 'Input de campo do formulário (foco)',
    state: ':focus',
    declarations: {}
  },
].sort((a, b) => a.name.localeCompare(b.name)))

export const originalSchema = {
  questions: [{
    id: 1,
    key: 'occupation',
    type: 'single',
    statement: 'Qual a sua situação atual?',
    help: '',
    options: [{
      key: 'employed',
      label: 'Employed',
      value: 'employed'
    }, {
      key: 'retired',
      label: 'Retired',
      value: 'retired'
    }, {
      key: 'unemployed',
      label: 'Unemployed',
      value: 'unemployed'
    }, {
      key: 'student',
      label: 'Student',
      value: 'student'
    }]
  }, {
    id: 2,
    key: 'credit-score',
    type: 'single',
    statement: 'Qual seu score de crédito?',
    help: '',
    options: [{
      key: 'no-credit',
      label: 'No credit (300)',
      value: 'no-credit'
    }, {
      key: 'limited',
      label: 'Limited (300-549)',
      value: 'limited'
    }, {
      key: 'low',
      label: 'Low (550-649)',
      value: 'low'
    }, {
      key: 'good',
      label: 'Good (650-850)',
      value: 'good'
    }]
  }, {
    id: 3,
    key: 'income',
    type: 'single',
    statement: 'Qual sua renda atual?',
    help: '',
    options: [{
      key: '-1200',
      label: 'Até R$ 1.200,00',
      value: '-1200'
    }, {
      key: '1201-3000',
      label: 'Entre R$ 1.201,00 e R$ R$ 3.000,00',
      value: '1201-3000'
    }, {
      key: '3001-5000',
      label: 'Entre R$ 3.001,00 e R$ R$ 5.000,00',
      value: '3001-5000'
    }, {
      key: '5001-',
      label: 'Mais de R$ 5.001,00',
      value: '5001-'
    }]
  }, {
    id: 4,
    key: 'is-credit-blocked',
    type: 'yes-no',
    statement: 'Você está negativado?',
    help: '',
    options: [{
      key: 'yes',
      label: 'Yes',
      value: true
    }, {
      key: 'no',
      label: 'No',
      value: false
    }]
  }, {
    id: 5,
    key: 'benefits',
    type: 'multiple',
    statement: 'Quais seus benefícios preferidos?',
    help: '',
    options: [{
      key: 'cashback',
      label: 'Cashback',
      value: 'cashback'
    }, {
      key: 'discounts',
      label: 'Discounts',
      value: 'discounts'
    }, {
      key: 'mileage',
      label: 'Mileage',
      value: 'mileage'
    }, {
      key: 'free-annuity',
      label: 'Free Annuity',
      value: 'free-annuity'
    }]
  }],
  sections: {
    footer: { children: [] },
    header: {
      children: [{
        is: 'h2',
        children: [{
          content: 'Quiz',
        }]
      }]
    }
  },
  steps: [{
    children: [{
      is: 'question',
      config: {
        question: 'income'
      }
    }],
    id: 1,
    is_start: true,
    next: 2
  }, {
    children: [{
      is: 'p',
      content: 'Before question ...'
    }, {
      is: 'question',
      config: {
        question: 'occupation'
      }
    }],
    id: 2,
    next: 3
  }, {
    children: [{
      is: 'question',
      config: {
        question: 'is-credit-blocked'
      }
    }],
    id: 3,
    next: 4
  }, {
    children: [{
      is: 'question',
      config: {
        question: 'income'
      }
    }],
    id: 4,
    next: 5
  }, {
    children: [{
      is: 'question',
      config: {
        question: 'benefits'
      }
    }],
    id: 5,
    next: 6
  }, {
    children: [{
      is: 'p',
      content: 'Before gate ...'
    }, {
      is: 'gate',
      children: [{
        is: 'h3',
        content: 'Send your contact ...'
      }]
    }],
    id: 6,
    next: null
  }]
}

export const Templates = {
  QUIZ_FULL_PAGE: `<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="icon" href="">
  <title>TITLE</title>
  <link rel="stylesheet" href="BUCKET_ENPOINT/assets/quiz-v2/app.css">
  <!-- Google Analytics -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=ANALYTICS_ID"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'ANALYTICS_ID');
  </script>
</head>
<body class="quiz-window">
  <div id="app">RENDER</div>
  <script>window.jbf={quiz:{url:'QUIZ_URL',selector:'#app'}}</script>
  <script type="module" src="BUCKET_ENPOINT/assets/quiz-v2/app.js"></script>
</body>
</html>`,

  QUIZ_HEAD: `<link rel="stylesheet" href="BUCKET_ENPOINT/assets/quiz-v2/app.css">`,

  QUIZ_BODY: `<div id="app"></div>
<script>window.jbf={quiz:{url:'QUIZ_URL',selector:'#app'}}</script>
<script type="module" src="BUCKET_ENPOINT/assets/quiz-v2/app.js"></script>`
}
