<template>
<CModal
  @close="close"
  :show-close-button="true"
  :title="user.id ? `Editar Usuário [#${user.id}]` : 'Adicionar Usuário'"
>
  <form
    class="form"
    id="userModalForm"
    @submit.prevent="tryToSave"
  >
    <fieldset>
      <div class="form__fields form__fields--row">
        <div class="input flex--2">
          <label class="input__label" for="userName">Nome do usuário</label>
          <input class="input__control" id="userName" type="text" v-model.trim="user.name" />
          <span class="input__error" v-if="v$.name.$error">Informe o nome do usuário</span>
        </div>
        <div class="input input--no-label">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="user.is_active" />
            <span>Está ativo</span>
          </label>
        </div>
      </div>
      <template v-if="user.role !== 'system'">
        <div class="input">
          <label class="input__label" for="userEmail">Email do usuário</label>
          <input class="input__control" id="userEmail" type="text" v-model.trim="user.email" />
          <span class="input__error" v-if="v$.email.$error">Informe um email válido para o usuário</span>
        </div>
        <div
          class="input"
          v-if="!!user.id"
        >
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="restartPassword" />
            <span>Reiniciar senha do usuário</span>
          </label>
        </div>
        <div
          class="input"
          v-if="!user.id || restartPassword"
        >
          <label class="input__label" for="userName">Senha do usuário</label>
          <input class="input__control" id="userName" type="text" v-model.trim="user.password" />
          <span v-if="v$.password.$error">
            <span class="input__error" v-if="v$.password.required.$invalid">Informe uma senha para o usuário</span>
            <span class="input__error" v-else-if="v$.password.minLength.$invalid">
              A senha deve conter no mínimo {{ v$.password.minLength.$params.min }} caracteres
            </span>
          </span>
        </div>
        <CSelect
          id="userAccount"
          label="Conta gestora do usuário"
          name="userAccount"
          :options="accounts"
          v-model="user.account_id"
        >
          <span class="input__error" v-if="v$.account_id.$error">Escolha uma conta</span>
        </CSelect>
        <CSelect
          id="userRole"
          label="Tipo de acesso principal"
          name="userRole"
          option-label-prop="label"
          option-value-prop="key"
          :options="allowedRoles"
          v-model="user.role"
        >
          <span class="input__error" v-if="v$.role.$error">Escolha um tipo de acesso</span>
        </CSelect>
      </template>
    </fieldset>

    <fieldset hidden>
      <Toolbar title="Times">
        <template #actions>
          <div class="toolbar__group">
            <CButton
              caption="Adicionar aos Times"
              class="btn--secondary"
              @click="addToTeam()"
              icon="add"
            />
          </div>
        </template>
      </Toolbar>

      <section>
        <div
          class="form__fields form__fields--row mb-4"
          :key="index"
          v-for="(team, index) in user.teams"
        >
          <CSelect
            class="flex--1"
            :options="teams"
            v-model="team.team_id"
          />
          <CSelect
            class="flex--1"
            option-label-prop="label"
            option-value-prop="key"
            :options="allowedRoles"
            v-if="false"
            v-model="team.role"
          />
          <button
            class="btn btn--icon"
            @click="removeFromTeam(index)"
            type="button"
          ><span class="material-icons-outlined">clear</span></button>
        </div>
      </section>
    </fieldset>
  </form>

  <template #footer>
    <footer class="modal__footer">
      <CButton
        caption="Cancelar"
        @click="close"
      />
      <CButton
        caption="Salvar"
        class="btn--primary"
        form="userModalForm"
        icon="save"
        type="submit"
      />
    </footer>
  </template>
</CModal>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { email, minLength, required, requiredIf } from '@vuelidate/validators'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import * as obj from '@/support/object'

import CButton from '@/components/CButton'
import CModal from '@/components/Modal'
import CSelect from '@/components/components/form/Select'
import Toolbar from '@/components/Toolbar'

const emit = defineEmits(['close'])
const props = defineProps({
  accounts: Array,
  allowedRoles: Array,
  item: Object,
  teams: Array
})

const emptyUser = () => ({
  account_id: null,
  email: '',
  id: null,
  is_active: false,
  name: '',
  password: '',
  role: 'basic',
  teams: []
})

const store = useStore()
const rules = computed(() => ({
  account_id: { required },
  email: { required, email },
  name: { required },
  password: { required: requiredIf(!user.value.id), minLength: minLength(8) },
  role: { required }
}))
const user = ref(emptyUser())
const restartPassword = ref(false)
const v$ = useVuelidate(rules, user)

const addToTeam = () => user.value.teams.push({
  role: user.value.role,
  team_id: null
})

const close = () => {
  user.value = emptyUser()
  restartPassword.value = false

  v$.value.$reset()
  emit('close')
}

const removeFromTeam = index => user.value.teams.splice(index, 1)

const tryToSave = async () => {
  if (!await v$.value.$validate()) return

  await store.dispatch('users/saveUser', user.value)
  close()
}

watch(
  () => props.item,
  item => user.value = obj.isEmpty(item) ? emptyUser() : { teams: [], ...obj.copy(item) },
  { immediate: true }
)
</script>
