<script setup>
import { useEditor } from '../editor/editor.js'

import CInput from '@/components/components/form/Input'
import CSelect from '@/components/components/form/Select'

const { selected } = useEditor()
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <div class="input mb-2">
    <label class="input__label" for="quizGateBypassFor">Fazer bypass do gate por</label>
    <div class="input__group flex">
      <input
        class="input__control"
        id="quizGateBypassFor"
        type="text"
        v-model.number="selected.configs.bypassFor"
      />
      <span class="input__control input__control--static">dias</span>
    </div>
  </div>
  <CSelect
    class="input mb-2"
    id="quizGateMaxSplitIndex"
    label="Fazer split de leads"
    :options="[
      // { id: 1, name: 'Por probabilidade' },
      { id: 2, name: 'Em 2 contas' },
      { id: 3, name: 'Em 3 contas' },
      { id: 4, name: 'Em 4 contas' },
      { id: 5, name: 'Em 5 contas' },
    ]"
    placeholder="Desabilitado"
    v-model="selected.configs.maxSplitIndex"
  />
  <CInput
    class="mb-0"
    label="Texto de botão de enviar"
    v-model="selected.configs.sendText"
  />
</section>
</template>
