import { getApiEndpoint } from '@/support/tenant'
import Repository  from './repository'

class Dashboard extends Repository
{
  all () {
    return this.client.get(this.urlTo())
  }

  updateWidget (key, options = {}) {
    return this.client.get(this.urlTo(key), options)
  }
}

export default new Dashboard({
  base: getApiEndpoint(),
  path: 'dashboard'
})
