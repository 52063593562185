import { computed } from 'vue'
import { format } from '@/support/number'
import { useStore } from 'vuex'

export function useFields () {
  const store = useStore()

  const data = computed(() => store.state.quizzesV2.conversionsStatistics)
  const stepsCounts = computed(() => data.value.map(item => item.steps_count))
  const maxStep = computed(() => stepsCounts.value.length ? Math.max(...stepsCounts.value) : 0)

  const fields = computed(() => ({
    start: 'Sessões',
    ...reduceFields(keyIdx => 'Passo ' + keyIdx),
    end: 'Conversões'
  }))

  const stepsFields = computed(() => {
    return reduceFields((keyIdx, key) => ({
      aggregator: (carry, item) => carry + item[key],
      formatter: format,
      label: 'Passo ' + keyIdx
    }))
  })

  function reduceFields (accumulator) {
    return '*'.repeat(maxStep.value)
      .split('')
      .reduce((acc, curr, idx) => {
        const keyIdx = idx + 1
        const key = 'step' + keyIdx
        acc[key] = accumulator(keyIdx, key)

        return acc
      }, {})
  }

  return { fields, stepsFields }
}
