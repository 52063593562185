<template>
<article class="card card--flat data-cards-list__item">
  <div
    class="flex--2"
    v-if="!error"
  >
    E <span v-html="composeCriteriasDescription(rule)"></span>,
    <span v-html="composeTargetDescription(rule)"></span>
  </div>
  <div
    class="alert alert--danger alert--ghost flex--2"
    v-else
  >
    <span class="icon alert__icon">error_outline</span>
    <p class="alert__title">
      Regra #{{ rule.id }} está com a configuração inconsistente. Edite para ajustar a regra.
    </p>
  </div>
  <div class="data-cards-list__item-actions">
    <CButton
      caption="Editar"
      class="btn--link btn--fit"
      @click="$emit('edit', rule)"
    />
    <CButton
      caption="Apagar"
      class="btn--link btn--fit"
      @click="$emit('destroy', rule)"
    />
  </div>
</article>
</template>

<script>
import CButton from '@/components/CButton'

export default {
  name: 'DistributionRuleListItem',

  components: { CButton },

  props: {
    fields: Array,
    rule: Object
  },

  data () {
    return { error: null }
  },

  methods: {
    composeCriteriasDescription (rule) {
      return Object.entries(rule.criterias).map(entry => {
        const field = this.fields.find(one => one.key === entry[0])
        const values = field?.options || []
        const options = typeof values === 'function' ? values() : values

        return [
          '<span class="distribution-rules__rule-attribute">',
          `<b>${field.name}</b>`,
          'é igual a',
          `<b>${options.find(one => one.key === entry[1])?.name ?? ''}</b>`,
          '</span>'
        ].join(' ')
      }).join(' e ')
    },

    composeTargetDescription (rule) {
      try {
        const list = rule.target_platform_account.lists.find(one => one.id === rule.target_list_id)

        return [
          'enviar para a lista',
          '<span class="distribution-rules__rule-attribute">',
          `<b>${list.name}</b>`,
          'da conta',
          '<b>',
          rule.target_platform_account.platform,
          rule.target_platform_account.name,
          '</b>',
          '</span>',
          ...(!rule.meta.sms_list ? [] : [
            'e para a conta de SMSs',
            '<span class="distribution-rules__rule-attribute">',
            `<b>${rule.meta.sms_list}</b>`,
            '</span>',
          ]),
        ].join(' ')
      } catch (error) {
        this.error = true
      }
    }
  }
}
</script>
