<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

import AppLayout from '@/components/layout/App'
import CConfirm from '@/components/components/dialogs/Confirm'
import List from './List'
import Toolbar from '@/components/Toolbar'
import UserForm from './Form'

const store = useStore()
const loading = ref(true)
const toDestroy = ref(null)
const user = ref(null)

const confirmingDestroy = computed(() => !!toDestroy.value)
const editing = computed(() => !!user.value)
const accounts = computed(() => store.state.accounts.accounts)
const roles = computed(() => store.state.roles.roles)
const users = computed(() => store.state.users.users)
const allowedRoles = computed(() => store.getters['roles/allowedRoles'])
const teams = computed(() => store.getters['teams/teams'])

const confirmDestroy = user => toDestroy.value = user

const destroy = async () => {
  await store.dispatch('users/destroyUser', toDestroy.value)
  toDestroy.value = null
}

const edit = (item = {}) => user.value = item

onBeforeMount(async () => {
  store.dispatch('teams/loadTeams')
  store.dispatch('accounts/loadAccounts')
  await store.dispatch('users/loadUsers')
  loading.value = false
})
</script>

<template>
<AppLayout>
  <section class="layout__main users">
    <div class="container">
      <Toolbar
        :has-primary-action="true"
        @primary-action="edit()"
        primary-action-caption="Adicionar Usuário"
        primary-action-icon="add"
        title="Usuários"
      />

      <List
        @destroy="confirmDestroy($event)"
        @edit="edit($event)"
        :items="users"
        :loading="loading"
        :roles="roles"
      />
    </div>
  </section>

  <UserForm
    :accounts="accounts"
    :allowed-roles="allowedRoles"
    @close="user = null"
    :item="user"
    :teams="teams"
    v-if="editing"
  />

  <CConfirm
    alert="Apagar um usuário é uma ação crítica e irá apagar todos os dados relacionados com ele."
    @cancel="toDestroy = null"
    @confirmed="destroy()"
    v-if="confirmingDestroy"
  >
    <p>Tem certeza que deseja apagar o usuário <strong>{{ toDestroy.name }}</strong>?</p>
  </CConfirm>
</AppLayout>
</template>
