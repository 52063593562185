<script>
export default {
  meta: {
    closed: true,
    custom: true
  }
}
</script>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  count: {
    default: 3,
    type: Number
  },
  index: {
    default: 0,
    type: Number
  },
  indexPosition: {
    default: 'right',
    type: String
  },
  showIndex: {
    default: true,
    type: Boolean
  }
})

const width = computed(() => (((props.index + 1) / props.count) * 100) + '%')
</script>

<template>
<section class="quiz__progress">
  <slot />
  <div class="quiz__progress-bar">
    <div
      class="quiz__progress-bar-thumb"
      :style="{ width }"
    ></div>
  </div>
  <span
    class="quiz__progress-bar-index"
    :style="{ order: indexPosition === 'left' ? -1 : 1 }"
    v-if="showIndex"
  >({{ index + 1 }}/{{ count }})</span>
</section>
</template>

<style>
.quiz__progress {
  align-items: center;
  display: flex;
  gap: 0.75rem;
  margin: 0 auto 1.25rem;
}

.quiz__progress-bar {
  background: #ccc;
  border-radius: 6px;
  flex: 1;
  height: 6px;
  position: relative;
}

.quiz__progress-bar-thumb {
  background: var(--color-progress-bar, #666);
  border-radius: 6px;
  bottom: 0;
  position: absolute;
  top: 0;
}

.quiz__progress-bar-index {
  color: var(--color-step);
  font-weight: 500;
}
</style>
