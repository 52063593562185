<script setup>
import { computed } from 'vue'

import CInput from '@/components/components/form/Input'
import CSelect from '@/components/components/form/Select'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Object })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <CInput
    class="mb-2"
    label="URL do link"
    v-model="data.href"
  />
  <CSelect
    class="input mb-0"
    label="Abrir em"
    :options="[
      { id: '_blank', name: 'Nova aba' },
      { id: '_self', name: 'Mesma aba' },
    ]"
    v-model="data.target"
  />
</section>
</template>
