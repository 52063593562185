<script setup>
import { computed } from 'vue'
import { groupBy, summarize, tidy } from '@tidyjs/tidy'
import omit from 'lodash/omit'
import without from 'lodash/without'

import Grid from './Grid'

const props = defineProps({
  data: Array,
  except: {
    default: () => [],
    type: Array
  },
  fields: Object,
  summarize: Object,
  summaryFields: Array,
})

const groupFields = computed(() => without(
  Object.keys(omit(props.fields, props.summaryFields)),
  ...props.except
))
const filteredData = computed(() => tidy(
  props.data,
  groupBy(groupFields.value, [
    summarize(props.summarize)
  ])
))
const filteredFields = computed(() => omit(props.fields, props.except))
</script>

<template>
<Grid
  :data="filteredData"
  :fields="filteredFields"
/>
</template>
