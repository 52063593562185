<script>
export default {
  meta: {
    closed: true,
    custom: true
  }
}
</script>

<script setup>
import { generateId } from '../support.js'

defineProps({
  label: String,
  mask: String,
  placeholder: String,
  required: Boolean,
  validationMessage: String
})

const id = generateId('f-')
</script>

<template>
<div class="quiz__gate-field">
  <slot />
  <label
    class="quiz__gate-field-label"
    :for="id"
  >{{ label }}</label>
  <input
    class="quiz__gate-field-control"
    :id="id"
    :placeholder="placeholder"
    :required="required"
    type="text"
  >
</div>
</template>
