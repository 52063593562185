import { getApiEndpoint } from '@/support/tenant'
import Repository  from './repository'

class PlatformAccounts extends Repository
{
  all () {
    return this.client.get(this.urlTo())
  }

  destroy (data) {
    return this.client.delete(this.urlTo(), data)
  }

  fields (data) {
    return this.client.post(this.urlTo('fields', false), data)
  }

  find (id) {
    return this.client.get(this.urlTo(id))
  }

  lightList () {
    return this.client.get(this.urlTo('light-list', false))
  }

  save (data) {
    const method = data.id ? 'put' : 'post'

    return this.client[method](this.urlTo(), data)
  }

  /**
   * @todo refactor
   */
  smsPlatformsLists () {
    return this.client.get(this.urlTo('sms-platforms-lists', false))
  }
}

export default new PlatformAccounts({
  base: getApiEndpoint(),
  path: 'platform-accounts'
})
