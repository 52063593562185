import './support/tenant'
import './styles/main.scss'
import { createApp } from 'vue'
import App from './App.vue'
import client from '@/support/http/client'
import { initErrorReporting } from './support/error-reporting'
import store from './store'
import router from './router'

const app = createApp(App)

initErrorReporting(app, router)

// @todo refactor
client.interceptors.response.push(ex => {
  store.state.app.notices.push({
    icon: 'error_outline',
    classes: 'alert--danger',
    closeable: true,
    description: ex.message
  })
})

app.use(store).use(router).mount('#app')
