<template>
<component
  class="btn"
  :is="asLink ? 'a' : 'button'"
  v-bind="attrs"
>
  <slot>
    <span
      class="icon"
      :class="{ 'icon--prefix': !!caption }"
      v-if="icon"
    >{{ icon }}</span>
    {{ caption }}
  </slot>
</component>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  asLink: Boolean,
  caption: String,
  icon: String,
  type: {
    default: 'button',
    type: String
  }
})

const attrs = computed(() => props.asLink ? { href: '#' } : { type: props.type })
</script>
