import smsPlatformAccounts from '@/repositories/sms-platform-accounts'

export default {
  namespaced: true,

  state: () => ({
    optOutStatistics: [],
    rulersStatistics: [],
    smsPlatformAccounts: [],
    smsPlatformAccountsLightList: []
  }),

  mutations: {
    addSmsPlatformAccount (state, { item }) {
      state.smsPlatformAccounts.push(item)
    },

    removeSmsPlatformAccount (state, { item }) {
      state.smsPlatformAccounts.splice(state.smsPlatformAccounts.findIndex(one => one === item), 1)
    },

    replaceSmsPlatformAccount (state, { item, index }) {
      state.smsPlatformAccounts.splice(index, 1, item)
    },

    setOptOutStatistics (state, payload) {
      state.optOutStatistics = payload
    },

    setRulersStatistics (state, payload) {
      state.rulersStatistics = payload
    },

    setSmsPlatformAccounts (state, payload) {
      state.smsPlatformAccounts = payload
    },

    setSmsPlatformAccountsLightList (state, payload) {
      state.smsPlatformAccountsLightList = payload
    }
  },

  actions: {
    async destroySmsPlatformAccount ({ commit }, data) {
      await smsPlatformAccounts.destroy(data)
      commit('removeSmsPlatformAccount', { item: data })
    },

    async loadOptOutStatistics ({ commit }) {
      commit('setOptOutStatistics', await smsPlatformAccounts.optOutStatistics())
    },

    async loadRulersStatistics ({ commit }) {
      commit('setRulersStatistics', await smsPlatformAccounts.rulersStatistics())
    },

    async loadSmsPlatformAccounts ({ commit }) {
      commit('setSmsPlatformAccounts', await smsPlatformAccounts.all())
    },

    async loadSmsPlatformAccountsLightList ({ commit }) {
      commit('setSmsPlatformAccountsLightList', await smsPlatformAccounts.lightList())
    },

    async saveSmsPlatformAccount ({ commit, state }, data) {
      const item = await smsPlatformAccounts.save(data)
      const index = state.smsPlatformAccounts.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceSmsPlatformAccount' : 'addSmsPlatformAccount', { item, index })

      return item
    }
  }
}
