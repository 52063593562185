import client from '@/support/http/client'

export default class Repository
{
  constructor ({ base, path, workspace }) {
    this.base = base.replace(/\/$/, '')
    this.path = path
    this.workspace = workspace
    this.client = client
  }

  onWorkspace (workspace) {
    this.workspace = workspace

    return this
  }

  urlTo (path = '', workspaced = true) {
    return [
      this.base,
      workspaced ? this.workspace : null,
      this.path,
      path
    ].filter(part => !!part).join('/')
  }
}
