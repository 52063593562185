import { getDef } from '../components/index.js'

export const findNodes = (children, is) => {
  const matches = []

  if (!Array.isArray(children)) return matches

  children.forEach(child => {
    matches.push(...(child.is === is ? [child] : findNodes(child.children, is)))
  })

  return matches
}

export const isAnyParentClosed = (node, parents) => {
  const parent = parents.get(node)

  if (!parent) return

  const def = getDef(parent)

  if (def?.meta?.closed) return true

  return isAnyParentClosed(parent, parents)
}

export const isNodeClosed = (node, parents) => {
  const def = getDef(node)

  return node?.meta?.closed || def?.meta?.closed || (parents && isAnyParentClosed(node, parents))
}

export const isNodeSelectable = (node, parents) => {
  const def = getDef(node)

  return node?.meta?.custom || def?.meta?.custom || !isNodeClosed(node, parents)
}

export const shouldHaveChildren = what => Array.isArray(what.children) && !what.children.length

export const shouldHaveContent = what => ('children' in what)
  && !Array.isArray(what.children)
  && !what?.children?.length
