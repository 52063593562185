<script setup>
import { computed, ref, watch } from 'vue'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'

import CButton from '@/components/CButton'

const emit = defineEmits()

const props = defineProps({
  actions: Array,
  data: Array,
  fields: Object
})

const data = ref([])
const filters = ref({})
const sortField = ref()
const sortDirection = ref()
const aggregationCarry = {}

const fieldsKeys = computed(() => Object.keys(props.fields))
const filteredData = computed(() => doFilter(data.value, filters.value))
const sortedData = computed(() => orderBy(
  filteredData.value,
  [sortField.value],
  [sortDirection.value]
))
const hasAggregators = computed(() => fieldsKeys.value.some(key => props.fields[key]?.aggregator))

const afterAggregateField = (key, field) => field.afterAggregator(aggregationCarry)

const aggregateField = (key, field) => {
  aggregationCarry[key] = filteredData.value.reduce(field.aggregator, 0)

  return aggregationCarry[key]
}

const doFilter = (data, filters) => {
  const filtersKeys = Object.keys(filters)
  const filtersCount = filtersKeys.length

  return data.filter(item => {
    let matchCount = 0

    for (const key of filtersKeys) {
      matchCount += +('' + formatValue(props.fields[key], item[key]))
        .toLowerCase()
        .includes(filters[key].toLowerCase())
    }

    return matchCount === filtersCount
  })
}

const filter = (field, value) => {
  if (!value) {
    filters.value = omit(filters.value, [field])
  }

  if (value) {
    filters.value[field] = value
  }
}

const formatStyle = (field, value) => {
  return field.styleFormatter ? field.styleFormatter(value) : {}
}

const formatValue = (field, value) => {
  return field.formatter ? field.formatter(value) : value
}

const getValue = (item, key) => {
  const field = props.fields[key]

  return field.value ? field.value(item) : item[field.key || key]
}

const sort = field => {
  if (field === sortField.value || !sortField.value) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc'
  }

  sortField.value = field
}

watch(() => props.data, value => {
  data.value = value
  sortField.value = null
  sortDirection.value = null
})
</script>

<template>
<table class="table">
  <thead>
    <tr>
      <th
        class="grid__field-header"
        @click.prevent="sort(field)"
        :key="field"
        v-for="field of fieldsKeys"
      >
        {{ fields[field].label }}
        <span
          class="icon"
          v-if="sortField === field"
        >{{ sortDirection === 'asc' ? 'expand_less' : 'expand_more' }}</span>
      </th>
      <th v-if="actions"></th>
    </tr>
    <tr>
      <th
        class="grid__field-filter"
        :key="field"
        v-for="field of fieldsKeys"
      >
        <input
          class="input__control"
          @input="filter(field, $event.target.value)"
          placeholder="Filtrar ..."
          type="text"
        />
      </th>
      <th v-if="actions"></th>
    </tr>
  </thead>
  <tbody>
    <tr
      :key="index"
      v-for="(row, index) of sortedData"
    >
      <td
        :key="field"
        :style="formatStyle(fields[field], getValue(row, field))"
        v-for="field of fieldsKeys"
      >{{ formatValue(fields[field], getValue(row, field)) }}</td>
      <td v-if="actions" style="white-space: nowrap">
        <template
          :key="actIndex"
          v-for="(action, actIndex) of actions"
        >
          <CButton
            @click="emit(action.event, row)"
            :caption="action.caption"
            :class="action.cssClass"
            :icon="action.icon"
            v-if="!action.showIf || action.showIf(row)"
          />
        </template>
      </td>
    </tr>
  </tbody>
  <tfoot v-if="hasAggregators">
    <tr>
      <td
        class="grid__field-footer"
        :key="field"
        v-for="field of fieldsKeys"
      >
        {{ fields[field].aggregator
          ? formatValue(fields[field], aggregateField(field, fields[field]))
          : '' }}
        {{ !fields[field].aggregator && fields[field].afterAggregator
          ? formatValue(fields[field], afterAggregateField(field, fields[field]))
          : '' }}
      </td>
      <td v-if="actions"></td>
    </tr>
  </tfoot>
</table>
</template>

<style lang="scss" scoped>
.table {
  border-collapse: separate;
  font-size: 0.75rem;

  th,
  td {
    padding: 0.25rem !important;
  }

  thead {
    background: var(--color-white);
    position: sticky;
    top: 0;
  }

  tfoot {
    background: var(--color-gray-lighter);
    font-weight: 500;
    position: sticky;
    bottom: 0;
  }
}

.grid__field-header {
  cursor: pointer;

  .icon {
    color: var(--color-primary);
    line-height: 0;
    margin-top: -4px;
    vertical-align: middle;
  }
}

.grid__field-filter {
  .input__control {
    font-weight: 400;
    height: auto;
    padding: var(--space-1);
  }
}
</style>
