<template>
<main class="layout layout--app">
  <TopBar @toggle-right-bar="store.commit('toggleRightBar')" />
  <Sidebar
    :is-open="store.state.ui.sidebarOpen"
    @toggle="store.commit('toggleSidebar')"
  />
  <slot />
  <RightBar :is-open="store.state.ui.rightBarOpen" />
</main>
</template>

<script setup>
import { useStore } from 'vuex'

import RightBar from './RightBar'
import Sidebar from './Sidebar'
import TopBar from './TopBar'

const store = useStore()
</script>
