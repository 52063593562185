<script setup>
import { components, createComponent, isSelfClosing } from '../components/index.js'
import { computed, nextTick, withModifiers } from 'vue'
import { isNodeClosed, isNodeSelectable, shouldHaveChildren, shouldHaveContent } from './support.js'
import { nextStep, previousStep } from '../support.js'
import { useEditor } from './editor.js'

import DropHandler from './DropHandler.vue'
import Nodes from './Nodes.vue'

const props = defineProps({
  node: [Object, String],
  parent: Object
})

const {
  assertHoverPosition,
  dropPosition,
  hovered,
  hoveredElePosition,
  isDragging,
  parentsMap,
  rebuildParentsMap,
  select,
  selected,
  currentStep
} = useEditor()

parentsMap.set(props.node, props.parent)

const isRoot = computed(() => !props.parent)
const isSelfClosed = props.node?.is && isSelfClosing(props.node.is)

const classes = computed(() => ({
  'empty-children': shouldHaveChildren(props.node),
  'empty-content': shouldHaveContent(props.node),
  'empty-img': props.node.is === 'img' && !props.node.attrs?.src,
  'hovered': hovered.value === props.node,
  'selected': selected.value === props.node,
  'hidden': props.node.hide === true,
  ['component-' + props.node.is]: true
}))

const handlers = {
  onClick: withModifiers(() => select(props.node, props.parent), ['stop', 'prevent']),
  onMouseover: withModifiers(() => hovered.value = props.node, ['stop', 'prevent']),
  onDragenter: withModifiers(eve => {
    hovered.value = props.node
    hoveredElePosition.value = eve.currentTarget.getBoundingClientRect()
  }, ['stop', 'prevent']),
  onDragover: withModifiers(eve => assertHoverPosition(eve, eve.target), ['prevent']),
  onDrop: withModifiers(eve => drop(eve), ['stop', 'prevent'])
}

if (isRoot.value) {
  handlers.onMouseout = withModifiers(() => {
    hovered.value = null
    hoveredElePosition.value = {}
  }, ['stop', 'prevent'])
}

if (!isNodeSelectable(props.node, parentsMap)) {
  delete handlers.onClick
  delete handlers.onMouseover
}

const bindings = computed(() => ({
  ...(props.node.attrs || {}),
  ...handlers,
  ...(props.node.configs || {}),
  ...parseLiveAttrs(props.node.attrs, props.node.configs)
}))

function drop (eve) {
  const key = eve.dataTransfer.getData('text')
  const others = createComponent(key)

  if (key === 'step') {
    const shouldUseParent = ['question', 'gate'].includes(props.node.is)
    const currentNode = shouldUseParent ? props.parent : props.node
    const parentNode = shouldUseParent ? parentsMap.get(props.parent) : props.parent

    placeNode(others, dropPosition.value, currentNode, parentNode)
    rebuildParentsMap()
    nextTick(() => dropPosition.value === 'before' ? previousStep() : nextStep())
  } else {
    placeNode(others, dropPosition.value, props.node, props.parent)
  }

  hovered.value = null
  isDragging.value = false
  assertHoverPosition()
}

function parseLiveAttrs (_, configs = {}) {
  const out = {}

  if (configs?.hideWhen && configs?.hideSteps) {
    out.hidden = assertHiddenAttribute(configs)
  }

  return out
}

// @todo refactor
//#region hidden assertion
const hiddenAssertions = {
  is: (steps, current) => steps.includes(current),
  between: (steps, current) => current >= steps[0] && current <= steps[1],
  from: (steps, current) => current >= steps[0],
  to: (steps, current) => current <= steps[0]
}

function assertHiddenAttribute (configs) {
  const when = configs.hideWhen
  const steps = configs.hideSteps.split(',')
    .map(one => parseInt(one))
    .filter(one => !isNaN(one))

  return hiddenAssertions[when] ? hiddenAssertions[when](steps, currentStep.value + 1) : false
}
//#endregion

function placeNode (newNode, position, currentNode, parentNode) {
  const shouldPlaceInside = position === 'inside'
    && !isNodeClosed(currentNode, parentsMap)

  if (shouldPlaceInside && Array.isArray(currentNode.children) || isRoot.value) {
    currentNode.children.push(newNode)
    select(newNode, currentNode)
  }

  if (!isRoot.value && !shouldPlaceInside && Array.isArray(parentNode.children)) {
    parentNode.children.splice(
      parentNode.children.indexOf(currentNode) + (position === 'before' ? 0 : 1),
      0,
      newNode
    )
    select(newNode, parentNode)
  }
}
</script>

<template>
<component
  class="component"
  :class="classes"
  :is="components[node.is]?.component || node.is"
  v-bind="bindings"
  v-if="node.is && !isSelfClosed"
>
  <DropHandler v-if="!isRoot && isDragging && hovered === node" />
  <Nodes
    :nodes="node.children"
    :parent="node"
    v-if="Array.isArray(node.children)"
  />
  <template v-else>{{ node.children }}</template>
</component>
<component
  class="component"
  :class="classes"
  :is="components[node.is]?.component || node.is"
  v-bind="bindings"
  v-else-if="node.is && isSelfClosed"
></component>
<template v-else>{{ node }}</template>
</template>

<style>
.quiz-builder__board-screen .component {
  position: relative;
}

.quiz-builder__board-screen .empty-children,
.quiz-builder__board-screen .empty-content {
  background: #f9f9f9;
  color: #aaa;
  font-size: var(--placeholder-size, 0.75rem);
  margin: 0;
  min-height: 3rem;
  outline: 1px dashed #bbb;
  padding: var(--space-4);
  place-items: center;
  text-align: center;
}

.quiz-builder__board-screen label.empty-children,
.quiz-builder__board-screen label.empty-content {
  min-height: 1.5rem;
  padding: 0 var(--space-4);
  place-items: center start;
  text-align: left;
}

.quiz-builder__board-screen .empty-children:before {
  content: 'Arraste um componente para cá ...';
  font-size: var(--placeholder-size, 1rem);
}

.quiz-builder__board-screen .empty-content:before {
  content: 'Edite o texto ...';
}

.quiz-builder__board-screen .empty-children[data-placeholder]:before,
.quiz-builder__board-screen .empty-content[data-placeholder]:before {
  content: attr(data-placeholder);
}

.quiz-builder__board-screen .empty-img {
  background-color: #f9f9f9;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' xml:space='preserve'%3E%3Cpath fill='%23ccc' d='M2 55h60a2 2 0 0 0 2-2V11a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v42c0 1.1.9 2 2 2zm2-4v-3.1l20-18.1 10.6 10.6a2 2 0 0 0 3-.2l4.7-6.3L60 50V51H4zm56-38v31.5l-16.7-15a2 2 0 0 0-2.9.3L35.8 36 25.4 25.6a2 2 0 0 0-2.7 0L4 42.4V13h56z'/%3E%3Cpath fill='%23ccc' d='M51 29a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm0-10a3 3 0 1 1 0 6 3 3 0 0 1 0-6z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px;
  height: 3rem;
  outline: 1px dashed #bbb;
  text-indent: -10000px;
  width: 8rem;
}

.quiz-builder__board-screen .hovered {
  outline: 1px solid #e0e0e0;
}

.quiz-builder__board-screen .selected {
  outline: 1px solid #66f !important;
}

.quiz-builder__board-screen .hidden {
  display: none;
}
</style>
