<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useStore } from 'vuex'

import AppLayout from '@/components/layout/App'
import CConfirm from '@/components/components/dialogs/Confirm'
import CButton from '@/components/CButton'
import Copy from './Copy'
import Embed from './Embed'
import Form from './Form'
import List from './List'
import Toolbar from '@/components/Toolbar'

const store = useStore()
const loading = ref(true)
const quiz = ref(null)
const quizToCopy = ref(null)
const quizToEmbed = ref(null)
const toDestroy = ref(null)

const isOnJBF = window.location.href.includes('jbfdigital')

const quizzes = computed(() => store.state.quizzesV2.quizzes)
const confirmingDestroy = computed(() => !!toDestroy.value)
const copying = computed(() => !!quizToCopy.value)
const editing = computed(() => !!quiz.value)
const embeding = computed(() => !!quizToEmbed.value)

const confirmDestroy = quiz => toDestroy.value = quiz

const copy = (item = {}) => quizToCopy.value = item

const destroy = async () => {
  await store.dispatch('quizzesV2/destroyQuiz', toDestroy.value)
  toDestroy.value = null
}

const doCopy = (item) => {
  quiz.value = { ...item, id: null, key: '', name: '' }
  quizToCopy.value = null
}

const edit = (item = {}) => quiz.value = item

const embed = (item = {}) => quizToEmbed.value = item

watch(() => store.state.quizzesV2.toCopy, item => {
  if (!item) return

  doCopy(item)
  store.state.quizzesV2.toCopy = null
})

onBeforeMount(async () => {
  await store.dispatch('quizzesV2/loadQuizzes')
  loading.value = false
  store.dispatch('loadQuizQuestions')
})
</script>

<template>
<AppLayout>
  <section class="layout__main quizzes">
    <div class="container">
      <Toolbar
        :has-primary-action="true"
        @primary-action="edit()"
        primary-action-caption="Adicionar Quiz"
        primary-action-icon="add"
        title="Quizzes"
        v-if="!isOnJBF"
      />
      <Toolbar title="Quizzes" v-else>
        <template #actions>
          <div class="toolbar__group">
            <router-link
              class="btn btn--secondary"
              :to="{ name: 'quizzes-v1' }"
            >Quizzes v1</router-link>
            <CButton
              caption="Adicionar Quiz"
              class="btn--primary"
              @click="edit()"
              icon="add"
            />
          </div>
        </template>
      </Toolbar>

      <List
        @copy-it="copy"
        @destroy="confirmDestroy"
        @edit="edit"
        @embed="embed"
        :items="quizzes"
        :loading="loading"
      />
    </div>
  </section>

  <Form
    @close="quiz = null"
    :item="quiz"
    size="wide"
    v-if="editing"
  />

  <Embed
    @close="quizToEmbed = null"
    :item="quizToEmbed"
    size="xl"
    v-if="embeding"
  />

  <Copy
    @close="quizToCopy = null"
    @do-copy="doCopy(quizToCopy)"
    :item="quizToCopy"
    v-if="copying"
  />

  <CConfirm
    alert="Apagar um quiz irá removê-lo de todos os lugares onde está veiculado."
    @cancel="toDestroy = null"
    @confirmed="destroy()"
    v-if="confirmingDestroy"
  >
    <p>Tem certeza que deseja apagar o quiz <strong>{{ toDestroy.name }}</strong>?</p>
  </CConfirm>
</AppLayout>
</template>
