<script setup>
import { useAppVersion } from './support/app-version'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import Alert from '@/components/Alert'
import BlankLayout from '@/components/layout/Blank'
import CModal from '@/components/Modal'

const route = useRoute()
const store = useStore()
const { refreshShortcut, showVersionWarning } = useAppVersion()

function removeNotice (notice) {
  store.state.app.notices.splice(store.state.app.notices.indexOf(notice), 1)
}
</script>

<template>
<TransitionGroup
  class="temp-notices-stack"
  name="alerts"
  tag="div"
>
  <Alert
    :class="notice.classes"
    @close="removeNotice(notice)"
    :closeable="notice.closeable"
    :description="notice.description"
    :icon="notice.icon"
    :key="notice"
    :title="notice.title"
    v-for="notice of store.state.app.notices"
  />
</TransitionGroup>
<router-view v-if="store.state.app.loaded || route.name === 'auth.login'" />
<BlankLayout v-else>
  <div class="loading loading--lg"></div>
</BlankLayout>
<CModal
  title="Vamos atualizar o HUB \o/"
  v-if="showVersionWarning"
>
  <div>
    Seu HUB tem uma nova versão disponível e precisa ser atualizado.
    Faça refresh com <strong>{{ refreshShortcut }}</strong>
    para atualizar.
  </div>
  <template #footer>
    <footer class="modal__footer"></footer>
  </template>
</CModal>
</template>

<style lang="scss">
.temp-notices-stack {
  position: fixed;
  right: var(--space-2);
  top: var(--space-2);
  z-index: 9999;
  width: 18rem;

  .alert {
    margin-bottom: var(--space-2);
    padding: var(--space-3) var(--space-8) var(--space-3) var(--space-11);
    width: 100%;

    .alert__icon {
      left: var(--space-3);
    }

    .alert__close {
      right: 0;
      top: 0;
    }
  }
}

.alerts-enter-active,
.alerts-leave-active {
  transition: all 0.5s ease;
}

.alerts-enter-from,
.alerts-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
