import Accounts from '../views/accounts/Accounts'
import AdsAccounts from '../views/ads-accounts/AdsAccounts'
import AdsCampaigns from '../views/ads-campaigns/AdsCampaigns'
import AdsGroups from '../views/ads-campaigns/AdsGroups'
import AdsSchedules from '../views/ads-schedules/AdsSchedules'
import Dashboard from '../views/dashboard/Dashboard'
import DistributionRules from '../views/DistributionRules'
import Fields from '../views/Fields'
import HelpCenter from '../views/help-center/HelpCenter'
import Login from '../views/Login'
import NoWorkspaces from '../views/NoWorkspaces'
import Parameters from '../views/Parameters'
import PlatformAccounts from '../views/PlatformAccounts'
import PushNotifications from '../views/push-notifications/PushNotifications'
import QuizQuestions from '../views/QuizQuestions'
import Quizzes from '../views/Quizzes'
import QuizzesV2 from '../views/quizzes-v2/Quizzes'
import SmsPlatformAccounts from '../views/sms-platform-accounts/SmsPlatformAccounts'
import Teams from '../views/teams/Teams'
import Users from '../views/users/Users'
import Webhooks from '../views/Webhooks'
import Workspaces from '../views/Workspaces'

export default [
  {
    component: Dashboard,
    meta: {
      active: true,
      auth: true,
      icon: 'dashboard',
      label: 'Dashboard'
    },
    name: 'dashboard',
    path: '/',
  },
  {
    component: Login,
    name: 'auth.login',
    path: '/login'
  },
  {
    component: PlatformAccounts,
    meta: {
      abilities: ['manage-platform-accounts'],
      active: true,
      auth: true,
      icon: 'account_box',
      label: 'Contas de Mailing'
    },
    name: 'platform-accounts',
    path: '/platform-accounts'
  },
  {
    component: SmsPlatformAccounts,
    meta: {
      abilities: ['manage-sms-platform-accounts'],
      active: true,
      auth: true,
      icon: 'sms',
      label: 'Contas de SMS'
    },
    name: 'sms-platform-accounts',
    path: '/sms-platform-accounts'
  },
  {
    component: PushNotifications,
    meta: {
      abilities: ['manage-push-notifications'],
      active: true,
      auth: true,
      icon: 'tap_and_play',
      label: 'Push Notifications'
    },
    name: 'push-notifications',
    path: '/push-notifications'
  },
  {
    component: DistributionRules,
    meta: {
      abilities: ['manage-distribution-rules'],
      active: true,
      auth: true,
      icon: 'account_tree',
      label: 'Distribuição'
    },
    name: 'distribution-rules',
    path: '/distribution-rules'
  },
  {
    component: QuizzesV2,
    meta: {
      abilities: ['manage-quizzes'],
      active: true,
      auth: true,
      icon: window.location.href.includes('jbfdigital') ? null : 'quiz',
      label: 'Quizzes'
    },
    name: 'quizzes',
    path: '/quizzes'
  },
  {
    component: Quizzes,
    meta: {
      abilities: ['manage-quizzes'],
      active: true,
      auth: true,
      icon: window.location.href.includes('jbfdigital') ? 'quiz' : null,
      label: 'Quizzes'
    },
    name: 'quizzes-v1',
    path: '/quizzes-v1'
  },
  {
    component: Parameters,
    meta: {
      abilities: ['manage-parameters'],
      active: true,
      auth: true,
      icon: 'settings',
      label: 'Parâmetros'
    },
    name: 'parameters',
    path: '/parameters'
  },
  {
    component: QuizQuestions,
    meta: {
      active: true,
      abilities: ['manage-quiz-questions'],
      auth: true,
      icon: 'question_answer',
      label: 'Perguntas de quiz',
      workspaced: false
    },
    name: 'quiz-questions',
    path: '/quiz-questions'
  },
  {
    component: Fields,
    meta: {
      abilities: ['manage-fields'],
      active: true,
      auth: true,
      icon: 'dynamic_form',
      label: 'Campos',
      workspaced: false
    },
    name: 'fields',
    path: '/fields'
  },
  {
    component: AdsGroups,
    meta: {
      abilities: ['manage-ads-groups'],
      active: true,
      auth: true,
      icon: 'work_outline',
      label: 'AdGroups',
      workspaced: false
    },
    name: 'ads-groups',
    path: '/ads-groups'
  },
  {
    component: Webhooks,
    meta: {
      abilities: ['manage-webhooks'],
      active: true,
      auth: true,
      icon: 'settings_ethernet',
      label: 'Webhooks',
      workspaced: false
    },
    name: 'webhooks',
    path: '/webhooks'
  },
  {
    component: Workspaces,
    meta: {
      abilities: ['manage-workspaces'],
      active: true,
      auth: true,
      icon: 'workspaces',
      label: 'Workspaces',
      workspaced: false
    },
    name: 'workspaces',
    path: '/workspaces'
  },
  {
    component: Teams,
    meta: {
      abilities: ['manage-teams'],
      active: false,
      auth: true,
      icon: 'people',
      label: 'Times',
      workspaced: false
    },
    name: 'teams',
    path: '/teams'
  },
  {
    component: Users,
    meta: {
      abilities: ['manage-users'],
      active: true,
      auth: true,
      icon: 'person',
      label: 'Usuários',
      workspaced: false
    },
    name: 'users',
    path: '/users'
  },
  {
    component: HelpCenter,
    meta: {
      active: true,
      auth: true,
      icon: 'help_outline',
      label: 'Central de Ajuda',
      workspaced: false
    },
    name: 'help-center',
    path: '/help-center'
  },
  {
    component: Accounts,
    meta: {
      abilities: ['manage-accounts'],
      active: false,
      auth: true,
      icon: 'cases',
      label: 'Subcontas',
      workspaced: false
    },
    name: 'accounts',
    path: '/accounts'
  },
  {
    component: NoWorkspaces,
    meta: {
      auth: true,
      workspaced: false
    },
    name: 'no-workspaces',
    path: '/no-workspaces'
  },
  {
    meta: {
      abilities: ['manage-transfers'],
      active: false,
      auth: true,
      icon: 'swap_horizontal_circle',
      label: 'Tranferências',
      workspaced: false
    },
    name: 'transfers'
  },


  {
    component: AdsAccounts,
    meta: {
      abilities: ['manage-ads-accounts'],
      active: true,
      auth: true,
      label: 'Contas de Campanhas',
      workspaced: false
    },
    name: 'ads-accounts',
    path: '/ads-accounts'
  },
  {
    component: AdsSchedules,
    meta: {
      abilities: ['manage-ads-schedules'],
      active: true,
      auth: true,
      // icon: 'work_history',
      label: 'Agendamento de Campanhas',
      workspaced: false
    },
    name: 'ads-schedules',
    path: '/ads-schedules'
  },
  {
    component: AdsCampaigns,
    meta: {
      abilities: ['manage-ads-campaigns'],
      active: true,
      auth: true,
      label: 'Campanhas',
      workspaced: false
    },
    name: 'ads-campaigns',
    path: '/ads-campaigns'
  },
]
