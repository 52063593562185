<script>
export default {
  meta: {
    closed: true,
    custom: true
  }
}
</script>

<script setup>
import { generateId } from '../support.js'

defineProps({
  label: String,
  termsLinkText: String,
  termsUrl: String,
  required: Boolean,
  validationMessage: String
})

const id = generateId('f-')
</script>

<template>
<div class="quiz__gate-field">
  <slot />
  <label
    class="quiz__gate-field-label-checkbox"
    :for="id"
  >
    <input
      class="quiz__gate-field-control-checkbox"
      :id="id"
      :required="required"
      type="checkbox"
    >
    <span>
      {{ label }}
      <a
        :href="termsUrl"
        target="_blank"
      >{{ termsLinkText }}</a>
    </span>
  </label>
</div>
</template>

<style>
.quiz__gate-field-label-checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
}

.quiz__gate-field-control-checkbox {
  height: 1rem;
  width: 1rem;
}
</style>
