<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

import { CanvasRenderer } from 'echarts/renderers'
import { DatasetComponent, GridComponent, LegendComponent, TooltipComponent } from 'echarts/components'
import { init, use } from 'echarts/core'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'

use([
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  TooltipComponent,
  UniversalTransition
])

const store = useStore()
let chart = null
const chartEle = ref(null)
const data = computed(() => store.getters['dashboard/leadsAmountsByDate'])
const loading = computed(() => store.state.dashboard.widgetsLoading['leads-statistics'])

const initChart = () => {
  chart = init(chartEle.value)

  chart.setOption({
    grid: {
      bottom: 30,
      left: '5%',
      right: '15%',
      top: 20
    },
    legend: {
      orient: 'vertical',
      padding: [0, 20],
      right: 0,
      top: 'center'
    },
    series: [
      { name: 'Recebidos', type: 'line', smooth: true },
      { name: 'Válidos', type: 'line', smooth: true }
    ],
    tooltip: { trigger: 'axis' },
    xAxis: { type: 'category' },
    yAxis: { type: 'value' }
  })
}

const updateChart = () => {
  if (!chart) return

  chart.setOption({
    dataset: {
      dimensions: ['creation', 'received_amount', 'valid_amount'],
      source: data.value
    }
  })
}

watch(() => data.value, updateChart, { immediate: true })

onMounted(initChart)
onUnmounted(() => {
  chart.dispose()
  chart = null
})
</script>

<template>
<article class="card">
  <header class="card__header">
    <h3 class="card__title caption">Leads Recebidos e Válidos</h3>
  </header>
  <section
    class="card__body"
    :class="{ 'loading loading--lg': loading }"
  >
    <div
      class="chart"
      ref="chartEle"
    ></div>
  </section>
</article>
</template>

<style lang="scss" scoped>
.chart {
  height: 250px;
}
</style>
