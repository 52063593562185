<template>
<div class="question tile">
  <div class="question__index">{{ index + 1 }}</div>
  <div class="tile__content">
    <div class="form__fields form__fields--row mb-0" :class="{ 'mb-2': false }">
      <div class="input flex--0 flex--nowrap">
        <CButton
          class="btn--icon mr-1"
          @click="move('up', question)"
          :disabled="index <= 0"
          icon="expand_less"
        />
        <CButton
          class="btn--icon"
          @click="move('down', question)"
          :disabled="index >= lastIndex"
          icon="expand_more"
        />
      </div>
      <div class="input">
        <div class="input__select">
          <select
            class="input__control"
            v-model="data.quiz_question_id"
          >
            <option :value="null">[ Selecione ]</option>
            <option
              :key="item.id"
              :value="item.id"
              v-for="item of quizQuestions"
            >{{ item.question }}</option>
          </select>
        </div>
      </div>
      <CButton
        class="btn--icon"
        @click="$emit('remove', question)"
        icon="clear"
      />
    </div>
    <div class="input mb-0">
      <label class="input__switch nowrap">
        <input
          type="checkbox"
          v-model="data.configs.hide_image"
        />
        <span>Esconder imagem da pergunta</span>
      </label>
    </div>
    <div
      class="input"
      :class="{ 'mb-0': !nextByResponses }"
      v-if="false"
    >
      <label class="input__switch nowrap">
        <input
          :disabled="!data.quiz_question_id"
          type="checkbox"
          v-model="nextByResponses"
        />
        <span>Próxima pergunta é baseada nas respostas</span>
      </label>
    </div>
    <div
      class="question__responses"
      v-if="nextByResponses"
    >
      <div
        class="question__response"
        :key="next.value"
        v-for="next in data.next"
      >
        <div>{{ next.label }}</div>
        <div class="input">
          <div class="input__select">
            <select
              @change="handleAction(next.quiz_question_id, question)"
              class="input__control"
              v-model="next.quiz_question_id"
            >
              <option :value="null">[ Selecione ]</option>
              <option
                :key="item.id"
                :value="item.id"
                v-for="item of nextQuestions"
              >{{ item.question }}</option>
              <optgroup label="Ações">
                <option value="@add">Adicionar e selecionar ...</option>
              </optgroup>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import CButton from '@/components/CButton'
import { mapState } from 'vuex'
// import * as obj from '@/support/object'

export default {
  name: 'Question',

  components: { CButton },

  props: {
    index: Number,
    lastIndex: Number,
    question: Object,
    questions: Array
  },

  data () {
    return {
      nextByResponses: false
    }
  },

  created () {
    // this.data = obj.copy(this.question)
    // this.nextByResponses = !!this.data.next.length

    // this.unwatchData = this.$watch('data', value => this.$emit('update', value), { deep: true })
    // this.unwatchQuizQuestionId = this.$watch('data.quiz_question_id', value => {
    //   this.data.next = this.nextByResponses && value ? this.makeNextOptions() : []

    //   if (!value) {
    //     this.nextByResponses = false
    //   }
    // })
    // this.unwatchNextByResponse = this.$watch('nextByResponses', value => {
    //   this.data.next = value ? this.makeNextOptions() : []
    // })
  },

  beforeUnmount () {
    // this.unwatchData()
    // this.unwatchQuizQuestionId()
    // this.unwatchNextByResponse()
  },

  computed: {
    ...mapState(['quizQuestions']),

    data: {
      get () {
        return this.question.pivot || this.question
      },
      set (value) {
        this.$emit('update:question', value)
      }
    },

    nextQuestions () {
      return this.questions
        .filter(question => question.id !== this.data.id)
        .map(question => ({
          id: question.id,
          question: `${question.order} - ` + (
            this.findQuestion(question.quiz_question_id)?.question
            || 'Aguardando seleção ...'
          )
        }))
    },

    questionReponsesOptions () {
      return this.quizQuestions
        .find(question => question.id === this.data.quiz_question_id)?.response_options
    }
  },

  methods: {
    findQuestion (id) {
      return this.quizQuestions.find(question => question.id === id)
    },

    handleAction (action, question) {
      if (action === '@add') {
        this.$emit('add-next', question)
      }
    },

    makeNextOptions () {
      return this.questionReponsesOptions.map(option => ({
        label: option.label,
        quiz_question_id: null,
        value: option.value
      }))
    },

    move (direction, question) {
      this.nextByResponses = false

      this.$emit(direction, question)
    }
  }
}
</script>

<style lang="scss">
.question {
  border: 1px solid var(--color-primary-lighter);
  border-radius: 4px;
  overflow: hidden;

  .tile__content {
    padding: var(--space-4) var(--space-4) var(--space-4) 0;
  }
}

.question__index {
  align-items: center;
  align-self: stretch;
  background: var(--color-primary-lighter);
  display: flex;
  padding: var(--space-2);
}

.question__responses {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.question__response {
  align-items: center;
  border-top: 1px solid var(--color-gray-light);
  display: flex;
  justify-content: space-between;
  padding: var(--space-1) 0;
  width: 100%;

  .input,
  .input__select {
    margin-bottom: 0;
  }

  .input {
    margin-left: var(--space-4);
    width: 50%;
  }
}
</style>
