<template>
<div class="result-page">
  <div class="form__fields form__fields--row form__fields--to-end mb-2">
    <div class="input">
      <label
        class="input__label"
        :for="'resultPageUrl' + index"
      >URL da Página</label>
      <input
        class="input__control"
        :id="'resultPageUrl' + index"
        type="text"
        v-model.trim="data.url"
      />
    </div>
    <CButton
      class="btn--icon"
      @click="$emit('remove', page)"
      icon="clear"
    />
  </div>
  <div
    class="input mb-0"
    :class="{ 'mb-2': data.by_rules }"
  >
    <label class="input__switch nowrap">
      <input type="checkbox" v-model="data.by_rules" />
      <span>Enviar para essa página de acordo com regras</span>
    </label>
  </div>
  <RulesBuilder
    :facts="facts"
    :has-multiple-rules="false"
    :has-named-rules="false"
    v-if="data.by_rules"
    v-model:rules="data.rules"
  />
</div>
</template>

<script>
import CButton from '@/components/CButton'
import RulesBuilder from '@/components/components/rules-builder/Builder'

export default {
  name: 'ResultPage',

  components: {
    CButton,
    RulesBuilder
  },

  props: {
    facts: Object,
    index: Number,
    page: Object
  },

  computed: {
    data: {
      get () {
        return this.page
      },
      set (value) {
        this.$emit('update:page', value)
      }
    }
  },

  watch: {
    'data.by_rules': {
      handler (value) {
        if (value && !this.data.rules.length) {
          this.data.rules.push({
            id: this.data.rules.length + 1,
            criteria: {
              match: 'all',
              conditions: []
            }
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.result-page {
  border: 1px solid var(--color-primary-lighter);
  border-radius: 4px;
  margin-bottom: var(--space-4);
  overflow: hidden;
  padding: var(--space-4);
}

.result-page__index {
  align-items: center;
  align-self: stretch;
  background: var(--color-primary-lighter);
  display: flex;
  padding: var(--space-2);
}

.result-page__responses {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.result-page__response {
  align-items: center;
  border-top: 1px solid var(--color-gray-light);
  display: flex;
  justify-content: space-between;
  padding: var(--space-1) 0;
  width: 100%;

  .input,
  .input__select {
    margin-bottom: 0;
  }

  .input {
    margin-left: var(--space-4);
    width: 50%;
  }
}
</style>
