<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import { format, formatCurrencyUSD as formatCurrency } from '@/support/number'
import { daysOffsetToInterval, formatDate } from '@/support/datetime'
import { sum } from '@tidyjs/tidy'
import { useStore } from 'vuex'
import without from 'lodash/without'

import FieldsSelector from '@/components/components/grids/FieldsSelector'
import Grid from '@/components/components/grids/SummaryGrid'

const store = useStore()
const availableFields = [
  { key: 'account_name', label: 'Conta', selected: true },
  { key: 'list', label: 'Lista', selected: true },
]
const availableFieldsKeys = availableFields.map(field => field.key)
const shownFieldsKeys = availableFields.filter(field => field.selected).map(field => field.key)

const shownFields = ref(shownFieldsKeys)
const loading = ref(false)

const data = computed(() => store.state.smsPlatformAccounts.rulersStatistics)
const hiddenFields = computed(() => without(availableFieldsKeys, ...shownFields.value))

watch(() => store.state.workspaceId, load)

onBeforeMount(load)

async function load () {
  loading.value = true
  await store.dispatch('smsPlatformAccounts/loadRulersStatistics')
  loading.value = false
}
</script>

<template>
<article class="card">
  <header class="card__header">
    <h3 class="card__title caption">Envios de SMS de régua</h3>
  </header>
  <section class="card__body">
    <div class="flex mb-4 table-controls">
      <h6>Ontem, {{ formatDate(daysOffsetToInterval(-1).from) }}</h6>
      <div class="toolbar__group">
        <FieldsSelector
          :fields="availableFields"
          id="rulersStatisticsTableFields"
          v-model="shownFields"
        />
      </div>
    </div>
    <div
      class="table-wrapper"
      :class="{ 'loading loading--lg': loading }"
    >
      <Grid
        class="table--statistics"
        :data="data"
        :except="hiddenFields"
        :fields="{
          account_name: { label: 'Conta' },
          list: { label: 'Lista' },
          message_1: {
            aggregator: (carry, item) => carry + item.message_1,
            formatter: format,
            label: 'Msg 1 Envios'
          },
          message_1_cost: {
            aggregator: (carry, item) => carry + item.message_1_cost,
            formatter: formatCurrency,
            label: 'Msg 1 Custo'
          },
          message_2: {
            aggregator: (carry, item) => carry + item.message_2,
            formatter: format,
            label: 'Msg 2 Envios'
          },
          message_2_cost: {
            aggregator: (carry, item) => carry + item.message_2_cost,
            formatter: formatCurrency,
            label: 'Msg 2 Custo'
          },
          message_3: {
            aggregator: (carry, item) => carry + item.message_3,
            formatter: format,
            label: 'Msg 3 Envios'
          },
          message_3_cost: {
            aggregator: (carry, item) => carry + item.message_3_cost,
            formatter: formatCurrency,
            label: 'Msg 3 Custo'
          },
          message_4: {
            aggregator: (carry, item) => carry + item.message_4,
            formatter: format,
            label: 'Msg 4 Envios'
          },
          message_4_cost: {
            aggregator: (carry, item) => carry + item.message_4_cost,
            formatter: formatCurrency,
            label: 'Msg 4 Custo'
          },
          message_5: {
            aggregator: (carry, item) => carry + item.message_5,
            formatter: format,
            label: 'Msg 5 Envios'
          },
          message_5_cost: {
            aggregator: (carry, item) => carry + item.message_5_cost,
            formatter: formatCurrency,
            label: 'Msg 5 Custo'
          }
        }"
        :summarize="{
          message_1: sum('message_1'),
          message_1_cost: sum('message_1_cost'),
          message_2: sum('message_2'),
          message_2_cost: sum('message_2_cost'),
          message_3: sum('message_3'),
          message_3_cost: sum('message_3_cost'),
          // @todo refactor back with message 4 and 5
          // message_4: sum('message_4'),
          // message_4_cost: sum('message_4_cost'),
          // message_5: sum('message_5'),
          // message_5_cost: sum('message_5_cost')
        }"
        :summary-fields="[
          'message_1',
          'message_1_cost',
          'message_2',
          'message_2_cost',
          'message_3',
          'message_3_cost',
          // @todo refactor back with message 4 and 5
          // 'message_4',
          // 'message_4_cost',
          // 'message_5',
          // 'message_5_cost',
        ]"
      />
    </div>
  </section>
</article>
</template>

<style lang="scss" scoped>
.table-controls {
  align-items: center;
  justify-content: space-between;

  > *:not(:last-child) {
    margin-right: var(--space-6);
  }
}

.table-wrapper {
  max-height: 500px;
  min-height: 40px;
  overflow: auto;
}

.disabled {
  opacity: 0.4;
}
</style>
