<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import { format } from '@/support/number'
import { daysOffsetToInterval, formatDate, formatToDB } from '@/support/datetime'
import { sum } from '@tidyjs/tidy'
import { useStore } from 'vuex'
import without from 'lodash/without'

import CButton from '@/components/CButton'
import DateSelect from '@/components/components/form/DateSelect'
import FieldsSelector from '@/components/components/grids/FieldsSelector'
import Grid from '@/components/components/grids/SummaryGrid'

const store = useStore()
const key = 'sms-leads-statistics'
const availableFields = [
  { key: 'created_at', label: 'Data', selected: true },
  { key: 'workspace', label: 'Workspace', selected: false },
  { key: 'origin', label: 'Origem', selected: false },
  { key: 'path', label: 'Path', selected: false },
  { key: 'quiz', label: 'Quiz', selected: false },
  { key: 'target', label: 'Target', selected: false },
  { key: 'target_path', label: 'Target path', selected: false },
  { key: 'platform_account', label: 'Conta', selected: true },
  { key: 'list', label: 'Lista', selected: true },
  { key: 'message', label: 'Mensagem', selected: true },
]
const availableFieldsKeys = availableFields.map(field => field.key)
const shownFieldsKeys = availableFields.filter(field => field.selected).map(field => field.key)

const period = ref(daysOffsetToInterval(-7))
const shownFields = ref(shownFieldsKeys)

const data = computed(() => store.getters['dashboard/smsLeadsStatistics'])
const loading = computed(() => store.state.dashboard.widgetsLoading[key])
const hiddenFields = computed(() => without(availableFieldsKeys, ...shownFields.value))
const periodToRequest = computed(() => (!period.value ? {} : {
  from: formatToDB(period.value.from),
  to: formatToDB(period.value.to)
}))

const load = () => {
  store.dispatch('dashboard/updateWidget', { key, options: periodToRequest.value })
}

watch(() => period.value, load)

onBeforeMount(load)
</script>

<template>
<article class="card">
  <header class="card__header">
    <h3 class="card__title caption">Total de Leads de SMS</h3>
  </header>
  <section class="card__body">
    <div class="flex mb-4 table-controls">
      <div class="toolbar__group">
        <DateSelect
          class="mb-0"
          :is-range="true"
          v-model="period"
        />
      </div>
      <div class="toolbar__group">
        <CButton
          class="tooltip"
          @click="load()"
          data-tooltip="Atualizar"
          icon="refresh"
        />
        <FieldsSelector
          :fields="availableFields"
          id="smsLeadsAmountTableFields"
          v-model="shownFields"
        />
      </div>
    </div>
    <div
      class="table-wrapper"
      :class="{ 'loading loading--lg': loading }"
    >
      <Grid
        class="table--statistics"
        :data="data"
        :except="hiddenFields"
        :fields="{
          created_at: { label: 'Data', formatter: formatDate },
          workspace: { label: 'Workspace' },
          origin: { label: 'Origem' },
          path: { label: 'Path' },
          quiz: { label: 'Quiz' },
          target: { label: 'Target' },
          target_path: { label: 'Target path' },
          platform_account: { label: 'Conta' },
          list: { label: 'Lista' },
          message: { label: 'Mensagem' },
          received_amount: {
            aggregator: (carry, item) => carry + item.received_amount,
            formatter: format,
            label: 'Recebidos'
          },
          sent_amount: {
            aggregator: (carry, item) => carry + item.sent_amount,
            formatter: format,
            label: 'Enviados'
          },
          delivered_amount: {
            aggregator: (carry, item) => carry + item.delivered_amount,
            formatter: format,
            label: 'Entregues'
          },
          waiting_amount: {
            aggregator: (carry, item) => carry + item.waiting_amount,
            formatter: format,
            label: 'Aguardando'
          },
          errors_amount: {
            aggregator: (carry, item) => carry + item.errors_amount,
            formatter: format,
            label: 'Erros'
          }
        }"
        :summarize="{
          received_amount: sum('received_amount'),
          sent_amount: sum('sent_amount'),
          delivered_amount: sum('delivered_amount'),
          waiting_amount: sum('waiting_amount'),
          errors_amount: sum('errors_amount')
        }"
        :summary-fields="[
          'received_amount',
          'sent_amount',
          'delivered_amount',
          'waiting_amount',
          'errors_amount',
        ]"
      />
    </div>
  </section>
</article>
</template>

<style lang="scss" scoped>
.table-controls {
  align-items: center;
  justify-content: space-between;

  > *:not(:last-child) {
    margin-right: var(--space-6);
  }
}

.table-wrapper {
  max-height: 250px;
  min-height: 40px;
  overflow: auto;
}

.disabled {
  opacity: 0.4;
}
</style>
