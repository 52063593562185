<template>
<nav
  class="sidebar"
  :class="{ 'sidebar--open': isOpen }"
>
  <button
    class="btn btn--ghost btn--icon sidebar__toggler mb-12"
    @click="emit('toggle')"
    type="button"
  >
    <span class="icon">menu</span>
  </button>
  <article class="tile sidebar__user line-compact mb-6">
    <figure
      class="avatar avatar--rounded avatar--xs"
      :data-initials="store.state.auth.user.initials"
    ></figure>
    <section class="tile__content">
      <p>Bem vindo</p>
      <p class="soft">{{ store.state.auth.user.first_name }}</p>
    </section>
  </article>
  <section class="menu">
    <router-link
      class="menu__item"
      :key="link.name"
      :to="{ name: link.name }"
      v-for="link of allowed"
    >
      <span class="icon">{{ link.meta.icon }}</span>
      <span>{{ link.meta.label }}</span>
    </router-link>
  </section>
</nav>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import auth from '@/services/auth'
import routes from '@/router/routes'

const emit = defineEmits(['toggle'])

defineProps({ isOpen: Boolean })

const store = useStore()

const isLinkValid = route => (
  route?.meta?.active !== false
  && route?.meta?.icon
  && route?.meta?.label
)
const validLinks = routes.filter(isLinkValid)
  .map(route => {
    const { component, ...rest } = route /* eslint-disable-line no-unused-vars */

    return rest
  })

const links = ref(validLinks)

const allowed = computed(() => {
  return links.value.filter(link => auth.userCan(link?.meta?.abilities))
})
</script>
