import dashboard from '@/repositories/dashboard'
import { formatDate } from '@/support/datetime'

export default {
  namespaced: true,

  state: () => ({
    widgetsData: {},
    widgetsLoading: {}
  }),

  mutations: {
    setLoadingWidget (state, { key, loading }) {
      state.widgetsLoading[key] = loading
    },

    setWidgetData (state, { key, data }) {
      state.widgetsData[key] = data
    }
  },

  actions: {
    async updateWidget ({ commit }, { key, options = {} }) {
      commit('setLoadingWidget', { key, loading: true })

      const data = await dashboard.updateWidget(key, options)

      commit('setLoadingWidget', { key, loading: false })
      commit('setWidgetData', { key, data })

      return data
    }
  },

  getters: {
    campaignsStatistics (state) {
      return state.widgetsData['campaigns-statistics'] || []
    },

    leadsAmountsByDate (state, getters) {
      return Object.values(getters.leadsStatistics.reduce((acc, item) => {
        if (!acc[item.created_at]) {
          acc[item.created_at] = {
            creation: formatDate(item.created_at),
            received_amount: 0,
            valid_amount: 0
          }
        }

        acc[item.created_at].received_amount += item.received_amount
        acc[item.created_at].valid_amount += item.valid_amount

        return acc
      }, {})).reverse()
    },

    leadsStatistics (state) {
      return state.widgetsData['leads-statistics'] || []
    },

    smsErrorsStatistics (state) {
      return state.widgetsData['sms-errors-statistics'] || []
    },

    smsLeadsStatistics (state) {
      return state.widgetsData['sms-leads-statistics'] || []
    }
  }
}
