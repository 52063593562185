<script setup>
import { computed } from 'vue'

import CButton from '@/components/CButton'
import Criteria from './Criteria'

const emit = defineEmits(['remove'])
const props = defineProps({
  facts: Object,
  hasName: {
    default: true,
    type: Boolean
  },
  isRemoveable: {
    default: true,
    type: Boolean
  },
  rule: Object
})

const value = computed({
  get: () => props.rule,
  set: value => emit('update:rule', value)
})
</script>

<template>
<article class="rules-builder__rule">
  <header
    class="rules-builder__rule-header mb-2"
    v-if="hasName || isRemoveable"
  >
    <div class="form__fields form__fields--row mb-0">
      <div
        class="input"
        v-if="hasName"
      >
        <input
          class="input__control"
          placeholder="Nome da regra ..."
          type="text"
          v-model.trim="value.name"
        />
      </div>
      <CButton
        class="btn--icon rules-builder__button"
        @click="emit('remove', rule)"
        icon="clear"
        v-if="isRemoveable"
      />
    </div>
  </header>

  <Criteria
    :facts="facts"
    :is-root="true"
    v-model:criteria="value.criteria"
  />
</article>
</template>
