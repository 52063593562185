import { assignNodesIds } from '../../support.js'

import EmailField from './EmailField.vue'
import Gate from './Gate.vue'
import MobileField from './MobileField.vue'
import NameField from './NameField.vue'
import Progress from './Progress.vue'
import Question from './Question.vue'
import Step from './Step.vue'
import Steps from './Steps.vue'
import TermsField from './TermsField.vue'

const selfClosingTags = [
  'area',
  'base',
  'br',
  'col',
  'command',
  'embed',
  'hr',
  'img',
  'input',
  'keygen',
  'link',
  'meta',
  'param',
  'source',
  'track',
  'wbr',
]

export const isSelfClosing = tag => selfClosingTags.includes(tag.toLowerCase())

export const makeChildren = component => (component.make && component.make()) || []

export const components = {
  header: {
    icon: 'crop_16_9',
    is: 'header',
    children: [
      {
        is: 'img',
        attrs: {
          class: 'quiz__header-image'
        }
      }
    ],
    name: 'Cabeçalho',
    attrs: {
      class: 'quiz__header'
    }
  },
  progress: {
    icon: 'percent',
    is: 'progress',
    component: Progress,
    meta: Progress.meta || {},
    name: 'Barra de Progresso'
  },
  steps: {
    icon: 'splitscreen',
    is: 'steps',
    component: Steps,
    children: makeChildren(Steps),
    meta: Steps.meta || {},
    name: 'Passos do Quiz'
  },
  step: {
    icon: 'crop_portrait',
    is: 'step',
    component: Step,
    children: makeChildren(Step),
    meta: Step.meta || {},
    name: 'Passo do Quiz'
  },
  question: {
    icon: 'question_answer',
    is: 'question',
    component: Question,
    meta: Question.meta || {},
    name: 'Pergunta'
  },
  gate: {
    icon: 'contact_mail',
    is: 'gate',
    component: Gate,
    children: makeChildren(Gate),
    meta: Gate.meta || {},
    name: 'Formulário de Lead'
  },
  name: {
    icon: 'smart_button',
    is: 'name',
    component: NameField,
    meta: NameField.meta || {},
    name: 'Campo de Nome'
  },
  email: {
    icon: 'email',
    is: 'email',
    component: EmailField,
    meta: EmailField.meta || {},
    name: 'Campo de Email'
  },
  mobile: {
    icon: 'smartphone',
    is: 'mobile',
    component: MobileField,
    meta: MobileField.meta || {},
    name: 'Campo de Telefone'
  },
  terms: {
    icon: 'check_box',
    is: 'terms',
    component: TermsField,
    meta: TermsField.meta || {},
    name: 'Aceite de Termos'
  },
  footer: {
    icon: 'crop_16_9',
    is: 'footer',
    children: [],
    name: 'Rodapé',
    attrs: {
      class: 'quiz__footer'
    }
  },
  section: {
    icon: 'view_stream',
    is: 'section',
    children: [],
    name: 'Seção'
  },
  p: {
    icon: 'text_fields',
    is: 'p',
    children: '',
    name: 'Parágrafo'
  },
  img: {
    icon: 'image',
    is: 'img',
    name: 'Imagem'
  },
  a: {
    icon: 'link',
    is: 'a',
    attrs: {
      href: '#'
    },
    children: '',
    name: 'Link'
  }
}

export const getDef = node => components[node.is] || node

// @todo adjust eslint errors
export function createComponent (key) {
  // eslint-disable-next-line no-unused-vars
  const { component, icon, name, ...def } = JSON.parse(JSON.stringify(components[key]))

  assignNodesIds(def)

  return def
}
