import accounts from '@/repositories/push-accounts'

export default {
  namespaced: true,

  state: () => ({
    accounts: [],
    segments: []
  }),

  mutations: {
    addAccount (state, { item }) {
      state.accounts.push(item)
    },

    removeAccount (state, { item }) {
      state.accounts.splice(state.accounts.findIndex(one => one === item), 1)
    },

    replaceAccount (state, { item, index }) {
      state.accounts.splice(index, 1, item)
    },

    setAccounts (state, payload) {
      state.accounts = payload
    },

    setSegments (state, payload) {
      state.segments = payload
    }
  },

  actions: {
    async destroyAccount ({ commit }, data) {
      await accounts.destroy(data)
      commit('removeAccount', { item: data })
    },

    async loadAccounts ({ commit }) {
      commit('setAccounts', await accounts.all())
    },

    async loadSegments ({ commit }, accountId) {
      commit('setSegments', await accounts.segments(accountId))
    },

    async saveAccount ({ commit, state }, data) {
      const item = await accounts.save(data)
      const index = state.accounts.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceAccount' : 'addAccount', { item, index })

      return item
    }
  }
}
