<script setup>
import { format, formatPercentage } from '@/support/number'
import { onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({ item: Object })

const store = useStore()
const statistics = ref({})

onBeforeMount(async () => {
  statistics.value = await store.dispatch('pushNotifications/loadStatistics', props.item.id)
})
</script>

<template>
<div
  class="card statistics-panel"
  :class="{ loading: statistics.success === undefined }"
>
  <div><span class="muted">Enviados:</span> <b>{{ format(statistics.attempted) }}</b></div>
  <div><span class="muted">Entregues:</span> <b>{{ format(statistics.delivered) }}</b></div>
  <div><span class="muted">Clicados:</span> <b>{{ format(statistics.clicked) }}</b></div>
  <div><span class="muted">CTR:</span> <b>{{ formatPercentage(statistics.ctr, 3) }}</b></div>
</div>
</template>

<style scoped>
.statistics-panel {
  padding: var(--space-2);
  position: absolute;
  white-space: nowrap;
}
</style>
