<template>
<AppLayout>
  <section class="layout__main fields">
    <div class="container">
      <Toolbar
        :has-primary-action="true"
        @primary-action="showEditModal = true"
        primary-action-caption="Adicionar Campo"
        primary-action-icon="add"
        title="Campos"
      />

      <List
        @destroy="confirmDestroy($event)"
        @edit="edit($event)"
        :items="fields"
        :loading="loading"
      />
    </div>
  </section>

  <Modal
    @close="showEditModal = false"
    :closeable-by-backdrop="false"
    :show-close-button="true"
    size="lg"
    :title="(field.id ? 'Editar' : 'Adicionar') + ' Campo' + (field.id ? ` [#${field.id}]` : '')"
    v-if="showEditModal"
  >
    <form
      class="form"
      id="fieldModalForm"
      @submit.prevent="tryToSave"
    >
      <fieldset>
        <div class="form__fields form__fields--row">
          <div class="input">
            <label class="input__label" for="fieldKey">Chave do campo</label>
            <input class="input__control" id="fieldKey" type="text" v-model.trim="field.key" />
            <span class="input__error" v-if="v$.field.key.$error">Informe a chave do campo</span>
          </div>
          <div class="input">
            <label class="input__label" for="fieldName">Nome do campo</label>
            <input class="input__control" id="fieldName" type="text" v-model.trim="field.name" />
            <span class="input__error" v-if="v$.field.name.$error">Informe o nome do campo</span>
          </div>
        </div>
        <div class="form__fields form__fields--row">
          <div class="input flex--2">
            <label class="input__label" for="fieldType">Tipo do campo</label>
            <div class="input__select">
              <select class="input__control" id="fieldType" v-model.trim="field.type">
                <option :value="null">[ Selecione ]</option>
                <optgroup
                  :label="group"
                  :key="group"
                  v-for="(groupFields, group) of fieldsTypes"
                >
                  <option
                    :key="fieldType.key"
                    v-for="fieldType of groupFields"
                    :value="fieldType.key"
                  >{{ fieldType.label }}</option>
                </optgroup>
              </select>
            </div>
            <span class="input__error" v-if="v$.field.type.$error">Informe o tipo do campo</span>
          </div>
          <div class="input input--no-label">
            <label class="input__switch nowrap">
              <input type="checkbox" v-model="field.is_active" />
              <span>Está ativo</span>
            </label>
          </div>
        </div>
        <div class="input">
          <label class="input__label" for="fieldSource">Fonte de dados</label>
          <input class="input__control" id="fieldSource" type="text" v-model.trim="field.configs.source" />
          <span class="input__help">Informe a fonte de dados. Ex: 'fields.import_date'</span>
        </div>
        <div class="input">
          <label class="input__label" for="fieldDescription">Descrição do campo</label>
          <textarea class="input__control" id="fieldDescription" v-model.trim="field.description"></textarea>
        </div>
      </fieldset>
    </form>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="showEditModal = false"
        />
        <CButton
          caption="Salvar"
          class="btn--primary"
          form="fieldModalForm"
          icon="save"
          type="submit"
        />
      </footer>
    </template>
  </Modal>

  <Modal
    @close="confirmingDestroy = false"
    title="Confirmação"
    v-if="confirmingDestroy"
  >
    <template #default>
      <p>Tem certeza que deseja apagar o campo
        <strong>{{ fieldToDestroy.name }} ({{ fieldToDestroy.key }})</strong>?</p>
      <Alert
        class="alert--info alert--ghost"
        icon="info"
        title="Apagar um campo é uma ação crítica e irá removê-lo de todos os locais onde é usado."
      />
    </template>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="confirmingDestroy = false"
        />
        <CButton
          caption="Apagar"
          class="btn--danger"
          @click="destroy"
          icon="delete"
        />
      </footer>
    </template>
  </Modal>
</AppLayout>
</template>

<script>
import Alert from '@/components/Alert'
import AppLayout from '@/components/layout/App'
import CButton from '@/components/CButton'
import { createNamespacedHelpers } from 'vuex'
import List from './fields/List'
import { load } from '@/support/loading'
import Modal from '@/components/Modal'
import { required } from '@vuelidate/validators'
import Toolbar from '@/components/Toolbar'
import { useVuelidate } from '@vuelidate/core'
import * as obj from '@/support/object'

const { mapState } = createNamespacedHelpers('fields')

const FieldsTypesKeys = {
  EMAIL_VALIDATION_ACCEPT_ALL: 'email_validation_accept_all',
  EMAIL_VALIDATION_DISPOSABLE: 'email_validation_disposable',
  EMAIL_VALIDATION_FREE: 'email_validation_free',
  EMAIL_VALIDATION_REASON: 'email_validation_reason',
  EMAIL_VALIDATION_RESULT: 'email_validation_result',
  EMAIL_VALIDATION_ROLE: 'email_validation_role',
  EMAIL_VALIDATION_DELIVERABLE: 'email_validation_deliverable',
  FIRST_NAME: 'first_name',
  IMPORT_DATE: 'import_date',
  LAST_NAME: 'last_name',
  DATE: 'date',
  TIME: 'time',
  DATE_TIME: 'date_time',
  NUMBER: 'number',
  TEXT: 'text'
}

const fieldsTypes = {
  'Campos pré-definidos': [
    { key: FieldsTypesKeys.FIRST_NAME, label: 'Primeiro nome' },
    { key: FieldsTypesKeys.LAST_NAME, label: 'Último nome' },
    { key: FieldsTypesKeys.IMPORT_DATE, label: 'Data de importação' },
    { key: FieldsTypesKeys.EMAIL_VALIDATION_ACCEPT_ALL, label: 'Validação email - Accept all' },
    { key: FieldsTypesKeys.EMAIL_VALIDATION_DISPOSABLE, label: 'Validação email - Disposable' },
    { key: FieldsTypesKeys.EMAIL_VALIDATION_FREE, label: 'Validação email - Free' },
    { key: FieldsTypesKeys.EMAIL_VALIDATION_REASON, label: 'Validação email - Reason' },
    { key: FieldsTypesKeys.EMAIL_VALIDATION_RESULT, label: 'Validação email - Result' },
    { key: FieldsTypesKeys.EMAIL_VALIDATION_ROLE, label: 'Validação email - Role' },
    { key: FieldsTypesKeys.EMAIL_VALIDATION_DELIVERABLE, label: 'Validação email - Deliverable' },
  ],
  'Campos personalizados': [
    { key: FieldsTypesKeys.DATE, label: 'Data' },
    { key: FieldsTypesKeys.TIME, label: 'Hora' },
    { key: FieldsTypesKeys.DATE_TIME, label: 'Data e hora' },
    { key: FieldsTypesKeys.NUMBER, label: 'Número' },
    { key: FieldsTypesKeys.TEXT, label: 'Texto' },
  ]
}

const emptyField = () => ({
  configs: {
    source: null
  },
  description: '',
  is_active: false,
  key: '',
  name: '',
  type: null
})

export default {
  name: 'Fields',

  components: {
    Alert,
    AppLayout,
    CButton,
    List,
    Modal,
    Toolbar
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      confirmingDestroy: false,
      loading: false,
      showEditModal: false,
      field: emptyField(),
      fieldsTypes,
      fieldToDestroy: {}
    }
  },

  created () {
    this.unwatch = this.$watch(
      () => this.$route,
      () => load(this, 'fields/loadFields'),
      { immediate: true }
    )
  },

  beforeRouteLeave () {
    this.unwatch()
  },

  computed: mapState(['fields']),

  watch: {
    showEditModal (show) {
      if (!show) {
        this.field = emptyField()
        this.v$.$reset()
      }
    }
  },

  methods: {
    confirmDestroy (field) {
      this.fieldToDestroy = field
      this.confirmingDestroy = true
    },

    async destroy () {
      await this.$store.dispatch('fields/destroyField', this.fieldToDestroy)
      this.fieldToDestroy = {}
      this.confirmingDestroy = false
    },

    edit (field) {
      this.field = obj.copy(field)
      this.showEditModal = true
    },

    async tryToSave () {
      if (!await this.v$.$validate()) return

      await this.$store.dispatch('fields/saveField', this.field)

      this.showEditModal = false
    }
  },

  validations () {
    return {
      field: {
        key: { required },
        name: { required },
        type: { required }
      }
    }
  }
}
</script>
