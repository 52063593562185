import auth from '../services/auth'
import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '../store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes: routes.filter(route => route?.meta?.active !== false)
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(one => one?.meta?.auth)) {
    if (auth.logged() && !store.state.app.loaded) {
      await store.dispatch('loadAppInitialState')
    }

    if (!auth.logged()) {
      next({ name: 'auth.login' })
    } else if (!userCanNavigateTo(to)) {
      next({ name: 'dashboard' })
    } else if (hasNoWorkspaces(to)) {
      next({ name: 'no-workspaces' })
    } else {
      next()
    }
  } else {
    next()
  }
})

function hasNoWorkspaces (to) {
  return to.matched.some(one => isWorkspaced(one.meta) && !store.getters.hasWorkspaces)
}

function isWorkspaced (meta) {
  return meta.workspaced || meta.workspaced === undefined
}

function userCanNavigateTo (to) {
  return to.matched.some(one => auth.userCan(one?.meta?.abilities))
}

export default router
