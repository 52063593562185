<script setup>
import { computed } from 'vue'

import CSelect from '@/components/components/form/Select'
import FileInput from '@/components/components/form/FileInput'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Object })

const data = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <FileInput
    accept="image/svg+xml, image/png, image/jpeg"
    class="mb-2"
    folder="quizzes"
    id="imageAttrUrl"
    label="URL da imagem"
    v-model.trim="data.src"
  />
  <CSelect
    class="mb-2"
    label="Preenchimento"
    :options="[
      { id: 'cover', name: 'Preenchido proporcional' },
      { id: 'contain', name: 'Contido proporcional' },
      { id: 'fill', name: 'Esticar para preencher'}
    ]"
    v-model="data.style.objectFit"
  />
  <CSelect
    class="mb-0"
    label="Enquadramento"
    :options="[
      { id: 'center', name: 'Central' },
      { id: 'top', name: 'Acima' },
      { id: 'right', name: 'À direita' },
      { id: 'bottom', name: 'Abaixo' },
      { id: 'left', name: 'À esquerda' }
    ]"
    v-model="data.style.objectPosition"
  />
</section>
</template>
