import { getApiEndpoint } from '@/support/tenant'
import Repository  from './repository'

class Quizzes extends Repository
{
  abStatistics (options) {
    return this.client.get(this.urlTo('ab-statistics'), options)
  }

  all () {
    return this.client.get(this.urlTo(), { version: '2' })
  }

  conversionsStatistics (options) {
    return this.client.get(this.urlTo('conversions-statistics', false), options)
  }

  deploy (data) {
    return this.client.post(this.urlTo(`${data.id}/deploy`), data)
  }

  destroy (data) {
    return this.client.delete(this.urlTo(), data)
  }

  find (id) {
    return this.client.get(this.urlTo(id))
  }

  save (data) {
    const method = data.id ? 'put' : 'post'

    return this.client[method](this.urlTo(), data)
  }
}

export default new Quizzes({
  base: getApiEndpoint(),
  path: 'quizzes'
})
