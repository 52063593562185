<template>
<AppLayout>
  <section class="layout__main quizzes">
    <div class="container">
      <Toolbar title="Quizzes">
        <template #actions>
          <div class="toolbar__group">
            <router-link
              class="btn btn--secondary"
              :to="{ name: 'quizzes' }"
            >Quizzes v2</router-link>
            <CButton
              caption="Adicionar Quiz"
              class="btn--primary"
              @click="showEditModal = true"
              icon="add"
            />
          </div>
        </template>
      </Toolbar>

      <List
        @copy-it="copy"
        @destroy="confirmDestroy"
        @edit="edit"
        @embed="embed"
        :items="quizzes"
        :loading="loading"
      />
    </div>
  </section>

  <Modal
    @close="showEditModal = false"
    :closeable-by-backdrop="false"
    size="xl"
    :show-close-button="true"
    :title="(quiz.id ? 'Editar' : 'Adicionar') + ' Quiz' + (quiz.id ? ` [#${quiz.id}]` : '')"
    v-if="showEditModal"
  >
    <form
      class="form"
      id="quizModalForm"
      @submit.prevent="tryToSave"
    >
      <fieldset class="form__step" v-if="formStep === 'basic'">
        <Toolbar title="Dados Básicos" />

        <div class="form__fields form__fields--row">
          <div class="input flex--3">
            <label class="input__label" for="quizName">Nome do Quiz</label>
            <input
              class="input__control"
              id="quizName"
              @input="!quiz.id && setKey(quiz.name)"
              type="text"
              v-model.trim="quiz.name"
            />
            <span class="input__error" v-if="v$.quiz.name.$error">Informe o nome do quiz</span>
          </div>
          <div class="input flex--2">
            <label class="input__label" for="quizKey">Resuma o nome em no máximo duas palavras</label>
            <input class="input__control" id="quizKey" type="text" v-model.trim="quiz.key" />
            <span class="input__error" v-if="v$.quiz.key.$error">Informe a chave do quiz</span>
          </div>
        </div>
        <div class="input">
          <label class="input__label" for="quizHeadline">Headline do Quiz</label>
          <input class="input__control" id="quizHeadline" type="text" v-model.trim="quiz.configs.headline" />
        </div>
        <div class="input">
          <label class="input__label" for="quizSubheadline">Subheadline do Quiz</label>
          <input class="input__control" id="quizSubheadline" type="text" v-model.trim="quiz.configs.subheadline" />
        </div>
        <div class="input">
          <label class="input__label" for="quizDurationText">Texto da duração</label>
          <input class="input__control" id="quizDurationText" type="text" v-model.trim="quiz.configs.duration_text" />
        </div>
        <div class="input">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="quiz.configs.has_persistent_header" />
            <span>Exibir cabeçalho em todas as perguntas</span>
          </label>
        </div>
        <div class="input">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="quiz.is_active" />
            <span>Está ativo</span>
          </label>
        </div>
      </fieldset>

      <fieldset class="form__step" v-if="formStep === 'questions'">
        <Toolbar title="Fluxo de Perguntas">
          <template #actions>
            <div class="toolbar__group">
              <CButton
                caption="Adicionar Pergunta"
                class="btn--secondary"
                @click="addQuestion"
                icon="add"
              />
            </div>
          </template>
        </Toolbar>

        <section>
          <Question
            @add-next="addNextTo"
            @down="moveQuestionDown"
            :key="question.id"
            :index="index"
            :last-index="quiz.quiz_questions.length - 1"
            :questions="quiz.quiz_questions"
            @remove="removeQuestion"
            @up="moveQuestionUp"
            v-for="(question, index) in quiz.quiz_questions"
            v-model:question="quiz.quiz_questions[index]"
          />
          <!-- <span class="input__error" v-if="v$.quiz.quiz_questions.$error">Adicione pelo menos 1 pergunta</span> -->
        </section>
      </fieldset>

      <fieldset class="form__step" v-if="formStep === 'gate'">
        <Toolbar title="Coleta do Lead" />

        <div class="input input--column">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="quiz.configs.gate.is_inactive" />
            <span>Inativar coleta do lead</span>
          </label>
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="quiz.configs.gate.is_early" />
            <span>Coletar leads no início</span>
          </label>
        </div>
        <div class="form__fields form__fields--row">
          <div class="input flex--col">
            <label class="input__label" for="quizGateBypassFor">Fazer bypass do gate por</label>
            <div class="input__group flex">
              <input class="input__control" id="quizGateBypassFor" type="text" v-model.number="quiz.configs.gate.bypass_for" />
              <span class="input__control input__control--static">dias</span>
            </div>
          </div>
          <CSelect
            id="quizGateMaxSplitIndex"
            label="Fazer split de leads"
            :options="[
              {
                id: 2,
                name: 'Em 2 contas'
              },
              {
                id: 3,
                name: 'Em 3 contas'
              },
              {
                id: 4,
                name: 'Em 4 contas'
              },
              {
                id: 5,
                name: 'Em 5 contas'
              },
            ]"
            placeholder="Desabilitado"
            v-model="quiz.configs.gate.max_split_index"
          />
        </div>
        <FileInput
          accept="image/svg+xml, image/png, image/jpeg"
          folder="quizzes"
          id="quizThemeGateImage"
          label="URL da imagem do gate"
          v-model.trim="quiz.configs.gate.image"
        />

        <h6 class="mb-4">Cabeçalho e Rodapé</h6>
        <div class="input">
          <label class="input__label" for="quizGateHeadline">Headline do formulário</label>
          <input class="input__control" id="quizGateHeadline" type="text" v-model.trim="quiz.configs.gate.headline" />
        </div>
        <div class="input">
          <label class="input__label" for="quizGateSubheadline">Subheadline do formulário</label>
          <input class="input__control" id="quizGateSubheadline" type="text" v-model.trim="quiz.configs.gate.subheadline" />
        </div>
        <div class="form__fields form__fields--row">
          <div class="input">
            <label class="input__label" for="quizGateFormTitle">Título do formulário</label>
            <input class="input__control" id="quizGateFormTitle" type="text" v-model.trim="quiz.configs.gate.form_title" />
          </div>
          <div class="input">
            <label class="input__label" for="quizGateCtaText">Texto do CTA</label>
            <input class="input__control" id="quizGateCtaText" type="text" v-model.trim="quiz.configs.gate.cta_label" />
          </div>
        </div>

        <h6 class="mb-4">Campo de Nome</h6>
        <div class="input input--column">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="quiz.configs.gate.collect_name" />
            <span>Coletar nome</span>
          </label>
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="quiz.configs.gate.name_required" />
            <span>Nome obrigatório</span>
          </label>
        </div>
        <div class="form__fields form__fields--row">
          <div class="input">
            <label class="input__label" for="quizGateNameLabel">Label do nome</label>
            <input class="input__control" id="quizGateNameLabel" type="text" v-model.trim="quiz.configs.gate.name_label" />
          </div>
          <div class="input">
            <label class="input__label" for="quizGateNamePlaceholder">Placeholder do nome</label>
            <input class="input__control" id="quizGateNamePlaceholder" type="text" v-model.trim="quiz.configs.gate.name_placeholder" />
          </div>
          <div class="input">
            <label class="input__label" for="quizGateNameValidationMessage">Mensagem de validação do nome</label>
            <input class="input__control" id="quizGateNameValidationMessage" type="text" v-model.trim="quiz.configs.gate.name_validation_message" />
          </div>
        </div>

        <h6 class="mb-4">Campo de Email</h6>
        <div class="form__fields form__fields--row">
          <div class="input">
            <label class="input__label" for="quizGateEmailLabel">Label do email</label>
            <input class="input__control" id="quizGateEmailLabel" type="text" v-model.trim="quiz.configs.gate.email_label" />
          </div>
          <div class="input">
            <label class="input__label" for="quizGateEmailPlaceholder">Placeholder do email</label>
            <input class="input__control" id="quizGateEmailPlaceholder" type="text" v-model.trim="quiz.configs.gate.email_placeholder" />
          </div>
          <div class="input">
            <label class="input__label" for="quizGateEmailValidationMessage">Mensagem de validação do email</label>
            <input class="input__control" id="quizGateEmailValidationMessage" type="text" v-model.trim="quiz.configs.gate.email_validation_message" />
          </div>
        </div>
        <div class="input">
          <label class="input__label" for="quizGateEmailDomains">Domínios de email permitidos</label>
          <input class="input__control" id="quizGateEmailDomains" type="text" v-model.trim="quiz.configs.gate.email_domains" />
        </div>

        <h6 class="mb-4 mt-6">Campo de Celular</h6>
        <div class="input input--column">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="quiz.configs.gate.collect_mobile" />
            <span>Coletar celular</span>
          </label>
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="quiz.configs.gate.mobile_required" />
            <span>Celular obrigatório</span>
          </label>
        </div>
        <div class="form__fields form__fields--row">
          <div class="input">
            <label class="input__label" for="quizGateMobileLabel">Label do celular</label>
            <input class="input__control" id="quizGateMobileLabel" type="text" v-model.trim="quiz.configs.gate.mobile_label" />
          </div>
          <div class="input">
            <label class="input__label" for="quizGateMobilePlaceholder">Placeholder do celular</label>
            <input class="input__control" id="quizGateMobilePlaceholder" type="text" v-model.trim="quiz.configs.gate.mobile_placeholder" />
          </div>
        </div>
        <div class="form__fields form__fields--row">
          <div class="input">
            <label class="input__label" for="quizGateMobileMask">Máscara do celular</label>
            <input class="input__control" id="quizGateMobileMask" type="text" v-model.trim="quiz.configs.gate.mobile_mask" />
          </div>
          <div class="input">
            <label class="input__label" for="quizGateMobileValidationMessage">Mensagem de validação do celular</label>
            <input class="input__control" id="quizGateMobileValidationMessage" type="text" v-model.trim="quiz.configs.gate.mobile_validation_message" />
          </div>
        </div>

        <h6 class="mb-4">Aceite de termos e política</h6>
        <div class="form__fields form__fields--row">
          <div class="input flex--2">
            <label class="input__label" for="quizGatePrivacyPolicyText">Texto da política de privacidade</label>
            <input class="input__control" id="quizGatePrivacyPolicyText" type="text" v-model.trim="quiz.configs.gate.privacy_policy_text" />
          </div>
          <div class="input flex--3">
            <label class="input__label" for="quizGatePrivacyPolicyUrl">URL da política de privacidade</label>
            <input class="input__control" id="quizGatePrivacyPolicyUrl" type="text" v-model.trim="quiz.configs.gate.privacy_policy_url" />
          </div>
        </div>
        <div class="form__fields form__fields--row">
          <div class="input flex--2">
            <label class="input__label" for="quizGateTermsCheck">Texto do checkbox de termos de uso</label>
            <input class="input__control" id="quizGateTermsCheck" type="text" v-model.trim="quiz.configs.gate.checkbox_terms_text" />
          </div>
          <div class="input flex--3">
            <label class="input__label" for="quizGateTermsUrl">URL dos termos de uso</label>
            <input class="input__control" id="quizGateTermsUrl" type="text" v-model.trim="quiz.configs.gate.terms_url" />
          </div>
        </div>
        <div class="form__fields form__fields--row">
          <div class="input">
            <label class="input__label" for="quizGateAutoAcceptTerms">Texto de auto aceitação dos termos</label>
            <input class="input__control" id="quizGateAutoAcceptTerms" type="text" v-model.trim="quiz.configs.gate.auto_accept_terms_text" />
          </div>
        </div>
        <div class="form__fields form__fields--row">
          <div class="input">
            <label class="input__label" for="quizGateAcceptTermsValidationMessage">Mensagem de validação do aceite</label>
            <input class="input__control" id="quizGateAcceptTermsValidationMessage" type="text" v-model.trim="quiz.configs.gate.accept_terms_validation_message" />
          </div>
        </div>
      </fieldset>

      <fieldset class="form__step" v-if="formStep === 'ads-wrapper'">
        <Toolbar title="Ads e Wrapper" />

        <div class="input input--column">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="quiz.configs.gate.is_reward_ads_active" />
            <span>Ativar reward ads</span>
          </label>
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="quiz.configs.gate.is_params_bypass_test_active" />
            <span>Ativar teste de bypass de parâmetros</span>
          </label>
        </div>
        <CSelect
          id="quizGateWrapperConfigDomain"
          label="Usar configurações de wrapper do domínio"
          :options="[
            {
              id: 'memivi',
              name: 'Memivi'
            },
            {
              id: 'ecofinanca',
              name: 'Ecofinança'
            },
            {
              id: 'finc4you',
              name: 'Finc4You'
            }
          ]"
          placeholder="Desabilitado"
          v-model="quiz.configs.gate.wrapper_config_domain"
        />
      </fieldset>

      <fieldset class="form__step" v-if="formStep === 'result'">
        <Toolbar title="Páginas de Resultado">
          <template #actions>
            <div class="toolbar__group">
              <CButton
                caption="Adicionar Página"
                class="btn--secondary"
                @click="addPage"
                icon="add"
              />
            </div>
          </template>
        </Toolbar>

        <section>
          <ResultPage
            :facts="pageCriteriasFacts"
            :index="index"
            :key="page.id"
            @remove="removePage"
            v-for="(page, index) in quiz.configs.result_pages"
            v-model:page="quiz.configs.result_pages[index]"
          />
          <span class="input__error" v-if="v$.quiz.configs.result_pages.$error">Adicione pelo menos 1 página</span>
        </section>
      </fieldset>

      <fieldset class="form__step" v-if="formStep === 'theme'">
        <Toolbar title="Tema" />

        <FileInput
          accept="image/svg+xml, image/png, image/jpeg"
          folder="quizzes"
          id="quizThemeLogoUrl"
          label="URL da logo"
          v-model.trim="quiz.configs.theme.logo"
        />
        <div class="form__fields form__fields--row">
          <ColorInput
            id="quizThemeColorText"
            label="Cor do texto"
            v-model.trim="quiz.configs.theme.colors.text"
          />
          <ColorInput
            id="quizThemeColorHeadlines"
            label="Cor dos headlines"
            v-model.trim="quiz.configs.theme.colors.headlines"
          />
          <ColorInput
            id="quizThemeColorSubheadlines"
            label="Cor de destaque dos subheadlines"
            v-model.trim="quiz.configs.theme.colors.subheadlines_destak"
          />
        </div>
        <div class="form__fields form__fields--row">
          <ColorInput
            id="quizThemeColorButtons"
            label="Cor dos botões"
            v-model.trim="quiz.configs.theme.colors.buttons"
          />
          <ColorInput
            id="quizThemeColorButtonsDestak"
            label="Cor dos botões ativos"
            v-model.trim="quiz.configs.theme.colors.buttons_destak"
          />
          <ColorInput
            id="quizThemeColorProgressBar"
            label="Cor da barra de progresso"
            v-model.trim="quiz.configs.theme.colors.progress_bar"
          />
        </div>
        <div class="form__fields form__fields--row">
          <ColorInput
            id="quizThemeColorStep"
            label="Cor do indicador de questão"
            v-model.trim="quiz.configs.theme.colors.step"
          />
          <ColorInput
            id="quizThemeColorDestak"
            label="Cor de destaque"
            v-model.trim="quiz.configs.theme.colors.destak"
          />
          <ColorInput
            id="quizThemeColorFormCta"
            label="Cor do CTA do formulário"
            v-model.trim="quiz.configs.theme.colors.form_cta"
          />
        </div>
        <div class="form__fields form__fields--row">
          <ColorInput
            id="quizThemeColorFormFieldBorder"
            label="Cor da borda dos campos"
            v-model.trim="quiz.configs.theme.colors.form_field_border"
          />
          <ColorInput
            id="quizThemeColorLinks"
            label="Cor dos links"
            v-model.trim="quiz.configs.theme.colors.links"
          />
          <div class="input"></div>
        </div>
        <div class="input">
          <label class="input__label" for="quizThemeCustomCss">CSS personalizado</label>
          <textarea class="input__control quiz__custom-css" id="quizThemeCustomCss" v-model.trim="quiz.configs.theme.custom_css"></textarea>
        </div>
      </fieldset>
    </form>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="showEditModal = false"
        />
        <CButton
          caption="Anterior"
          class="btn--secondary"
          @click="toPreviousStep"
          icon="navigate_before"
          v-if="formStep !== 'basic'"
        />
        <CButton
          caption="Próximo"
          class="btn--secondary"
          @click="toNextStep"
          icon="navigate_next"
          v-if="formStep !== 'theme'"
        />
        <a
          class="btn btn--secondary"
          :href="previewUrl"
          target="_blank"
          v-if="quiz.id"
        >
          <span class="icon icon--prefix">preview</span>
          Preview
        </a>
        <CButton
          caption="Salvar"
          class="btn--primary"
          form="quizModalForm"
          icon="save"
          type="submit"
          v-if="formStep === 'theme' || !!quiz.id"
        />
      </footer>
    </template>
  </Modal>

  <Modal
    @close="showEmbedModal = false"
    :closeable-by-backdrop="false"
    size="xl"
    title="Veicular Quiz"
    v-if="showEmbedModal"
  >
    <section class="form">
      <fieldset>
        <Toolbar title="Página personalizada" />

        <p>Use o template abaixo como base para sua página:</p>
        <pre class="code"><code>{{ fullPageTemplate }}</code></pre>
        <p>Você também pode adicionar apenas o código abaixo no head da sua página:</p>
        <pre class="code"><code>{{ headTemplate }}</code></pre>
        <p>E adicionar o código abaixo no body da sua página:</p>
        <pre class="code"><code>{{ bodyTemplate }}</code></pre>
      </fieldset>
    </section>
  </Modal>

  <Modal
    @close="confirmingDestroy = false"
    title="Confirmação"
    v-if="confirmingDestroy"
  >
    <template #default>
      <p>Tem certeza que deseja apagar o quiz <strong>{{ quizToDestroy.name }}</strong>?</p>
      <Alert
        class="alert--info alert--ghost"
        icon="info"
        title="Apagar um quiz irá removê-lo de todos os lugares onde está veiculado."
      />
    </template>

    <template #footer>
      <footer class="modal__footer">
        <CButton
          caption="Cancelar"
          @click="confirmingDestroy = false"
        />
        <CButton
          caption="Apagar"
          class="btn--danger"
          @click="destroy"
          icon="delete"
        />
      </footer>
    </template>
  </Modal>
</AppLayout>
</template>

<script>
import Alert from '@/components/Alert'
import AppLayout from '@/components/layout/App'
import CButton from '@/components/CButton'
import ColorInput from '@/components/components/form/ColorInput'
import CSelect from '@/components/components/form/Select'
import FileInput from '@/components/components/form/FileInput'
import groupBy from 'lodash/groupBy'
import List from './quizzes/List'
import { mapState } from 'vuex'
import { minLength, required } from '@vuelidate/validators'
import Modal from '@/components/Modal'
import * as obj from '@/support/object'
import Question from './components/quizzes/Question'
import ResultPage from './components/quizzes/ResultPage'
import { Templates } from '@/support/templates'
import Toolbar from '@/components/Toolbar'
import { useVuelidate } from '@vuelidate/core'
import slugify from 'slugify'

const emptyPage = () => ({
  id: (new Date()).getTime(),
  rules: [],
  url: ''
})

const emptyQuestion = (order = null) => ({
  configs: {
    hide_image: false
  },
  id: (new Date()).getTime(),
  next: [],
  order,
  quiz_id: null,
  quiz_question_id: null,
})

const emptyQuiz = () => ({
  configs: {
    gate: {
      email_domains: 'gmail.com, hotmail.com, outlook.com, yahoo.com.br',
      max_split_index: null,
      mobile_mask: '(##) #####-####',
      wrapper_config_domain: null
    },
    result_pages: [],
    theme: {
      colors: {}
    }
  },
  id: null,
  is_active: false,
  key: '',
  name: '',
  quiz_questions: [emptyQuestion(1)]
})

export default {
  name: 'Quizzes',

  components: {
    Alert,
    AppLayout,
    CButton,
    ColorInput,
    CSelect,
    FileInput,
    List,
    Modal,
    Question,
    ResultPage,
    Toolbar
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      confirmingDestroy: false,
      formStep: 'basic',
      loading: false,
      showEditModal: false,
      showEmbedModal: false,
      quiz: emptyQuiz(),
      quizToDestroy: {}
    }
  },

  created () {
    this.unwatch = this.$watch(
      () => this.$route,
      async () => {
        this.loading = true
        await this.$store.dispatch('loadQuizzes')
        this.loading = false
        this.$store.dispatch('loadQuizQuestions')
      },
      { immediate: true }
    )
  },

  beforeRouteLeave () {
    this.unwatch()
  },

  computed: {
    ...mapState(['apiEndpoint', 'bucketEndpoint', 'quizQuestions']),

    // @todo back to mapState
    quizzes () {
      return this.$store.state.quizzes.filter(quiz => quiz.configs?.version != 2)
    },

    bodyTemplate () {
      return Templates.QUIZ_BODY
        .replace(/BUCKET_ENPOINT/g, this.bucketEndpoint)
        .replace(/QUIZ_URL/g, `${this.apiEndpoint}quiz/${this.quiz.key}`)
    },

    headTemplate () {
      return Templates.QUIZ_HEAD.replace(/BUCKET_ENPOINT/g, this.bucketEndpoint)
    },

    fullPageTemplate () {
      return Templates.QUIZ_FULL_PAGE
        .replace(/BUCKET_ENPOINT/g, this.bucketEndpoint)
        .replace(/QUIZ_URL/g, `${this.apiEndpoint}quiz/${this.quiz.key}`)
    },

    pageCriteriasFacts () {
      const facts = this.pageCriteriasQuestionsFacts

      facts.push({
        group: 'Automático',
        label: 'Percentual de distribuição',
        path: 'split_percentage',
        type: 'number'
      })

      return groupBy(facts, 'group')
    },

    pageCriteriasQuestionsFacts () {
      return this.quiz.quiz_questions
        .filter(quizQuestion => !!quizQuestion.quiz_question_id)
        .map(quizQuestion => {
          const question = this.findQuestion(quizQuestion.quiz_question_id)

          return {
            group: 'Perguntas',
            label: question.question,
            options: question.response_options,
            path: question.configs.response_key,
            type: 'string'
          }
        })
    },

    previewUrl () {
      return [
        this.$store.state.apiEndpoint + this.quiz.workspace_id,
        'quizzes',
        this.quiz.id,
        'preview?quiz-mode=preview'
      ].join('/')
    }
  },

  methods: {
    addNextTo (question) {
      console.log(question)
      this.addQuestion()
    },

    addPage () {
      this.quiz.configs.result_pages.push(emptyPage())
    },

    addQuestion () {
      this.quiz.quiz_questions.push(emptyQuestion(this.quiz.quiz_questions.length + 1))
    },

    confirmDestroy (quiz) {
      this.quizToDestroy = quiz
      this.confirmingDestroy = true
    },

    copy (quiz) {
      this.edit(quiz)
      this.quiz.id = null
    },

    async destroy () {
      await this.$store.dispatch('destroyQuiz', this.quizToDestroy)
      this.quizToDestroy = {}
      this.confirmingDestroy = false
    },

    edit (quiz) {
      this.quiz = obj.copy(quiz)
      this.quiz.configs.gate.max_split_index ??= null
      this.quiz.configs.gate.wrapper_config_domain ??= null
      this.showEditModal = true
    },

    embed (quiz) {
      this.quiz = obj.copy(quiz)
      this.showEmbedModal = true
    },

    findQuestion (id) {
      return this.quizQuestions.find(question => question.id === id)
    },

    moveQuestion (direction, question) {
      const index = this.quiz.quiz_questions.findIndex(one => one.id === question.id)
      const offset = direction === 'up' ? -1 : 1
      const shouldReturn = (direction === 'up' && index <= 0)
        || (direction === 'down' && index >= this.quiz.quiz_questions.length - 1)

      if (shouldReturn) {
        return
      }

      this.quiz.quiz_questions.splice(
        index + offset,
        0,
        ...this.quiz.quiz_questions.splice(index, 1)
      )
      this.reorder()
    },

    moveQuestionUp (question) {
      this.moveQuestion('up', question)
    },

    moveQuestionDown (question) {
      this.moveQuestion('down', question)
    },

    removePage (page) {
      this.quiz.configs.result_pages.splice(
        this.quiz.configs.result_pages.findIndex(one => one === page),
        1
      )
    },

    removeQuestion (question) {
      this.quiz.quiz_questions.splice(
        this.quiz.quiz_questions.findIndex(one => one === question),
        1
      )
    },

    reorder () {
      this.quiz.quiz_questions.forEach((question, index) => { question.order = index + 1 })
    },

    setKey (key) {
      this.quiz.key = slugify(key, { lower: true, strict: true })
    },

    toNextStep () {
      const steps = ['basic', 'questions', 'gate', 'ads-wrapper', 'result', 'theme']

      this.formStep = steps[steps.findIndex(step => step === this.formStep) + 1]
    },

    toPreviousStep () {
      const steps = ['basic', 'questions', 'gate', 'ads-wrapper', 'result', 'theme']

      this.formStep = steps[steps.findIndex(step => step === this.formStep) - 1]
    },

    async tryToSave () {
      if (!await this.v$.$validate()) return

      await this.$store.dispatch('saveQuiz', this.quiz)

      this.showEditModal = false
    },

    updateQuestion (question) {
      this.quiz.quiz_questions.splice(
        this.quiz.quiz_questions.findIndex(one => one.id === question.id),
        1,
        question
      )
    }
  },

  validations () {
    return {
      quiz: {
        key: { required },
        name: { required, minLength: minLength(2) },
        // quiz_questions: { required },
        configs: { result_pages: { required } }
      }
    }
  },

  watch: {
    showEditModal (show) {
      if (!show) {
        this.formStep = 'basic'
        this.quiz = emptyQuiz()
        this.v$.$reset()
      }
    }
  }
}
</script>

<style lang="scss">
.form__step {
  .toolbar__title {
    font-size: var(--h5-size);
  }
}

.code {
  background: #f9f9f9;
  display: block;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.85rem;
  font-weight: 400;
  margin-bottom: 1.5rem;

  code {
    display: block;
    overflow: auto;
    padding: 0.75rem;

    &::-webkit-scrollbar {
      background: var(--color-gray-lighter);
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-black-lighter);
      border-radius: 1px;
    }
  }
}

.quiz__custom-css {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.85rem;
  font-weight: 600;
  min-height: 100px;
}
</style>
