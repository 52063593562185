<script setup>
import { computed } from 'vue'
import { buildQuizQuestions, setStore } from '../support.js'
import { useEditor } from '../editor/editor.js'
import { useStore } from 'vuex'

import CInput from '@/components/components/form/Input'
import CSelect from '@/components/components/form/Select'

const props = defineProps({ questions: Array })

const { selected } = useEditor()
const store = useStore()

setStore(store)

const bind = computed({
  get () { return selected.value.configs.bind },
  set (value) {
    selected.value.configs.bind = value
    selected.value.configs.question = props.questions.find(one => one.id === value)

    buildQuizQuestions()
  }
})
</script>

<template>
<section class="quiz-builder__attrs-collapsible-section">
  <CSelect
    class="mb-2"
    label="Linkar com pergunta"
    option-label-prop="question"
    :options="questions"
    v-model.number="bind"
  />
  <CSelect
    class="mb-2"
    label="Mostrar imagem da pergunta"
    :options="[
      { id: -1, name: 'Acima do enunciado' },
      { id: 0, name: 'Abaixo do enunciado' }
    ]"
    v-model.number="selected.configs.imagePosition"
  />
  <CSelect
    class="mb-2"
    label="Mostrar respostas em"
    :options="[
      { id: 1, name: '1 coluna' },
      { id: 2, name: '2 colunas' },
      { id: 3, name: '3 colunas' },
      { id: 4, name: '4 colunas' }
    ]"
    v-model.number="selected.configs.columns"
  />
  <CInput
    class="mb-0"
    label="Texto de botão de próximo"
    v-model="selected.configs.nextText"
  />
</section>
</template>
