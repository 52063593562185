import { getApiEndpoint } from '@/support/tenant'
import Repository  from './repository'

class PushNotifications extends Repository
{
  all () {
    return this.client.get(this.urlTo())
  }

  allStatistics (options) {
    return this.client.get(this.urlTo('statistics', false), options)
  }

  destroy (data) {
    return this.client.delete(this.urlTo(), data)
  }

  find (id) {
    return this.client.get(this.urlTo(id))
  }

  save (data) {
    const method = data.id ? 'put' : 'post'

    return this.client[method](this.urlTo(), data)
  }

  statistics (id) {
    return this.client.get(this.urlTo(id + '/statistics'))
  }
}

export default new PushNotifications({
  base: getApiEndpoint(),
  path: 'push-notifications'
})
