<template>
<section class="quiz__steps">
  <slot />
</section>
</template>

<style>
.quiz__steps {
  margin-bottom: 2rem;
}
</style>
