<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'

import AppLayout from '@/components/layout/App'
import CConfirm from '@/components/components/dialogs/Confirm'
import List from './List'
import Form from './Form'
import StatisticsTable from './StatisticsTable'
import Toolbar from '@/components/Toolbar'

const store = useStore()
const loading = ref(true)
const smsPlatformAccount = ref(null)
const toDestroy = ref(null)

const smsPlatformAccounts = computed(() => store.state.smsPlatformAccounts.smsPlatformAccounts)
const confirmingDestroy = computed(() => !!toDestroy.value)
const editing = computed(() => !!smsPlatformAccount.value)
const shouldShowRulersStatistics = computed(() =>
  smsPlatformAccounts.value.length
  && smsPlatformAccounts.value.some(acc => acc.platform === 'Twilio')
)

const confirmDestroy = smsPlatformAccount => toDestroy.value = smsPlatformAccount

const destroy = async () => {
  await store.dispatch('smsPlatformAccounts/destroySmsPlatformAccount', toDestroy.value)
  toDestroy.value = null
}

const edit = (item = {}) => smsPlatformAccount.value = item

onBeforeMount(async () => {
  await store.dispatch('smsPlatformAccounts/loadSmsPlatformAccounts')
  loading.value = false
})
</script>

<template>
<AppLayout>
  <section class="layout__main sms-platform-accounts">
    <div class="container">
      <Toolbar
        :has-primary-action="true"
        @primary-action="edit()"
        primary-action-caption="Adicionar Conta"
        primary-action-icon="add"
        title="Contas de SMS"
      />

      <StatisticsTable
        class="mb-8"
        v-if="shouldShowRulersStatistics"
      />

      <List
        @destroy="confirmDestroy"
        @edit="edit"
        :items="smsPlatformAccounts"
        :loading="loading"
      />
    </div>
  </section>

  <Form
    @close="smsPlatformAccount = null"
    :item="smsPlatformAccount"
    size="xxl"
    v-if="editing"
  />

  <CConfirm
    alert="Apagar uma conta é uma ação crítica e irá apagar todos os dados relacionados a ela."
    @cancel="toDestroy = null"
    @confirmed="destroy()"
    v-if="confirmingDestroy"
  >
    <p>Tem certeza que deseja apagar a conta <strong>{{ toDestroy.name }}</strong>?</p>
  </CConfirm>
</AppLayout>
</template>
