import fields from '@/repositories/fields'

export default {
  namespaced: true,

  state: () => ({
    fields: []
  }),

  mutations: {
    addField (state, { item }) {
      state.fields.push(item)
    },

    removeField (state, { item }) {
      state.fields.splice(state.fields.findIndex(one => one === item), 1)
    },

    replaceField (state, { item, index }) {
      state.fields.splice(index, 1, item)
    },

    setFields (state, payload) {
      state.fields = payload
    }
  },

  actions: {
    async destroyField ({ commit }, data) {
      await fields.destroy(data)
      commit('removeField', { item: data })
    },

    async loadFields ({ commit }) {
      commit('setFields', await fields.all())
    },

    async saveField ({ commit, state }, data) {
      const item = await fields.save(data)
      const index = state.fields.findIndex(one => one.id === item.id)

      commit(index >= 0 ? 'replaceField' : 'addField', { item, index })

      return item
    }
  }
}
