<template>
<CModal
  @close="close"
  :show-close-button="true"
  size="lg"
  :title="account.id ? `Editar Subconta [#${account.id}]` : 'Adicionar Subconta'"
>
  <form
    class="form"
    id="accountModalForm"
    @submit.prevent="tryToSave"
  >
    <fieldset>
      <div class="form__fields form__fields--row">
        <div class="input flex--2">
          <label class="input__label" for="accountName">Nome da subconta</label>
          <input class="input__control" id="accountName" type="text" v-model.trim="account.name" />
          <span class="input__error" v-if="v$.name.$error">Informe o nome da subconta</span>
        </div>
        <div class="input input--no-label">
          <label class="input__switch nowrap">
            <input type="checkbox" v-model="account.is_active" />
            <span>Está ativa</span>
          </label>
        </div>
      </div>
    </fieldset>
  </form>

  <template #footer>
    <footer class="modal__footer">
      <CButton
        caption="Cancelar"
        @click="close"
      />
      <CButton
        caption="Salvar"
        class="btn--primary"
        form="accountModalForm"
        icon="save"
        type="submit"
      />
    </footer>
  </template>
</CModal>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { required } from '@vuelidate/validators'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import * as obj from '@/support/object'

import CButton from '@/components/CButton'
import CModal from '@/components/Modal'

const emit = defineEmits(['close'])
const props = defineProps({ item: Object })

const emptyAccount = () => ({
  id: null,
  is_active: false,
  name: ''
})

const store = useStore()
const rules = computed(() => ({ name: { required } }))
const account = ref(emptyAccount())
const v$ = useVuelidate(rules, account)
const mainAccount = computed(() => store.getters['accounts/mainAccount'])

const close = () => {
  account.value = emptyAccount()

  v$.value.$reset()
  emit('close')
}

const tryToSave = async () => {
  if (!await v$.value.$validate()) return

  account.value.parent_id = mainAccount.value.id

  await store.dispatch('accounts/saveAccount', account.value)
  close()
}

watch(
  () => props.item,
  item => account.value = obj.isEmpty(item) ? emptyAccount() : { users: [], ...obj.copy(item) },
  { immediate: true }
)
</script>
