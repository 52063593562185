const DefaultMessages = {
  500: 'Um erro não idenficado ocorreu no servidor. Nosso helpdesk já foi notificado.',
  400: 'A requisição recebida é inválida. Nosso helpdesk já foi notificado.',
  401: 'A autenticação falhou. Você está logado?',
  403: 'Seu acesso ao recurso não está autorizado.',
  404: 'O recurso solicitado não exite mais.',
  422: 'Os dados enviados não foram validados corretamente. Por favor revise os dados.'
}

function makeDefaultOptions (data, method = 'post') {
  const isFormData = data instanceof FormData
  const options = {
    body: isFormData ? data : JSON.stringify(data),
    credentials: 'include',
    method
  }

  if (!isFormData) {
    options.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  return options
}

function interceptError (error) {
  const info = {
    ...error,
    originalMessage: error.message,
    message: DefaultMessages[error.status] || DefaultMessages['500']
  }

  client.interceptors.response.map(interceptor => interceptor(info))

  throw error
}

function interceptHttpError (response) {
  if (response.ok) return

  const error = new Error(`HTTP error: status ${response.status}`)
  error.status = response.status
  error.content = response

  throw error
}

function objectToQueryString (params) {
  return Object.keys(params)
    .map(key => key + '=' + encodeURIComponent(params[key]))
    .join('&')
}

async function parseJsonResponse (response) {
  try {
    return await response.json()
  } catch (error) {
    return null
  }
}

const client = {
  interceptors: {
    response: []
  },

  delete (url, data = {}) {
    if (data.id) {
      url += '/' + data.id
    }

    return fetch(url, makeDefaultOptions(data, 'delete'))
      .then(response => {
        interceptHttpError(response)
      })
      .catch(interceptError)
  },

  get (url, data = {}) {
    const queryString = objectToQueryString(data)
    url += queryString ? '?' + queryString : ''

    return fetch(url, { credentials: 'include' })
      .then(response => {
        interceptHttpError(response)

        return parseJsonResponse(response)
      })
      .catch(interceptError)
  },

  post (url, data = {}) {
    return fetch(url, makeDefaultOptions(data, 'post'))
      .then(response => {
        interceptHttpError(response)

        return parseJsonResponse(response)
      })
      .catch(interceptError)
  },

  put (url, data = {}) {
    url += '/' + data.id

    return fetch(url, makeDefaultOptions(data, 'put'))
      .then(response => {
        interceptHttpError(response)

        return parseJsonResponse(response)
      })
      .catch(interceptError)
  }
}

export default client
