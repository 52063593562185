<template>
<transition name="modal-transition">
  <div
    class="modal-overlay"
    @click.self="closeableByBackdrop && emit('close')"
  >
    <section
      :class="modalClasses"
      class="card modal"
    >
      <slot name="modal">
        <slot name="header">
          <header class="modal__header">
            <h3 class="modal__title">{{ title }}</h3>
            <CButton
              class="btn--icon btn--fit btn--ghost modal__close"
              @click="emit('close')"
              icon="close"
              v-if="showCloseButton"
            />
          </header>
        </slot>
        <slot name="body">
          <section class="modal__body">
            <slot />
          </section>
        </slot>
        <slot name="footer">
          <footer class="modal__footer">
            <CButton
              caption="Ok"
              class="btn--primary"
              @click="emit('close')"
            />
          </footer>
        </slot>
      </slot>
    </section>
  </div>
</transition>
</template>

<script setup>
import { computed } from 'vue'

import CButton from './CButton'

const emit = defineEmits(['close'])

const props = defineProps({
  closeableByBackdrop: {
    default: true,
    type: Boolean
  },
  showCloseButton: Boolean,
  size: String,
  title: String
})

const modalClasses = computed(() => [props.size ? `modal--${props.size}` : ''])
</script>

<style lang="scss">
.modal__title {
  color: var(--color-primary);
}

.modal-transition-enter {
  opacity: 0;
}

.modal-transition-leave-active {
  opacity: 0;
}

.modal-transition-enter .modal,
.modal-transition-leave-active .modal {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
